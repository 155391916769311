import { Box, Button, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { MGAnimatedIcon } from '../MGAnimatedIcon/MGAnimatedIcon';
import { MGModal } from '../MGModal';
import { MGConfirmModalOptions } from './MGConfirmModal';
import { MGConfirmModalComponentInput } from './MGConfirmModal.Component.Input';

type CustomFormValues = { key: string; value: string }[];

export type ConfirmArgs = {
  customFormValues: CustomFormValues;
  value?: string | null;
};
interface MGConfirmModalComponentProps {
  open: boolean;
  handleConfirm: ({ value, customFormValues }: ConfirmArgs) => void;
  handleCancel: () => void;
  options: MGConfirmModalOptions;
}

export const MGConfirmModalComponent = ({
  open,
  handleConfirm,
  handleCancel,
  options,
}: MGConfirmModalComponentProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  if (!open || !options) return null;

  const handleConfirmClick = () => {
    if (formRef.current) {
      const inputFields = formRef.current.getElementsByTagName('input');
      const formValues = Array.from(inputFields).map((input: HTMLInputElement) => ({
        key: input.name,
        value: input.value,
      }));
      handleConfirm({ value: inputValue, customFormValues: formValues });
    }
    handleConfirm({ customFormValues: [], value: inputValue });
  };

  return ReactDOM.createPortal(
    <MGModal open={open} handleToggle={handleCancel} PaperStyle={{ paddingTop: 0 }}>
      <Stack alignItems={'center'} spacing={0} paddingX={4} paddingY={1} sx={{ maxWidth: '512px' }}>
        <MGAnimatedIcon type={options.type} />
        <Stack width={'100%'} spacing={4}>
          <Stack alignItems={'center'} textAlign={'center'} spacing={2}>
            <Typography variant={'h3'}>{options.title}</Typography>
            {options.text && <Typography variant={'regular'}>{options.text}</Typography>}
          </Stack>

          {options.inputType && (
            <Box display={'grid'}>
              <MGConfirmModalComponentInput
                input={options.inputType}
                inputLabel={options.inputLabel}
                value={inputValue}
                onChange={setInputValue}
              />
            </Box>
          )}

          {options.customContent && <form ref={formRef}>{options.customContent}</form>}

          {options.hideCancelButton && options.hideConfirmButton ? null : (
            <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'center'}>
              {!options.hideCancelButton && (
                <Button
                  variant={'contained'}
                  color={'white'}
                  onClick={handleCancel}
                  endIcon={options.cancelButtonIcon}
                >
                  {options.cancelButtonText || 'Abbrechen'}
                </Button>
              )}
              {!options.hideConfirmButton && (
                <Button
                  variant={'contained'}
                  color={options.type}
                  onClick={handleConfirmClick}
                  endIcon={options.confirmButtonIcon}
                  disabled={options.inputType && !inputValue}
                >
                  {options.confirmButtonText || 'Bestätigen'}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </MGModal>,
    document.body,
  );
};
