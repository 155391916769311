import { MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { presentDateTime } from '@mg/ui/src/presenters/date.ts';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../i18n';
import { presentKWh } from '../../../../presenters/number';
import { MGTooltip } from '../../../shared/MGTooltip';
import { RemoteReadingStatusIcon } from './RemoteReadingStatusIcon';

type Props = {
  measuring_point: MeasuringPointSchema;
  obis_code: string;
};
export const LastReading = ({ measuring_point, obis_code }: Props) => {
  const { t } = useTranslation('latestReadings');

  if (measuring_point.last_reading == null) throw new Error('No last reading included');

  const last_reading = measuring_point.last_reading.find((reading) => reading.obis_code === obis_code);

  const remote_reading_status = measuring_point.remote_reading_status.find(
    (status) => status.obis_code === obis_code,
  );

  return (
    <>
      <RemoteReadingStatusIcon
        obis_code={obis_code}
        measuring_point={measuring_point}
        remote_reading_status={remote_reading_status}
      />
      <Typography variant={'small'} sx={{ marginLeft: '10px' }}>
        {last_reading ? (
          <MGTooltip
            title={t(`${measuring_point.type}_${obis_code}`, { nsSeparator: false })}
            text={
              <Stack>
                <span>
                  {t('reading_at', {
                    date: presentDateTime(last_reading.timestamp),
                  })}
                </span>
                {measuring_point.conversion_factor != 1 && (
                  <span>
                    {t('calculated_with_conversion_factor', {
                      factor: measuring_point.conversion_factor,
                    })}
                  </span>
                )}
              </Stack>
            }
          >
            <span>{presentKWh(last_reading.value * (measuring_point.conversion_factor || 1))}</span>
          </MGTooltip>
        ) : (
          t('no_data')
        )}
      </Typography>
    </>
  );
};
