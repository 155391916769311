import { z } from 'zod'

export const getPaymentPlanPdfsAsZipQueryParamsSchema = z.object({
  document_type: z.literal('payment_plan').default('payment_plan'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  start_date_from: z.union([z.string(), z.null()]).optional(),
  start_date_to: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getPaymentPlanPdfsAsZip200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getPaymentPlanPdfsAsZip400Schema = z.string()

export const getPaymentPlanPdfsAsZipQueryResponseSchema = z.lazy(() => getPaymentPlanPdfsAsZip200Schema)