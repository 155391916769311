import { useQueries } from '@tanstack/react-query';
import { BillingPreflightIn, getBillingPreflightDataQueryOptions } from '../api-internal';

export const usePreflightBillingQueries = (params: BillingPreflightIn[]) => {
  return useQueries({
    queries: params.map((param) => ({
      ...getBillingPreflightDataQueryOptions(param),
      staleTime: 0,
      gcTime: 0,
      cacheTime: 0,
    })),
  });
};
