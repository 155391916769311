import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { z } from 'zod'

export const measuringPointSubscriptionSchemaSchema = z.object({
  cancel_date: z.union([z.string().date(), z.null()]).describe('The date when the subscription was canceled.').optional(),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  ended_at: z.union([z.string().date(), z.null()]).describe('The date when the subscription ended.').optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  start_date: z.string().date().describe('The starting date for the subscription.'),
  state: z.string().max(12).default('inactive').describe('The state the subscription is in.'),
})