import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ProvideDocumentContext } from '../../features/documents/document-context/ProvideDocumentContext';

import { DocumentContext } from '../../features/documents/document-context/common';

type DocumentsRowActionProps = PropsWithChildren<DocumentContext>;
export const DocumentsRowAction = (props: DocumentsRowActionProps) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" height="100%">
      <ProvideDocumentContext {...props} />
    </Stack>
  );
};
