import { palette } from '@mg/ui/src/styles';
import { Link } from '@mui/material';
import { useAtom } from 'jotai';
import { ArrowUpRight } from 'lucide-react';
import { useUserInfo } from '../../helpers/userInfo';
import { useTranslation } from '../../i18n';
import { currentProjectIdAtom } from '../../jotai/atoms';

export const FeedbackLinkButton = () => {
  const [currentProjectId] = useAtom(currentProjectIdAtom);
  const { t } = useTranslation('sidebar');
  const [user] = useUserInfo();

  const url =
    `https://landingpages.metergrid.de/nutzerumfrage?projectId=${currentProjectId}` +
    (user ? `&customerId=${user.id}` : '');

  return (
    <Link
      variant="button"
      href={url}
      target="_blank"
      rel="noopener"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1.5,
        flexShrink: 0,
        py: 1,
        px: 1.8,
        mb: 1,

        borderRadius: '8px',
        background: palette.primary.light,
        boxShadow: '0px 0px 30px 0px rgba(7, 45, 180, 0.10)',
        transition: 'border 0.15s ease',
        border: '1px solid transparent',

        color: '#0032A0',
        fontWeight: '450',
        lineHeight: '24px' /* 150% */,
        letterSpacing: '-0.16px',
        textDecoration: 'none',
        userSelect: 'none',

        ':hover': {
          border: '1px solid #0069E3',
        },
      }}
    >
      <ArrowUpRight color={'#0032A0'} size={20} />
      {t('feedback_btn_label')}
    </Link>
  );
};
