import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { useTranslation } from '../../../i18n';
import noDataLottie from '../../../lottie/no-data.json';

export const DataGridCardNoRowsOverlay = ({ message = null }) => {
  const { t } = useTranslation('shared');
  return (
    <Box display={'flex'} height="100%" alignItems="center" justifyContent="center">
      <Box width={'200px'} height={'200px'} textAlign={'center'} marginBottom={'40px'}>
        <Lottie animationData={noDataLottie} loop={false} />
        <Box position={'relative'}>
          <Box sx={{ position: 'absolute', width: '200px', bottom: 0 }}>
            <Typography variant={'extraSmall'}>{message || t('no_data_available')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
