import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { SettleTransactionsMutationRequest, SettleTransactionsMutationResponse, SettleTransactions422 } from '../types/SettleTransactions.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const settleTransactionsMutationKey = () => [{ url: '/api-internal/transactions/settle' }] as const

export type SettleTransactionsMutationKey = ReturnType<typeof settleTransactionsMutationKey>

/**
 * @summary Settle Transactions
 * {@link /api-internal/transactions/settle}
 */
async function settleTransactions(data?: SettleTransactionsMutationRequest, config: Partial<RequestConfig<SettleTransactionsMutationRequest>> = {}) {
  const res = await client<SettleTransactionsMutationResponse, SettleTransactions422, SettleTransactionsMutationRequest>({
    method: 'POST',
    url: `/api-internal/transactions/settle`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Settle Transactions
 * {@link /api-internal/transactions/settle}
 */
export function useSettleTransactions(
  options: {
    mutation?: UseMutationOptions<SettleTransactionsMutationResponse, SettleTransactions422, { data?: SettleTransactionsMutationRequest }>
    client?: Partial<RequestConfig<SettleTransactionsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? settleTransactionsMutationKey()

  return useMutation<SettleTransactionsMutationResponse, SettleTransactions422, { data?: SettleTransactionsMutationRequest }>({
    mutationFn: async ({ data }) => {
      return settleTransactions(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}