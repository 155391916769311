import { z } from 'zod'

export const getInvoicePdfsAsZipQueryParamsSchema = z.object({
  document_type: z.literal('invoice').default('invoice'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  category: z.union([z.enum(['regular invoice', 'cancellation invoice']), z.null()]).optional(),
  state: z.union([z.enum(['issued', 'canceled']), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  issue_date_from: z.union([z.string().datetime(), z.null()]).optional(),
  issue_date_to: z.union([z.string().datetime(), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getInvoicePdfsAsZip200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getInvoicePdfsAsZip400Schema = z.string()

export const getInvoicePdfsAsZipQueryResponseSchema = z.lazy(() => getInvoicePdfsAsZip200Schema)