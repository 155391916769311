import { z } from 'zod'

export const measuringPointPanelNestedSchemaSchema = z.object({
  id: z.union([z.number().int(), z.null()]).optional(),
  malo_id: z.union([z.string(), z.null()]).describe('The Market Location Identification Number for the panel').optional(),
  melo_id: z.union([z.string(), z.null()]).describe('The Measuring Location Identification Number for the panel').optional(),
  name: z.string().max(255).describe('Unique identifier of the panel'),
  project: z.number().int().describe('The project where the measuring point panel is located'),
  should_be_renamed: z.boolean().default(false).describe('Indicates if the panel should be renamed in the first onboarding step'),
  type: z.string().max(64).default('SINK'),
})