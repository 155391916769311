import {
  MeasuringPointDataSchema,
  MissingDataOut,
  useCreateMeasuringPointData,
} from '@mg/api-wrappers/src/api-internal';
import { modelValidationErrorsForLocation } from '@mg/api-wrappers/src/error-handling';
import { MGTooltip } from '@mg/ui/src/components/MGTooltip';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { ClickAwayListener, FormControl, IconButton, OutlinedInput, Stack } from '@mui/material';
import { Check } from 'lucide-react';
import { FormEventHandler, useMemo, useState } from 'react';
import {
  parseNumberInput,
  sanitizeNumberInput,
  validateNumberInputNonempty,
} from '../../../../../helpers/number-input';
import { useTranslation } from '../../../../../i18n';
import { BillingMeteringDataData } from './Billing.MeteringData.Data';

type BillingMeteringDataMissingDataProps = {
  missingData: MissingDataOut;
  timestamp: string;
};
export const BillingMeteringDataMissingData = ({
  missingData,
  timestamp,
}: BillingMeteringDataMissingDataProps) => {
  const { t, tString } = useTranslation('billing');
  const [value, setValue] = useState('');
  const [createdData, setCreatedData] = useState<MeasuringPointDataSchema | null>(null);
  const createMutation = useCreateMeasuringPointData({ mutation: { onError: () => setIsTooltipOpen(true) } });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const formValid = validateNumberInputNonempty(value);

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!missingData.measuring_point?.id) {
      throw new Error('Missing measuring point');
    }
    if (!missingData.obis_code) {
      throw new Error('Missing obis code');
    }

    createMutation.mutate(
      {
        data: {
          measuring_point_id: missingData.measuring_point.id,
          obis_code: missingData.obis_code,
          timestamp: timestamp,
          value: parseNumberInput(value),
          for_billing: true,
        },
      },
      {
        onSuccess: setCreatedData,
      },
    );
  };

  const errorText = useMemo(() => {
    const error = createMutation.error;
    if (!error) return null;

    switch (error.type) {
      case 'https://metergrid.de/api-errors/public-errors': {
        for (const pubError of error.errors) {
          if (pubError.code === 'DATA_NOT_MONOTONICALLY_INCREASING') {
            return t(
              pubError.conflicting_timestamp < timestamp
                ? 'missing_data_error_value_lower_than_preceding'
                : 'missing_data_error_value_higher_than_following',
              {
                conflictingTimestamp: presentDate(pubError.conflicting_timestamp),
                conflictingValue: presentKwh(pubError.conflicting_value),
              },
            );
          }
        }
        return error.errors[0].message;
      }
      case 'https://metergrid.de/api-errors/model-validation-error': {
        const valueErrors = modelValidationErrorsForLocation(error, ['value']);
        if (valueErrors.length > 0) {
          return valueErrors[0].message;
        }
        break;
      }
    }

    return error.title || t('missing_data_error_unknown');
  }, [createMutation.error, t, timestamp]);

  if (createdData) return <BillingMeteringDataData data={createdData} />;

  return (
    <Stack direction={'column'} justifyContent={'center'} height={'100%'}>
      <Stack component={'form'} direction={'row'} alignItems={'center'} gap={1} onSubmit={submit}>
        <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
          <Stack>
            <MGTooltip title={errorText} open={isTooltipOpen}>
              <FormControl variant="outlined">
                <OutlinedInput
                  value={value}
                  onChange={(e) => setValue(sanitizeNumberInput(e.target.value, value))}
                  disabled={createMutation.isPending}
                  placeholder={tString('missing_data_input_placeholder', {
                    timestamp: presentDate(timestamp),
                  })}
                  size="small"
                  error={!!errorText}
                  endAdornment={tString('missing_data_input_end_adornment')}
                />
              </FormControl>
            </MGTooltip>
          </Stack>
        </ClickAwayListener>
        <IconButton color="primary" size="small" type="submit" disabled={!formValid}>
          <Check size={25} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
