import { Box, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { palette } from '../../styles';

type MGBadgeProps = PropsWithChildren<{
  sx?: SxProps;
}>;
export const MGBadge = ({ children, sx }: MGBadgeProps) => (
  <Box
    component="span"
    sx={{
      borderRadius: '999rem',
      lineHeight: 1,
      py: 0.5,
      px: 0.8,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      fontSize: '10px',
      fontWeight: 500,
      width: 'auto',
      height: 'auto',
      transitionProperty: 'backgroundColor, color',
      transitionDuration: '.15s',
      transitionTimingFunction: 'ease-in',
      display: 'inline-block',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      ...sx,
    }}
  >
    {children}
  </Box>
);
