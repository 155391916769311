import { useListSepaTransactions } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { palette } from '@mg/ui/src/styles';
import { Box, Button, Card, CardActionArea, CardContent, Chip, Stack, Typography } from '@mui/material';
import { ChevronRight } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from '../../../../i18n';
import { useMultiStepPanelContext } from '../../../shared/MultiStepPanel/MultiStepPanel.Context';
import { ErrorIndicator } from '../ErrorIndicator';
import { useFunnelContext } from '../FunnelProvider.Context';

export const ProjectsStep = () => {
  const { t } = useTranslation('sepaGeneration');

  const { currentStep, previous, goto } = useMultiStepPanelContext();
  const { data: funnelData, setData: setFunnelData } = useFunnelContext();

  const selectedProjects = funnelData.projects.filter((project) => project.value);

  const { data, isLoading, isError, isSuccess } = useListSepaTransactions({
    page_size: 10000,
    include_customer: true,
    project_ids: selectedProjects.map((project) => project.id),
    time_range_from: funnelData.range[0]?.toISOString(),
    time_range_to: funnelData.range[1]?.toISOString(),
  });

  useEffect(() => {
    if (isSuccess && data.items.length !== funnelData.transactions.length) {
      setFunnelData({
        ...funnelData,
        transactions: data.items.map((transaction, index) => ({
          value: true,
          voucherDate: transaction.voucher_date,
          amount: parseFloat(transaction.amount + ''),
          reason: transaction.reason ?? '',
          id: transaction.id ?? -1,
          originalFieldIndex: index,
          customerId: transaction.customer?.id ?? -1,
          customerDisplayName: transaction.customer?.display_name ?? '',
          projectId: transaction.project ?? -1,
        })),
      });
    }
  }, [data, funnelData, setFunnelData, isSuccess]);

  return (
    <>
      <Box sx={{ maxHeight: '400px', gap: 2, overflowY: 'scroll', pr: 2, m: 0 }}>
        {(isLoading || isError) && (
          <Stack height={250} alignItems={'center'} justifyContent={'center'} gap={2}>
            {isLoading && <MGLoader />}
            {isError && <ErrorIndicator>{t('projects_step_error')}</ErrorIndicator>}
          </Stack>
        )}
        {isSuccess && (
          <Box minHeight={250}>
            <Stack gap={2} direction={'row'} alignItems={'stretch'} flexWrap={'wrap'}>
              {selectedProjects.map((project) => (
                <Box key={'project-' + project.id} width={'calc(50% - 16px)'}>
                  <Card sx={{ height: '100%' }}>
                    <CardActionArea
                      sx={{ height: '100%' }}
                      onClick={(event) => {
                        event.preventDefault();
                        setFunnelData({ ...funnelData, viewPositionsForProjectId: project.id });
                        goto('positions');
                      }}
                    >
                      <CardContent>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Stack
                            direction={'row'}
                            gap={1}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flex={1}
                          >
                            <Typography>{project.label}</Typography>
                            <Chip
                              size="small"
                              color="primary"
                              label={
                                funnelData.transactions?.filter(
                                  (ta) => ta.projectId === project.id && ta.value,
                                ).length
                              }
                            />
                          </Stack>
                          <ChevronRight size={20} style={{ flexShrink: 0 }} color={palette.text.gray} />
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
            </Stack>
          </Box>
        )}
      </Box>

      <Stack direction={'row'} justifyContent={'space-between'} mt={1}>
        <Button
          variant={'contained'}
          color={'white'}
          onClick={(event) => {
            event.preventDefault();
            setFunnelData({ ...funnelData, transactions: [] });
            previous();
          }}
        >
          {t(`modal_button_back_${currentStep.key}`)}
        </Button>
        <Button
          variant={'contained'}
          onClick={(event) => {
            event.preventDefault();
            goto('transactions');
          }}
          disabled={data?.total_items === 0}
        >
          {t(`modal_button_next_${currentStep.key}`)}
        </Button>
      </Stack>
    </>
  );
};
