import { MGActionMenu } from '@mg/ui/src/components/MGActionMenu';
import { ArrowRightFromLine, Plus } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from '../../../../../i18n';
import { CreatePlanModal } from '../../../../modals/CreatePlanModal';

export const CustomerDetailsContractsPlanActionMenu = ({ customerId }) => {
  const [createPlanModalOpen, setCreatePlanModalOpen] = useState(false);
  const { t } = useTranslation('customerDetails');

  const menuItems = [
    {
      text: t('plans_action_menu_assign_plan'),
      icon: <ArrowRightFromLine size={18} />,
      href: `/customers/${customerId}/subscription/edit/`,
    },
    {
      text: t('plans_action_menu_create_plan'),
      icon: <Plus size={18} />,
      onClick: () => setCreatePlanModalOpen(true),
    },
  ];
  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <CreatePlanModal hideButton isOpen={createPlanModalOpen} onToggle={setCreatePlanModalOpen} />
    </>
  );
};
