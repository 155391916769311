import { useListCustomers } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles/palette';
import { Box, Button, Stack, Typography } from '@mui/material';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useTranslation } from '../../../i18n';
import { RadioGroup } from '../../shared/HookFormIntegration/RadioGroup';
import { Switch } from '../../shared/HookFormIntegration/Switch';
import { MuiBaseModal } from '../MuiBaseModal';
import { CheckboxFieldsetContainer } from '../SepaXmlGenerationModal/CheckboxFieldset';

type CreateLetterModalFieldValues = {
  allTenants: boolean;
  tenant: string;
};

type CreateLetterModalProps = {
  toggler: ReactElement;
};

export const CreateLetterModal = ({ toggler }: CreateLetterModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('createLetter');
  const currentProjectId = useCurrentProject();
  const [open, setOpen] = useState(false);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateLetterModalFieldValues>({
    values: {
      allTenants: true,
      tenant: '',
    },
  });
  const allTenants = useWatch<CreateLetterModalFieldValues>({ control, name: 'allTenants' });

  const { data: customerList } = useListCustomers({
    order_by: 'id',
    project_id: currentProjectId,
    page_size: 1000,
  });

  useEffect(() => {
    if (customerList?.items[0]) setValue('tenant', customerList.items[0].id?.toString() ?? '');
  }, [customerList, setValue]);

  const onSubmit = () => {
    const { tenant, allTenants } = getValues();

    if (!allTenants) {
      navigate('/documents/letter/create?tenant=' + tenant);
    } else {
      navigate('/documents/letter/create');
    }
  };

  return (
    <>
      {cloneElement(toggler, { onClick: () => setOpen(!open) })}
      <MuiBaseModal
        open={open}
        handleToggle={() => setOpen(!open)}
        height={'auto'}
        width={'710px'}
        position={{ anchorTop: 'center' }}
        showCloseButton
        PaperStyle={{ overflow: 'hidden' }}
        BodyStyle={{ overflow: 'hidden' }}
        title={t('create_letter_modal_title')}
      >
        <Box component={'p'} mt={2.5}>
          {t('create_letter_modal_subtitle')}
        </Box>

        <Box component={'form'} onSubmit={handleSubmit(onSubmit)} mt={4}>
          <Box mt={2}>
            <Typography color={palette.text.black} fontWeight={400}>
              {t('create_letter_form_switch_all_tenants_label')}
            </Typography>
            <Switch
              control={control}
              name={'allTenants'}
              label={t('create_letter_form_switch_all_tenants_label2')}
            />
          </Box>

          {!allTenants && (
            <Box mt={2}>
              <CheckboxFieldsetContainer
                title={t('create_letter_form_checkbox_fieldset_tenants_label')}
                height={'200px'}
                error={errors.tenant?.root?.message}
              >
                <Box sx={{ mt: 1 }}>
                  <RadioGroup
                    control={control}
                    name="tenant"
                    options={
                      customerList?.items.map((customer) => ({
                        label: customer.display_name ?? '',
                        value: (customer.id ?? -1) + '',
                      })) ?? []
                    }
                  />
                </Box>
              </CheckboxFieldsetContainer>
            </Box>
          )}

          <Stack direction={'row'} justifyContent={'space-between'} mt={4}>
            <Button variant={'outlined'} onClick={() => {}}>
              {t('create_letter_form_modal_button_cancel')}
            </Button>
            <Button variant={'contained'} color={'primary'} type="submit">
              {t('create_letter_form_modal_button_submit')}
            </Button>
          </Stack>
        </Box>
      </MuiBaseModal>
    </>
  );
};
