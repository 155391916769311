import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  CreateLetterMutationRequest,
  CreateLetterMutationResponse,
  CreateLetterQueryParams,
  CreateLetter400,
  CreateLetter422,
} from '../types/CreateLetter.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const createLetterMutationKey = () => [{ url: '/api-internal/letters/' }] as const

export type CreateLetterMutationKey = ReturnType<typeof createLetterMutationKey>

/**
 * @summary Create Letter
 * {@link /api-internal/letters/}
 */
async function createLetter(
  data: CreateLetterMutationRequest,
  params?: CreateLetterQueryParams,
  config: Partial<RequestConfig<CreateLetterMutationRequest>> = {},
) {
  const res = await client<CreateLetterMutationResponse, CreateLetter400 | CreateLetter422, CreateLetterMutationRequest>({
    method: 'POST',
    url: `/api-internal/letters/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Create Letter
 * {@link /api-internal/letters/}
 */
export function useCreateLetter(
  options: {
    mutation?: UseMutationOptions<
      CreateLetterMutationResponse,
      CreateLetter400 | CreateLetter422,
      { data: CreateLetterMutationRequest; params?: CreateLetterQueryParams }
    >
    client?: Partial<RequestConfig<CreateLetterMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createLetterMutationKey()

  return useMutation<CreateLetterMutationResponse, CreateLetter400 | CreateLetter422, { data: CreateLetterMutationRequest; params?: CreateLetterQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return createLetter(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}