import { z } from 'zod'

export const residualPowerPlanNestedSchemaSchema = z.object({
  cancel_period: z.string().max(128).default('MONTHS_3_TO_TERM_END').describe('The terms to cancel the plan.'),
  duration: z.string().max(128).default('MONTHS_12').describe('The duration of the plan.'),
  id: z.union([z.number().int(), z.null()]).optional(),
  is_deletable: z.boolean(),
  is_editable: z.boolean(),
  name: z.string().max(255).default('Reststrom').describe('Display name of the plan.'),
  price_base: z.union([z.string(), z.number()]).default('100.00').describe('The price in EUR per year for supplying the service (before tax).'),
  price_per_kwh: z.union([z.string(), z.number()]).default('25.00').describe('The price in Euro Cent per consumed kilowatt-hour (before tax).'),
  supplier: z.string().max(255).default('Muster Energy').describe('Supplying entity of the plan.'),
  valid_from: z.string().date().describe('The start of the validity period.'),
  valid_to: z.union([z.string().date(), z.null()]).describe('The end of the validity period. If unset, valid indefinitely.').optional(),
})