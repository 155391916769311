import { PaymentPlanFilter, useListCustomers } from '@mg/api-wrappers/src/api-internal';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardFilterForm } from '../../shared/DataGridCard/DataGridCard.FilterForm';
import { DeclarativeFormFieldType } from '../../shared/DeclarativeForm';

type CustomPaymentPlanFilter = Partial<PaymentPlanFilter>;

type PaymentPlanFilterFormValues = {
  customer: string;
  startDateRange: [Dayjs | null, Dayjs | null];
};

export const DocumentsPaymentPlansTableFilterForm = () => {
  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);
  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t, tString } = useTranslation('documents');

  const createInitialFormValues: () => PaymentPlanFilterFormValues = () => ({
    customer: '',
    startDateRange: [null, null],
  });

  const customerOptions = useMemo(() => {
    if (!customersQuery.data) return [];

    return customersQuery.data?.items
      .map((customer) => ({ value: customer.id + '', label: customer.display_name + '' }))
      .filter((option, optionIndex, self) => self.findIndex((o) => o.value === option.value) === optionIndex);
  }, [customersQuery.data]);

  return (
    <>
      <DataGridCardFilterForm
        formId={'payment-plan-table-filter'}
        formTitle={t('payment_plan_filter_title')}
        filter={filter}
        onFilter={(filter) => {
          setFilterValue(filter);
          setFilterModalOpen(false);
        }}
        initialValues={createInitialFormValues()}
        serialize={(formValues: PaymentPlanFilterFormValues) => {
          const customerId = parseInt(formValues.customer);

          const result: CustomPaymentPlanFilter = {};

          if (customerId) result.customer = customerId;

          if (formValues.startDateRange[0])
            result.start_date_from = (formValues.startDateRange[0] as Dayjs)?.format('YYYY-MM-DD');

          if (formValues.startDateRange[1])
            result.start_date_to = (formValues.startDateRange[1] as Dayjs)?.format('YYYY-MM-DD');

          return result;
        }}
        deserialize={(filter: CustomPaymentPlanFilter) => {
          const formValues: PaymentPlanFilterFormValues = createInitialFormValues();

          if (filter.customer) formValues.customer = filter.customer + '';

          if (filter?.start_date_from) formValues.startDateRange[0] = dayjs(new Date(filter.start_date_from));

          if (filter?.start_date_to) formValues.startDateRange[1] = dayjs(new Date(filter.start_date_to));

          return formValues;
        }}
        fieldDefinitions={[
          {
            name: 'customer',
            label: t('filter_customer_label'),
            innerLabel: t('filter_customer_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: customerOptions,
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'startDateRange',
            label: t('payment_plan_filter_start_date_range_label'),
            type: DeclarativeFormFieldType.DateRange,
            dateRangePickerInputProps: { clearable: true },
          },
        ]}
      />
    </>
  );
};
