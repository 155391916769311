import {
  type BillingIntervalSchemaOutput,
  GetBillingIntervalsQueryParamsAutomaticEndDate,
  useGetBillingIntervals,
  useListCustomers,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { presentDate } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Box, Card, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMolecule } from 'bunshi/react';
import { useAtom, useAtomValue } from 'jotai';
import { TriangleAlert } from 'lucide-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from '../../../../../feature-flags';
import { useSelectedProjectsFilter } from '../../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../../i18n';
import { DataGridCardLoadingOverlay } from '../../../../shared/DataGridCard/DataGridCard.LoadingOverlay';
import { DataGridCardNoRowsOverlay } from '../../../../shared/DataGridCard/DataGridCard.NoRowsOverlay';
import { billingMolecule } from '../../Billing.Atoms';

export const BillingBillingPeriodCardTable = () => {
  const { billingDebug: isDebug } = useFlags();
  const { tString } = useTranslation('billing');
  const { t: tErrors } = useTranslation('errors');

  const [searchParams] = useSearchParams();

  const {
    billingCustomersAtom,
    endDateAtom,
    endDurationAtom,
    selectedSubscriptionIds,
    startDateAtom,
    startDurationAtom,
    useEndDurationListener,
    useStartDurationListener,
  } = useMolecule(billingMolecule);
  const [rowSelection, setRowSelection] = useAtom(selectedSubscriptionIds);
  const [, setBillingCustomers] = useAtom(billingCustomersAtom);
  const startDate = useAtomValue(startDateAtom);
  const startDuration = useAtomValue(startDurationAtom);
  const endDate = useAtomValue(endDateAtom);
  const endDuration = useAtomValue(endDurationAtom);

  // Queries

  const projectFilter = useSelectedProjectsFilter();
  const customerQuery = useListCustomers({
    ...projectFilter,
    include_subscription: true,
    skip_pagination: true,
  });
  const customers = customerQuery.data?.items ?? [];

  const preselectedSubscriptionIds =
    searchParams
      .get('subscriptionIds')
      ?.split(',')
      .filter((id) => id.length > 0)
      .map((id) => parseInt(id)) ?? [];

  const billingIntervalsQuery = useGetBillingIntervals(
    {
      subscription_ids: customers
        ?.map((customer) => customer.subscription?.id as number)
        .filter((id) => (preselectedSubscriptionIds.length ? preselectedSubscriptionIds.includes(id) : true)),
      start_date: startDate?.format('YYYY-MM-DD'),
      automatic_end_date: ['12MONTHS', 'LAST_YEAR'].includes(endDuration)
        ? (endDuration as GetBillingIntervalsQueryParamsAutomaticEndDate)
        : undefined,
      fixed_end_date: endDuration === 'DATE' ? endDate?.format('YYYY-MM-DD') : undefined,
    },
    { query: { enabled: customers.length > 0 } },
  );

  useEndDurationListener((get, set) => {
    isDebug && console.log('[BillingBillingPeriodCardTable]', 'endDuration changed: reset row selection');
    set(selectedSubscriptionIds, null);
  });

  useStartDurationListener((get, set) => {
    isDebug && console.log('[BillingBillingPeriodCardTable]', 'startDuration changed: reset row selection');
    set(selectedSubscriptionIds, null);
  });

  // (Re-)initialize row selection if billing intervals are loaded
  useEffect(() => {
    if (rowSelection === null && billingIntervalsQuery.data) {
      isDebug && console.log('[BillingBillingPeriodCardTable]', 'Initializing row selection');
      const rowSelectionUpdate = (billingIntervalsQuery.data ?? [])
        .filter((interval) => {
          if (interval.errors && interval.errors.length) return false;

          if (preselectedSubscriptionIds.length > 0)
            return preselectedSubscriptionIds.includes(interval.subscription?.id ?? -1);

          return true;
        })
        .map((interval) => interval.subscription?.id ?? -1);

      setRowSelection(rowSelectionUpdate);
    }
  }, [billingIntervalsQuery.data, rowSelection, searchParams, setRowSelection, isDebug]);

  // Set Billable customers in context if there is no error
  useEffect(() => {
    if (!rowSelection) return;

    const billingCustomersUpdate = (billingIntervalsQuery.data ?? []).filter(
      (interval) => !interval.errors?.length && rowSelection.includes(interval.subscription?.id ?? -1),
    );
    setBillingCustomers(billingCustomersUpdate);
  }, [billingIntervalsQuery.data, setBillingCustomers, rowSelection]);

  const columns: GridColDef<BillingIntervalSchemaOutput>[] = [
    {
      field: 'customer',
      headerName: tString('billing_period_card_customer'),
      flex: 2,
      renderCell: (params) => <MGCustomerWithAvatar customer={params.row.customer} />,
    },
    {
      field: 'billing_from',
      headerName: tString('billing_period_card_bill_from'),
      flex: 1,
      colSpan: (value, row) => ((row.errors?.length ?? 0) > 0 ? 2 : 1),
      valueGetter: (_, row) => presentDate(row?.start_date),
      renderCell: (params) => {
        if (params.row.errors?.length) {
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'} height={'100%'}>
              <TriangleAlert color={palette.error.main} size={16} />
              <Typography variant={'extraSmall'} color={'error'}>
                {tErrors(params.row.errors[0].code)}
              </Typography>
            </Stack>
          );
        }
        return params.value;
      },
    },
    {
      field: 'billing_to',
      headerName: tString('billing_period_card_bill_to'),
      flex: 1,
      valueGetter: (_, row) => presentDate(row?.end_date),
    },
  ];

  if ((endDuration === 'DATE' && !endDate) || (startDuration === 'DATE' && !startDate)) {
    return (
      <Box sx={{ paddingY: '20px' }}>
        <MGAlert
          sx={{ marginTop: '20px' }}
          severity={'error'}
          title={'Fehlendes Datum'}
          subtitle={'Datum auswählen um Ergebnisse anzuzeigen'}
          icon={<TriangleAlert />}
        />
      </Box>
    );
  }

  return (
    <Card sx={{ padding: 2 }}>
      <DataGridPro
        isRowSelectable={({ row }) => !row.errors || row.errors.length === 0}
        onRowSelectionModelChange={(row) => {
          setRowSelection(row);
        }}
        rowSelectionModel={rowSelection ?? []}
        checkboxSelection={true}
        autoHeight
        sx={{ '--DataGrid-overlayHeight': '300px' }}
        columns={columns}
        rows={billingIntervalsQuery.data || []}
        getRowId={(row) => row.subscription.id || Math.random()}
        loading={billingIntervalsQuery.isLoading}
        slots={{
          noRowsOverlay: () => (
            <Box height={'300px'}>
              <DataGridCardNoRowsOverlay />
            </Box>
          ),
          loadingOverlay: () => <DataGridCardLoadingOverlay showLinearProgress={false} showSpinner={true} />,
        }}
        getRowSpacing={() => ({ top: 5, bottom: 5 })}
      />
    </Card>
  );
};
