import { useGetMeasuringPointPanel } from '@mg/api-wrappers/src/api-internal';
import { Grid, Typography, useTheme } from '@mui/material';
import { Wifi } from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { LastReadings } from '../../features/metering/LastReadings/LastReadings';
import { MetergridCard } from '../../shared/MetergridCard';

export const MeteringDetailsMeasuringPoint = ({ panelId }) => {
  const theme = useTheme();
  const { t } = useTranslation('meteringDetails');

  const { data, isLoading, isError } = useGetMeasuringPointPanel(panelId, {
    include_current_measuring_point: true,
    include_last_reading: true,
  });

  return (
    <MetergridCard isLoading={isLoading} isError={isError}>
      <Grid container>
        <Grid item xs={12}>
          <Wifi color={theme.palette.primary.main} />
        </Grid>
        <Grid item xs={12} marginTop={'10px'}>
          <Typography>{t('meter_card_title')}</Typography>
        </Grid>

        <Grid item container xs={12} marginTop={'10px'} rowSpacing={'10px'}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant={'small'}>{t('meter_serial')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{data?.current_measuring_point?.serial || '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant={'small'}>{t('last_reading')}</Typography>
            </Grid>
            <Grid item xs={6}>
              {data?.current_measuring_point ? (
                <LastReadings measuringPoint={data.current_measuring_point} />
              ) : (
                '-'
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant={'small'}>{t('conversion_factor')}</Typography>
            </Grid>
            <Grid item xs={6}>
              {data?.current_measuring_point?.conversion_factor}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MetergridCard>
  );
};
