'use client';

import { Box, Card, SxProps, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from '../../i18n';
import error from '../../lottie/error.json';
import loader from '../../lottie/loader.json';
import noDataLottie from '../../lottie/no-data.json';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  noData?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps;
};
/** @deprecated Use MGCard from @mg/ui/src/ */
export const MetergridCard = ({
  children,
  isLoading = false,
  isError = false,
  noData = false,
  style,
  sx,
}: Props) => {
  const { t } = useTranslation('shared');
  const renderCardContent = () => {
    if (isLoading) {
      return (
        <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box sx={{ height: '50px', width: '50px' }}>
            <Lottie animationData={loader} loop={true} />
          </Box>
        </Box>
      );
    }
    if (isError) {
      return (
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box sx={{ height: '50px', width: '50px' }}>
            <Lottie animationData={error} loop={false} />
          </Box>
          <Typography variant={'extraSmall'}>{t('oops_something_went_wrong')}</Typography>
        </Box>
      );
    }

    if (noData) {
      return (
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box sx={{ height: '100px', width: '100px' }}>
            <Lottie animationData={noDataLottie} loop={false} />
          </Box>
          <Typography variant={'extraSmall'}>{t('no_data_available')}</Typography>
        </Box>
      );
    }

    return (
      <motion.div
        style={{ height: '100%' }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          delay: 0,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <Card id={'datagrid-paper'} sx={{ padding: '30px', height: '100%', ...style, ...sx }}>
      {renderCardContent()}
    </Card>
  );
};
