import { ScopeProvider } from 'bunshi/react';
import { PropsWithChildren } from 'react';
import { billingScope } from './Billing.Atoms';

export const BillingProvider = ({ children }: PropsWithChildren) => {
  return (
    <ScopeProvider scope={billingScope} value="billing">
      {children}
    </ScopeProvider>
  );
};
