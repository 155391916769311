import {
  measuringPointDataGranularity,
  MeasuringPointDataSchema,
  ObisCodes,
  useGetMeasuringPointPanel,
  useListMeasuringPointDataForGraph,
  useListMeasuringPoints,
} from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { LineChart } from 'lucide-react';
import { useMemo } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { MeteringDetailsMeasuringPointDataGraph } from './MeteringDetails.MeasuringPointData.Graph';

export const MeteringDetailsMeasuringPointData = ({ panelId }) => {
  const theme = useTheme();
  const { t } = useTranslation('meteringDetails');
  const projectIdFilter = useSelectedProjectsFilter();

  const panelQuery = useGetMeasuringPointPanel(panelId, {
    include_current_measuring_point: true,
    include_project: true,
  });

  const currentMeasuringPointId = panelQuery.data?.current_measuring_point?.id;
  const pointQuery = useListMeasuringPoints({
    panel_id: panelId,
    ...projectIdFilter,
  });
  const measuringPointDataQuery = useListMeasuringPointDataForGraph({
    panel_ids: [panelId],
    granularity: measuringPointDataGranularity.day,
    always_first: true,
  });

  const groupedMeasuringPointData: undefined | Record<number, Record<ObisCodes, MeasuringPointDataSchema[]>> =
    useMemo(() => {
      return measuringPointDataQuery.data?.reduce((acc, dataPoint) => {
        const { measuring_point_id, obis_code } = dataPoint;
        if (!acc[measuring_point_id]) {
          acc[measuring_point_id] = {};
        }
        if (!acc[measuring_point_id][obis_code]) {
          acc[measuring_point_id][obis_code] = [];
        }
        acc[measuring_point_id][obis_code].push(dataPoint);
        return acc;
      }, {});
    }, [measuringPointDataQuery.data]);
  const isLoading = panelQuery.isLoading || pointQuery.isLoading || measuringPointDataQuery.isLoading;
  const isError = panelQuery.isError || pointQuery.isError;
  const data = pointQuery.data;
  const noData =
    groupedMeasuringPointData === undefined || Object.keys(groupedMeasuringPointData).length === 0;
  return (
    <MGCard isLoading={isLoading} isError={isError} noData={noData}>
      <Grid container>
        <Grid item xs={12}>
          <LineChart color={theme.palette.primary.main} />
        </Grid>
        <Grid item xs={7} marginTop={'10px'}>
          <Typography>{t('data_card_title')}</Typography>
        </Grid>
        <Grid item xs={5} display={'flex'} justifyContent={'flex-end'}>
          {panelQuery.data?.project?.is_read_remotely === false && (
            <Button href="/metering/data">{t('edit_data_button')}</Button>
          )}
        </Grid>
        <Grid item xs={12} height={'390px'} marginTop={'20px'}>
          <MeteringDetailsMeasuringPointDataGraph
            measuring_point_informations={data || []}
            measuring_point_data={groupedMeasuringPointData}
            currentMeasuringPointId={currentMeasuringPointId}
          />
        </Grid>
      </Grid>
    </MGCard>
  );
};
