import { palette } from '../../styles';
import './MGAnimatedIcon.css';

export interface MGAnimatedIconProps {
  type: 'info' | 'error' | 'success';
}
export const MGAnimatedIcon = ({ type }: MGAnimatedIconProps) => {
  // TODO: Replace with https://lottiefiles.com/de/marketplace/ui-essential-18 when we have the pro version

  switch (type) {
    case 'info':
      return (
        <div>
          <div className="svg-box">
            <svg className="circular" style={{ stroke: palette.primary.main }}>
              <circle
                className="path"
                cx={75}
                cy={75}
                r={50}
                fill="none"
                strokeWidth={3}
                strokeMiterlimit={10}
              />
            </svg>
            <svg className="alert-sign" style={{ stroke: palette.primary.main }}>
              <g transform="matrix(1,0,0,1,-614.516,-257.346)">
                <g>
                  <line x1="621.52" x2="621.52" y2="270" y1="272" fill={palette.primary.main} />
                </g>
                <g>
                  <line
                    className="line"
                    x1="621.52"
                    y1="285"
                    x2="621.52"
                    y2="315"
                    fill={palette.primary.main}
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      );

    case 'error':
      return (
        <div className="svg-box">
          <svg className="circular" style={{ stroke: palette.error.main }}>
            <circle
              className="path"
              cx={75}
              cy={75}
              r={50}
              fill="none"
              strokeWidth={3}
              strokeMiterlimit={10}
            />
          </svg>
          <svg className="cross" style={{ stroke: palette.error.main }}>
            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
              <path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none" />
            </g>
            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
              <path className="second-line" d="M634.087,300.805L673.361,261.53" />
            </g>
          </svg>
        </div>
      );

    case 'success':
      return (
        <div className="svg-box">
          <svg className="circular" style={{ stroke: palette.success.main }}>
            <circle
              className="path"
              cx={75}
              cy={75}
              r={50}
              fill="none"
              strokeWidth={3}
              strokeMiterlimit={10}
            />
          </svg>
          <svg className="checkmark" style={{ stroke: palette.success.main }}>
            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
              <path
                className="checkmark__check"
                fill="none"
                d="M616.306,283.025L634.087,300.805L673.361,261.53"
              />
            </g>
          </svg>
        </div>
      );
  }
};
