import { palette } from '@mg/ui/src/styles';
import { Card, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';

export const CustomerDetailsTabs = ({ currentTab, onChange, tabs }) => {
  const [params, setParams] = useSearchParams();

  const { t } = useTranslation('customerDetails');

  useEffect(() => {
    handleTabChange(null, params.get('customer_details_tab') || 'data');
  }, [params]);

  const handleTabChange = (_, value) => {
    onChange(value);
    setParams({ customer_details_tab: value }, { replace: true });
  };

  return (
    <Card sx={{ height: '64px' }}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        sx={{
          height: '100%',
          '.MuiTabs-flexContainer': {
            height: '100%',
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            value={tab}
            label={t(`tabs_${tab}`)}
            sx={{
              color: palette.text.disabled,
              fontWeight: 400,
              '&.Mui-selected': {
                color: palette.text.secondary,
                fontWeight: 400,
              },
            }}
          />
        ))}
      </Tabs>
    </Card>
  );
};
