import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { Stack } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtomValue } from 'jotai';
import { InfoIcon } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';
import { billingMolecule } from '../../Billing.Atoms';
import { BillingBillingPeriodCardFormEndDateSelect } from './Billing.BillingPeriodCardForm.EndDateSelect';
import { BillingBillingPeriodCardFormStartDateSelect } from './Billing.BillingPeriodCardForm.StartDateSelect';

export const BillingBillingPeriodCardForm = () => {
  const { t } = useTranslation('billing');

  const { startDurationAtom } = useMolecule(billingMolecule);
  const startDuration = useAtomValue(startDurationAtom);

  return (
    <Stack spacing={3}>
      <BillingBillingPeriodCardFormStartDateSelect />
      {startDuration === 'DATE' && (
        <MGAlert
          severity={'info'}
          title={t('hint')}
          subtitle={t('billing_period_card_manual_form_hint')}
          icon={<InfoIcon />}
        />
      )}
      <BillingBillingPeriodCardFormEndDateSelect />
    </Stack>
  );
};
