import { Download, MailCheckIcon, MailX, Send } from 'lucide-react';
import { downloadFile } from '../../../helpers/downloadFile';
import { useTranslation } from '../../../i18n';

import { DeliverableDocumentType, useBulkMarkAsSent } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useDocumentContext } from '../../features/documents/document-context/document-context';
import { useDocumentEmailDialog } from './useDocumentEmailDialog';

type CommonActionMenuItemsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadPdfQuery: UseQueryResult<Blob, any>;
  documentType: DeliverableDocumentType;
};
export const useCommonActionMenuItems = ({
  downloadPdfQuery,
  documentType,
}: CommonActionMenuItemsProps): MGActionMenuItemProps[] => {
  const { t } = useTranslation('documents');
  const { openSendDialog } = useDocumentEmailDialog();
  const { document, queryKey } = useDocumentContext();
  const projectId = useCurrentProject();
  const bulkMarkAsSent = useBulkMarkAsSent();
  const queryClient = useQueryClient();

  const items: MGActionMenuItemProps[] = [
    {
      text: t('download'),
      icon: <Download size={'18'} color={palette.primary.main} />,
      disabled: !document.pdf?.is_ready,
      disabledHint: t('common_action_menu_document_not_ready_yet'),
      onClick: () => {
        downloadFile(downloadPdfQuery);
      },
    },
  ];

  if (document.customer) {
    items.push({
      text: t('common_action_menu_send_email'),
      icon: <Send size={'18'} color={palette.primary.main} />,
      disabled: document.delivery_in_progress || !document.customer.email || !document.pdf?.is_ready,
      disabledHint: document.delivery_in_progress
        ? t('common_action_menu_currently_sending_email')
        : !document.customer.email
          ? t('common_action_menu_no_email_address')
          : t('common_action_menu_document_not_ready_yet'),
      onClick: openSendDialog,
    });

    const data = {
      filter: {
        document_type: documentType,
        project_id: projectId,
        ids: [document.id],
      },
    };

    if (document.delivered_to_customer) {
      items.push({
        text: t('action_bar_mark_as_unsent'),
        icon: <MailX size={'18'} color={palette.primary.main} />,
        onClick: () => {
          bulkMarkAsSent.mutate(
            { data: { ...data, sent: false } },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({ queryKey });
                toast.success(t('document_marked_as_unsent'));
              },
            },
          );
        },
      });
    } else {
      items.push({
        text: t('action_bar_mark_as_sent'),
        icon: <MailCheckIcon size={'18'} color={palette.primary.main} />,
        onClick: () => {
          bulkMarkAsSent.mutate(
            { data: { ...data, sent: true } },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({ queryKey });
                toast.success(t('document_marked_as_sent'));
              },
            },
          );
        },
      });
    }
  }

  return items;
};
