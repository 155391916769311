import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countLettersQueryParamsSchema = z.object({
  document_type: z.literal('letter').default('letter'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  issue_date_from: z.union([z.string(), z.null()]).optional(),
  issue_date_to: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const countLetters200Schema = z.number().int()

/**
 * @description Unprocessable Entity
 */
export const countLetters422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countLettersQueryResponseSchema = z.lazy(() => countLetters200Schema)