import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { measuringPointNestedSchemaSchema } from './measuringPointNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { projectNestedSchemaSchema } from './projectNestedSchemaSchema.ts'
import { z } from 'zod'

export const measuringPointPanelSchemaSchema = z.object({
  changeable: z.boolean().default(true),
  current_measuring_point: z.union([z.lazy(() => measuringPointNestedSchemaSchema), z.null()]).optional(),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  is_deletable: z.boolean(),
  is_editable: z.boolean(),
  malo_id: z.union([z.string(), z.null()]).describe('The Market Location Identification Number for the panel').optional(),
  measuring_points: z.union([z.array(z.lazy(() => measuringPointNestedSchemaSchema)), z.null()]).optional(),
  melo_id: z.union([z.string(), z.null()]).describe('The Measuring Location Identification Number for the panel').optional(),
  name: z.string().max(255).describe('Unique identifier of the panel'),
  project: z.union([z.lazy(() => projectNestedSchemaSchema), z.null()]).optional(),
  should_be_renamed: z.boolean().default(false).describe('Indicates if the panel should be renamed in the first onboarding step'),
  subscriptions: z.union([z.array(z.lazy(() => measuringPointSubscriptionNestedSchemaSchema)), z.null()]).optional(),
  type: z.string().max(64).default('SINK'),
})