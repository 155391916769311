import { z } from 'zod'

export const transactionStatisticsSchemaSchema = z.object({
  closed_amount: z.number(),
  closed_count: z.number().int(),
  open_amount: z.number(),
  open_count: z.number().int(),
  overdue_amount: z.number(),
  overdue_count: z.number().int(),
  plan_end_date: z.string().datetime(),
  planned_amount: z.number(),
  total_count: z.number().int(),
})