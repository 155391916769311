import { Palette, SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface TypeText {
    black?: string;
    gray: string;
  }

  interface Palette {
    border: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    purple: SimplePaletteColorOptions;
  }
}

export const palette = {
  common: {
    black: '#051c2c',
    white: '#FFF',
  },
  text: {
    primary: '#50606B',
    secondary: '#051c2c',
    disabled: '#A0A7B6',
    black: '',
    gray: '',
  },
  primary: {
    main: '#0069E3',
    light: '#E6F6FF',
    dark: '#072DB4',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#0FD79B',
    light: '#F2FCFA',
    dark: '#0FD79B',
    contrastText: '#FFF',
  },
  error: {
    main: '#E70A3F',
    light: '#FFE7EA',
    dark: '#E70A3F',
    contrastText: '#FFF',
  },
  warning: {
    main: '#FF640C',
    light: '#FFF1CE',
    dark: '#FF640C',
    contrastText: '#FFF',
  },
  success: {
    main: '#0FD79B',
    light: '#D7FFF3',
    dark: '#0FD79B',
    contrastText: '#FFF',
  },
  gray: {
    main: '#B0B7C3',
    light: '#F2F4F8',
    dark: '#5A6078',
    contrastText: '#FFF',
  },
  white: {
    dark: '#B2B2B2FF',
    light: '#FFFFFF',
    main: '#FFFFFF',
    //contrastText: "#0069E3",
  },
  info: {
    main: '#0069E3',
    light: '#E6F6FF',
    dark: '#072DB4',
    contrastText: '#FFF',
  },
  purple: {
    dark: '#6741D9',
    light: '#F3F0FF',
    main: '#9775FA',
    contrastText: '#FFF',
  },
  border: {
    main: '#ccd6ec',
  },
} as Palette;

palette.info = palette.primary;
palette.text = {
  ...palette.text,
  gray: palette.text.primary,
  black: palette.text.secondary,
};
