import { DeliverableDocumentType, useBulkMarkAsSent } from '@mg/api-wrappers/src/api-internal';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai/index';
import { Download, MailCheckIcon, MailXIcon, SendIcon } from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../helpers/downloadFile';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { ActionBarActionButtonsAtom, filterValueAtom } from '../../../jotai/actionBar';
import { useBulkEmailDocumentsModalControl } from '../../features/documents/bulk-email/hooks';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';

type UseSetActionBarButtons = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAsZipQuery: UseQueryResult<Blob, any>;
  documentType: DeliverableDocumentType;
};
export const useSetActionBarButtons = ({ getAsZipQuery, documentType }: UseSetActionBarButtons) => {
  const { tString } = useTranslation('documents');
  const setActionButtons = useSetAtom(ActionBarActionButtonsAtom);
  const currentProjectId = useSelectedProjectsFilter();
  const bulkEmailDocuments = useBulkEmailDocumentsModalControl();
  const bulkMarkAsSent = useBulkMarkAsSent();
  const { dataGridApiRef } = useGridApi();
  const projectId = useCurrentProject();
  const [filter] = useAtom(filterValueAtom);
  const queryClient = useQueryClient();

  useEffect(() => {
    setActionButtons([
      {
        label: tString('action_bar_download'),
        onClick: () => {
          if (!dataGridApiRef.current?.apiLoaded) return;

          if (dataGridApiRef.current.allItemsSelected()) {
            downloadFile(getAsZipQuery, {
              ...dataGridApiRef.current.getReactQueryFilter(),
              document_type: documentType,
            });
          } else {
            const ids = [...dataGridApiRef.current.getSelectedRows().keys()];
            downloadFile(getAsZipQuery, { ids, ...currentProjectId, document_type: documentType });
          }
        },
        icon: <Download size={18} />,
      },
      {
        label: tString('action_bar_send_by_email'),
        onClick: () => {
          if (!dataGridApiRef.current?.apiLoaded) return;

          const ids = [...dataGridApiRef.current.getSelectedRows().keys()] as number[];
          bulkEmailDocuments.open({
            documentType,
            ids: ids,
            allSelected: dataGridApiRef.current.allItemsSelected(),
          });
        },
        icon: <SendIcon size={18} />,
      },
      {
        label: tString('action_bar_mark_as_sent'),
        onClick: () => {
          if (!dataGridApiRef.current?.apiLoaded) return;

          const data = {
            sent: true,
            filter: {
              document_type: documentType,
              project_id: projectId,
              ...filter,
            },
          };

          if (!dataGridApiRef.current.allItemsSelected()) {
            data.filter['ids'] = [...dataGridApiRef.current.getSelectedRows().keys()];
          }

          bulkMarkAsSent.mutate(
            { data },
            {
              onSuccess: () => {
                queryClient.invalidateQueries();
                toast.success(tString('documents_marked_as_sent'));
              },
            },
          );
        },
        icon: <MailCheckIcon size={18} />,
      },
      {
        label: tString('action_bar_mark_as_unsent'),
        onClick: () => {
          if (!dataGridApiRef.current?.apiLoaded) return;

          const data = {
            sent: false,
            filter: {
              document_type: documentType,
              project_id: projectId,
              ...filter,
            },
          };

          if (!dataGridApiRef.current.allItemsSelected()) {
            data.filter['ids'] = [...dataGridApiRef.current.getSelectedRows().keys()];
          }

          bulkMarkAsSent.mutate(
            { data },
            {
              onSuccess: () => {
                queryClient.invalidateQueries();
                toast.success(tString('documents_marked_as_unsent'));
              },
            },
          );
        },
        icon: <MailXIcon size={18} />,
      },
    ]);
  }, [currentProjectId, dataGridApiRef.current, getAsZipQuery, setActionButtons]);
};
