// Allow explicit any in this file for generic filter and form value objects
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { ReactNode, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { filterModalOpenAtom } from '../../../jotai/actionBar';
import { DeclarativeForm, DeclarativeFormFieldDefinition } from '../../shared/DeclarativeForm';

type Props = {
  filter: any;
  onFilter: (filter: any) => void;

  /** Transform function to convert form values from DeclarativeForm into a filter object */
  serialize: (formValues: any) => any;
  /** Transform function to convert a filter object into form values for DeclarativeForm */
  deserialize: (filter?: any) => any;

  /** Unique identifier for the form */
  formId: string;
  /** Displayed form title */
  formTitle: ReactNode;
  /** Field definitions for the form. See DeclarativeForm. */
  fieldDefinitions: DeclarativeFormFieldDefinition[];
  /** Initial values for the form. */
  initialValues: FieldValues;
};

/**
 * Wires up a DeclarativeForm with serialization utilities for better integration with DataGridCard.
 */
export const DataGridCardFilterForm = ({
  filter,
  onFilter,
  serialize,
  deserialize,
  formId,
  formTitle,
  fieldDefinitions,
  initialValues,
}: Props) => {
  const [formValues, setFormValues] = useState<FieldValues>(initialValues);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);

  const handleSubmitButtonClick = (data: FieldValues) => {
    const updatedFilter = serialize(data);
    onFilter(updatedFilter);
  };

  /**
   * Restore form state from filter when existing
   */
  useEffect(() => {
    const updatedFormValues = deserialize(filter);
    setFormValues(updatedFormValues);
  }, [filter, deserialize]);

  return (
    <>
      <Typography variant="h2" fontWeight="500" mb={4}>
        {formTitle}
      </Typography>
      <DeclarativeForm
        id={formId}
        onSubmit={handleSubmitButtonClick as SubmitHandler<FieldValues>}
        onCancel={() => {
          setFilterModalOpen(false);
          setFormValues(initialValues);
        }}
        values={formValues}
        fieldDefinitions={fieldDefinitions}
      />
    </>
  );
};
