import { z } from 'zod'

export const customerIncludesSchemaSchema = z.object({
  include_active_sepa_mandate_id: z.boolean().default(false),
  include_address: z.boolean().default(false),
  include_bank_account: z.boolean().default(false),
  include_current_plan: z.boolean().default(false),
  include_last_billing_date: z.boolean().default(false),
  include_measuring_point_panel: z.boolean().default(false),
  include_plans: z.boolean().default(false),
  include_postal_address: z.boolean().default(false),
  include_subscription: z.boolean().default(false),
  include_total_open_amount: z.boolean().default(false),
  include_total_settled_amount: z.boolean().default(false),
})