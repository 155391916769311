import { Box, Grid, SxProps, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { MGLoader } from '../MGLoader';

type Props = {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  center?: boolean;
  rightSection?: ReactNode | ReactNode[];
  stickyFooter?: ReactNode | ReactNode[];
  loading?: boolean;
  sx?: SxProps;
};
export const MGPageLayout = ({
  children,
  title,
  subtitle,
  center,
  rightSection,
  stickyFooter,
  loading,
  sx,
}: Props) => {
  return (
    <Grid container justifyContent={'center'} sx={{ height: '100vh', ...sx }}>
      <Grid
        container
        sx={{
          maxWidth: '1100px',
          alignContent: 'flex-start',
          paddingX: '20px',
        }}
      >
        {title && (
          <>
            <Grid
              item
              xs={12}
              container
              sx={{
                marginBottom: '0px',
                height: subtitle ? '150px' : '100px',
                marginTop: '40px',
              }}
              id={'page-title'}
            >
              <Grid
                item
                xs={rightSection ? 6 : 12}
                sx={
                  center
                    ? { display: 'flex', justifyContent: 'center' }
                    : { display: 'flex', alignItems: 'center' }
                }
              >
                <Typography variant={'h1'} sx={{ marginBottom: '0px' }}>
                  {title}
                </Typography>
              </Grid>
              {rightSection && !center && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {rightSection}
                </Grid>
              )}

              {subtitle && (
                <Grid
                  item
                  xs={12}
                  sx={center ? { display: 'flex', justifyContent: 'center', textAlign: 'center' } : {}}
                >
                  <Typography color={'gray.dark'}>{subtitle}</Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {/*Page Content*/}
        <Grid
          item
          xs={12}
          sx={{
            paddingBottom: '20px',
          }}
        >
          {loading ? (
            <Box height={'calc(100vh - 300px)'}>
              <MGLoader />
            </Box>
          ) : (
            children
          )}
        </Grid>
      </Grid>

      {stickyFooter && !loading && (
        <Box
          sx={{
            backgroundColor: '#FFF',
            position: 'sticky',
            bottom: '0px',
            width: '100%',
            boxShadow: '-5px 0 21px -7px #888',
            zIndex: 2,
          }}
        >
          <motion.div animate={{ height: loading ? '0px' : '70px' }}>{stickyFooter}</motion.div>
        </Box>
      )}
    </Grid>
  );
};
