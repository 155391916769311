import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { SwitchProjectMutationRequest, SwitchProjectMutationResponse, SwitchProjectQueryParams, SwitchProject422 } from '../types/SwitchProject.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const switchProjectMutationKey = () => [{ url: '/api-internal/projects/switch/' }] as const

export type SwitchProjectMutationKey = ReturnType<typeof switchProjectMutationKey>

/**
 * @summary Switch Project
 * {@link /api-internal/projects/switch/}
 */
async function switchProject(
  data: SwitchProjectMutationRequest,
  params?: SwitchProjectQueryParams,
  config: Partial<RequestConfig<SwitchProjectMutationRequest>> = {},
) {
  const res = await client<SwitchProjectMutationResponse, SwitchProject422, SwitchProjectMutationRequest>({
    method: 'POST',
    url: `/api-internal/projects/switch/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Switch Project
 * {@link /api-internal/projects/switch/}
 */
export function useSwitchProject(
  options: {
    mutation?: UseMutationOptions<SwitchProjectMutationResponse, SwitchProject422, { data: SwitchProjectMutationRequest; params?: SwitchProjectQueryParams }>
    client?: Partial<RequestConfig<SwitchProjectMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? switchProjectMutationKey()

  return useMutation<SwitchProjectMutationResponse, SwitchProject422, { data: SwitchProjectMutationRequest; params?: SwitchProjectQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return switchProject(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}