import { z } from 'zod'

/**
 * @description One entry in ModelValidationErrorOut.errors
 */
export const modelValidationErrorInstanceOutSchema = z
  .object({
    code: z.union([z.string(), z.null()]).optional(),
    location: z.array(z.string()),
    message: z.string(),
    params: z.union([z.object({}).strict(), z.null()]).optional(),
  })
  .describe('One entry in ModelValidationErrorOut.errors')