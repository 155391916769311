import { Stack } from '@mui/material';
import { ReactFormExtendedApi } from '@tanstack/react-form';
import { ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TanstackForm = ReactFormExtendedApi<any, any>;
interface Props {
  form: TanstackForm;
  children: ReactNode;
  spacing?: number;
}

export const MGForm = ({ form, spacing = 2, children }: Props) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    form.handleSubmit();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Stack spacing={spacing}>{children}</Stack>
    </form>
  );
};
