import { useGetCustomer } from '@mg/api-wrappers/src/api-internal';
import { MGCollapsibleCard } from '@mg/ui/src/components/MGCollapsibleCard/MGCollapsibleCard';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../../i18n';
import { CustomerDetailsTitleCard } from '../../../CustomerDetails/CustomerDetails.TitleCard';
import { CustomerDetailsDataAddressCard } from '../../../CustomerDetails/Pages/Data/CustomerDetails.Data.AddressCard';
import { CustomerDetailsDataBaseDataCardShort } from '../../../CustomerDetails/Pages/Data/CustomerDetails.Data.BaseDataCardShort';

type Props = {
  customerId?: number;
};
export const BillingDetailsPerCustomerCustomerCard = ({ customerId }: Props) => {
  const { t } = useTranslation('billing');

  const { data: customer, isLoading } = useGetCustomer(customerId as number, {
    include_address: true,
    include_postal_address: true,
    include_measuring_point_panel: true,
    include_subscription: true,
  });

  return (
    <MGCollapsibleCard
      collapsedContent={
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <MGStatusIndicator status={'success'} />
            <Typography variant={'large'} color={'text.secondary'}>
              {t('details_per_customer_transactions_customer_card_title')}
            </Typography>
          </Stack>
        </>
      }
    >
      <Stack spacing={2}>
        <CustomerDetailsTitleCard hideActions customer={customer} />
        <Stack direction={'row'} spacing={2}>
          <Box flex={1}>
            <CustomerDetailsDataAddressCard isLoading={isLoading} customer={customer} />
          </Box>
          <Box flex={1}>
            <CustomerDetailsDataBaseDataCardShort isLoading={isLoading} customer={customer} />
          </Box>
        </Stack>
      </Stack>
    </MGCollapsibleCard>
  );
};
