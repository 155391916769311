import { theme } from '@mg/ui/src/styles/theme';
import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { TolgeeProvider } from '@tolgee/react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { PostHogProvider } from 'posthog-js/react';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import './App.scss';
import { FlagsProvider } from './feature-flags';
import { getEnvironment } from './helpers/getEnvironment';
import { tolgee } from './i18n';
import { AppLayout } from './layout/AppLayout';
import { UnprotectedAppLayout } from './layout/UnprotectedAppLayout.tsx';
import { queryClient } from './queryClient';

export const App = () => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  dayjs.locale('de-DE');

  LicenseInfo.setLicenseKey(
    '2bc0df1bc1b6094f86aaf2f792ba5ce5Tz05MzE2MyxFPTE3NTA5NTM2MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );

  Sentry.init({
    dsn: 'https://e38e93d7a7c78fd381ae46079fb7953a@o4507826961448960.ingest.de.sentry.io/4507855558869072',
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
    environment: getEnvironment(),
    enabled: getEnvironment() !== 'localhost',
    release: import.meta.env.VITE_RELEASE || 'unknown',
  });

  useEffect(() => {
    tolgee.run().then(() => setTranslationsLoaded(true));
  }, []);

  const PosthogProvider = ({ children }) => {
    if (getEnvironment() === 'localhost') {
      return children;
    }
    return (
      <PostHogProvider
        apiKey={import.meta.env.VITE_POSTHOG_KEY}
        options={{ api_host: 'https://eu.i.posthog.com' }}
      >
        {children}
      </PostHogProvider>
    );
  };

  return (
    <Suspense>
      {!translationsLoaded ? null : (
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <TolgeeProvider tolgee={tolgee} fallback=".">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
                <PosthogProvider>
                  <ToastContainer position="bottom-right" newestOnTop transition={Flip} />
                  <FlagsProvider>
                    <BrowserRouter>
                      <Routes>
                        {/* Non protected routes */}
                        <Route path={'/login/*'} element={<UnprotectedAppLayout />} />
                        {/*Protected routes */}
                        <Route path={'/*'} element={<AppLayout />} />
                      </Routes>
                    </BrowserRouter>
                  </FlagsProvider>
                </PosthogProvider>
              </LocalizationProvider>
            </TolgeeProvider>
          </QueryClientProvider>
        </ThemeProvider>
      )}
    </Suspense>
  );
};
