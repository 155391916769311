import {
  MeasuringPointPanelSchema,
  useGetProjectRemoteReadabilityStatus,
  useListMeasuringPointPanelsInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { palette } from '@mg/ui/src/styles';
import { WifiOffOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { LastReadings } from '../../features/metering/LastReadings/LastReadings';
import { CreateMeasuringPointModal } from '../../modals/CreateMeasuringPointModal';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { MGTooltip } from '../../shared/MGTooltip';
import { PageLayout } from '../../shared/PageLayout';
import { MeteringActionsMenu } from './Metering.ActionsMenu';
import { MeteringPageActionMenu } from './Metering.PageActionMenu';

export const Metering = () => {
  const projectFilter = useSelectedProjectsFilter();
  const currentProjectId = useCurrentProject();
  const { data: status } = useGetProjectRemoteReadabilityStatus(currentProjectId);
  const navigate = useNavigate();
  const { t, tString } = useTranslation('metering');

  const columns: GridColDef<MeasuringPointPanelSchema>[] = [
    {
      field: 'name',
      headerName: tString('measuring_point_panel'),
      flex: 1,
      renderCell: ({ value }) => (
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
      ),
    },
    {
      field: 'type',
      headerName: tString('type'),
      flex: 0.8,
      valueGetter: (value) => t(value),
    },
    {
      field: 'current_measuring_point',
      sortable: false,
      headerName: tString('measuring_point_serial'),
      flex: 1,
      renderCell: (params) => {
        const value = !params.row.measuring_points
          ? undefined
          : params.value?.serial || params.row.measuring_points[0]?.serial;

        if (!value) {
          return (
            <CreateMeasuringPointModal
              panelId={params.row.id ?? -1}
              toggleButtonComponent={
                <Button
                  variant={'contained'}
                  color={'white'}
                  size={'small'}
                  endIcon={<PlusCircle color={palette.primary.main} size={20} />}
                >
                  {t('add')}
                </Button>
              }
            />
          );
        }

        return (
          <Box display={'flex'} alignItems={'center'} height={'100%'}>
            <Typography variant={'small'}>{value}</Typography>
            {/*
             // @ts-expect-error TS18048 */}
            {params.row.measuring_points?.length > 1 && (
              <MGTooltip
                title={t('more_measuring_points_tooltip_title')}
                text={t('more_measuring_points_tooltip_text', {
                  // @ts-expect-error TS18049
                  count: params.row.measuring_points.length - 1,
                })}
              >
                <Chip
                  size={'small'}
                  // @ts-expect-error TS18049
                  label={`+ ${params.row.measuring_points.length - 1}`}
                  sx={{
                    width: '33px',
                    height: '17px',
                    fontSize: '10px',
                    marginLeft: '5px',
                  }}
                />
              </MGTooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: 'x',
      headerName: tString('last_reading'),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid container display={'flex'} alignItems={'center'} height={'100%'}>
            {params.row.current_measuring_point && (
              <LastReadings measuringPoint={params.row.current_measuring_point} />
            )}
          </Grid>
        );
      },
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      sortable: false,
      flex: 1.2,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Box display={'flex'} alignItems={'center'} height={'100%'}>
              <Typography variant={'small'}>-</Typography>
            </Box>
          );
        }

        return <MGCustomerWithAvatar customer={params.value} />;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 20,
      renderCell: (params) => {
        return (
          <Box display={'flex'} alignItems={'center'} height={'100%'}>
            <MeteringActionsMenu
              rowId={params.row.id as number}
              customerId={params.row.customer?.id}
              row={params.row}
            />
          </Box>
        );
      },
    },
  ];

  const showAlert = status === 'error';
  return (
    <>
      <PageLayout
        title={t('metering_page_title')}
        subtitle={t('metering_page_subtitle')}
        rightSection={<MeteringPageActionMenu />}
      >
        <Box sx={{ height: `calc(100% - ${showAlert ? '90px' : '0px'})` }}>
          {showAlert && (
            <Alert
              icon={<WifiOffOutlined />}
              severity={'error'}
              variant={'outlined'}
              sx={{ marginBottom: '20px' }}
            >
              <Typography variant={'small'} fontWeight={'fontWeightBold'}>
                {t('alert_title')}
              </Typography>
              <br />
            </Alert>
          )}
          <FilterProvider>
            <DataGridCard
              height={`calc(100vh - ${showAlert ? 370 : 290}px)`}
              datagridProps={{
                onRowClick: (row) => {
                  navigate(`${row.id}`);
                },
              }}
              rowHeight={70}
              showSearch={true}
              searchPlaceholder={tString('search_placeholder')}
              columns={columns}
              reactQueryHook={useListMeasuringPointPanelsInfinite}
              reactQueryHookParams={{
                include_current_measuring_point: true,
                include_customer: true,
                include_last_reading: true,
                include_measuring_points: true,
                include_subscriptions: true,
                ...projectFilter,
              }}
            />
          </FilterProvider>
        </Box>
      </PageLayout>
    </>
  );
};
