import {
  BillingIntervalSchemaOutput,
  BillingPreflightOut,
  GetBillingPreflightDataQuery,
} from '@mg/api-wrappers/src/api-internal';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { palette } from '@mg/ui/src/styles';
import { Card, Stack, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useMolecule } from 'bunshi/react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { billingMolecule } from '../../Billing.Atoms';

type Props = {
  queries: UseQueryResult<GetBillingPreflightDataQuery['Response'], GetBillingPreflightDataQuery['Errors']>[];
  onChange: (customer: BillingPreflightOut['customer']) => void;
  selectedCustomer: BillingIntervalSchemaOutput['customer'];
};

export const BillingDetailsPerCustomerSidebar = ({ queries, onChange, selectedCustomer }: Props) => {
  const { verifiedTransactionsAtom } = useMolecule(billingMolecule);
  const verifiedTransactions = useAtomValue(verifiedTransactionsAtom);

  const getStatus = (data: BillingPreflightOut) => {
    if (
      !data ||
      !data.is_billable ||
      data.levy_structure_errors?.length > 0 ||
      data.subscription_plan_errors?.length > 0 ||
      data.residual_power_plan_errors?.length > 0
    )
      return 'error';

    if (!verifiedTransactions[data?.customer?.id as number]) {
      return 'warning';
    }

    return 'success';
  };

  const CustomerSidebarItem = ({ selected = false, customer, loading, status }) => {
    return (
      <Stack
        direction={'row'}
        spacing={2}
        height={'40px'}
        alignItems={'center'}
        onClick={() => onChange(customer)}
        sx={{
          borderRadius: '8px',
          padding: 2,
          backgroundColor: selected ? palette.gray.light : 'none',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: palette.gray.light,
            transition: 'background-color 0.5s',
          },
        }}
      >
        <MGStatusIndicator status={status} loading={loading} />
        <Typography variant={'small'} noWrap>
          {customer?.display_name}
        </Typography>
      </Stack>
    );
  };

  const sortedData = useMemo(
    () =>
      queries?.sort((a, b) => {
        const statusA = getStatus(a.data!);
        const statusB = getStatus(b.data!);

        if (statusA && !statusB) return -1;
        if (!statusA && statusB) return 1;
        return 0;
      }),
    [queries],
  );

  return (
    <Card sx={{ padding: 2 }}>
      <ul style={{ listStyleType: 'none' }}>
        {sortedData?.map(({ data, isFetching }) => {
          if (!data) return null;
          return (
            <motion.li
              key={data?.customer?.id}
              layout
              style={{ marginTop: '5px' }}
              transition={{
                type: 'spring',
                damping: 25,
                stiffness: 120,
              }}
            >
              <CustomerSidebarItem
                key={data.customer?.display_name || '' + data.customer?.id}
                selected={selectedCustomer.id === data.customer?.id}
                customer={data.customer}
                loading={isFetching}
                status={getStatus(data)}
              />
            </motion.li>
          );
        })}
      </ul>
    </Card>
  );
};
