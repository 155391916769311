import { measuringPointDataNestedSchemaSchema } from './measuringPointDataNestedSchemaSchema.ts'
import { obisCodesSchema } from './obisCodesSchema.ts'
import { panelTypeSchema } from './panelTypeSchema.ts'
import { remoteReadingStatusSchema } from './remoteReadingStatusSchema.ts'
import { z } from 'zod'

export const measuringPointNestedSchemaSchema = z.object({
  conversion_factor: z.number().int(),
  id: z.number().int(),
  is_read_remotely: z.boolean(),
  last_reading: z.union([z.array(z.lazy(() => measuringPointDataNestedSchemaSchema)), z.null()]).optional(),
  obis_codes: z.array(z.lazy(() => obisCodesSchema)),
  owner_id: z.union([z.number().int(), z.null()]),
  panel_id: z.number().int(),
  remote_reading_status: z.array(z.lazy(() => remoteReadingStatusSchema)),
  serial: z.string(),
  type: z.lazy(() => panelTypeSchema),
})