import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountPlansQueryResponse, CountPlansQueryParams, CountPlans422 } from '../types/CountPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countPlansQueryKey = (params: CountPlansQueryParams) => [{ url: '/api-internal/plans/count' }, ...(params ? [params] : [])] as const

export type CountPlansQueryKey = ReturnType<typeof countPlansQueryKey>

/**
 * @summary Count Plans
 * {@link /api-internal/plans/count}
 */
async function countPlans(params: CountPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountPlansQueryResponse, CountPlans422, unknown>({ method: 'GET', url: `/api-internal/plans/count`, params, ...config })
  return res.data
}

export function countPlansQueryOptions(params: CountPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countPlansQueryKey(params)
  return queryOptions<CountPlansQueryResponse, CountPlans422, CountPlansQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countPlans(params, config)
    },
  })
}

/**
 * @summary Count Plans
 * {@link /api-internal/plans/count}
 */
export function useCountPlans<TData = CountPlansQueryResponse, TQueryData = CountPlansQueryResponse, TQueryKey extends QueryKey = CountPlansQueryKey>(
  params: CountPlansQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountPlansQueryResponse, CountPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countPlansQueryKey(params)

  const query = useQuery({
    ...(countPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}