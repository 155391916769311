import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListPlansQueryResponse, ListPlansQueryParams, ListPlans422 } from '../types/ListPlans.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listPlansInfiniteQueryKey = (params: ListPlansQueryParams) => [{ url: '/api-internal/plans/' }, ...(params ? [params] : [])] as const

export type ListPlansInfiniteQueryKey = ReturnType<typeof listPlansInfiniteQueryKey>

/**
 * @summary List Plans
 * {@link /api-internal/plans/}
 */
async function listPlans(params: ListPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListPlansQueryResponse, ListPlans422, unknown>({ method: 'GET', url: `/api-internal/plans/`, params, ...config })
  return res.data
}

export function listPlansInfiniteQueryOptions(params: ListPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listPlansInfiniteQueryKey(params)
  return infiniteQueryOptions<ListPlansQueryResponse, ListPlans422, ListPlansQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListPlansQueryParams['page']
      }
      return listPlans(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Plans
 * {@link /api-internal/plans/}
 */
export function useListPlansInfinite<
  TData = InfiniteData<ListPlansQueryResponse>,
  TQueryData = ListPlansQueryResponse,
  TQueryKey extends QueryKey = ListPlansInfiniteQueryKey,
>(
  params: ListPlansQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListPlansQueryResponse, ListPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPlansInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listPlansInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}