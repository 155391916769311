import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';

const getCSRFToken = (): string => {
  const input = document.getElementById('csrf_token');
  if (!input) {
    console.warn('CSRF token not found');
  }
  return input?.getAttribute('value') || '';
};

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_HOST,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFTOKEN': getCSRFToken(),
  },
  paramsSerializer: {
    indexes: null, // no brackets at all
  },
});

export type RequestConfig<TData = unknown> = AxiosRequestConfig<TData>;
export type ResponseConfig<TData = unknown> = AxiosResponse<TData>;

export const kubbClient = async <TData, TError = unknown, TVariables = unknown>(
  config: RequestConfig<TVariables>,
): Promise<ResponseConfig<TData>> => {
  return axiosInstance
    .request<TVariables, AxiosResponse<TData>>({ ...config })
    .catch((e: AxiosError<TError> | Error) => {
      if (isAxiosError<TError>(e) && e.response) {
        // switch (e.code) {
        //   case 'ERR_NETWORK':
        //     break;
        // }
        throw e.response.data;
      }
      throw e;
    });
};

export default kubbClient;
