import { CustomerSchema, useUpdateCustomerNotes } from '@mg/api-wrappers/src/api-internal';
import { MGCard, MGCardContent, MGCardHeader } from '@mg/ui/src/components/MGCard';
import { MGLoaderButton } from '@mg/ui/src/components/MGLoaderButton/MGLoaderButton';
import { palette } from '@mg/ui/src/styles/palette';
import { Stack, TextField, Typography } from '@mui/material';
import { CheckCheck, CircleAlert, NotebookText } from 'lucide-react';
import { useEffect, useState } from 'react';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../../i18n';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataNotesCard = ({ customer }: Props) => {
  const { t, tString } = useTranslation('customerDetails');
  const [textFieldValue, setTextFieldValue] = useState<string | undefined>(customer?.notes || '');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const updateNotesQuery = useUpdateCustomerNotes();

  useEffect(() => {
    setTextFieldValue(customer?.notes || '');
  }, [customer?.notes]);

  const isUnsavedNewContent = !!textFieldValue && customer?.notes !== textFieldValue;

  const handleSave = async () => {
    if (!customer) throw new Error('Customer is not defined');

    setButtonLoading(true);
    await updateNotesQuery.mutateAsync({ customer_id: customer.id!, data: { notes: textFieldValue || '' } });
    await invalidateAllQueries();
    setButtonLoading(false);
  };

  return (
    <MGCard>
      <MGCardHeader leftHeader={<NotebookText />} />
      <MGCardContent>
        <Stack spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'small'}>{t('data_notes_subtitle')}</Typography>
            <MGLoaderButton
              loading={buttonLoading}
              onClick={handleSave}
              variant={'contained'}
              disabled={!isUnsavedNewContent}
            >
              {t('data_notes_save')}
            </MGLoaderButton>
          </Stack>
          <TextField
            multiline
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            rows={8}
            error={isUnsavedNewContent}
            placeholder={tString('data_notes_placeholder')}
          />
          {isUnsavedNewContent ? (
            <Stack direction={'row'} alignItems={'center'} spacing={1} marginTop={'5px !important'}>
              <CircleAlert size={15} color={palette.error.main} />
              <Typography variant={'extraSmall'} color={'error'}>
                {t('data_notes_unsaved_changes')}
              </Typography>
            </Stack>
          ) : (
            <>
              {updateNotesQuery.isSuccess && (
                <Stack direction={'row'} alignItems={'center'} spacing={1} marginTop={'5px !important'}>
                  <CheckCheck size={15} color={palette.success.dark} />
                  <Typography variant={'extraSmall'} color={'secondary.dark'}>
                    {t('data_notes_success_message')}
                  </Typography>
                </Stack>
              )}
              {updateNotesQuery.isError && (
                <Stack direction={'row'} alignItems={'center'} spacing={1} marginTop={'5px !important'}>
                  <CircleAlert size={15} color={palette.error.main} />
                  <Typography variant={'extraSmall'} color={'error'}>
                    {t('data_notes_error_message')}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </MGCardContent>
    </MGCard>
  );
};
