import { MeasuringPointDataNestedSchema, MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { presentKwh } from '@mg/ui/src/presenters';
import { presentDateTime } from '@mg/ui/src/presenters/date.ts';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useTranslation } from '../../../i18n';
import { MeteringHistoryTableRowActionMenu } from './MeteringHistory.Table.RowActionMenu';

type Props = {
  measuringPointList: MeasuringPointSchema[] | undefined;
};

export const MeteringHistoryTable = ({ measuringPointList }: Props) => {
  const { tString } = useTranslation('meteringDetails');

  const flatDataArray = measuringPointList
    ?.map((measuringPoint) => {
      return Object.values(measuringPoint.data || {})
        .flat()
        .map((data: MeasuringPointDataNestedSchema) => {
          return {
            serial: measuringPoint.serial,
            ...data,
          };
        });
    })
    .flat();

  const columns: GridColDef<MeasuringPointDataNestedSchema>[] = [
    {
      field: 'id',
    },
    {
      field: 'serial',
      headerName: tString('meter_serial'),
      flex: 1,
    },
    {
      field: 'obis_code',
      headerName: tString('meter_obis'),
      flex: 1,
    },
    {
      field: 'timestamp',
      headerName: tString('data_card_timestamp'),
      flex: 1,
      renderCell: (params) => presentDateTime(params.value),
    },
    {
      field: 'value',
      headerName: tString('data_card_value'),
      flex: 1,
      renderCell: (params) => presentKwh(params.value),
    },
    {
      field: 'actions',
      flex: 0.2,
      headerName: '',
      align: 'right',
      renderCell: (params) => <MeteringHistoryTableRowActionMenu row={params.row} />,
    },
  ];

  const uniqueData = _.uniqBy(flatDataArray || [], 'id');

  return (
    <DataGridPro
      disableColumnMenu
      disableColumnResize
      isCellEditable={() => false}
      isRowSelectable={() => false}
      hideFooter
      rows={uniqueData}
      loading={false}
      autoPageSize={false}
      columnVisibilityModel={{
        id: false,
      }}
      sx={{
        '.MuiDataGrid-row': { paddingY: '0px' },
        '.MuiDataGrid-filler': { height: '0px' },
      }}
      columns={columns}
    />
  );
};
