import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  GetPlanChangeDocumentPdfsAsZipQueryResponse,
  GetPlanChangeDocumentPdfsAsZipQueryParams,
  GetPlanChangeDocumentPdfsAsZip400,
} from '../types/GetPlanChangeDocumentPdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPlanChangeDocumentPdfsAsZipQueryKey = (params: GetPlanChangeDocumentPdfsAsZipQueryParams) =>
  [{ url: '/api-internal/plan_change_documents/pdfs' }, ...(params ? [params] : [])] as const

export type GetPlanChangeDocumentPdfsAsZipQueryKey = ReturnType<typeof getPlanChangeDocumentPdfsAsZipQueryKey>

/**
 * @summary Get Plan Change Document Pdfs As Zip
 * {@link /api-internal/plan_change_documents/pdfs}
 */
async function getPlanChangeDocumentPdfsAsZip(params: GetPlanChangeDocumentPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetPlanChangeDocumentPdfsAsZipQueryResponse, GetPlanChangeDocumentPdfsAsZip400, unknown>({
    method: 'GET',
    url: `/api-internal/plan_change_documents/pdfs`,
    params,
    ...config,
  })
  return res.data
}

export function getPlanChangeDocumentPdfsAsZipQueryOptions(params: GetPlanChangeDocumentPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getPlanChangeDocumentPdfsAsZipQueryKey(params)
  return queryOptions<
    GetPlanChangeDocumentPdfsAsZipQueryResponse,
    GetPlanChangeDocumentPdfsAsZip400,
    GetPlanChangeDocumentPdfsAsZipQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPlanChangeDocumentPdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Plan Change Document Pdfs As Zip
 * {@link /api-internal/plan_change_documents/pdfs}
 */
export function useGetPlanChangeDocumentPdfsAsZip<
  TData = GetPlanChangeDocumentPdfsAsZipQueryResponse,
  TQueryData = GetPlanChangeDocumentPdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetPlanChangeDocumentPdfsAsZipQueryKey,
>(
  params: GetPlanChangeDocumentPdfsAsZipQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetPlanChangeDocumentPdfsAsZipQueryResponse, GetPlanChangeDocumentPdfsAsZip400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPlanChangeDocumentPdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getPlanChangeDocumentPdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPlanChangeDocumentPdfsAsZip400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}