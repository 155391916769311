import { MGIcon } from '@mg/ui/src/components/MGIcon';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Lock } from 'lucide-react';
import { useTranslation } from '../../../i18n';

export const SidebarItemOnboardingTooltip = ({ children, hidden = true }) => {
  const { t } = useTranslation('sidebar');
  return (
    <Tooltip
      children={children}
      slotProps={{
        tooltip: {
          sx: {
            padding: '20px',
            backgroundColor: '#F3F4F5',
            maxHeight: '200px',
            border: '1px solid #ccd6ec',
            '& .MuiTooltip-arrow': {
              color: '#F3F4F5',
            },
          },
        },
      }}
      title={
        hidden ? null : (
          <Grid container color={'#051C2C'} spacing={2}>
            <Grid item xs={12}>
              <MGIcon variant={'soft'}>
                <Lock />
              </MGIcon>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'small'}>{t('onboarding_tooltip_title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'extraSmall'} fontWeight={'300'}>
                {t('onboarding_tooltip_subtitle')}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      placement={'right'}
      arrow
    />
  );
};
