import { palette } from '@mg/ui/src/styles';
import { Box, Button, ButtonProps, IconButton, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { Settings2, X } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from '../../../i18n';
import { EverythingSelectedAtom, filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { MuiBaseModal } from '../../modals/MuiBaseModal';
import { ActionBarFilterView } from './DatagridCard.ActionBar/ActionBarFilterView';
import { ActionBarSelectionView } from './DatagridCard.ActionBar/ActionBarSelectionView';

type Props = {
  filteredItemCount?: number;
  selectedItemCount: number;
  onSelectAll: () => void;
  onSelectionCancel?: () => void;
  filterModalContent: ReactNode;
};

export const DataGridCardActionBar = ({
  filteredItemCount,
  selectedItemCount,
  onSelectAll,
  onSelectionCancel,
  filterModalContent,
}: Props) => {
  const [filterValue, setFilterValue] = useAtom(filterValueAtom);
  const [filterModalOpen, setFilterModalOpen] = useAtom(filterModalOpenAtom);
  const [, setEverythingSelected] = useAtom(EverythingSelectedAtom);
  const { t } = useTranslation('actionBar');

  return (
    <>
      <Box
        position="fixed"
        bottom={20}
        left={'calc(50% + 140px )'}
        sx={{
          transform: 'translate(-50%)',
        }}
      >
        {selectedItemCount > 0 ? (
          <ActionBarSelectionView
            selectedItemCount={selectedItemCount}
            onSelectAll={() => {
              setEverythingSelected(true);
              onSelectAll();
            }}
            onSelectionCancel={onSelectionCancel}
          />
        ) : Object.keys(filterValue).length > 0 ? (
          <ActionBarFilterView
            filteredItemCount={filteredItemCount}
            showEditFilter={!!filterModalContent}
            onFilterEdit={() => setFilterModalOpen(!filterModalOpen)}
            onFilterCancel={() => setFilterValue({})}
          />
        ) : filterModalContent ? (
          <Button
            size={'large'}
            variant={'contained'}
            startIcon={<Settings2 size={20} />}
            onClick={() => setFilterModalOpen(true)}
          >
            {t('filter_selection')}
          </Button>
        ) : (
          <></>
        )}
      </Box>
      <MuiBaseModal
        open={filterModalOpen}
        handleToggle={() => setFilterModalOpen(!filterModalOpen)}
        width={600}
        height={600}
        BodyStyle={{ height: '100%' }}
      >
        <>{filterModalContent}</>
      </MuiBaseModal>
    </>
  );
};

export const ActionBar = ({ onCancel, children }: { onCancel: () => void } & PropsWithChildren) => {
  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="center"
      gap={2}
      height={'42px'}
      sx={{
        px: 2,
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        backgroundColor: palette.primary.main,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
      >
        <X size={20} color={palette.primary.contrastText} />
      </IconButton>
      {children}
    </Stack>
  );
};

export const ActionBarButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      size={'large'}
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: 'transparent',
        fontWeight: '400',
        fontSize: '14px',
        color: palette.primary.contrastText,
        boxShadow: 'none',
        ':hover': {
          background: 'transparent',
        },

        ':disabled': {
          color: palette.primary.dark,
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick(e);
      }}
    />
  );
};

export const ActionBarDivider = () => <Box borderRight="1px solid white" height={20} px={1} />;
