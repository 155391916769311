import { useCountCustomers } from '@mg/api-wrappers/src/api-internal';
import { Button, Typography } from '@mui/material';
import { Files } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { DashboardCard } from '../Dashboard.Card';

export const DashboardQuickActionsCustomers = () => {
  const projectFilter = useSelectedProjectsFilter();
  const customerQuery = useCountCustomers({ ...projectFilter });

  const { t } = useTranslation('dashboard');
  return (
    <DashboardCard
      loading={customerQuery.isLoading}
      title={t('quick_actions_customer_title')}
      subtitle={
        <Typography variant={'small'}>
          {t('quick_actions_customer_subtitle', {
            activeCount: customerQuery.data?.active,
            cancelledCount: customerQuery.data?.cancelled,
            futureCount: customerQuery.data?.future,
          })}{' '}
        </Typography>
      }
      icon={<Files />}
      button={
        <Button variant={'contained'} color={'white'} component={Link} to={'/customers'}>
          {t('quick_actions_customer_last_button')}
        </Button>
      }
    />
  );
};
