import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountInvoicesQueryResponse, CountInvoicesQueryParams, CountInvoices422 } from '../types/CountInvoices.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countInvoicesQueryKey = (params: CountInvoicesQueryParams) => [{ url: '/api-internal/invoices/count' }, ...(params ? [params] : [])] as const

export type CountInvoicesQueryKey = ReturnType<typeof countInvoicesQueryKey>

/**
 * @summary Count Invoices
 * {@link /api-internal/invoices/count}
 */
async function countInvoices(params: CountInvoicesQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountInvoicesQueryResponse, CountInvoices422, unknown>({ method: 'GET', url: `/api-internal/invoices/count`, params, ...config })
  return res.data
}

export function countInvoicesQueryOptions(params: CountInvoicesQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countInvoicesQueryKey(params)
  return queryOptions<CountInvoicesQueryResponse, CountInvoices422, CountInvoicesQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countInvoices(params, config)
    },
  })
}

/**
 * @summary Count Invoices
 * {@link /api-internal/invoices/count}
 */
export function useCountInvoices<
  TData = CountInvoicesQueryResponse,
  TQueryData = CountInvoicesQueryResponse,
  TQueryKey extends QueryKey = CountInvoicesQueryKey,
>(
  params: CountInvoicesQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountInvoicesQueryResponse, CountInvoices422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countInvoicesQueryKey(params)

  const query = useQuery({
    ...(countInvoicesQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountInvoices422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}