import {
  countLettersQueryKey,
  LetterCreateSchema,
  LetterUpdateSchema,
  listLettersQueryKey,
  useCreateLetter,
  useGetCustomer,
  useListLetters,
  useUpdateLetter,
} from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard';
import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout/MGPageLayout';
import { Box, Button, Chip, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ArrowLeft, Files, User, Users } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useTranslation } from '../../../i18n';

import { CreateLetterDocumentPreview } from './CreateLetter.DocumentPreview';

import { MGLoader } from '@mg/ui/src/components/MGLoader/MGLoader';
import { Descendant } from '@mg/ui/src/components/MGRichTextEditor/MGRichTextEditor.CustomTypes';
import {
  deserializeHtmlStr,
  serializeAst,
} from '@mg/ui/src/components/MGRichTextEditor/MGRichTextEditor.Utils';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useForm, useWatch } from 'react-hook-form';
import { downloadFileContent } from '../../../helpers/downloadFile';
import { CreateLetterForm, CreateLetterFormValues } from './CreateLetter.Form';
import { generatePreviewPdf } from './CreateLetter.utils';

export const CreateLetter = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const projectId = useCurrentProject();
  const { t } = useTranslation('createLetter');

  const editLetterId = parseInt(params.letterId ?? 'unset', 10);
  const isEditPage = !isNaN(editLetterId);
  const searchParamTenant = searchParams.get('tenant');

  const [tabValue, setTabValue] = useState(0);

  const createLetterFormMethods = useForm<CreateLetterFormValues>({
    values: { subject: '', issue_date: null },
    defaultValues: { subject: '', issue_date: dayjs() },
  });
  const subjectValue = useWatch({ control: createLetterFormMethods.control, name: 'subject' });
  const issue_dateValue = useWatch({ control: createLetterFormMethods.control, name: 'issue_date' });

  const [ast, setAst] = useState<Descendant[] | undefined>();
  const bodyValue = useMemo(() => serializeAst(ast ?? []), [ast]);

  const queryClient = useQueryClient();
  const invalidateCreateUpdateRelatedQueries = () => {
    queryClient.invalidateQueries({ queryKey: [listLettersQueryKey({ project_id: -1 })[0]] });
    queryClient.invalidateQueries({ queryKey: [countLettersQueryKey({ project_id: -1 })[0]] });
  };

  const createLetterMutation = useCreateLetter({
    mutation: {
      onSuccess: () => invalidateCreateUpdateRelatedQueries(),
    },
  });

  const listLettersQuery = useListLetters(
    {
      project_id: projectId,
      order_by: 'id',
      ids: [editLetterId],
      include_customer: true,
    },
    { query: { enabled: isEditPage } },
  );

  const editLetter = useMemo(
    () => createLetterMutation.data ?? listLettersQuery.data?.items[0],
    [listLettersQuery.data, createLetterMutation.data],
  );

  const receivingCustomerId = editLetter?.customer?.id ?? parseInt(searchParamTenant ?? '', 10);
  const getCustomerQuery = useGetCustomer(
    receivingCustomerId ?? -1,
    { include_address: true, include_postal_address: true, include_subscription: true },
    { query: { enabled: !!receivingCustomerId } },
  );
  const receivingCustomer = getCustomerQuery.data;

  const updateLetterMutation = useUpdateLetter({
    mutation: {
      onSuccess: () => invalidateCreateUpdateRelatedQueries(),
    },
  });

  const letter: LetterCreateSchema = {
    subject: subjectValue,
    body: bodyValue,
    issue_date: (issue_dateValue ?? dayjs()).format('YYYY-MM-DD'),
    project_id: projectId,
    subscription_id: receivingCustomer?.subscription?.id,
  };

  const letterUpdate: LetterUpdateSchema = {
    subject: subjectValue,
    body: bodyValue,
    issue_date: (issue_dateValue ?? dayjs()).format('YYYY-MM-DD'),
    subscription_id: receivingCustomer?.subscription?.id,
  };

  const isAnyQueryLoading = listLettersQuery.isLoading || getCustomerQuery.isLoading;
  const isLoading = isAnyQueryLoading;

  // Initialize form with values
  useEffect(() => {
    if (createLetterMutation.isSuccess || updateLetterMutation.isSuccess) {
      // No init because mutation was executed
      return;
    }

    if (isEditPage && editLetter) {
      // Initialize form with values in case of an existing letter
      createLetterFormMethods.setValue('subject', editLetter.subject ?? '');
      createLetterFormMethods.setValue('issue_date', dayjs(editLetter.issue_date));
      setAst(deserializeHtmlStr(editLetter.body ?? '<p></p>'));
    } else if (!isEditPage) {
      // Initialize form with default values in case of a new letter
      createLetterFormMethods.setValue('subject', '');
      createLetterFormMethods.setValue('issue_date', dayjs());
      setAst(deserializeHtmlStr('<p></p>'));
    }
  }, [
    editLetter,
    createLetterFormMethods,
    searchParamTenant,
    isEditPage,
    createLetterMutation.isSuccess,
    updateLetterMutation.isSuccess,
  ]);

  return (
    <>
      <MGPageLayout>
        {isLoading && (
          <Box sx={{ height: 250 }}>
            <MGLoader />
          </Box>
        )}
        {!isLoading && (
          <Box mt={5}>
            <Button
              variant={'text'}
              component={Link}
              to={'/documents?tab=letter'}
              startIcon={<ArrowLeft size={20} />}
              endIcon={<Files size={20} />}
            >
              {t('link_back')}
            </Button>

            <MGCard sx={{ marginTop: 2 }}>
              <Stack spacing={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'baseline'} gap={2}>
                  <Typography variant={'h1'} sx={{ whiteSpace: 'nowrap' }}>
                    {t('page_title')}
                  </Typography>
                  <Chip
                    size="small"
                    label={
                      <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                        {receivingCustomer ? (
                          <>
                            <User size={16} />
                            <span>
                              {t('badge_receiver_display_name', {
                                str: receivingCustomer?.display_name ?? '',
                              })}
                            </span>
                          </>
                        ) : (
                          <>
                            <Users size={16} />
                            <span>{t('badge_receiver_all_tenants')}</span>
                          </>
                        )}
                      </Stack>
                    }
                  />
                </Stack>

                <Tabs value={tabValue} onChange={(_event, value) => setTabValue(value)}>
                  <Tab label={t('tab_form')} sx={{ my: 2 }} />
                  <Tab label={t('tab_preview')} sx={{ my: 2 }} />
                </Tabs>
              </Stack>

              {tabValue === 0 && (
                <CreateLetterForm
                  formMethods={createLetterFormMethods}
                  initialBodyAst={ast}
                  onBodyAstChange={setAst}
                />
              )}

              {tabValue === 1 && (
                <Box mt={2}>
                  <CreateLetterDocumentPreview letter={letter} />
                </Box>
              )}

              <Stack direction={'row'} justifyContent={'flex-end'} gap={2} mt={4}>
                <Button
                  variant={'contained'}
                  color={'white'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/documents');
                  }}
                >
                  {t('btn_close')}
                </Button>

                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={(e) => {
                    e.preventDefault();
                    editLetter
                      ? updateLetterMutation.mutate({ id: editLetter?.id ?? -1, data: letterUpdate })
                      : createLetterMutation.mutate({ data: letter });
                  }}
                >
                  {t('btn_save')}
                </Button>

                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={(e) => {
                    e.preventDefault();
                    editLetter
                      ? updateLetterMutation.mutate({ id: editLetter?.id ?? -1, data: letterUpdate })
                      : createLetterMutation.mutate({ data: letter });
                    generatePreviewPdf(letter).then((pdfBlob) => {
                      // FIXME: This is a workaround to fix the filename issue. When using tolgee, the filename is not displayed correctly (trailing '____' characters)
                      // const fileName = tString('preview_file_name');
                      return downloadFileContent(pdfBlob, 'Brief.pdf');
                    });
                  }}
                >
                  {t('btn_save_and_download')}
                </Button>
              </Stack>
            </MGCard>
          </Box>
        )}
      </MGPageLayout>
    </>
  );
};
