import { EditOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

type Props = {
  projectId: number;
};
export const ProjectsRowActionsMenu = ({ projectId }: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const { t } = useTranslation('projects');

  const menuItems: MenuItem[] = [
    {
      text: t('edit_project'),
      icon: <EditOutlined />,
      onClick: () => setEditModalOpen(!editModalOpen),
    },
  ];

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
      <DjangoFormModal
        pathToDjangoForm={`/projects/${projectId}/edit/`}
        title={t('edit_project') as string}
        open={editModalOpen}
        handleToggle={() => {
          setEditModalOpen(!editModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          setEditModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
      />
    </>
  );
};
