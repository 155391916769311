import { Box, Card, Collapse, IconButton, Stack } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';

export type MGControlledCollapsibleCardProps = {
  collapsedContent: ReactNode;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
} & PropsWithChildren;

export const MGControlledCollapsibleCard = ({
  children,
  expanded,
  collapsedContent,
  onExpand,
}: MGControlledCollapsibleCardProps) => {
  return (
    <Card sx={{ padding: '25px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} onClick={() => onExpand(!expanded)}>
        {collapsedContent}
        <IconButton onClick={() => onExpand(!expanded)}>
          {expanded ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </Stack>
      <Collapse in={expanded} timeout="auto">
        <Box sx={{ paddingY: '20px' }}>{children}</Box>
      </Collapse>
    </Card>
  );
};
