import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountProjectsQueryResponse, CountProjects422 } from '../types/CountProjects.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countProjectsQueryKey = () => [{ url: '/api-internal/projects/count' }] as const

export type CountProjectsQueryKey = ReturnType<typeof countProjectsQueryKey>

/**
 * @summary Count Projects
 * {@link /api-internal/projects/count}
 */
async function countProjects(config: Partial<RequestConfig> = {}) {
  const res = await client<CountProjectsQueryResponse, CountProjects422, unknown>({ method: 'GET', url: `/api-internal/projects/count`, ...config })
  return res.data
}

export function countProjectsQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = countProjectsQueryKey()
  return queryOptions<CountProjectsQueryResponse, CountProjects422, CountProjectsQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countProjects(config)
    },
  })
}

/**
 * @summary Count Projects
 * {@link /api-internal/projects/count}
 */
export function useCountProjects<
  TData = CountProjectsQueryResponse,
  TQueryData = CountProjectsQueryResponse,
  TQueryKey extends QueryKey = CountProjectsQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<CountProjectsQueryResponse, CountProjects422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countProjectsQueryKey()

  const query = useQuery({
    ...(countProjectsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountProjects422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}