import { z } from 'zod'

/**
 * @description An error intended to be exposed to the user.\n\nInheriting classes must define a `_message_template` attribute, and optionally additional attributes with\nprimitive type hints. The `_message_template` can use attribute values using placeholders of the same name.\n\nThe class name is used as the error code, and must be all uppercase and unique across the application.
 */
export const publicErrorSchema = z
  .object({
    code: z.string(),
    message: z.string(),
  })
  .describe(
    'An error intended to be exposed to the user.\n\nInheriting classes must define a `_message_template` attribute, and optionally additional attributes with\nprimitive type hints. The `_message_template` can use attribute values using placeholders of the same name.\n\nThe class name is used as the error code, and must be all uppercase and unique across the application.',
  )