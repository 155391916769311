import { Button } from '@mui/material';
import { Pencil } from 'lucide-react';
import { useTranslation } from '../../i18n';

export const EditLevyStructureModal = ({ levyStructureId, disabled }) => {
  const { t } = useTranslation('levies');

  return (
    <>
      <Button
        size={'large'}
        color={'primary'}
        variant={'contained'}
        href={`/levystructures/${levyStructureId}/edit/`}
        endIcon={<Pencil size={18} />}
        sx={{ marginLeft: '20px' }}
        disabled={disabled}
      >
        {t('edit')}
      </Button>
    </>
  );
};
