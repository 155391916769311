import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListSubscriptionPlansQueryResponse, ListSubscriptionPlansQueryParams, ListSubscriptionPlans422 } from '../types/ListSubscriptionPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listSubscriptionPlansQueryKey = (params: ListSubscriptionPlansQueryParams) =>
  [{ url: '/api-internal/subscription_plans/' }, ...(params ? [params] : [])] as const

export type ListSubscriptionPlansQueryKey = ReturnType<typeof listSubscriptionPlansQueryKey>

/**
 * @summary List Subscription Plans
 * {@link /api-internal/subscription_plans/}
 */
async function listSubscriptionPlans(params: ListSubscriptionPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListSubscriptionPlansQueryResponse, ListSubscriptionPlans422, unknown>({
    method: 'GET',
    url: `/api-internal/subscription_plans/`,
    params,
    ...config,
  })
  return res.data
}

export function listSubscriptionPlansQueryOptions(params: ListSubscriptionPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listSubscriptionPlansQueryKey(params)
  return queryOptions<ListSubscriptionPlansQueryResponse, ListSubscriptionPlans422, ListSubscriptionPlansQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listSubscriptionPlans(params, config)
    },
  })
}

/**
 * @summary List Subscription Plans
 * {@link /api-internal/subscription_plans/}
 */
export function useListSubscriptionPlans<
  TData = ListSubscriptionPlansQueryResponse,
  TQueryData = ListSubscriptionPlansQueryResponse,
  TQueryKey extends QueryKey = ListSubscriptionPlansQueryKey,
>(
  params: ListSubscriptionPlansQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListSubscriptionPlansQueryResponse, ListSubscriptionPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listSubscriptionPlansQueryKey(params)

  const query = useQuery({
    ...(listSubscriptionPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListSubscriptionPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}