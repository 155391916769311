import { ReactElement, cloneElement } from 'react';
import { useMultiStepPanelContext } from './MultiStepPanel.Context';

export type MultiStepPanelView = {
  layout: ReactElement;
};

export const MultiStepPanelView = ({ layout }: MultiStepPanelView) => {
  const { currentStep } = useMultiStepPanelContext();

  return layout ? cloneElement(layout, {}, currentStep.content) : currentStep.content;
};
