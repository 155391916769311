import * as d3 from 'd3';

// Function to generate a hex color based on a string
export const generateColorFromId = (id: number) => {
  return d3.interpolateRainbow(hashToFloatBetweenZeroAndOne(id));
};

export const hashToFloatBetweenZeroAndOne = (key) => {
  // Simple hash using bitwise operations (you could also just use the key directly)
  let hash = key * 2654435761; // Multiply by a large prime number for some mixing
  hash = hash >>> 0; // Convert to unsigned 32-bit integer

  // Normalize to a float between 0 and 1
  return hash / 0xffffffff;
};
