import { createContext, useContext } from 'react';
import { Step } from './MultiStepPanel.Types';

export const MultiStepPanelContext = createContext<
  | {
      currentStep: Step;
      context: Record<string, unknown>;
      setContext: (context: Record<string, unknown>) => void;
      next: (context?: Record<string, unknown>) => void;
      previous: (context?: Record<string, unknown>) => void;
      goto: (key: string, context?: Record<string, unknown>) => void;
    }
  | undefined
>(undefined);

export const useMultiStepPanelContext = () => {
  const context = useContext(MultiStepPanelContext);
  if (context === undefined) {
    throw new Error('useMultiStepPanelontext must be used within a MultiStepPanelProvider');
  }
  return context;
};
