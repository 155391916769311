import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetLevyStructureQueryResponse, GetLevyStructurePathParams, GetLevyStructure422 } from '../types/GetLevyStructure.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLevyStructureQueryKey = (id: GetLevyStructurePathParams['id']) => [{ url: '/api-internal/levy_structures/:id', params: { id: id } }] as const

export type GetLevyStructureQueryKey = ReturnType<typeof getLevyStructureQueryKey>

/**
 * @summary Get Levy Structure
 * {@link /api-internal/levy_structures/:id}
 */
async function getLevyStructure(id: GetLevyStructurePathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetLevyStructureQueryResponse, GetLevyStructure422, unknown>({
    method: 'GET',
    url: `/api-internal/levy_structures/${id}`,
    ...config,
  })
  return res.data
}

export function getLevyStructureQueryOptions(id: GetLevyStructurePathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getLevyStructureQueryKey(id)
  return queryOptions<GetLevyStructureQueryResponse, GetLevyStructure422, GetLevyStructureQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLevyStructure(id, config)
    },
  })
}

/**
 * @summary Get Levy Structure
 * {@link /api-internal/levy_structures/:id}
 */
export function useGetLevyStructure<
  TData = GetLevyStructureQueryResponse,
  TQueryData = GetLevyStructureQueryResponse,
  TQueryKey extends QueryKey = GetLevyStructureQueryKey,
>(
  id: GetLevyStructurePathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetLevyStructureQueryResponse, GetLevyStructure422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLevyStructureQueryKey(id)

  const query = useQuery({
    ...(getLevyStructureQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetLevyStructure422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}