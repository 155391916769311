import { z } from 'zod'

export const measuringPointDataSchemaSchema = z.object({
  id: z.union([z.number().int(), z.null()]).optional(),
  is_deletable: z.boolean(),
  is_editable: z.boolean(),
  measuring_point_id: z.number().int(),
  obis_code: z
    .string()
    .max(128)
    .default('1-0:1.8.0')
    .describe('The OBIS code identifies the corresponding device value.It is a text string composed according to the OBIS standard (see IEC 62056-61).'),
  origin: z.string().max(255).describe('The origin of the measuring point data.'),
  project_id: z.number().int(),
  reason: z.string().max(255).default('NONE').describe('The reason for creating measuring point data.'),
  timestamp: z.string().datetime().describe('Records when the data item was read from the device.'),
  value: z.number(),
})