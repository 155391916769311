import { useCountProjects, useGetProject } from '@mg/api-wrappers/src/api-internal';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useTranslation } from '../../../i18n';
import { Loader } from '../../shared/Loader/Loader';
import { SidebarProjectSelectorSearch } from './Sidebar.ProjectSelector.Search';

export const SidebarProjectSelector = () => {
  const [open, setOpen] = useState(false);
  const currentProjectId = useCurrentProject();
  const { t } = useTranslation('sidebar');

  // Future feature request to show data across all projects
  const projectQuery = useGetProject(currentProjectId);
  const projectCountQuery = useCountProjects();

  const currentProject = projectQuery.data;
  const isOnlyOneProject = projectCountQuery.data === 1;

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Grid container id={'project-select-main-div'}>
        <Grid item xs={4}>
          <Avatar
            src={'/static/img/logo-signet.svg'}
            variant={'square'}
            sx={{ borderRadius: '10px', width: '50px', height: '50px' }}
          />
        </Grid>
        <Grid item xs={8} container>
          <Grid item container xs={isOnlyOneProject ? 12 : 10} display={'flex'} alignContent={'center'}>
            {projectQuery.isLoading || projectCountQuery.isLoading ? (
              // @ts-expect-error TS2322
              <Loader width={'30px'} height={'30px'} />
            ) : (
              <>
                <Grid item xs={12} display={'flex'}>
                  <Typography fontWeight={'fontWeightBold'} variant={'small'}>
                    {!currentProject ? t('new_project') : currentProject.name || 'Projekt'}
                  </Typography>
                </Grid>
                <Grid item xs={12} display={'flex'}>
                  <Typography variant={'extraSmall'} noWrap>
                    {currentProject?.location}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          {!isOnlyOneProject && (
            <Grid item xs={2} display={'flex'} alignItems={'center'}>
              <IconButton id={'dropdown-button'} onClick={() => setOpen(!open)}>
                <ChevronsUpDown size={20} />
              </IconButton>
              <SidebarProjectSelectorSearch open={open} setOpen={setOpen} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
