import { Button, Grid, Paper, Typography } from '@mui/material';
import { Lightbulb } from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { SoftIcon } from '../../shared/SoftIcon';

export const SupportPageFAQCard = () => {
  const { t } = useTranslation('supportPage');

  const openFaqBar = () => {
    const elements = document.getElementsByClassName('helpbar-placeholder');
    const faqBar = elements[0];
    faqBar.classList.add('open');
  };

  return (
    <>
      <Paper sx={{ padding: '50px' }}>
        <Grid container>
          <Grid item xs={12} marginBottom={'20px'}>
            <SoftIcon>
              <Lightbulb />
            </SoftIcon>
          </Grid>
          <Grid item xs={12} marginBottom={'10px'}>
            <Typography variant={'h5'} fontWeight={900}>
              {t('faq_title')}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={'30px'}>
            <Typography>{t('faq_subtitle')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={openFaqBar} variant={'contained'} color={'gray'}>
              {t('faq_open_button')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
