export interface PresentableCustomer {
  first_name?: string | null;
  last_name?: string | null;
  company?: string | null;
  address?: {
    street?: string | null;
    street_number?: string | null;
    address_2?: string | null;
    zip_code?: string | null;
    city?: string | null;
  };
}

export const customerDisplayName = (customer: PresentableCustomer) => {
  if (customer.first_name || customer.last_name) {
    return customerFullName(customer);
  } else if (customer.company) {
    return customer.company;
  } else {
    return `Kunde in ${customerAddressOneLine(customer)}`;
  }
};

export const customerFullName = (customer: PresentableCustomer) => {
  return `${customer.first_name} ${customer.last_name}`.trim();
};

export const customerAddressOneLine = (customer: PresentableCustomer) => {
  return customerAddressLines(customer).join(', ');
};

export const customerAddressMultiLine = (customer: PresentableCustomer) => {
  return customerAddressLines(customer).join('\n');
};

const customerAddressLines = (customer: PresentableCustomer): string[] => {
  if (!customer.address) return [];

  return [
    `${customer.address.street} ${customer.address.street_number}`,
    customer.address.address_2,
    `${customer.address.zip_code} ${customer.address.city}`,
  ].filter((line): line is string => Boolean(line && line.trim() != ''));
};
