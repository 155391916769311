import { ThemeProvider } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { theme } from '../../styles';
import { ConfirmArgs, MGConfirmModalComponent } from './MGConfirmModal.Component';
import { MGConfirmModalComponentInputProps } from './MGConfirmModal.Component.Input';

export interface MGConfirmModalOptions {
  title: string;
  text?: string;
  type: 'info' | 'error' | 'success';
  inputType?: MGConfirmModalComponentInputProps['input'];
  inputLabel?: string;
  hideCancelButton?: boolean;
  hideConfirmButton?: boolean;
  cancelButtonText?: string;
  cancelButtonIcon?: ReactElement;
  confirmButtonText?: string;
  confirmButtonIcon?: ReactElement;
  customContent?: ReactNode;
}

interface MGConfirmModalReturnValue extends Partial<ConfirmArgs> {
  isConfirmed: boolean;
  isCancelled: boolean;
}

// Function to create and control the modal, returning a Promise
export const MGConfirmModal = {
  fire: (options: MGConfirmModalOptions): Promise<MGConfirmModalReturnValue> => {
    return new Promise((resolve) => {
      const modalContainer = document.createElement('div');
      const root = createRoot(modalContainer);

      root.render(
        <ThemeProvider theme={theme}>
          <MGConfirmModalComponent
            open={true}
            handleConfirm={({ value, customFormValues }) => {
              resolve({ isConfirmed: true, isCancelled: false, value, customFormValues });
              root.unmount();
            }}
            handleCancel={() => {
              resolve({ isConfirmed: false, isCancelled: true });
              root.unmount();
            }}
            options={options}
          />
        </ThemeProvider>,
      );
    });
  },
};
