import { palette } from '@mg/ui/src/styles';
import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTranslation } from '../../../../i18n';
import loader from '../../../../lottie/downloading.json';
import { useMultiStepPanelContext } from '../../../shared/MultiStepPanel/MultiStepPanel.Context';
import { StepKey } from '../constants';
import { FUNNEL_CONTEXT_INITIAL_DATA, useFunnelContext } from '../FunnelProvider.Context';

export const DownloadingStep = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation('sepaGeneration');
  const { setData: setFunnelData } = useFunnelContext();
  const { goto } = useMultiStepPanelContext();

  useEffect(() => {
    setTimeout(() => {
      closeModal();
      setFunnelData({ ...FUNNEL_CONTEXT_INITIAL_DATA });
      goto(StepKey.TimeFrame);
    }, 5000);
  });

  return (
    <>
      <Box height={'150px'} width={'150px'} mx={'auto'}>
        <Lottie animationData={loader} loop={true} />
      </Box>
      <Box textAlign={'center'} mb={4}>
        <Typography component={'p'} variant="large" fontSize={24} color={palette.text.black} mb={1}>
          {t('download_step_title')}
        </Typography>
        <Typography component={'p'} variant="small" color={palette.text.gray}>
          {t('download_step_subtitle')}
        </Typography>
      </Box>
    </>
  );
};
