import { obisCodesSchema } from './obisCodesSchema.ts'
import { z } from 'zod'

export const measuringPointDataFilterSchemaSchema = z.object({
  measuring_point_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  obis_codes: z.union([z.array(z.lazy(() => obisCodesSchema)), z.null()]).optional(),
  panel_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  timestamp_from: z.union([z.string().datetime(), z.null()]).optional(),
  timestamp_to: z.union([z.string().datetime(), z.null()]).optional(),
})