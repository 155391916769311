import { BillingIntervalSchemaOutput } from '@mg/api-wrappers/src/api-internal';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { createScope, molecule } from 'bunshi';
import { Dayjs } from 'dayjs';
import { atom } from 'jotai';
import { atomWithListeners } from '../../../jotai/atomWithListeners';

export const billingScope = createScope('global');
export const billingMolecule = molecule((mol, scope) => {
  const value = scope(billingScope);
  if (value === 'global') throw new Error('billingMolecule can only be used within <ProvideBilling>');

  const billingCustomersAtom = atom<BillingIntervalSchemaOutput[]>([]);

  const startDateAtom = atom<Dayjs | null>(null);
  const [startDurationAtom, useStartDurationListener] = atomWithListeners<
    'START_OR_LAST_BILLING' | 'DATE' | string
  >('START_OR_LAST_BILLING');
  const endDateAtom = atom<Dayjs | null>(null);
  const [endDurationAtom, useEndDurationListener] = atomWithListeners<'12MONTHS' | 'LAST_YEAR' | 'DATE'>(
    'LAST_YEAR',
  );

  const selectedSubscriptionIds = atom<GridRowSelectionModel | null>(null);
  const verifiedTransactionsAtom = atom<{ [customerId: number]: boolean }>({});

  const isInitializedAtom = atom(false);

  return {
    billingCustomersAtom,
    startDateAtom,
    startDurationAtom,
    useStartDurationListener,
    endDateAtom,
    useEndDurationListener,
    endDurationAtom,
    selectedSubscriptionIds,
    verifiedTransactionsAtom,
    isInitializedAtom,
  };
});
