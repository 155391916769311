import { useAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useHydrateAtoms } from 'jotai/utils';
import { PropsWithChildren, useEffect } from 'react';
import { DocumentContext, documentContextAtom } from './common';

export const ProvideDocumentContext = (props: PropsWithChildren<DocumentContext>) => {
  return (
    <ScopeProvider atoms={[documentContextAtom]}>
      <ProvideDocumentContextInner {...props} />
    </ScopeProvider>
  );
};

const ProvideDocumentContextInner = ({ children, ...context }: PropsWithChildren<DocumentContext>) => {
  useHydrateAtoms([[documentContextAtom, context]]);
  const [value, setValue] = useAtom(documentContextAtom);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(context)) {
      setValue(context);
    }
  }, [setValue, context]);

  return children;
};
