import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingCompleteMutationResponse,
  SetProjectToOnboardingCompletePathParams,
  SetProjectToOnboardingCompleteQueryParams,
  SetProjectToOnboardingComplete400,
  SetProjectToOnboardingComplete422,
} from '../types/SetProjectToOnboardingComplete.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const setProjectToOnboardingCompleteMutationKey = () => [{ url: '/api-internal/projects/{id}/complete_onboarding' }] as const

export type SetProjectToOnboardingCompleteMutationKey = ReturnType<typeof setProjectToOnboardingCompleteMutationKey>

/**
 * @summary Set Project To Onboarding Complete
 * {@link /api-internal/projects/:id/complete_onboarding}
 */
async function setProjectToOnboardingComplete(
  id: SetProjectToOnboardingCompletePathParams['id'],
  params?: SetProjectToOnboardingCompleteQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const res = await client<SetProjectToOnboardingCompleteMutationResponse, SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete422, unknown>({
    method: 'POST',
    url: `/api-internal/projects/${id}/complete_onboarding`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @summary Set Project To Onboarding Complete
 * {@link /api-internal/projects/:id/complete_onboarding}
 */
export function useSetProjectToOnboardingComplete(
  options: {
    mutation?: UseMutationOptions<
      SetProjectToOnboardingCompleteMutationResponse,
      SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete422,
      { id: SetProjectToOnboardingCompletePathParams['id']; params?: SetProjectToOnboardingCompleteQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setProjectToOnboardingCompleteMutationKey()

  return useMutation<
    SetProjectToOnboardingCompleteMutationResponse,
    SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete422,
    { id: SetProjectToOnboardingCompletePathParams['id']; params?: SetProjectToOnboardingCompleteQueryParams }
  >({
    mutationFn: async ({ id, params }) => {
      return setProjectToOnboardingComplete(id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}