import { z } from 'zod'

export const getSepaMandatePdfPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getSepaMandatePdf200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getSepaMandatePdf400Schema = z.string()

export const getSepaMandatePdfQueryResponseSchema = z.lazy(() => getSepaMandatePdf200Schema)