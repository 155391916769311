import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  ListMeasuringPointPanelsQueryResponse,
  ListMeasuringPointPanelsQueryParams,
  ListMeasuringPointPanels422,
} from '../types/ListMeasuringPointPanels.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listMeasuringPointPanelsInfiniteQueryKey = (params: ListMeasuringPointPanelsQueryParams) =>
  [{ url: '/api-internal/measuring_point_panels/' }, ...(params ? [params] : [])] as const

export type ListMeasuringPointPanelsInfiniteQueryKey = ReturnType<typeof listMeasuringPointPanelsInfiniteQueryKey>

/**
 * @summary List Measuring Point Panels
 * {@link /api-internal/measuring_point_panels/}
 */
async function listMeasuringPointPanels(params: ListMeasuringPointPanelsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListMeasuringPointPanelsQueryResponse, ListMeasuringPointPanels422, unknown>({
    method: 'GET',
    url: `/api-internal/measuring_point_panels/`,
    params,
    ...config,
  })
  return res.data
}

export function listMeasuringPointPanelsInfiniteQueryOptions(params: ListMeasuringPointPanelsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listMeasuringPointPanelsInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListMeasuringPointPanelsQueryResponse,
    ListMeasuringPointPanels422,
    ListMeasuringPointPanelsQueryResponse,
    typeof queryKey,
    number
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListMeasuringPointPanelsQueryParams['page']
      }
      return listMeasuringPointPanels(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Measuring Point Panels
 * {@link /api-internal/measuring_point_panels/}
 */
export function useListMeasuringPointPanelsInfinite<
  TData = InfiniteData<ListMeasuringPointPanelsQueryResponse>,
  TQueryData = ListMeasuringPointPanelsQueryResponse,
  TQueryKey extends QueryKey = ListMeasuringPointPanelsInfiniteQueryKey,
>(
  params: ListMeasuringPointPanelsQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListMeasuringPointPanelsQueryResponse, ListMeasuringPointPanels422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMeasuringPointPanelsInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listMeasuringPointPanelsInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListMeasuringPointPanels422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}