import { gatewayStatusMeterSchema } from './gatewayStatusMeterSchema.ts'
import { z } from 'zod'

export const gatewayStatusSchema = z.object({
  dev_eui: z.string(),
  last_message_received_at: z.union([z.string().datetime(), z.null()]).optional(),
  meters: z.array(z.lazy(() => gatewayStatusMeterSchema)),
  refreshed_at: z.string().datetime(),
  rsrp: z.union([z.number().int(), z.null()]),
  serial: z.string(),
  signal_strength: z.union([z.number().int(), z.null()]),
})