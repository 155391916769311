import {
  deliverableDocumentType,
  LetterSchema,
  useGetLetterPdfsAsZip,
  useListLettersInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { presentDate } from '../../../presenters/date';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { DocumentsLetterTableFilterForm } from './Documents.LetterTable.FilterForm';
import { DocumentsLetterTableRowActionMenu } from './Documents.LetterTable.RowActionMenu';
import { DocumentsRowAction } from './Documents.RowAction';
import { DocumentsStatusAndQuickaction } from './Documents.StatusAndQuickaction';
import { useSetActionBarButtons } from './useSetActionBarButtons';

type Props = {
  customerId?: number | null;
};

export const DocumentsLetterTable = ({ customerId }: Props) => {
  const { t, tString } = useTranslation('documents');
  const currentProjectId = useSelectedProjectsFilter();
  const getLettersAsZipQuery = useGetLetterPdfsAsZip(
    { ...currentProjectId, customer_id: customerId },
    { query: { enabled: false } },
  );
  const { dataGridApiRef } = useGridApi();
  const [filter] = useAtom(filterValueAtom);

  useSetActionBarButtons({
    getAsZipQuery: getLettersAsZipQuery,
    documentType: deliverableDocumentType.letter,
  });

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      sortable: false,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <MGCustomerWithAvatar customer={params.row.customer} noCustomerDisplayText={t('all_tenants')} />
        );
      },
    },
    {
      field: 'subject',
      headerName: tString('subject'),
      flex: 0.5,
    },
    {
      field: 'issue_date',
      headerName: tString('issued'),
      flex: 0.3,
      renderCell: (params) => {
        return presentDate(params.row.issue_date);
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0,
      align: 'right',
      sortable: false,
      renderCell: (params) =>
        dataGridApiRef.current?.apiLoaded && (
          <DocumentsRowAction
            document={params.row}
            documentType={deliverableDocumentType.letter}
            queryKey={dataGridApiRef.current.getQueryKey()}
          >
            <DocumentsStatusAndQuickaction />
            <DocumentsLetterTableRowActionMenu />
          </DocumentsRowAction>
        ),
    },
  ] as GridColDef<LetterSchema>[];

  return (
    <Box sx={{ height: '100%' }}>
      <DataGridCard
        height={'calc(100% - 50px)'}
        searchPlaceholder={tString('search_placeholder') as string}
        columns={columns}
        reactQueryHook={useListLettersInfinite}
        reactQueryHookParams={{
          ...currentProjectId,
          ...filter,
          document_type: deliverableDocumentType.letter,
          include_customer: true,
          include_pdf: true,
          customer_id: customerId,
        }}
        reactQueryHookQueryOptions={{
          meta: {
            refetchForActiveTasks: true,
          },
        }}
        datagridProps={{
          columnVisibilityModel: { id: false },
        }}
        selectable
        rowHeight={50}
        filterModalContent={<DocumentsLetterTableFilterForm />}
      />
    </Box>
  );
};
