import { MGBanner, MGBannerActionLayout } from '@mg/ui/src/components/MGBanner';
import { MGConfirmModal } from '@mg/ui/src/components/MGConfirmModal';
import { Chip, Switch, Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';

export type BillingFeatureBannerProps = { old?: boolean };

export const BillingFeatureBanner = ({ old }: BillingFeatureBannerProps) => {
  const { t, tString } = useTranslation('billing');
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const selectedUserId =
    /\/customers\/(\d+)\/(choose-billing-date|pre-billing).*$/.exec(location.pathname)?.[1] ??
    searchParams.get('userIds')?.split(',')[0];

  const handleClick = () => {
    if (old) {
      MGConfirmModal.fire({
        title: tString('switch_to_new_flow_modal_title'),
        text: tString('switch_to_new_flow_modal_text'),
        type: 'info',
        confirmButtonText: tString('switch_to_new_flow_modal_confirm_button'),
        cancelButtonText: tString('switch_to_new_flow_modal_cancel_button'),
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate('/customers/billing/intervals' + (selectedUserId ? `?userIds=${selectedUserId}` : ''));
        }
      });
    } else {
      MGConfirmModal.fire({
        title: tString('switch_to_old_flow_modal_title'),
        text: tString('switch_to_old_flow_modal_text'),
        type: 'info',
        confirmButtonText: tString('switch_to_old_flow_modal_confirm_button'),
        cancelButtonText: tString('switch_to_old_flow_modal_cancel_button'),
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate(
            selectedUserId
              ? `/customers/${selectedUserId}/choose-billing-date/`
              : '/projects/choose-billing-date/',
          );
        }
      });
    }
  };

  return (
    <MGBanner sx={{ paddingY: 0, height: '50px' }}>
      <MGBannerActionLayout
        sx={{ height: '50px' }}
        action={
          <>
            <Typography variant={'small'}>{t('old_invoicing_flow')}</Typography>
            <Switch checked={!old} onClick={handleClick} />
            <Typography variant={'small'}>{t('new_invoicing_flow')}</Typography>
          </>
        }
      >
        <Chip
          size={'small'}
          variant={'soft'}
          color={'primary'}
          label={t('new')}
          sx={{ borderRadius: '8px' }}
        />

        <Typography variant={'small'} color={'text.secondary'}>
          {t(old ? 'switch_to_new_flow_banner' : 'switch_to_old_flow_banner')}
        </Typography>
      </MGBannerActionLayout>
    </MGBanner>
  );
};
