import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Grid2X2, LineChart } from 'lucide-react';

type Props = {
  selectedView: 'chart' | 'table';
  onChange: (view: 'chart' | 'table') => void;
};
export const GraphTableToggleButton = ({ selectedView, onChange }: Props) => {
  return (
    <ToggleButtonGroup value={selectedView} size={'small'}>
      <ToggleButton value="chart" onClick={() => onChange('chart')}>
        <LineChart />
      </ToggleButton>
      <ToggleButton value="table" onClick={() => onChange('table')}>
        <Grid2X2 />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
