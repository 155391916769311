import { Check, Close, RocketLaunch } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from '../../../i18n';
import { SoftIcon } from '../../shared/SoftIcon';

type Props = {
  color?: 'white' | 'dark';
  type: 'basic' | 'plus';
  onToggle: () => void;
};
export const MetergridPlusCard = ({ color = 'white', type, onToggle }: Props) => {
  const { t } = useTranslation('metergridPlus');

  const listItems = [
    { key: 'billing', basic: true, plus: true },
    { key: 'accounting', basic: true, plus: true },
    { key: 'dashboard', basic: true, plus: true },
    { key: 'change_plans', basic: false, plus: true },
    { key: 'obligatory_notifications', basic: false, plus: true },
    { key: 'reminders', basic: false, plus: true },
    { key: 'communication', basic: false, plus: true },
  ];

  const fontColor = color === 'dark' ? 'primary.contrastText' : 'gray.dark';

  return (
    <>
      <Paper
        sx={{
          padding: '30px',
          backgroundColor: color === 'dark' ? 'primary.dark' : 'inherit',
          borderRadius: '40px',
          position: 'relative',
        }}
      >
        <Grid container spacing={'10px'} sx={{ minHeight: 'calc(100vh - 250px)' }}>
          <Grid item xs={12}>
            <img
              style={{ marginBottom: '20px', marginTop: '10px' }}
              src={`/static/img/icons/${type === 'basic' ? 'tea-leaf.svg' : 'tree.svg'}`}
            />

            <Typography color={color === 'dark' ? 'primary.contrastText' : 'inherit'} variant={'h3'}>
              {t(`${type}_title`)}
            </Typography>
            <Typography color={fontColor}>{t(`${type}_subtitle`)}</Typography>

            <Divider
              sx={{
                width: '20%',
                color: color === 'dark' ? 'primary.contrastText' : 'primary.dark',
                border: '2px solid',
                marginY: '20px',
                borderRadius: '10px',
              }}
            />

            <List>
              {listItems.map((item) => {
                const isChecked = item[type];
                return (
                  <ListItem key={item.key}>
                    <ListItemIcon>
                      <SoftIcon variant={isChecked ? 'primary' : 'error'} size={25}>
                        {isChecked ? <Check sx={{ width: '15px' }} /> : <Close sx={{ width: '15px' }} />}
                      </SoftIcon>
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'extraSmall' }}
                      sx={{ color: fontColor }}
                    >
                      {t(`list_items_${item.key}`)}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid item container xs={12} alignSelf={'flex-end'}>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Box>
                <Button
                  color={type === 'basic' ? 'gray' : 'primary'}
                  variant={type === 'basic' ? 'outlined' : 'contained'}
                  size={'large'}
                  onClick={onToggle}
                  disabled={type === 'basic'}
                >
                  {type === 'basic' ? (
                    <>
                      {t('button_current_plan')} <Check sx={{ marginLeft: '5px' }} />
                    </>
                  ) : (
                    <>
                      {t('button_upgrade_now')} <RocketLaunch sx={{ marginLeft: '5px' }} />
                    </>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {type === 'plus' && (
          <img
            style={{ position: 'absolute', top: '-9px', right: '-9px' }}
            src={'static/img/icon-empfehlung.svg'}
          />
        )}
      </Paper>
    </>
  );
};
