import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { MGCardVerticalListLayoutItem } from './MGCard.VerticalListLayout.Item';

export type VerticalListLayoutProps = PropsWithChildren;

export const MGCardVerticalListLayout = ({ children }: VerticalListLayoutProps) => {
  return (
    <Stack spacing={3} paddingTop={1}>
      {children}
    </Stack>
  );
};

MGCardVerticalListLayout.Item = MGCardVerticalListLayoutItem;
