import { useGetProject, useGetProjectOnboardingCompleteness } from '@mg/api-wrappers/src/api-internal';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useTranslation } from '../../../../i18n';
import { PageLayout } from '../../../shared/PageLayout';
import { DashboardNewsletterCard } from '../Dashboard.NewsletterCard';
import { DashboardSupportCard } from '../Dashboard.SupportCard';
import { OnboardingFooter } from './Onboarding.Footer';
import { OnboardingProjectStatusCard } from './Onboarding.ProjectStatusCard';
import { OnboardingStepMetering } from './Onboarding.StepMetering';
import { OnboardingStepPlansAndLevies } from './Onboarding.StepPlansAndLevies';
import { OnboardingStepProjectData } from './Onboarding.StepProjectData';
import { OnboardingStepper } from './Onboarding.Stepper';

export const Onboarding = () => {
  const { t } = useTranslation('onboarding');
  const projectId = useCurrentProject();
  const onboardingQuery = useGetProjectOnboardingCompleteness(projectId);
  const projectQuery = useGetProject(projectId);
  const [step, setStep] = useState<'project_data' | 'plans_and_levies' | 'metering'>('project_data');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleStepChange = (value: 'project_data' | 'plans_and_levies' | 'metering') => {
    setSearchParams({ step: value }, { replace: true });
    setStep(value);
  };

  useEffect(() => {
    if (projectQuery.isLoading || onboardingQuery.isLoading) {
      return;
    }
    if (projectQuery.data && onboardingQuery.data && projectQuery.data.onboarding_base_data_completed) {
      if (searchParams.get('step')) {
        handleStepChange(searchParams.get('step') as 'project_data' | 'plans_and_levies' | 'metering');
        return;
      }

      const { onboarding_base_data_completed } = projectQuery.data;

      const { created_plan, created_residual_power_plan, created_levies, setup_customers } =
        onboardingQuery.data;

      if (onboarding_base_data_completed) {
        handleStepChange('plans_and_levies');
      }
      if (created_plan && created_residual_power_plan && created_levies && setup_customers) {
        handleStepChange('metering');
      }
    } else {
      handleStepChange('project_data');
    }
  }, [projectQuery.data, onboardingQuery.data]);

  const getOnboardingCompletenessPercentage = () => {
    const completnessAsArray = onboardingQuery.data && Object.values(onboardingQuery.data);
    if (!completnessAsArray) {
      return 0;
    }
    return Math.floor(
      (completnessAsArray.filter((value) => value === true).length / completnessAsArray.length) * 100,
    );
  };

  const getNextStepDisabled = () => {
    if (!onboardingQuery.data) {
      return true;
    }
    if (step === 'project_data') {
      return !onboardingQuery.data.project_data || !onboardingQuery.data.renamed_panels;
    }
    if (step === 'plans_and_levies') {
      return (
        !onboardingQuery.data.created_plan ||
        !onboardingQuery.data.created_residual_power_plan ||
        !onboardingQuery.data.created_levies ||
        !onboardingQuery.data.setup_customers
      );
    }
    if (step === 'metering') {
      return !onboardingQuery.data.setup_measuring_points;
    }
  };

  return (
    <>
      <PageLayout
        title={t('onboarding_page_title')}
        loading={onboardingQuery.isLoading}
        stickyFooter={
          <OnboardingFooter
            step={step}
            onChangeStep={handleStepChange}
            nextStepDisabled={getNextStepDisabled()}
          />
        }
      >
        <Stack spacing={4}>
          <OnboardingProjectStatusCard percentage={getOnboardingCompletenessPercentage()} />
          <OnboardingStepper step={step} />
          {step === 'project_data' && <OnboardingStepProjectData />}
          {step === 'plans_and_levies' && <OnboardingStepPlansAndLevies />}
          {step === 'metering' && <OnboardingStepMetering />}
          <Box />
          <DashboardNewsletterCard />
          <DashboardSupportCard />
        </Stack>
      </PageLayout>
    </>
  );
};
