import { MGDateRadioButton } from '@mg/ui/src/components/MGDateRadioButton/MGDateRadioButton';
import { MGTextRadioButton } from '@mg/ui/src/components/MGTextRadioButton';
import { Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtom } from 'jotai';
import { billingMolecule } from '../../Billing.Atoms';

export const BillingBillingPeriodCardFormStartDateSelect = () => {
  const { startDateAtom, startDurationAtom } = useMolecule(billingMolecule);
  const [startDate, setStartDate] = useAtom(startDateAtom);
  const [startDuration, setStartDuration] = useAtom(startDurationAtom);

  return (
    <Stack spacing={2}>
      <Typography variant={'small'} fontWeight={'fontWeightBold'}>
        Startdatum der Abrechnung
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <MGTextRadioButton
          label={'Erstbelieferung / Seit letzter Abrechnung'}
          selected={startDuration === 'START_OR_LAST_BILLING'}
          onClick={() => {
            setStartDate(null);
            setStartDuration('START_OR_LAST_BILLING');
          }}
        />
        <MGDateRadioButton
          label={'Individueller Start'}
          selected={startDuration === 'DATE'}
          onClick={() => {
            // Fixed issue that lead to the context not updating correctly
            if (startDuration !== 'DATE') {
              setStartDuration('DATE');
            }
          }}
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
        />
      </Stack>
    </Stack>
  );
};
