import { useGetProject } from '@mg/api-wrappers/src/api-internal';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { SidebarItemButton } from './Sidebar.Item.Button';
import { SidebarItemCollapsable } from './Sidebar.Item.Collapsable';

export type SidebarItem = {
  path?: string;
  icon: ReactElement;
  numberIcon?: number;
  i18nKey: string;
  isNotClickable?: boolean;
  paddingY?: string;
  height?: string;
  selected?: boolean;
  children?: SidebarItem[];
};

export const SidebarItem = (item: SidebarItem) => {
  const currentProjectQuery = useGetProject(useCurrentProject());
  const onboardingBaseDataCompleted = currentProjectQuery.data?.onboarding_base_data_completed;

  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];

  let isSelected =
    item.selected !== undefined ? item.selected : item.path?.replace(/^\//, '') === currentLocation;

  // Special case for the onboarding page
  if (currentLocation === 'onboarding' && item.path === '/') {
    isSelected = true;
  }

  if (item.children) {
    return <SidebarItemCollapsable {...item} />;
  }

  const disabledForOnboarding = !onboardingBaseDataCompleted && item.path !== '/';

  return (
    <SidebarItemButton
      selected={isSelected}
      i18nKey={item.i18nKey}
      icon={item.icon}
      disabled={item.isNotClickable || disabledForOnboarding || false}
      href={item.path as string}
    />
  );
};
