import {
  InvoiceFilter,
  invoiceFilterCategory,
  invoiceFilterState,
  useListCustomers,
} from '@mg/api-wrappers/src/api-internal';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardFilterForm } from '../../shared/DataGridCard/DataGridCard.FilterForm';
import { DeclarativeFormFieldType } from '../../shared/DeclarativeForm';

enum StatusOption {
  RegularInvoice = 'regularInvoice',
  Canceled = 'canceled',
  CancellationInvoice = 'cancellationInvoice',
}

export type CustomInvoiceFilter = Partial<InvoiceFilter>;

type InvoiceFilterFormValues = FieldValues & {
  status: string;
  customer: string;
  issueDateRange: [Dayjs | null, Dayjs | null];
};

export const DocumentsInvoiceTableFilterForm = () => {
  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t, tString } = useTranslation('documents');
  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);

  const createInitialFormValues: () => InvoiceFilterFormValues = () => ({
    status: '',
    customer: '',
    issueDateRange: [null, null],
  });

  const customerOptions = useMemo(() => {
    if (!customersQuery.data) return [];

    return customersQuery.data?.items
      .map((customer) => ({ value: customer.id + '', label: customer.display_name + '' }))
      .filter(
        (option, optionIndex, thisArg) => thisArg.findIndex((o) => o.value === option.value) === optionIndex,
      );
  }, [customersQuery.data]);

  return (
    <>
      <DataGridCardFilterForm
        formId={'invoice-table-filter'}
        formTitle={t('invoice_filter_title')}
        filter={filter}
        onFilter={(filter) => {
          setFilterValue(filter);
          setFilterModalOpen(false);
        }}
        initialValues={createInitialFormValues()}
        serialize={(formValues: InvoiceFilterFormValues) => {
          const customerId = parseInt(formValues.customer);

          const result: CustomInvoiceFilter = {};

          if (customerId) result.customer = customerId;

          if (formValues.status === StatusOption.Canceled) {
            result.state = invoiceFilterState.canceled;
          } else if (formValues.status === StatusOption.CancellationInvoice) {
            result.category = invoiceFilterCategory['cancellation invoice'];
          } else if (formValues.status === StatusOption.RegularInvoice) {
            result.category = invoiceFilterCategory['regular invoice'];
            result.state = invoiceFilterState.issued;
          }

          if (formValues.issueDateRange) {
            if (formValues.issueDateRange[0])
              result.issue_date_from = (formValues.issueDateRange[0] as Dayjs)?.toDate().toISOString();

            if (formValues.issueDateRange[1])
              result.issue_date_to = (formValues.issueDateRange[1] as Dayjs)?.toDate().toISOString();
          }
          return result;
        }}
        deserialize={(filter: CustomInvoiceFilter) => {
          const formValues: InvoiceFilterFormValues = createInitialFormValues();

          if (filter?.customer) formValues.customer = filter.customer + '';

          if (filter?.state === invoiceFilterState.canceled) formValues.status = StatusOption.Canceled;
          else if (filter?.category === invoiceFilterCategory['cancellation invoice'])
            formValues.status = StatusOption.CancellationInvoice;
          else if (
            filter?.category === invoiceFilterCategory['regular invoice'] &&
            filter?.state === invoiceFilterState.issued
          )
            formValues.status = StatusOption.RegularInvoice;

          if (filter?.issue_date_from) formValues.issueDateRange[0] = dayjs(new Date(filter.issue_date_from));
          if (filter?.issue_date_to) formValues.issueDateRange[1] = dayjs(new Date(filter.issue_date_to));

          return formValues;
        }}
        fieldDefinitions={[
          {
            name: 'status',
            label: t('filter_status_label'),
            innerLabel: t('invoice_filter_status_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: [
              { value: StatusOption.RegularInvoice, label: tString('issued') },
              { value: StatusOption.Canceled, label: tString('canceled') },
              { value: StatusOption.CancellationInvoice, label: tString('cancellation_invoice') },
            ],
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'customer',
            label: t('filter_customer_label'),
            innerLabel: t('filter_customer_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: customerOptions,
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'issueDateRange',
            label: t('invoice_filter_issue_date_range_label'),
            type: DeclarativeFormFieldType.DateRange,
            dateRangePickerInputProps: { clearable: true },
          },
        ]}
      />
    </>
  );
};
