import { MeasuringPointDataSchema, MissingDataOut } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from '../../../../../i18n';
import { BillingMeteringDataData } from './Billing.MeteringData.Data';
import { BillingMeteringDataMissingData } from './Billing.MeteringData.MissingData';

export const BillingMeteringDataCardTable = ({ meteringData }: { meteringData: MissingDataOut[] }) => {
  const { t } = useTranslation('latestReadings');
  const { t: tErrors } = useTranslation('errors');
  const columns: GridColDef<MissingDataOut>[] = [
    {
      field: 'serial',
      headerName: 'Zählernummer',
      valueGetter: (_, row) => row.measuring_point?.serial,
      flex: 0.7,
    },
    {
      field: 'type',
      headerName: 'Typ',
      valueGetter: (_, row) => t(row.measuring_point?.type + '_' + row.obis_code),
      flex: 0.7,
    },
    {
      field: 'start_data',
      headerName: 'Ablesung Anfang',
      renderCell: (params) => {
        if (params.value) {
          return <BillingMeteringDataData data={params.value} />;
        }
        return (
          <BillingMeteringDataMissingData missingData={params.row} timestamp={params.row.start_datetime} />
        );
      },
      flex: 1,
    } satisfies GridColDef<MissingDataOut, MeasuringPointDataSchema>,
    {
      field: 'end_data',
      headerName: 'Ablesung Ende',
      renderCell: (params) => {
        if (params.value) {
          return <BillingMeteringDataData data={params.value} />;
        }
        return (
          <BillingMeteringDataMissingData missingData={params.row} timestamp={params.row.end_datetime} />
        );
      },
      flex: 1,
    },
  ];

  const errors = meteringData[0] ? meteringData[0].errors || [] : [];

  // Datagrid requires measuring_point.id and obis_code to be present
  const displayableMeteringData = meteringData.filter((data) => data.measuring_point?.id && data.obis_code);

  return (
    <>
      {errors.length > 0 && (
        <MGAlert
          severity="error"
          title={tErrors(errors[0].code)}
          subtitle={tErrors(`${errors[0].code}_subtitle`)}
        />
      )}
      {displayableMeteringData.length > 0 && (
        <DataGridPro
          autoHeight
          columns={columns}
          rows={displayableMeteringData}
          getRowId={(row) => row.measuring_point!.id + row.obis_code!}
        />
      )}
    </>
  );
};
