import { useGetProjectOnboardingCompleteness } from '@mg/api-wrappers/src/api-internal';
import { Button, Grid, Stack } from '@mui/material';
import { CircleGauge } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useTranslation } from '../../../../i18n';
import { OnboardingCard, OnboardingCardProps } from './Onboarding.Card';

export const OnboardingStepMetering = () => {
  const { t } = useTranslation('onboarding');
  const projectId = useCurrentProject();
  const onboardingQuery = useGetProjectOnboardingCompleteness(projectId);

  const tiles: Omit<OnboardingCardProps, 'title' | 'subtitle'>[] = [
    {
      key: 'add_meters',
      icon: <CircleGauge />,
      status: onboardingQuery.data?.setup_measuring_points ? 'done' : 'todo',
      href: '/metering',
    },
  ];
  return (
    <Stack gap={2}>
      <Grid container spacing={3}>
        {tiles.map((tile) => (
          <Grid key={tile.key} item xs={12} md={6}>
            <OnboardingCard
              loading={onboardingQuery.isLoading}
              title={t(`onboarding_tile_${tile.key}_title`)}
              subtitle={t(`onboarding_tile_${tile.key}_subtitle`)}
              icon={tile.icon}
              status={tile.status}
              button={
                <Button
                  onClick={tile.onClick}
                  variant={tile.status === 'done' ? 'outlined' : 'contained'}
                  component={Link}
                  to={tile.href}
                >
                  {t(
                    tile.status === 'done'
                      ? `onboarding_tile_${tile.key}_edit_button`
                      : `onboarding_tile_${tile.key}_button`,
                  )}
                </Button>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
