import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../i18n';
import { LoginForm } from './Login.Form';

export const Login = () => {
  const { t } = useTranslation('login');
  return (
    <Stack maxWidth={'900px'} justifyContent={'center'} spacing={5}>
      <Stack spacing={3}>
        <Typography variant={'h1'} fontWeight={'500'}>
          {t('welcome')}
        </Typography>
        <Typography variant={'regular'} fontWeight={'300'}>
          {t('login_now')}
        </Typography>
      </Stack>
      <LoginForm />
    </Stack>
  );
};
