import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetCurrentUserQueryResponse, GetCurrentUser422 } from '../types/GetCurrentUser.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCurrentUserQueryKey = () => [{ url: '/api-internal/users/current' }] as const

export type GetCurrentUserQueryKey = ReturnType<typeof getCurrentUserQueryKey>

/**
 * @summary Get Current User
 * {@link /api-internal/users/current}
 */
async function getCurrentUser(config: Partial<RequestConfig> = {}) {
  const res = await client<GetCurrentUserQueryResponse, GetCurrentUser422, unknown>({ method: 'GET', url: `/api-internal/users/current`, ...config })
  return res.data
}

export function getCurrentUserQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = getCurrentUserQueryKey()
  return queryOptions<GetCurrentUserQueryResponse, GetCurrentUser422, GetCurrentUserQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCurrentUser(config)
    },
  })
}

/**
 * @summary Get Current User
 * {@link /api-internal/users/current}
 */
export function useGetCurrentUser<
  TData = GetCurrentUserQueryResponse,
  TQueryData = GetCurrentUserQueryResponse,
  TQueryKey extends QueryKey = GetCurrentUserQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<GetCurrentUserQueryResponse, GetCurrentUser422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCurrentUserQueryKey()

  const query = useQuery({
    ...(getCurrentUserQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCurrentUser422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}