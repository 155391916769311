import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import loader from './loader.json';
import './MGLoader.scss';

type Props = {
  size?: string | number;
};
export const MGLoader = ({ size = '50px' }: Props) => {
  return (
    <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Box
        sx={{ height: size, width: size }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Lottie animationData={loader} loop={true} style={{ display: 'flex' }} />
      </Box>
    </Box>
  );
};
