import { CustomerSchema, useExportMeasuringPointData } from '@mg/api-wrappers/src/api-internal';
import { Button } from '@mui/material';
import { ChevronDown, Download, FilePlus, Plus } from 'lucide-react';
import { useState } from 'react';
import { downloadFile } from '../../../../../helpers/downloadFile';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../../i18n';
import { DjangoFormModal } from '../../../../modals/DjangoFormModal';
import { ActionsMenu, MenuItem } from '../../../../shared/ActionsMenu';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsMeteringActionMenu = ({ customer }: Props) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [addMeterModalOpen, setAddMeterModalOpen] = useState(false);
  const { t } = useTranslation('customerDetails');
  const exportMeteringDataQuery = useExportMeasuringPointData({
    project_id: customer?.measuring_point_panel?.project || -1,
  });

  const menuItems: MenuItem[] = [
    {
      text: t('metering_menu_add_data'),
      icon: <FilePlus size={18} />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
    },
    {
      text: t('metering_menu_export_data'),
      icon: <Download size={18} />,
      onClick: () =>
        downloadFile(exportMeteringDataQuery, { project_id: customer?.measuring_point_panel?.project }),
    },
    {
      text: t('metering_menu_add_meter'),
      icon: <Plus size={18} />,
      onClick: () => setAddMeterModalOpen(!addMeterModalOpen),
    },
  ];

  return (
    <>
      <ActionsMenu
        buttonComponent={
          <Button variant={'contained'} endIcon={<ChevronDown />}>
            {t('metering_menu_actions')}
          </Button>
        }
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?panel_id=${customer?.measuring_point_panel?.id}`}
        title={t('metering_menu_add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/meters/create`}
        title={t('metering_menu_add_meter')}
        open={addMeterModalOpen}
        handleToggle={() => {
          setAddMeterModalOpen(!addMeterModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddMeterModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
        prefill={[{ id: 'id_panel', value: customer?.measuring_point_panel?.id as number }]}
      />
    </>
  );
};
