import { pagedTransactionSchemaSchema } from './pagedTransactionSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listSepaTransactionsQueryParamsSchema = z
  .object({
    include_customer: z.boolean().default(false),
    project_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
    time_range_from: z.union([z.string().datetime(), z.null()]).optional(),
    time_range_to: z.union([z.string().datetime(), z.null()]).optional(),
    page: z.union([z.number().int(), z.null()]).default(1),
    page_size: z.union([z.number().int(), z.null()]).default(25),
    order_by: z.union([z.string(), z.null()]).optional(),
    skip_pagination: z.union([z.boolean(), z.null()]).default(false),
    single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
  })
  .optional()

/**
 * @description OK
 */
export const listSepaTransactions200Schema = z.lazy(() => pagedTransactionSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const listSepaTransactions422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listSepaTransactionsQueryResponseSchema = z.lazy(() => listSepaTransactions200Schema)