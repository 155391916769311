import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Stack } from '@mui/material';
import { useTranslation } from '../../../i18n';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { DataGridApiProvider } from '../../shared/DataGridCard/useGridApi';
import { DocumentsCards } from './Documents.Cards';
import { DocumentsData } from './Documents.Data';

export const Documents = () => {
  return (
    <FilterProvider>
      <DataGridApiProvider>
        <DocumentsPage />
      </DataGridApiProvider>
    </FilterProvider>
  );
};

const DocumentsPage = () => {
  const { t } = useTranslation('documents');

  return (
    <>
      <MGPageLayout title={t('page_title')}>
        <Stack spacing={4} height={'calc(100vh - 150px)'}>
          <DocumentsCards />
          <DocumentsData />
        </Stack>
      </MGPageLayout>
    </>
  );
};
