import { palette } from '@mg/ui/src/styles/palette';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  CheckboxProps as MuiCheckboxProps,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { IntegrationBaseProps } from './IntegrationBaseProps';

type RadioGroupProps = IntegrationBaseProps & {
  checkboxProps?: MuiCheckboxProps;
  options: { value: string; label: ReactNode }[];
};

export const RadioGroup = ({ name, label, control, options }: RadioGroupProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          {label && <FormLabel>{label}</FormLabel>}
          <MuiRadioGroup name={field.name} value={field.value} onChange={field.onChange} ref={field.ref}>
            {options.map(({ label, value }, index) => (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio />}
                label={
                  typeof label === 'string' ? (
                    <Typography
                      fontSize={14}
                      color={palette.text.primary}
                      sx={{ display: 'inline-flex', flexDirection: 'row', gap: 1 }}
                    >
                      {label}
                    </Typography>
                  ) : (
                    label
                  )
                }
              />
            ))}
          </MuiRadioGroup>
        </FormControl>
      )}
    />
  );
};
