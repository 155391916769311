import { useGetCurrentUser, useObtainToken } from '@mg/api-wrappers/src/api-internal';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm';
import { MGFormFieldPassword } from '@mg/ui/src/components/MGForm/MGForm.Field.Password';
import { MGFormFieldText } from '@mg/ui/src/components/MGForm/MGForm.Field.Text';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton';
import { Box, Button, Stack } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useAtom } from 'jotai';
import { CircleHelp } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useUserInfo } from '../../../helpers/userInfo';
import { useTranslation } from '../../../i18n';
import { currentProjectIdAtom } from '../../../jotai/atoms';

export const LoginForm = () => {
  const obtainTokenMutation = useObtainToken();
  const userQuery = useGetCurrentUser({ query: { enabled: false } });
  const [, setUserInfo] = useUserInfo();
  const [, setCurrentProjectId] = useAtom(currentProjectIdAtom);
  const { tString } = useTranslation('login');

  const navigate = useNavigate();

  const form = useForm({
    onSubmit: async ({ value }) => {
      await obtainTokenMutation.mutateAsync(
        {
          data: {
            grant_type: 'password',
            username: value.email,
            password: value.password,
          },
        },
        {
          onSuccess: async (res) => {
            localStorage.setItem('accessToken', res.access_token);
            const userQueryRes = await userQuery.refetch();
            if (userQueryRes.data) {
              setUserInfo(userQueryRes.data);
              setCurrentProjectId(userQueryRes.data.current_project_id);
              const nextParam = new URLSearchParams(window.location.search).get('next');
              navigate(nextParam ? nextParam : '/');
            }
          },
          onError: () => {
            form.store.state.fieldMeta.email.errors = [''];
            form.store.state.fieldMeta.password.errors = [tString('login_failed')];
          },
        },
      );
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({ email: z.string().email(tString('invalid_email')), password: z.string() }),
    },
    defaultValues: { email: '', password: '' },
  });

  return (
    <MGForm form={form}>
      <Stack spacing={2}>
        <MGFormFieldText
          form={form}
          name={'email'}
          label={tString('email')}
          TextFieldProps={{ autoComplete: 'username', autoFocus: true }}
        />
        <Box>
          <MGFormFieldPassword form={form} name={'password'} label={tString('password')} />
          <Button startIcon={<CircleHelp />} component={Link} to={'reset-password'} tabIndex={-1}>
            {tString('btn_forgot_password')}
          </Button>
        </Box>
        <MGFormSubmitButton form={form} buttonLabel={tString('btn_login')} />
      </Stack>
    </MGForm>
  );
};
