import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { SendDocumentByEmailMutationResponse, SendDocumentByEmailQueryParams, SendDocumentByEmail422 } from '../types/SendDocumentByEmail.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const sendDocumentByEmailMutationKey = () => [{ url: '/api-internal/document_email/send_email' }] as const

export type SendDocumentByEmailMutationKey = ReturnType<typeof sendDocumentByEmailMutationKey>

/**
 * @summary Send Document By Email
 * {@link /api-internal/document_email/send_email}
 */
async function sendDocumentByEmail(params: SendDocumentByEmailQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<SendDocumentByEmailMutationResponse, SendDocumentByEmail422, unknown>({
    method: 'POST',
    url: `/api-internal/document_email/send_email`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @summary Send Document By Email
 * {@link /api-internal/document_email/send_email}
 */
export function useSendDocumentByEmail(
  options: {
    mutation?: UseMutationOptions<SendDocumentByEmailMutationResponse, SendDocumentByEmail422, { params: SendDocumentByEmailQueryParams }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? sendDocumentByEmailMutationKey()

  return useMutation<SendDocumentByEmailMutationResponse, SendDocumentByEmail422, { params: SendDocumentByEmailQueryParams }>({
    mutationFn: async ({ params }) => {
      return sendDocumentByEmail(params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}