import { levyNestedSchemaSchema } from './levyNestedSchemaSchema.ts'
import { z } from 'zod'

export const levyStructureSchemaSchema = z.object({
  id: z.union([z.number().int(), z.null()]).optional(),
  is_deletable: z.boolean(),
  is_editable: z.boolean(),
  levies: z.union([z.array(z.lazy(() => levyNestedSchemaSchema)), z.null()]).optional(),
  sum: z.union([z.string(), z.number(), z.null()]).optional(),
  valid_from: z.string().date().describe('The start of the validity period.'),
  valid_to: z.union([z.string().date(), z.null()]).describe('The end of the validity period (excluding). If unset, valid indefinitely.').optional(),
})