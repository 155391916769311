import { measuringPointSubscriptionSchemaSchema } from './measuringPointSubscriptionSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listSubscriptionsForPanelPathParamsSchema = z.object({
  id: z.number().int(),
})

export const listSubscriptionsForPanelQueryParamsSchema = z
  .object({
    include_customer: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const listSubscriptionsForPanel200Schema = z.array(z.lazy(() => measuringPointSubscriptionSchemaSchema))

/**
 * @description Unprocessable Entity
 */
export const listSubscriptionsForPanel422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listSubscriptionsForPanelQueryResponseSchema = z.lazy(() => listSubscriptionsForPanel200Schema)