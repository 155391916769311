import { providerNestedSchemaSchema } from './providerNestedSchemaSchema.ts'
import { z } from 'zod'

export const projectSchemaSchema = z.object({
  id: z.union([z.number().int(), z.null()]).optional(),
  is_read_remotely: z.boolean(),
  location: z.string().max(255).describe('Single location identifier for all buildings within the project'),
  name: z.union([z.string(), z.null()]).optional(),
  onboarding_base_data_completed: z.boolean().default(false).describe('Indicates if the project has completed the first onboarding phase'),
  onboarding_completed: z.boolean().default(false).describe('Indicates if the project is fully onboarded'),
  provider: z.union([z.lazy(() => providerNestedSchemaSchema), z.null()]).optional(),
})