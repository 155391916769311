import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../helpers/invalidateAllQueries';
import { useTranslation } from '../../i18n';
import { DisplayCard } from '../shared/DisplayCard';
import { DjangoFormModal } from './DjangoFormModal';
import { MuiBaseModal } from './MuiBaseModal';

type Props = {
  buttonColor?: 'primary' | 'success' | 'error' | 'warning' | 'inherit' | 'secondary' | 'info' | 'white';
};

export const CreateLevyStructureModal = ({ buttonColor = 'primary' }: Props) => {
  const [open, setOpen] = useState(false);
  const [djangoFormOpen, setDjangoFormOpen] = useState(false);
  const [djangoFormLink, setDjangoFormLink] = useState('');
  const { t } = useTranslation('levies');

  return (
    <>
      <Button
        size={'large'}
        color={buttonColor}
        variant={'contained'}
        onClick={() => setOpen(!open)}
        sx={{ marginLeft: '20px' }}
      >
        {t('create_levy')} <Add sx={{ marginLeft: '10px' }} />
      </Button>
      <MuiBaseModal
        open={open}
        handleToggle={() => setOpen(!open)}
        height={'500px'}
        width={'710px'}
        position={{ anchorTop: 'center' }}
        PaperStyle={{ overflow: 'hidden' }}
        BodyStyle={{ overflow: 'hidden' }}
        // TODO: Add translation
        title={'Umlage anlegen'}
      >
        <Stack direction={'row'} spacing={2} paddingY={3}>
          <DisplayCard
            title={t('modal_simple_title')}
            label={t('modal_recommended')}
            color={'success'}
            text={t('modal_simple_text')}
            onButtonClick={() => {
              setDjangoFormLink('/levystructures/simple/create');
              setDjangoFormOpen(true);
            }}
          />
          <DisplayCard
            title={t('modal_advanced_title')}
            label={t('modal_for_experts')}
            color={'gray'}
            text={t('modal_advanced_text')}
            onButtonClick={() => {
              setDjangoFormLink('/levystructures/create');
              setDjangoFormOpen(true);
            }}
          />
        </Stack>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant={'contained'} color={'gray'} onClick={() => setOpen(false)}>
            {t('modal_button_cancel')}
          </Button>
        </Box>
      </MuiBaseModal>

      <DjangoFormModal
        pathToDjangoForm={djangoFormLink}
        title={t('create_levy')}
        open={djangoFormOpen}
        handleToggle={() => {
          setDjangoFormOpen(!djangoFormOpen);
        }}
        onSuccess={() => {
          setDjangoFormOpen(false);
          setOpen(false);
          invalidateAllQueries();
        }}
        onError={(error) => {
          const errorHtml = document.createElement('html');
          errorHtml.innerHTML = error.response?.data as string;
          const errorText = errorHtml.getElementsByClassName('errormsg')[0] as HTMLElement;
          const errorMessages = errorText?.innerText?.match(/(?<=\[')(.*?)(?='\])/g);
          setDjangoFormOpen(false);
          toast.error(errorMessages ? errorMessages[0] : 'Ein Fehler ist aufgetreten');
        }}
      />
    </>
  );
};
