import { z } from 'zod'

export const customerNestedSchemaSchema = z.object({
  company: z.string().max(128).default(''),
  display_name: z.string().optional(),
  email: z.union([z.string(), z.null()]).optional(),
  first_name: z.union([z.string(), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  last_name: z.union([z.string(), z.null()]).optional(),
  notes: z.union([z.string(), z.null()]).optional(),
  phone_number: z.union([z.string(), z.null()]).optional(),
  salutation: z.string().max(128).default('NONE'),
})