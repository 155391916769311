import { billingIntervalSchemaOutputSchema } from './billingIntervalSchemaOutputSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getBillingIntervalsQueryParamsSchema = z.object({
  subscription_ids: z.array(z.number().int()),
  start_date: z.union([z.string().date(), z.null()]).optional(),
  fixed_end_date: z.union([z.string().date(), z.null()]).optional(),
  automatic_end_date: z.union([z.enum(['LAST_YEAR', '12MONTHS']), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getBillingIntervals200Schema = z.array(z.lazy(() => billingIntervalSchemaOutputSchema))

/**
 * @description Unprocessable Entity
 */
export const getBillingIntervals422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getBillingIntervalsQueryResponseSchema = z.lazy(() => getBillingIntervals200Schema)