import { customerNoActiveSepaMandateSchema } from './customerNoActiveSepaMandateSchema.ts'
import { customerNoBankAccountSchema } from './customerNoBankAccountSchema.ts'
import { generateSepaFilesInputSchema } from './generateSepaFilesInputSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const generateSepaFiles200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const generateSepaFiles400Schema = z.string()

/**
 * @description Unprocessable Entity
 */
export const generateSepaFiles422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.array(z.union([z.lazy(() => customerNoBankAccountSchema), z.lazy(() => customerNoActiveSepaMandateSchema)])),
])

export const generateSepaFilesMutationRequestSchema = z.lazy(() => generateSepaFilesInputSchema)

export const generateSepaFilesMutationResponseSchema = z.lazy(() => generateSepaFiles200Schema)