import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListPlanChangeDocumentsQueryResponse, ListPlanChangeDocumentsQueryParams, ListPlanChangeDocuments422 } from '../types/ListPlanChangeDocuments.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listPlanChangeDocumentsInfiniteQueryKey = (params: ListPlanChangeDocumentsQueryParams) =>
  [{ url: '/api-internal/plan_change_documents/' }, ...(params ? [params] : [])] as const

export type ListPlanChangeDocumentsInfiniteQueryKey = ReturnType<typeof listPlanChangeDocumentsInfiniteQueryKey>

/**
 * @summary List Plan Change Documents
 * {@link /api-internal/plan_change_documents/}
 */
async function listPlanChangeDocuments(params: ListPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListPlanChangeDocumentsQueryResponse, ListPlanChangeDocuments422, unknown>({
    method: 'GET',
    url: `/api-internal/plan_change_documents/`,
    params,
    ...config,
  })
  return res.data
}

export function listPlanChangeDocumentsInfiniteQueryOptions(params: ListPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listPlanChangeDocumentsInfiniteQueryKey(params)
  return infiniteQueryOptions<ListPlanChangeDocumentsQueryResponse, ListPlanChangeDocuments422, ListPlanChangeDocumentsQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListPlanChangeDocumentsQueryParams['page']
      }
      return listPlanChangeDocuments(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Plan Change Documents
 * {@link /api-internal/plan_change_documents/}
 */
export function useListPlanChangeDocumentsInfinite<
  TData = InfiniteData<ListPlanChangeDocumentsQueryResponse>,
  TQueryData = ListPlanChangeDocumentsQueryResponse,
  TQueryKey extends QueryKey = ListPlanChangeDocumentsInfiniteQueryKey,
>(
  params: ListPlanChangeDocumentsQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListPlanChangeDocumentsQueryResponse, ListPlanChangeDocuments422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPlanChangeDocumentsInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listPlanChangeDocumentsInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListPlanChangeDocuments422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}