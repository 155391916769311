import { customerSchemaSchema } from './customerSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getCustomerPathParamsSchema = z.object({
  customer_id: z.number().int(),
})

export const getCustomerQueryParamsSchema = z
  .object({
    include_measuring_point_panel: z.boolean().default(false),
    include_total_open_amount: z.boolean().default(false),
    include_total_settled_amount: z.boolean().default(false),
    include_last_billing_date: z.boolean().default(false),
    include_bank_account: z.boolean().default(false),
    include_address: z.boolean().default(false),
    include_postal_address: z.boolean().default(false),
    include_subscription: z.boolean().default(false),
    include_current_plan: z.boolean().default(false),
    include_plans: z.boolean().default(false),
    include_active_sepa_mandate_id: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const getCustomer200Schema = z.lazy(() => customerSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getCustomer422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getCustomerQueryResponseSchema = z.lazy(() => getCustomer200Schema)