import { MGCard, MGCardContent, MGCardTitle } from '@mg/ui/src/components/MGCard';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../../i18n';
import { BillingBillingPeriodCardTable } from './Billing.BillingPeriodCard.Table';
import { BillingBillingPeriodCardForm } from './BillingBillingPeriodCardForm';

export const BillingBillingPeriodCard = () => {
  const { t } = useTranslation('billing');

  return (
    <MGCard>
      <MGCardTitle>{t('billing_period_card_title')}</MGCardTitle>
      <MGCardContent>
        <Stack spacing={2} sx={{ transition: 'height 1s' }}>
          <Typography variant={'small'}>{t('billing_period_card_subtitle')}</Typography>
          <BillingBillingPeriodCardForm />
          <BillingBillingPeriodCardTable />
        </Stack>
      </MGCardContent>
    </MGCard>
  );
};
