import { Box, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { CalendarSearch } from 'lucide-react';
import { ReactNode, useState } from 'react';
import { presentDate } from '../../presenters';
import { palette } from '../../styles';
import { MGTextRadioButton } from '../MGTextRadioButton';

export interface MGDateRadioButtonProps {
  label?: ReactNode;
  selected: boolean;
  onClick?: () => void;
  onChange: (date: Dayjs | null) => void;
  value: Dayjs | null | undefined;
  children?: ReactNode;
}
export const MGDateRadioButton = (props: MGDateRadioButtonProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <MGTextRadioButton
      selected={props.selected}
      onClick={() => {
        props.onClick?.();
        setIsDatePickerOpen(true);
      }}
    >
      <Stack direction={'row'} width={'100%'} gap={1}>
        <Stack>
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <Typography sx={{ color: `${palette.gray.dark} !important` }} variant={'small'}>
              {props.label}
            </Typography>
          </Box>
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <Typography
              sx={{
                color: `${!props.value && props.selected ? palette.error.main : palette.gray.main} !important`,
              }}
              variant={'extraSmall'}
            >
              {props.value ? presentDate(props.value.toDate()) : 'Datum wählen'}
            </Typography>
          </Box>
        </Stack>

        <Box flex={1} display={'flex'} justifyContent={'flex-end'} alignSelf={'center'} width={'100%'}>
          <DatePicker
            value={props.value}
            open={isDatePickerOpen}
            onClose={() => setIsDatePickerOpen(false)}
            slots={{ field: DatePickerField }}
            onChange={(date) => {
              props.onChange(date);
            }}
          />
        </Box>
      </Stack>
    </MGTextRadioButton>
  );
};

type DatePickerFieldProps = {
  id;
  InputProps: { ref };
};
const DatePickerField = (props: DatePickerFieldProps) => (
  <div id={props.id} ref={props.InputProps.ref}>
    <CalendarSearch size={20} />
  </div>
);
