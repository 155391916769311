import { sepaMandateStateSchema } from './sepaMandateStateSchema.ts'
import { z } from 'zod'

export const sepaMandateFilterSchema = z.object({
  customer: z.union([z.number().int(), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  document_type: z.literal('sepa_mandate').default('sepa_mandate'),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  search: z.union([z.string(), z.null()]).optional(),
  state: z.union([z.lazy(() => sepaMandateStateSchema), z.null()]).optional(),
})