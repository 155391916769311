import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useTranslation } from '../../../i18n';
import { SearchModal } from '../../modals/SearchModal';
export const SidebarSearch = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const { tString } = useTranslation('sidebar');

  const onKeyDown = (e) => {
    // Open Search if CMD+K (on MAC) or CTRL+K (all other os) is pressed
    if (e.key === 'k' && (isMacOs ? !!e.metaKey : !!e.ctrlKey)) {
      e.preventDefault();
      setSearchModalOpen(!searchModalOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <>
      <TextField
        onClick={() => setSearchModalOpen(!searchModalOpen)}
        placeholder={tString('search_placeholder')}
        fullWidth
        focused={false}
        InputProps={{
          sx: { backgroundColor: '#EBEAE5' },
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box
                width={'40px'}
                display={'flex'}
                justifyContent={'center'}
                padding={'5px'}
                borderRadius={'5px'}
                sx={{ backgroundColor: '#DEDCD5' }}
              >
                <Typography fontSize={'0.78rem'}>⌘ K</Typography>
              </Box>
            </InputAdornment>
          ),
        }}
      />
      <SearchModal open={searchModalOpen} setOpen={setSearchModalOpen} />
    </>
  );
};
