import { BillingIn, useBilling } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { MGModal, MGModalBody } from '@mg/ui/src/components/MGModal';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtom } from 'jotai';
import Lottie from 'lottie-react';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../../i18n';
import loader from '../../../../../lottie/success.json';
import { billingMolecule } from '../../Billing.Atoms';

type Props = {
  disableToggle?: boolean;
};

export const BillingDetailsPerCustomerFinishBillingModal = ({ disableToggle }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('billing');

  const { billingCustomersAtom } = useMolecule(billingMolecule);
  const [billingCustomers] = useAtom(billingCustomersAtom);

  const billingMutation = useBilling();

  const startBilling = async () => {
    const billingInList: BillingIn[] = billingCustomers.map((billingCustomer) => ({
      subscription_id: billingCustomer.subscription.id ?? -1,
      start_date: billingCustomer.start_date ?? '',
      end_date: billingCustomer.end_date ?? '',
    }));

    await billingMutation.mutateAsync({ data: billingInList }, { onSuccess: invalidateAllQueries });
  };

  const renderPreBillContent = () => (
    <ModalContentLayout
      title={t('finish_billing_modal_billing_ready_title')}
      subtitle={t('finish_billing_modal_billing_ready_subtitle', { count: billingCustomers.length })}
      actions={
        <>
          <Button variant={'outlined'} onClick={() => setOpen(false)}>
            {t('finish_billing_modal_billing_ready_cancel_button')}
          </Button>
          <Button variant={'contained'} onClick={() => startBilling()}>
            {t('finish_billing_modal_billing_ready_confirm_button')}
          </Button>
        </>
      }
    />
  );

  const renderPostBillContent = () => (
    <ModalContentLayout
      before={
        <Stack height={'55px'} width={'150px'} mx={'auto'} justifyContent={'center'} alignItems={'center'}>
          <Lottie animationData={loader} loop={false} />
        </Stack>
      }
      title={t('finish_billing_modal_billing_done_title', { count: billingCustomers.length })}
      subtitle={t('finish_billing_modal_billing_done_subtitle', { count: billingCustomers.length })}
      actions={
        <>
          <Button variant={'outlined'} onClick={() => navigate('/')}>
            {t('finish_billing_modal_billing_done_cancel_button')}
          </Button>
          <Button variant={'contained'} onClick={() => navigate('/documents?tab=invoice')}>
            {t('finish_billing_modal_billing_done_confirm_button')}
          </Button>
        </>
      }
    />
  );

  const renderPostBillContentError = () => (
    <ModalContentLayout
      title={t('finish_billing_modal_billing_error_title')}
      subtitle={t('finish_billing_modal_billing_error_subtitle')}
      actions={
        <Button
          variant={'outlined'}
          onClick={() => {
            setOpen(false);
            billingMutation.reset();
          }}
        >
          {t('finish_billing_modal_billing_error_cancel_button')}
        </Button>
      }
    />
  );

  return (
    <>
      <Button variant={'contained'} onClick={() => setOpen(true)} disabled={disableToggle}>
        {t('details_per_customer_continue_button')}
      </Button>
      <MGModal open={open} handleToggle={() => setOpen(!open)}>
        <MGModalBody>
          {billingMutation.isIdle && renderPreBillContent()}
          {billingMutation.isPending && (
            <Box height={200} width={430}>
              <MGLoader />
            </Box>
          )}
          {billingMutation.isSuccess && renderPostBillContent()}
          {billingMutation.isError && renderPostBillContentError()}
        </MGModalBody>
      </MGModal>
    </>
  );
};

type ContentLayoutProps = {
  before?: ReactNode;
  after?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
};
const ModalContentLayout = ({ before, after, title, subtitle, actions }: ContentLayoutProps) => {
  return (
    <Stack spacing={2} padding={2} alignItems={'center'} gap={2}>
      {before}
      {title && <Typography variant={'h4'}>{title}</Typography>}
      {subtitle && (
        <Typography variant={'small'} textAlign={'center'} maxWidth={430}>
          {subtitle}
        </Typography>
      )}
      {actions && (
        <Stack direction={'row'} gap={2}>
          {actions}
        </Stack>
      )}
      {after}
    </Stack>
  );
};
