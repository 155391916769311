import { CurrentUserSchema } from '@mg/api-wrappers/src/api-internal';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const userInfoAtom = atomWithStorage<CurrentUserSchema | null>('userInfo', null, undefined, {
  getOnInit: true,
});

export const useUserInfo = () => {
  return useAtom(userInfoAtom);
};
