import { missingDataInSchema } from './missingDataInSchema.ts'
import { missingDataOutSchema } from './missingDataOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const listMissingData200Schema = z.array(z.lazy(() => missingDataOutSchema))

/**
 * @description Unprocessable Entity
 */
export const listMissingData422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listMissingDataMutationRequestSchema = z.array(z.lazy(() => missingDataInSchema))

export const listMissingDataMutationResponseSchema = z.lazy(() => listMissingData200Schema)