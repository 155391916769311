import { useListResidualPowerPlansInfinite } from '@mg/api-wrappers/src/api-internal';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { presentEuroCentPerKilowattHour, presentEuroPerYear } from '../../../presenters/number';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { PlansNoRowsOverlay } from './Plans.NoRowsOverlay';
import { PlansResidualPlansRowActionMenu } from './Plans.ResidualPlans.RowActionMenu';

export const PlansResidualPlans = ({ tabs, setCurrentTab }) => {
  const projectFilter = useSelectedProjectsFilter();
  const { tString } = useTranslation('plans');
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: tString('header_name'),
      flex: 0.6,
      sortable: false,
    },
    {
      field: 'price_base',
      headerName: tString('header_base_price'),
      flex: 0.4,
      valueGetter: (value) => presentEuroPerYear(value),
    },
    {
      field: 'price_per_kwh',
      flex: 0.4,
      headerName: tString('header_price_per_kwh'),
      valueGetter: (value) => presentEuroCentPerKilowattHour(value),
    },
    {
      field: 'supplier',
      headerName: tString('header_supplier'),
      flex: 0.4,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.2,
      renderCell: (params) => {
        return (
          <PlansResidualPlansRowActionMenu
            planId={params.row.id}
            isEditable={params.row.is_editable}
            isDeletable={params.row.is_editable}
          />
        );
      },
    },
  ];

  return (
    <DataGridCard
      reactQueryHook={useListResidualPowerPlansInfinite}
      reactQueryHookParams={{ ...projectFilter }}
      datagridProps={{
        onRowClick: (params) => {
          navigate(`/residualpowerplans/${params.row.id}/`);
        },
        slots: {
          noRowsOverlay: () => <PlansNoRowsOverlay type={'residual_plan'} />,
        },
      }}
      columns={columns}
      rowHeight={70}
      showSearch
      tabs={tabs}
      onTabChange={(key) => setCurrentTab(key)}
      height={'calc(100vh - 300px)'}
    />
  );
};
