/* Opens the FAQ Bar. Accepts the element id which should be opened */
export const openFaqBar = (id: string) => {
  const elements = document.getElementsByClassName('helpbar-placeholder');

  const faqBar = elements[0];

  faqBar.classList.add('open-large');
  faqBar.classList.add('open');

  const target = document.getElementById(id);
  target?.classList.add('show');
};
