import {
  useGetProjectRemoteReadabilityStatus,
  useListMeasuringPointPanels,
} from '@mg/api-wrappers/src/api-internal';
import { Button, Typography } from '@mui/material';
import TimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';
import { CircleAlert, CircleGauge } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { DashboardCard } from '../Dashboard.Card';

TimeAgo.addLocale(de);

export const DashboardQuickActionsMetering = () => {
  const { t } = useTranslation('dashboard');
  const projectFilter = useSelectedProjectsFilter();
  const timeAgo = new TimeAgo('de');

  const panelQuery = useListMeasuringPointPanels({
    ...projectFilter,
    include_last_reading: true,
    include_status: true,
    include_measuring_points: true,
    include_current_measuring_point: true,
    skip_pagination: true,
  });
  const remoteReadabilityStatusQuery = useGetProjectRemoteReadabilityStatus(projectFilter.project_id);

  const remotelyReadMeasuringPoints = panelQuery.data?.items.map((panel) =>
    panel.current_measuring_point?.is_read_remotely
      ? panel.current_measuring_point.last_reading?.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))[0]
      : null,
  );
  const inactivePoints = panelQuery.data?.items.filter((panel) => panel.measuring_points!.length > 1);

  const lastReading = remotelyReadMeasuringPoints?.sort((a, b) =>
    (a?.timestamp || 0) < (b?.timestamp || 0) ? -1 : 1,
  )[0];
  const isError = remoteReadabilityStatusQuery.data === 'error';

  return (
    <DashboardCard
      loading={panelQuery.isLoading}
      title={t('quick_actions_metering_title')}
      subtitle={
        <>
          <Typography variant={'small'}>
            {t('quick_actions_metering_subtitle_meters', {
              activeCount: panelQuery.data?.total_items,
              inactiveCount: inactivePoints?.length,
            })}
            <br />
          </Typography>

          {!isError ? (
            <Typography variant="small">
              {t('quick_actions_metering_subtitle_last_data', {
                timeframe: lastReading ? timeAgo.format(new Date(lastReading.timestamp)) : '-',
              })}
            </Typography>
          ) : (
            <Typography
              variant={'small'}
              color={'error'}
              sx={{ display: 'flex', alignItems: 'center', margin: '0px !important' }}
            >
              {t('quick_actions_metering_subtitle_missing_data')}
              <CircleAlert style={{ marginLeft: '5px' }} size={14} />
            </Typography>
          )}
        </>
      }
      icon={<CircleGauge />}
      button={
        <Button variant={'contained'} color={'white'} component={Link} to={'/metering'}>
          {t('quick_actions_metering_last_button')}
        </Button>
      }
    />
  );
};
