import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListPaymentPlansQueryResponse, ListPaymentPlansQueryParams, ListPaymentPlans422 } from '../types/ListPaymentPlans.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listPaymentPlansInfiniteQueryKey = (params: ListPaymentPlansQueryParams) =>
  [{ url: '/api-internal/payment_plans/' }, ...(params ? [params] : [])] as const

export type ListPaymentPlansInfiniteQueryKey = ReturnType<typeof listPaymentPlansInfiniteQueryKey>

/**
 * @summary List Payment Plans
 * {@link /api-internal/payment_plans/}
 */
async function listPaymentPlans(params: ListPaymentPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListPaymentPlansQueryResponse, ListPaymentPlans422, unknown>({
    method: 'GET',
    url: `/api-internal/payment_plans/`,
    params,
    ...config,
  })
  return res.data
}

export function listPaymentPlansInfiniteQueryOptions(params: ListPaymentPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listPaymentPlansInfiniteQueryKey(params)
  return infiniteQueryOptions<ListPaymentPlansQueryResponse, ListPaymentPlans422, ListPaymentPlansQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListPaymentPlansQueryParams['page']
      }
      return listPaymentPlans(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Payment Plans
 * {@link /api-internal/payment_plans/}
 */
export function useListPaymentPlansInfinite<
  TData = InfiniteData<ListPaymentPlansQueryResponse>,
  TQueryData = ListPaymentPlansQueryResponse,
  TQueryKey extends QueryKey = ListPaymentPlansInfiniteQueryKey,
>(
  params: ListPaymentPlansQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListPaymentPlansQueryResponse, ListPaymentPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPaymentPlansInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listPaymentPlansInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListPaymentPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}