import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  UpdateCustomerNotesMutationRequest,
  UpdateCustomerNotesMutationResponse,
  UpdateCustomerNotesPathParams,
  UpdateCustomerNotes422,
} from '../types/UpdateCustomerNotes.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const updateCustomerNotesMutationKey = () => [{ url: '/api-internal/customers/{customer_id}/notes' }] as const

export type UpdateCustomerNotesMutationKey = ReturnType<typeof updateCustomerNotesMutationKey>

/**
 * @summary Update Customer Notes
 * {@link /api-internal/customers/:customer_id/notes}
 */
async function updateCustomerNotes(
  customer_id: UpdateCustomerNotesPathParams['customer_id'],
  data: UpdateCustomerNotesMutationRequest,
  config: Partial<RequestConfig<UpdateCustomerNotesMutationRequest>> = {},
) {
  const res = await client<UpdateCustomerNotesMutationResponse, UpdateCustomerNotes422, UpdateCustomerNotesMutationRequest>({
    method: 'PATCH',
    url: `/api-internal/customers/${customer_id}/notes`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Update Customer Notes
 * {@link /api-internal/customers/:customer_id/notes}
 */
export function useUpdateCustomerNotes(
  options: {
    mutation?: UseMutationOptions<
      UpdateCustomerNotesMutationResponse,
      UpdateCustomerNotes422,
      { customer_id: UpdateCustomerNotesPathParams['customer_id']; data: UpdateCustomerNotesMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateCustomerNotesMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCustomerNotesMutationKey()

  return useMutation<
    UpdateCustomerNotesMutationResponse,
    UpdateCustomerNotes422,
    { customer_id: UpdateCustomerNotesPathParams['customer_id']; data: UpdateCustomerNotesMutationRequest }
  >({
    mutationFn: async ({ customer_id, data }) => {
      return updateCustomerNotes(customer_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}