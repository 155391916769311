import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  UpdateMeasuringPointPanelMutationRequest,
  UpdateMeasuringPointPanelMutationResponse,
  UpdateMeasuringPointPanelPathParams,
  UpdateMeasuringPointPanel422,
} from '../types/UpdateMeasuringPointPanel.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const updateMeasuringPointPanelMutationKey = () => [{ url: '/api-internal/measuring_point_panels/{id}' }] as const

export type UpdateMeasuringPointPanelMutationKey = ReturnType<typeof updateMeasuringPointPanelMutationKey>

/**
 * @summary Update Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
async function updateMeasuringPointPanel(
  id: UpdateMeasuringPointPanelPathParams['id'],
  data?: UpdateMeasuringPointPanelMutationRequest,
  config: Partial<RequestConfig<UpdateMeasuringPointPanelMutationRequest>> = {},
) {
  const res = await client<UpdateMeasuringPointPanelMutationResponse, UpdateMeasuringPointPanel422, UpdateMeasuringPointPanelMutationRequest>({
    method: 'PATCH',
    url: `/api-internal/measuring_point_panels/${id}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Update Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
export function useUpdateMeasuringPointPanel(
  options: {
    mutation?: UseMutationOptions<
      UpdateMeasuringPointPanelMutationResponse,
      UpdateMeasuringPointPanel422,
      { id: UpdateMeasuringPointPanelPathParams['id']; data?: UpdateMeasuringPointPanelMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateMeasuringPointPanelMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMeasuringPointPanelMutationKey()

  return useMutation<
    UpdateMeasuringPointPanelMutationResponse,
    UpdateMeasuringPointPanel422,
    { id: UpdateMeasuringPointPanelPathParams['id']; data?: UpdateMeasuringPointPanelMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return updateMeasuringPointPanel(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}