import { Box, Card, Skeleton, Stack } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  loading?: boolean;
  flex?: number;
  topContent: ReactNode;
  bottomContent: ReactNode;
};

/*
 * TODO: Make fully generic and add to /shared
 *  -> 3 Types of Cards: SingleItemCard, ListCard, ContentCard (used on Dashboard)
 * */
export const CustomerDetailsDataSingleItemCard = ({
  loading = false,
  flex,
  topContent,
  bottomContent,
}: Props) => {
  return (
    <Card sx={{ padding: '30px', flex, height: '100%' }}>
      <Stack spacing={2} height={'100%'} justifyContent={'center'}>
        <Box display={'flex'} justifyContent={'center'}>
          {loading ? <Skeleton width={'50px'} height={'30px'} /> : topContent}
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          {loading ? <Skeleton width={'150px'} /> : bottomContent}
        </Box>
      </Stack>
    </Card>
  );
};
