import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  GetProjectRemoteReadabilityStatusQueryResponse,
  GetProjectRemoteReadabilityStatusPathParams,
  GetProjectRemoteReadabilityStatus422,
} from '../types/GetProjectRemoteReadabilityStatus.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getProjectRemoteReadabilityStatusQueryKey = (id: GetProjectRemoteReadabilityStatusPathParams['id']) =>
  [{ url: '/api-internal/projects/:id/remote_readability_status', params: { id: id } }] as const

export type GetProjectRemoteReadabilityStatusQueryKey = ReturnType<typeof getProjectRemoteReadabilityStatusQueryKey>

/**
 * @summary Get Project Remote Readability Status
 * {@link /api-internal/projects/:id/remote_readability_status}
 */
async function getProjectRemoteReadabilityStatus(id: GetProjectRemoteReadabilityStatusPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetProjectRemoteReadabilityStatusQueryResponse, GetProjectRemoteReadabilityStatus422, unknown>({
    method: 'GET',
    url: `/api-internal/projects/${id}/remote_readability_status`,
    ...config,
  })
  return res.data
}

export function getProjectRemoteReadabilityStatusQueryOptions(id: GetProjectRemoteReadabilityStatusPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getProjectRemoteReadabilityStatusQueryKey(id)
  return queryOptions<
    GetProjectRemoteReadabilityStatusQueryResponse,
    GetProjectRemoteReadabilityStatus422,
    GetProjectRemoteReadabilityStatusQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getProjectRemoteReadabilityStatus(id, config)
    },
  })
}

/**
 * @summary Get Project Remote Readability Status
 * {@link /api-internal/projects/:id/remote_readability_status}
 */
export function useGetProjectRemoteReadabilityStatus<
  TData = GetProjectRemoteReadabilityStatusQueryResponse,
  TQueryData = GetProjectRemoteReadabilityStatusQueryResponse,
  TQueryKey extends QueryKey = GetProjectRemoteReadabilityStatusQueryKey,
>(
  id: GetProjectRemoteReadabilityStatusPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetProjectRemoteReadabilityStatusQueryResponse, GetProjectRemoteReadabilityStatus422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getProjectRemoteReadabilityStatusQueryKey(id)

  const query = useQuery({
    ...(getProjectRemoteReadabilityStatusQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetProjectRemoteReadabilityStatus422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}