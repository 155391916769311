import {
  Box,
  Button,
  Chip,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Info } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../../i18n';
import { presentDate } from '../../../../../presenters/date';
import { presentEuroCentPerKilowattHour, presentEuroPerYear } from '../../../../../presenters/number';
import { MuiBaseModal } from '../../../../modals/MuiBaseModal';
import { SoftIcon } from '../../../../shared/SoftIcon';

export const CustomerDetailsContractsSwitchPlanModal = ({
  subscriptionPlans,
  initiallySelectedPlanId,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedSubscriptionPlanId, setSelectedSubscriptionPlanId] = useState(initiallySelectedPlanId);
  const { t } = useTranslation('customerDetails');

  useEffect(() => {
    setSelectedSubscriptionPlanId(initiallySelectedPlanId);
  }, [initiallySelectedPlanId]);

  const handleSubmit = () => {
    onSelect(selectedSubscriptionPlanId);
    setOpen(false);
  };
  return (
    <>
      <Button onClick={() => setOpen(!open)} color={'white'} size={'small'} variant={'contained'}>
        {t('contracts_card_plan_modal_button')}
      </Button>
      <MuiBaseModal
        open={open}
        handleToggle={() => setOpen(!open)}
        width={'1200px'}
        PaperStyle={{ padding: '40px' }}
        title={t('contracts_card_plan_modal_title')}
      >
        <Stack paddingY={'20px'} height={'100%'} justifyContent={'space-between'} spacing={3}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <SoftIcon size={25}>
              <Info />
            </SoftIcon>
            <Typography variant={'small'}>{t('contracts_card_plan_modal_info_message')}</Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {subscriptionPlans?.map((sp) => (
                  <TableRow key={sp.id}>
                    <TableCell sx={{ border: 0 }}>
                      <Radio
                        checked={selectedSubscriptionPlanId === sp.id}
                        onChange={() => setSelectedSubscriptionPlanId(sp.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'} fontWeight={'500'}>
                        {sp.plan?.name}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'}>
                        {sp.valid_to
                          ? `${presentDate(sp.valid_from)} - ${presentDate(sp.valid_to)}`
                          : `Ab ${presentDate(sp.valid_from)}`}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'}>{presentEuroPerYear(sp.plan?.price_base)}</Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      {sp.plan?.plan_components?.map((component) => (
                        <Stack direction={'row'} spacing={1}>
                          <Typography variant={'small'}>
                            {presentEuroCentPerKilowattHour(component.price_per_kwh)}
                          </Typography>
                          <Typography variant={'small'}>
                            {t(`calculation_basis_${component.calculation_basis}`)}
                          </Typography>
                          <br />
                        </Stack>
                      ))}
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Chip
                        color={sp.is_active ? 'success' : 'gray'}
                        label={sp.is_active ? t('active') : t('inactive')}
                        sx={{ borderRadius: '8px' }}
                        size={'small'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button variant={'contained'} onClick={handleSubmit}>
              {t('contracts_card_modal_submit')}
            </Button>
          </Box>
        </Stack>
      </MuiBaseModal>
    </>
  );
};
