import { Box, Button, Divider, Stack } from '@mui/material';

export interface MGSelectAllButtonProps {
  onSelectAll: () => void;
  onUnselectAll: () => void;
  selectAllDisabled: boolean;
  unselectAllDisabled: boolean;
}
export const MGSelectAllButton = ({
  onSelectAll,
  onUnselectAll,
  selectAllDisabled = false,
  unselectAllDisabled = false,
}: MGSelectAllButtonProps) => {
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
      <Button onClick={onSelectAll} disabled={selectAllDisabled} sx={{ color: 'gray.dark' }}>
        {'Alles auswählen'}
      </Button>
      <Box height={'14px'}>
        <Divider orientation="vertical" />
      </Box>
      <Button onClick={onUnselectAll} disabled={unselectAllDisabled} sx={{ color: 'gray.dark' }}>
        {'Auswahl aufheben'}
      </Button>
    </Stack>
  );
};
