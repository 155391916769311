import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { SetNewPasswordMutationRequest, SetNewPasswordMutationResponse, SetNewPassword401, SetNewPassword422 } from '../types/SetNewPassword.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const setNewPasswordMutationKey = () => [{ url: '/api-internal/auth/set_new_password' }] as const

export type SetNewPasswordMutationKey = ReturnType<typeof setNewPasswordMutationKey>

/**
 * @summary Set New Password
 * {@link /api-internal/auth/set_new_password}
 */
async function setNewPassword(data: SetNewPasswordMutationRequest, config: Partial<RequestConfig<SetNewPasswordMutationRequest>> = {}) {
  const res = await client<SetNewPasswordMutationResponse, SetNewPassword401 | SetNewPassword422, SetNewPasswordMutationRequest>({
    method: 'POST',
    url: `/api-internal/auth/set_new_password`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Set New Password
 * {@link /api-internal/auth/set_new_password}
 */
export function useSetNewPassword(
  options: {
    mutation?: UseMutationOptions<SetNewPasswordMutationResponse, SetNewPassword401 | SetNewPassword422, { data: SetNewPasswordMutationRequest }>
    client?: Partial<RequestConfig<SetNewPasswordMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setNewPasswordMutationKey()

  return useMutation<SetNewPasswordMutationResponse, SetNewPassword401 | SetNewPassword422, { data: SetNewPasswordMutationRequest }>({
    mutationFn: async ({ data }) => {
      return setNewPassword(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}