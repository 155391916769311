import { useListPlansInfinite } from '@mg/api-wrappers/src/api-internal';
import { presentEuroPerYear } from '@mg/ui/src/presenters';
import { Chip, Stack, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UserCheck, UserCog, UserX } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { ComponentPrices } from '../../shared/ComponentPrices/ComponentPrices';
import { DataGridCard, DataGridCardProps } from '../../shared/DataGridCard/DataGridCard';
import { PlansNoRowsOverlay } from './Plans.NoRowsOverlay';
import { PlansTenantPlansRowActionMenu } from './Plans.TenantPlans.RowActionMenu';

export const PlansTenantPlans = ({
  tabs,
  setCurrentTab,
}: {
  tabs: DataGridCardProps['tabs'];
  setCurrentTab: DataGridCardProps['onTabChange'];
}) => {
  const projectFilter = useSelectedProjectsFilter();
  const { t, tString } = useTranslation('plans');
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: tString('header_name'),
      flex: 0.4,
    },
    {
      field: 'id',
      flex: 0.5,
      headerName: tString('header_subscriptions'),
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} spacing={1} height={'100%'} alignItems={'center'}>
            <Tooltip arrow placement={'top'} title={t('active_subscriptions')}>
              <Chip
                sx={{ borderRadius: '8px' }}
                variant={'soft'}
                color={'success'}
                label={
                  <>
                    <UserCheck size={'18'} /> {params.row.active_subscriptions}
                  </>
                }
              />
            </Tooltip>
            <Tooltip arrow placement={'top'} title={t('expired_subscriptions')}>
              <Chip
                sx={{ borderRadius: '8px' }}
                variant={'soft'}
                color={'gray'}
                label={
                  <>
                    <UserX size={'18'} /> {params.row.expired_subscriptions}
                  </>
                }
              />
            </Tooltip>
            <Tooltip arrow placement={'top'} title={t('planned_subscriptions')}>
              <Chip
                sx={{ borderRadius: '8px' }}
                variant={'soft'}
                color={'primary'}
                label={
                  <>
                    <UserCog size={'18'} /> {params.row.planned_subscriptions}
                  </>
                }
              />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'type',
      headerName: tString('header_type'),
      flex: 0.3,
      sortable: false,
      valueGetter: (_, row) => {
        return row?.plan_components?.length > 1 ? 'two_components' : 'mixed_price';
      },
      renderCell: (params) => {
        return (
          <Chip
            variant={'soft'}
            color={params.value === 'two_components' ? 'primary' : 'purple'}
            label={t(params.value)}
          />
        );
      },
    },
    {
      field: 'price_base',
      headerName: tString('header_base_price'),
      flex: 0.4,
      sortable: false,
      valueGetter: (value) => presentEuroPerYear(value),
    },
    {
      field: 'price_per_kwh',
      flex: 0.4,
      headerName: tString('header_price_per_kwh'),
      sortable: false,
      renderCell: (params) => {
        return <ComponentPrices planComponents={params.row.plan_components} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.2,
      renderCell: (params) => {
        return (
          <PlansTenantPlansRowActionMenu
            planId={params.row.id}
            isEditable={params.row.is_editable}
            isDeletable={params.row.is_editable}
          />
        );
      },
    },
  ];

  return (
    <DataGridCard
      reactQueryHook={useListPlansInfinite}
      reactQueryHookParams={{
        ...projectFilter,
        include_plan_components: true,
        include_active_subscriptions: true,
        include_expired_subscriptions: true,
        include_planned_subscriptions: true,
      }}
      columns={columns}
      height={'calc(100vh - 300px)'}
      rowHeight={70}
      showSearch
      tabs={tabs}
      searchPlaceholder={tString('search_placeholder')}
      onTabChange={setCurrentTab}
      datagridProps={{
        onRowClick: (row) => {
          navigate(`/plans/${row.id}`);
        },
        slots: {
          noRowsOverlay: () => <PlansNoRowsOverlay type={'plan'} />,
        },
      }}
    />
  );
};
