import { palette } from '@mg/ui/src/styles';
import { ClipboardPlus, Pencil, Plus, ReceiptText, Trash2, UserRound } from 'lucide-react';
import { cloneElement, ReactElement, useState } from 'react';
import { useTranslation } from '../../../i18n';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

type Props = {
  customerId: number;
  subscriptionId?: number | null;
  status: 'active' | 'inactive' | 'future' | 'canceled';
  hideGoToProfile?: boolean;
  buttonComponent?: ReactElement;
};
export const CustomersRowActionMenu = ({
  customerId,
  status,
  hideGoToProfile = false,
  subscriptionId,
  buttonComponent,
}: Props) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const { t } = useTranslation('customers');

  const menuItems: MenuItem[] = [
    {
      text: t('row_menu_go_to_profile'),
      icon: <UserRound size={18} />,
      hidden: hideGoToProfile,
      href: `/customers/${customerId}`,
    },
    {
      text: t('row_menu_edit'),
      icon: <Pencil size={18} />,
      href: `/customers/${customerId}/edit/`,
    },
    {
      text: t('row_menu_settle'),
      icon: <ReceiptText size={18} />,
      href: `/customers/billing/intervals?subscriptionIds=${subscriptionId}`,
    },
    {
      text: t('row_menu_create_sepa_mandate'),
      icon: <ClipboardPlus size={18} />,
      hidden: status === 'inactive',
      href: `/customers/${customerId}/sepa/`,
    },
    {
      text: t('row_menu_create_electricity_price_break_quota'),
      icon: <Plus size={18} />,
      hidden: status === 'inactive',
      href: `/customers/electricity_price_break_quota`,
    },
    {
      text: t('row_menu_cancel'),
      hidden: status === 'inactive',
      icon: <Trash2 size={18} color={palette.error.main} />,
      href: `/customers/${customerId}/cancel/`,
    },
  ];

  return (
    <>
      <ActionsMenu
        buttonComponent={
          buttonComponent &&
          cloneElement(buttonComponent, { onClick: () => setActionMenuOpen(!actionMenuOpen) })
        }
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
    </>
  );
};
