import { GetTransactionStatisticsQueryKey } from '@mg/api-wrappers/src/api-internal/hooks/useGetTransactionStatistics';
import { GetTransactionStatisticsQuery } from '@mg/api-wrappers/src/api-internal/types/GetTransactionStatistics';
import { presentDate } from '@mg/ui/src/presenters';
import { presentEuro } from '@mg/ui/src/presenters/number'; // /presenters/number';
import { palette } from '@mg/ui/src/styles';
import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { useTranslation } from '../../../i18n';

type AccountingStatisticsProps = {
  statisticsQueryResult: UseQueryResult<
    GetTransactionStatisticsQuery['Response'],
    GetTransactionStatisticsQuery['Errors']
  > & {
    queryKey: GetTransactionStatisticsQueryKey;
  };
};

export const AccountingStatistics = ({ statisticsQueryResult }: AccountingStatisticsProps) => {
  const { data: statistics, isLoading, error } = statisticsQueryResult;
  const { t } = useTranslation('transactions');
  if (error) return null;

  const openAmountColor =
    typeof statistics?.open_amount !== 'number' || statistics.open_amount === 0
      ? undefined
      : statistics.open_amount > 0
        ? palette.error.main
        : palette.success.main;

  return (
    <Grid container direction="row" spacing={2} justifyContent="space-between" sx={{ pb: 4 }}>
      <Grid item xs={4}>
        <AccountingStatisticsItemLayout
          title={statistics && presentEuro(statistics.planned_amount)}
          subtitle={
            <>
              {t('planned_income')}{' '}
              {statistics?.plan_end_date && presentDate(new Date(statistics?.plan_end_date))})
            </>
          }
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={4}>
        <AccountingStatisticsItemLayout
          title={statistics && presentEuro(statistics.closed_amount)}
          subtitle={t('already_paid')}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={4}>
        <AccountingStatisticsItemLayout
          title={
            statistics && (
              <Box component="span" color={openAmountColor}>
                {presentEuro(statistics.open_amount)}
              </Box>
            )
          }
          subtitle={t('open_amount')}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

type AccountingStatisticsItemProps = { title?: ReactNode; subtitle?: ReactNode; isLoading?: boolean };
const AccountingStatisticsItemLayout = ({ title, subtitle, isLoading }: AccountingStatisticsItemProps) => (
  <Paper sx={{ p: 4, borderColor: 'rgba(5, 28, 44, 0.10)', height: '100%' }}>
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="end">
        {isLoading ? (
          <Typography variant="h3">
            <Skeleton width={100} />
          </Typography>
        ) : (
          title && <Typography variant="h3">{title}</Typography>
        )}
      </Stack>

      <Typography variant="small" color="gray.dark" sx={{ fontWeight: '400' }}>
        {isLoading ? <Skeleton width={60} sx={{ display: 'inline-block' }} /> : subtitle}
      </Typography>
    </Stack>
  </Paper>
);
