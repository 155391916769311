import { SepaMandateFilter, sepaMandateState, useListCustomers } from '@mg/api-wrappers/src/api-internal';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardFilterForm } from '../../shared/DataGridCard/DataGridCard.FilterForm';
import { DeclarativeFormFieldType } from '../../shared/DeclarativeForm';

export type CustomSepaFilter = Partial<SepaMandateFilter>;

type SepaFilterFormValues = {
  state: string;
  customer: string;
};

export const DocumentsSepaTableFilterForm = () => {
  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t, tString } = useTranslation('documents');
  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);

  const createInitialFormValues: () => SepaFilterFormValues = () => ({
    state: '',
    customer: '',
  });

  const customerOptions = useMemo(() => {
    if (!customersQuery.data) return [];

    return customersQuery.data?.items
      .map((customer) => ({ value: customer.id + '', label: customer.display_name + '' }))
      .filter((option, optionIndex, self) => self.findIndex((o) => o.value === option.value) === optionIndex);
  }, [customersQuery.data]);

  return (
    <>
      <DataGridCardFilterForm
        formId={'sepa-table-filter'}
        formTitle={t('sepa_filter_title')}
        filter={filter}
        onFilter={(filter) => {
          setFilterValue(filter);
          setFilterModalOpen(false);
        }}
        initialValues={createInitialFormValues()}
        serialize={(formValues: SepaFilterFormValues) => {
          const customerId = parseInt(formValues.customer);

          const result: CustomSepaFilter = {};

          if (customerId) result.customer = customerId;
          if (formValues.state && formValues.state !== 'blank')
            result.state = formValues.state as SepaMandateFilter['state'];

          return result;
        }}
        deserialize={(filter: CustomSepaFilter) => {
          const formValues: SepaFilterFormValues = createInitialFormValues();

          if (filter.customer) formValues.customer = filter.customer + '';
          if (filter.state) formValues.state = filter.state;

          return formValues;
        }}
        fieldDefinitions={[
          {
            name: 'state',
            label: t('filter_status_label'),
            innerLabel: t('sepa_filter_status_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: [
              { value: sepaMandateState.ACCEPTED, label: tString(sepaMandateState.ACCEPTED) },
              { value: sepaMandateState.CANCELLED, label: tString(sepaMandateState.CANCELLED) },
              { value: sepaMandateState.CREATED, label: tString(sepaMandateState.CREATED) },
            ],
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'customer',
            label: t('filter_customer_label'),
            innerLabel: t('filter_customer_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: customerOptions,
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
        ]}
      />
    </>
  );
};
