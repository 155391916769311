import { MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generateColorFromId } from '../../../helpers/generateColor';
import { getMeasuringPointDataLabel } from '../../../helpers/getMeasuringPointDataLabel';
import {
  LineChartData,
  LineType,
  MGMultiLineChart,
  MGMultiLineChartProps,
} from '../../shared/Charts/MGMultiLineChart';
import { MeteringHistoryGraphLegend } from './MeteringHistory.Graph.Legend';

type Props = {
  measuringPointList: MeasuringPointSchema[] | undefined;
};

export const MeteringHistoryGraph = ({ measuringPointList }: Props) => {
  const theme = useTheme();

  const [selectedMeasuringPointIds, setSelectedMeasuringPointIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hoveredLegendItem, setHoveredLegendItem] = useState<number | null>(null);

  useEffect(() => {
    setSearchParams({ selectedMeasuringPoints: selectedMeasuringPointIds.toString() }, { replace: true });
  }, [selectedMeasuringPointIds]);

  useEffect(() => {}, []);

  useEffect(() => {
    const selectedMeasuringPoints = searchParams.get('selectedMeasuringPoints')?.split(',');
    if (selectedMeasuringPoints) {
      if (selectedMeasuringPoints) {
        setSelectedMeasuringPointIds(selectedMeasuringPoints.map((id) => parseInt(id)));
      }
    } else {
      setSelectedMeasuringPointIds(measuringPointList?.map((point) => point.id as number) || []);
    }
  }, [measuringPointList]);

  const chartData: LineChartData[] =
    measuringPointList && !!measuringPointList
      ? measuringPointList
          .map((point) => {
            const color = generateColorFromId(point.panel_id);
            const obisCodes = Object.keys(point.data!);
            const isHovered = hoveredLegendItem === point.id;
            return obisCodes.map((obisCode) => {
              const isConsumption = obisCode === '1-0:1.8.0';
              return {
                id: `${point.id}-${obisCode}-${point.type}`,
                point_id: point.id,
                panel_id: point.panel_id,
                color: isHovered || !hoveredLegendItem ? color : 'lightgray',
                strokeWidth: isHovered ? 3 : 2,
                lineType: !isConsumption ? LineType.Dashed : LineType.Solid,
                data: point.data![obisCode].map(({ timestamp, value }) => ({
                  x: new Date(timestamp),
                  y: point.conversion_factor ? value * point.conversion_factor : value,
                  panel_name: point.measuring_point_panel?.name,
                  customer_name: point.customer?.display_name,
                  obisCode,
                  serial: point.serial,
                })),
              };
            });
          })
          .flat()
      : [];

  const Tooltip: MGMultiLineChartProps['renderTooltip'] = ({ tooltipData }) => {
    if (!tooltipData?.nearestDatum?.datum) return;

    return (
      <Paper sx={{ borderRadius: theme.spacing(1), padding: theme.spacing(1, 2) }}>
        <Stack sx={{ gap: theme.spacing(1) }}>
          <Typography variant={'small'} fontWeight={'bold'}>
            {tooltipData.nearestDatum.datum.serial as string} <br />(
            {getMeasuringPointDataLabel(tooltipData.nearestDatum.key)})
          </Typography>
          <Typography variant={'extraSmall'}>
            {tooltipData.nearestDatum.datum.panel_name as string}
          </Typography>
          <Typography variant={'extraSmall'}>
            {tooltipData.nearestDatum.datum.customer_name as string}
          </Typography>

          <Typography variant={'extraSmall'}>{presentDate(tooltipData.nearestDatum.datum.x)}</Typography>

          <Typography fontWeight={'bold'} variant={'small'}>
            {presentKwh(tooltipData?.nearestDatum?.datum?.y || 0)}
          </Typography>
        </Stack>
      </Paper>
    );
  };

  return (
    <Stack spacing={3}>
      <Box height={'calc(100vh - 550px)'} minHeight={'400px'}>
        <ParentSize>
          {({ width, height }) => (
            <MGMultiLineChart
              dataSet={chartData.filter((data) =>
                selectedMeasuringPointIds.includes(data.point_id as number),
              )}
              width={width}
              height={height}
              renderTooltip={Tooltip}
            />
          )}
        </ParentSize>
      </Box>
      <Divider />

      <MeteringHistoryGraphLegend
        selectedMeasuringPointIds={selectedMeasuringPointIds}
        measuringPoints={measuringPointList}
        onChange={setSelectedMeasuringPointIds}
        onHover={setHoveredLegendItem}
        hoveredItem={hoveredLegendItem}
      />
    </Stack>
  );
};
