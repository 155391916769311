import { palette } from '@mg/ui/src/styles';
import axios from 'axios';
import { Trash2 } from 'lucide-react';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

type Props = {
  levyId: number;
  levyStructureId: number;
  isDeletable?: boolean;
};
export const LevyStructureLeviesRowActionMenu = ({ levyId, levyStructureId, isDeletable }: Props) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const { t } = useTranslation('levies');

  const menuItems: MenuItem[] = [
    {
      text: t('delete'),
      icon: <Trash2 color={palette.error.main} size={'18'} />,
      disabled: !isDeletable,
      onClick: () => {
        fireSweetalert({
          title: t('delete_levy_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_levy_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios.get(`/levystructures/${levyStructureId}/levies/${levyId}/delete/`).then(() => {
              invalidateAllQueries();
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
    </>
  );
};
