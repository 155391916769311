import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface MGModalTitleProps {
  children: string | ReactNode;
}

export const MGModalTitle = ({ children }: MGModalTitleProps) => {
  if (typeof children === 'string') {
    return <Typography variant={'h4'}>{children}</Typography>;
  }
  return children;
};
