import { createTinyFlags } from 'tiny-flags';

const flags = {
  billingDebug: {
    label: 'Billing: Enable debug',
    value: false,
  },
};

export const { FlagsProvider, useFlags } = createTinyFlags(flags);
