import { measuringPointSchemaSchema } from './measuringPointSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getMeasuringPointPathParamsSchema = z.object({
  id: z.number().int(),
})

export const getMeasuringPointQueryParamsSchema = z
  .object({
    include_data: z.boolean().default(false),
    include_measuring_point_panel: z.boolean().default(false),
    include_customer: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const getMeasuringPoint200Schema = z.lazy(() => measuringPointSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getMeasuringPoint422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getMeasuringPointQueryResponseSchema = z.lazy(() => getMeasuringPoint200Schema)