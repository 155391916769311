import { PlanComponentSchema } from '@mg/api-wrappers/src/api-internal';
import { MGCardVerticalListLayout } from '@mg/ui/src/components/MGCard';
import { presentDate, presentEuroPerYear } from '@mg/ui/src/presenters';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { ComponentPrices } from '../ComponentPrices/ComponentPrices';

export interface PlanRowCardProps {
  planName: string;
  priceBase?: number | string;
  startDate: string | Date;
  endDate?: string | Date | null;
  planComponents: PlanComponentSchema[];
}

export const PlanRowCard = ({
  planName,
  priceBase,
  startDate,
  endDate,
  planComponents,
}: PlanRowCardProps) => {
  const isMixedPrice = planComponents.length === 1;
  return (
    <Card>
      <Box sx={{ paddingY: 3, paddingX: 4 }}>
        <MGCardVerticalListLayout>
          <MGCardVerticalListLayout.Item label="Tarifname">
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Typography variant={'small'} fontWeight={500} noWrap>
                {planName}
              </Typography>
              <Box>
                <Chip
                  color={isMixedPrice ? 'purple' : 'primary'}
                  variant={'soft'}
                  label={isMixedPrice ? 'Mischpreis' : '2-Komponenten'}
                  size={'small'}
                />
              </Box>
            </Stack>
          </MGCardVerticalListLayout.Item>

          <MGCardVerticalListLayout.Item label="Gültigkeit">
            {presentDate(startDate)} - {endDate ? presentDate(endDate) : 'Heute'}
          </MGCardVerticalListLayout.Item>

          <MGCardVerticalListLayout.Item label="Grundpreis">
            {presentEuroPerYear(priceBase)}
          </MGCardVerticalListLayout.Item>

          <MGCardVerticalListLayout.Item label="Arbeitspreis">
            <ComponentPrices planComponents={planComponents} />
          </MGCardVerticalListLayout.Item>
        </MGCardVerticalListLayout>
      </Box>
    </Card>
  );
};
