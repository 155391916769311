import { MoreHoriz } from '@mui/icons-material';
import { Box, Divider, IconButton, Menu, Typography } from '@mui/material';
import { ReactElement, ReactNode, cloneElement, useEffect, useRef, useState } from 'react';
import { MGActionMenuItem, MGActionMenuItemProps } from './MGActionMenu.Item';

type Props = {
  title?: ReactNode;
  menuItems?: MGActionMenuItemProps[];
  children?: ReactNode;
  open?: boolean;
  onToggle?: () => void;
  buttonComponent?: ReactElement;
  spacing?: number;
};

export const MGActionMenu = ({
  title,
  menuItems,
  open,
  onToggle,
  buttonComponent,
  spacing,
  children,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (open !== undefined) {
      setMenuOpen(open);
    }
  }, [open]);

  const handleToggle = (e) => {
    if (onToggle) {
      onToggle();
    } else {
      setMenuOpen(!menuOpen);
    }
    e.stopPropagation();
  };

  return (
    <>
      {buttonComponent ? (
        cloneElement(buttonComponent, { ref: ref, onClick: handleToggle })
      ) : (
        <IconButton ref={ref} onClick={handleToggle}>
          <MoreHoriz />
        </IconButton>
      )}

      <Menu
        open={menuOpen}
        onClose={handleToggle}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ width: '500px' }}
        slotProps={{
          paper: {
            sx: {
              minWidth: '200px',
              overflow: 'visible',
              marginTop: spacing ? `${spacing}px` : '0px',
            },
          },
        }}
      >
        <Box paddingX={'20px'} paddingBottom={'10px'} paddingTop={'5px'}>
          <Typography variant={'small'}>{title || 'Aktionen'}</Typography>
        </Box>
        <Divider />

        {menuItems &&
          menuItems
            .filter((item) => !item.hidden)
            .map((item, i) => (
              <MGActionMenuItem
                key={`action-menu-item${i}`}
                {...item}
                onClick={() => {
                  setMenuOpen(false);
                  if (item.onClick) {
                    item.onClick();
                  }
                }}
              />
            ))}
        {children}
      </Menu>
    </>
  );
};
