import {
  CustomerSchema,
  getTransactionStatisticsQueryKey,
  listTransactionsInfiniteQueryKey,
} from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu } from '@mg/ui/src/components/MGActionMenu';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ChevronDown, Download, Plus, RefreshCw } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useSelectedProjectsFilter } from '../../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../../i18n';
import { DjangoFormModal } from '../../../../modals/DjangoFormModal';
import { SepaXmlGenerationModal } from '../../../../modals/SepaXmlGenerationModal/SepaXmlGenerationModal';

type Props = {
  customer: CustomerSchema | undefined;
};

export const CustomerDetailsAccountingActionMenu = ({ customer }: Props) => {
  const [createTransactionModalOpen, setCreateTransactionModalOpen] = useState(false);
  const [sepaXmlModalOpen, setSepaXmlModalOpen] = useState(false);
  const projectFilter = useSelectedProjectsFilter();
  const queryClient = useQueryClient();
  const { t } = useTranslation('customerDetails');

  const items = [
    {
      text: t('transaction_menu_create_transaction'),
      icon: <Plus size={20} />,
      onClick: () => setCreateTransactionModalOpen(true),
    },
    {
      text: t('transaction_menu_upload_mt940'),
      icon: <RefreshCw size={20} />,
      href: '/transactions/mt940_upload',
    },
    {
      text: t('transaction_menu_generate_sepa'),
      icon: <Download size={20} />,
      onClick: () => setSepaXmlModalOpen(true),
    },
  ];

  const invalidateQueries = useCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: getTransactionStatisticsQueryKey({ ...projectFilter }) }),
      queryClient.invalidateQueries({
        queryKey: listTransactionsInfiniteQueryKey({ ...projectFilter }),
      }),
    ]);
  }, [queryClient, projectFilter]);

  return (
    <>
      <MGActionMenu
        menuItems={items}
        spacing={15}
        buttonComponent={
          <Button endIcon={<ChevronDown />} variant={'contained'}>
            {t('transaction_menu_actions')}
          </Button>
        }
      />
      <DjangoFormModal
        open={createTransactionModalOpen}
        handleToggle={() => setCreateTransactionModalOpen(!createTransactionModalOpen)}
        title={t('transaction_menu_create_transaction')}
        pathToDjangoForm={'/transactions/create'}
        prefill={[{ id: 'id_customer', value: customer?.id as number }]}
        onSuccess={() => {
          setCreateTransactionModalOpen(false);
          invalidateQueries();
        }}
      />
      <SepaXmlGenerationModal
        open={sepaXmlModalOpen}
        handleToggle={() => setSepaXmlModalOpen(!sepaXmlModalOpen)}
      />
    </>
  );
};
