import { GetBillingPreflightDataQuery } from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useTranslation } from '../../../../../i18n';

type Props = {
  data: UseQueryResult<GetBillingPreflightDataQuery['Response'], GetBillingPreflightDataQuery['Errors']>[];
};
export const BillingDetailsPerCustomerLoading = ({ data }: Props) => {
  const { t } = useTranslation('billing');
  const ref = useRef<HTMLDivElement>(null);
  const cardContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !cardContentRef.current) return;
    const el = ref.current;
    const cardContentEl = cardContentRef.current;

    const observer = new ResizeObserver(() => {
      const y = el.getBoundingClientRect().y;
      const innerHeight = window.innerHeight;

      cardContentEl.style.height = `${innerHeight - y - 152}px`;
    });
    observer.observe(document.body);

    return () => observer.disconnect();
  }, [ref]);

  const countLoading = data.filter((entry) => entry.isLoading).length;
  const countFinished = data.length - countLoading;
  const total = countLoading + countFinished;
  const percentage = (countFinished / total) * 100;

  return (
    <div ref={ref}>
      <MGCard>
        <Stack justifyContent={'center'} alignItems={'center'} gap={3} ref={cardContentRef}>
          <Box sx={{ mb: 2 }}>
            <MGLoader />
          </Box>
          <Typography variant="h3">{t('details_per_customer_loading_title')}</Typography>
          <Box sx={{ width: '363px' }}>
            <LinearProgress variant="determinate" value={percentage} />
          </Box>
          <Typography variant="small" sx={{ mb: 8 }}>
            {t('details_per_customer_loading_subtitle', { current: countFinished, total })}
          </Typography>
        </Stack>
      </MGCard>
    </div>
  );
};
