import {
  ListMissingDataMutationResponse,
  MissingDataOut,
  useListMissingData,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtomValue } from 'jotai/index';
import _ from 'lodash';
import { InfoIcon, TriangleAlert } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from '../../../../../feature-flags';
import { useTranslation } from '../../../../../i18n';
import { billingMolecule } from '../../Billing.Atoms';
import { useRequireBillingInitialized } from '../../useRequireBillingInitialized';
import { BillingMeteringDataCard } from './Billing.MeteringData.Card';

export const BillingMeteringData = () => {
  useRequireBillingInitialized();
  const location = useLocation();

  const { t } = useTranslation('billing');
  const navigate = useNavigate();
  const { billingDebug: isDebug } = useFlags();

  const [meteringData, setMeteringData] = useState<ListMissingDataMutationResponse>([]);

  const missingDataMutation = useListMissingData();
  const { billingCustomersAtom } = useMolecule(billingMolecule);
  const billingCustomers = useAtomValue(billingCustomersAtom);

  const refreshMissingData = () => {
    const data = billingCustomers.map((entry) => ({
      subscription_id: entry.subscription.id!,
      start_date: entry.start_date!,
      end_date: entry.end_date!,
    }));
    missingDataMutation.mutateAsync({ data }).then((data) => {
      setMeteringData(data);
    });
  };

  useEffect(
    () => {
      refreshMissingData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Including 'missingDataMutation' would cause an infinite loop
    [billingCustomers],
  );

  const dataGrouped = useMemo(() => {
    const grouped = _.groupBy(meteringData, (item: MissingDataOut) => {
      return item.customer?.id || item.measuring_point_panel?.id || item.errors?.[0].code;
    }) as Record<string, MissingDataOut[]>;

    const groupedList = Object.values(grouped);

    groupedList.sort((arrA, arrB) => {
      const arrAErrors = arrA.reduce((acc, curr) => acc + (curr.errors?.length ?? 0), 0);
      const arrBErrors = arrB.reduce((acc, curr) => acc + (curr.errors?.length ?? 0), 0);
      const measuringPointPanelNameA = arrA[0].measuring_point_panel?.name ?? '';
      const measuringPointPanelNameB = arrB[0].measuring_point_panel?.name ?? '';
      const customerDisplayNameA = arrA[0].customer?.display_name ?? '';
      const customerDisplayNameB = arrB[0].customer?.display_name ?? '';

      return (
        arrBErrors - arrAErrors ||
        measuringPointPanelNameA.localeCompare(measuringPointPanelNameB) ||
        customerDisplayNameA.localeCompare(customerDisplayNameB)
      );
    });

    return groupedList;
  }, [meteringData]);

  if (missingDataMutation.isPending) {
    return <MGLoader />;
  }

  if (!billingCustomers.length) {
    return <Stack>Keine abrechenbaren Kunden gefunden</Stack>;
  }

  const hasError = meteringData.some((data) => !!data.errors?.length);
  return (
    <Stack spacing={5} minHeight={'calc(100vh - 280px)'} justifyContent={'space-between'}>
      <Stack spacing={2}>
        <MGAlert
          icon={<InfoIcon />}
          severity={'info'}
          title={t('billing_metering_data_info_title')}
          subtitle={t('billing_metering_data_info_subtitle')}
        />
        {hasError && (
          <MGAlert
            icon={<TriangleAlert />}
            severity={'error'}
            title={t('metering_details_missing_data_alert_title')}
            subtitle={t('metering_details_missing_data_alert_subtitle')}
          />
        )}
        <Typography color={'text.primary'}>{t('metering_details_title')}</Typography>

        {dataGrouped.map((data, index) => (
          <BillingMeteringDataCard key={index} meteringData={data} />
        ))}
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'contained'} color={'gray'} onClick={() => navigate(-1)}>
          {t('metering_details_back_button')}
        </Button>
        <Stack direction="row" spacing={2}>
          {hasError && (
            <Button variant={'contained'} onClick={refreshMissingData}>
              Erneut prüfen
            </Button>
          )}
          <Button
            variant={'contained'}
            component={Link}
            to={'/customers/billing/details-per-customer' + (location.search ? location.search : '')}
            disabled={(!isDebug && hasError) || !missingDataMutation.isSuccess}
          >
            {t('metering_details_continue_button')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
