import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectSchemaSchema } from './projectSchemaSchema.ts'
import { z } from 'zod'

export const getProjectPathParamsSchema = z.object({
  id: z.number().int(),
})

export const getProjectQueryParamsSchema = z
  .object({
    include_provider: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const getProject200Schema = z.lazy(() => projectSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getProject422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getProjectQueryResponseSchema = z.lazy(() => getProject200Schema)