import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectSchemaSchema } from './projectSchemaSchema.ts'
import { projectSwitchSchemaSchema } from './projectSwitchSchemaSchema.ts'
import { z } from 'zod'

export const switchProjectQueryParamsSchema = z
  .object({
    include_provider: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const switchProject200Schema = z.lazy(() => projectSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const switchProject422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const switchProjectMutationRequestSchema = z.lazy(() => projectSwitchSchemaSchema)

export const switchProjectMutationResponseSchema = z.lazy(() => switchProject200Schema)