import { MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { FieldHeading } from '../layout/FieldHeading';
import { FieldLabel } from '../layout/FieldLabel';
import { FieldLayout } from '../layout/FieldLayout';
import { BaseFieldProps } from '../types/BaseFieldProps';

export type SelectFieldProps = BaseFieldProps &
  MuiSelectProps & {
    name: string;

    control: Control;
    options: { value: string; label: string }[];
    hasBlankOption?: boolean;
    blankOptionLabel?: string;
    blankOptionValue?: string;
  };

export const SelectField = ({
  id,
  name,
  control,
  heading,
  label,
  options,
  hasBlankOption,
  blankOptionLabel,
  blankOptionValue,
}: SelectFieldProps) => {
  const fieldId = id + '-select';

  return (
    <FieldLayout
      titleSlot={<FieldHeading>{heading}</FieldHeading>}
      labelSlot={<FieldLabel id={fieldId}>{label}</FieldLabel>}
      inputSlot={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MuiSelect
              id={fieldId}
              labelId={fieldId + '-label'}
              label={label}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
              inputRef={field.ref}
            >
              {hasBlankOption && <MenuItem value={blankOptionValue || 'blank'}>{blankOptionLabel}</MenuItem>}
              {options.map(({ value, label }) => (
                <MenuItem key={id + '-' + value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        />
      }
    />
  );
};
