import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useRefreshQueriesWithRunningTasks = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (!query.options.meta?.refetchForActiveTasks) return false;
          if (query.state.status !== 'success') return false;
          if (!query.isActive()) return false;
          if (query.isDisabled()) return false;
          if (!hasActiveTask(query.state.data)) return false;

          return true;
        },
        refetchType: 'active',
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);
};

const hasActiveTask = (value, depth = 0): boolean => {
  if (Array.isArray(value)) {
    for (const item of value) {
      if (hasActiveTask(item, depth + 1)) return true;
    }
  } else if (value === null) {
    return false;
  } else if (typeof value === 'object') {
    if (value.celery_uuid) {
      if (!value.is_finished) {
        return true;
      }
    } else {
      for (const key in value) {
        if (hasActiveTask(value[key], depth + 1)) return true;
      }
    }
  }
  return false;
};
