import { VisibilityOutlined } from '@mui/icons-material';
import { useTranslation } from '../../../i18n';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

export const MeteringDetailsCustomerListActionMenu = ({ customerId }) => {
  const { t } = useTranslation('meteringDetails');

  const menuItems: MenuItem[] = [
    {
      text: t('customer_list_action_menu_show_customer'),
      icon: <VisibilityOutlined />,
      href: `/customers/${customerId}`,
    },
  ];

  return <ActionsMenu menuItems={menuItems} />;
};
