import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetLetterPdfsAsZipQueryResponse, GetLetterPdfsAsZipQueryParams, GetLetterPdfsAsZip400 } from '../types/GetLetterPdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLetterPdfsAsZipQueryKey = (params: GetLetterPdfsAsZipQueryParams) =>
  [{ url: '/api-internal/letters/pdfs' }, ...(params ? [params] : [])] as const

export type GetLetterPdfsAsZipQueryKey = ReturnType<typeof getLetterPdfsAsZipQueryKey>

/**
 * @summary Get Letter Pdfs As Zip
 * {@link /api-internal/letters/pdfs}
 */
async function getLetterPdfsAsZip(params: GetLetterPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetLetterPdfsAsZipQueryResponse, GetLetterPdfsAsZip400, unknown>({
    method: 'GET',
    url: `/api-internal/letters/pdfs`,
    params,
    ...config,
  })
  return res.data
}

export function getLetterPdfsAsZipQueryOptions(params: GetLetterPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getLetterPdfsAsZipQueryKey(params)
  return queryOptions<GetLetterPdfsAsZipQueryResponse, GetLetterPdfsAsZip400, GetLetterPdfsAsZipQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLetterPdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Letter Pdfs As Zip
 * {@link /api-internal/letters/pdfs}
 */
export function useGetLetterPdfsAsZip<
  TData = GetLetterPdfsAsZipQueryResponse,
  TQueryData = GetLetterPdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetLetterPdfsAsZipQueryKey,
>(
  params: GetLetterPdfsAsZipQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetLetterPdfsAsZipQueryResponse, GetLetterPdfsAsZip400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLetterPdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getLetterPdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetLetterPdfsAsZip400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}