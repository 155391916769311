import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Navigate } from 'react-router-dom';
import { Sidebar } from '../components/features/Sidebar/Sidebar';
import { userInfoAtom } from '../helpers/userInfo';
import { MainContainer } from './MainContainer';

export const AppLayout = () => {
  // Needs to be kept in sync with the width set in backend/frontend/static/theme/components/react-sidebar.scss
  const sidebarWidth = '280px';
  const userInfo = useAtomValue(userInfoAtom);

  if (!userInfo) {
    return <Navigate replace to="/login" />;
  }
  return (
    <Box>
      <Sidebar width={sidebarWidth} />
      <MainContainer sidebarWidth={sidebarWidth} />
    </Box>
  );
};
