import { palette } from '@mg/ui/src/styles';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import { FolderOpen } from 'lucide-react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from '../../../../i18n';
import { presentEuro } from '../../../../presenters/number';
import { Checkbox } from '../../../shared/HookFormIntegration/Checkbox';
import { useMultiStepPanelContext } from '../../../shared/MultiStepPanel/MultiStepPanel.Context';
import { CheckboxFieldsetContainer } from '../CheckboxFieldset';
import { ErrorIndicator } from '../ErrorIndicator';
import { useFunnelContext } from '../FunnelProvider.Context';

export type PositionsStepFieldValues = {
  transactions: {
    value: boolean;
    id: number;
    customerId: number;
    voucherDate: string;
    amount: number;
    reason: string;
    originalFieldIndex: number;
    customerDisplayName: string;
    projectId: number;
  }[];
};

export const PositionsStep = () => {
  const { t } = useTranslation('sepaGeneration');

  const { data: funnelData, setData: setFunnelData } = useFunnelContext();
  const { currentStep, previous } = useMultiStepPanelContext();

  const { control, handleSubmit, getValues } = useForm<PositionsStepFieldValues>({
    values: { transactions: funnelData.transactions },
  });
  const transactions = useWatch({ control, name: 'transactions' });

  const projectId = funnelData.viewPositionsForProjectId;

  const { fields, replace } = useFieldArray<PositionsStepFieldValues>({
    control,
    name: `transactions`,
  });

  const onSubmit = () => {
    setFunnelData({ ...funnelData, ...getValues() });
    previous();
  };

  const filteredFields = fields
    .map((field, index) => ({ ...field, originalIndex: index }))
    .filter((field) => field.projectId === projectId);

  const groups = groupBy(filteredFields, 'customerId');

  const groupedList = Object.keys(groups).map((customerId) => ({
    customerId: Number(customerId),
    customerDisplayName: groups[customerId][0].customerDisplayName,
    fields: groups[customerId],
  }));

  const setMappedTransactionListToFieldArray = (value = true) => {
    if (funnelData.transactions) {
      replace(
        funnelData.transactions.map((transaction) => ({
          ...transaction,
          value,
        })),
      );
    }
  };

  return (
    <>
      <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} gap={4}>
        <Box>
          {groupedList.length === 0 && (
            <Stack height={300} alignItems={'center'} justifyContent={'center'} gap={2}>
              <ErrorIndicator icon={<FolderOpen />}>{t('positions_empty')}</ErrorIndicator>
            </Stack>
          )}
          {groupedList.length > 0 && (
            <CheckboxFieldsetContainer
              title={t('checkbox_fieldset_positions')}
              itemCount={transactions.filter((ta) => ta.value && ta.projectId === projectId).length}
              height={'300px'}
              selectAllLabel={t('checkbox_fieldset_select_all')}
              deselectAllLabel={t('checkbox_fieldset_deselect_all')}
              onSelectAll={() => setMappedTransactionListToFieldArray()}
              onDeselectAll={() => setMappedTransactionListToFieldArray(false)}
            >
              <Stack gap={3} mt={2}>
                {groupedList.map((group) => (
                  <Box key={group.customerId}>
                    <Typography component={'p'}>{group.customerDisplayName}</Typography>
                    <Grid container mt={1.5}>
                      {group.fields.map((field) => (
                        <Grid key={field.projectId + '-' + field.id} item xs={12} md={6} pl={2}>
                          <Checkbox
                            name={`transactions.${field.originalIndex}.value`}
                            control={control}
                            label={
                              <Typography
                                fontSize={14}
                                color={palette.text.black}
                                sx={{ display: 'inline-flex', flexDirection: 'row', gap: 1 }}
                              >
                                <span style={{ width: '80px' }}>
                                  {dayjs(field.voucherDate).format('DD.MM.YYYY')}
                                </span>
                                <span>{presentEuro(field.amount)}</span>
                                <span>
                                  {t(`checkbox_fieldset_transactions_${field.reason?.toLowerCase()}`)}
                                </span>
                              </Typography>
                            }
                            formControlLabelProps={{
                              sx: {
                                '.MuiCheckbox-root': { py: 0.8 },
                              },
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Stack>
            </CheckboxFieldsetContainer>
          )}
        </Box>

        <Stack direction={'row'} justifyContent={'flex-end'}>
          {groupedList.length === 0 && (
            <Button
              variant={'contained'}
              color={'white'}
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
            >
              {t(`modal_button_back_${currentStep.key}`)}
            </Button>
          )}
          {groupedList.length > 0 && (
            <Button variant={'contained'} type="submit">
              {t(`modal_button_next_${currentStep.key}`)}
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};
