import {
  useSetProjectToOnboardingComplete,
  useSetProjectToOnboardingV1Complete,
} from '@mg/api-wrappers/src/api-internal';
import { Box, Button, Stack } from '@mui/material';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { invalidateAllQueries } from '../../../../helpers/invalidateAllQueries';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useTranslation } from '../../../../i18n';

type Props = {
  step: 'project_data' | 'plans_and_levies' | 'metering';
  nextStepDisabled?: boolean;
  onChangeStep: (step: 'project_data' | 'plans_and_levies' | 'metering') => void;
};
export const OnboardingFooter = ({ step, nextStepDisabled, onChangeStep }: Props) => {
  const { t } = useTranslation('onboarding');

  const projectId = useCurrentProject();
  const completeOnboardingBaseDataMutation = useSetProjectToOnboardingV1Complete();
  const completeOnboardingMutation = useSetProjectToOnboardingComplete();

  const handleNext = () => {
    if (step === 'project_data') {
      completeOnboardingBaseDataMutation.mutate(
        { id: projectId },
        {
          onSuccess: () => {
            onChangeStep('plans_and_levies');
            invalidateAllQueries();
          },
        },
      );
    }
    if (step === 'plans_and_levies') {
      onChangeStep('metering');
    }
    if (step === 'metering') {
      completeOnboardingMutation.mutate(
        { id: projectId },
        {
          onSuccess: () => {
            invalidateAllQueries();
          },
        },
      );
    }
  };

  const handlePrevious = () => {
    if (step === 'plans_and_levies') {
      onChangeStep('project_data');
    }
    if (step === 'metering') {
      onChangeStep('plans_and_levies');
    }
  };

  return (
    <Stack height={'100%'} direction={'row'} justifyContent={'space-between'} paddingX={'70px'}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {step !== 'project_data' && (
          <Button variant={'outlined'} startIcon={<ArrowLeft />} onClick={handlePrevious}>
            {t(`onboarding_sticky_footer_button_previous_${step}`)}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'contained'}
          endIcon={<ArrowRight />}
          disabled={nextStepDisabled}
          onClick={handleNext}
        >
          {t(`onboarding_sticky_footer_button_next_${step}`)}
        </Button>
      </Box>
    </Stack>
  );
};
