import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { requestPasswordResetInSchema } from './requestPasswordResetInSchema.ts'
import { z } from 'zod'

/**
 * @description No Content
 */
export const requestPasswordReset204Schema = z.unknown()

/**
 * @description Unprocessable Entity
 */
export const requestPasswordReset422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const requestPasswordResetMutationRequestSchema = z.lazy(() => requestPasswordResetInSchema)

export const requestPasswordResetMutationResponseSchema = z.lazy(() => requestPasswordReset204Schema)