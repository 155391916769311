import { type ModelValidationErrorInstanceOut, ModelValidationErrorOut } from './api-internal';

export type APIError = {
  type: string;
  title: string;
};

export type NetworkError = APIError & {
  type: 'https://metergrid.de/api-errors/network-error';
};

export type UnknownError = APIError & {
  type: 'https://metergrid.de/api-errors/unknown-error';
};

export const modelValidationErrorsForLocation = (
  error: ModelValidationErrorOut,
  location: string[],
): ModelValidationErrorInstanceOut[] => {
  return error.errors.filter((e) => locationsMatch(e.location, location));
};

const locationsMatch = (a: string[], b: string[]): boolean => {
  return a.length === b.length && a.every((value, index) => value == b[index]);
};
