import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { inoutPanelMissingSchema } from './inoutPanelMissingSchema.ts'
import { measuringPointDataSchemaSchema } from './measuringPointDataSchemaSchema.ts'
import { measuringPointMissingSchema } from './measuringPointMissingSchema.ts'
import { measuringPointNestedSchemaSchema } from './measuringPointNestedSchemaSchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { missingEndDataSchema } from './missingEndDataSchema.ts'
import { missingStartDataSchema } from './missingStartDataSchema.ts'
import { obisCodesSchema } from './obisCodesSchema.ts'
import { sourcePanelMissingSchema } from './sourcePanelMissingSchema.ts'
import { z } from 'zod'

export const missingDataOutSchema = z.object({
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  end_data: z.union([z.lazy(() => measuringPointDataSchemaSchema), z.null()]).optional(),
  end_datetime: z.string().datetime(),
  errors: z.union([
    z.array(
      z.union([
        z.lazy(() => inoutPanelMissingSchema),
        z.lazy(() => sourcePanelMissingSchema),
        z.lazy(() => measuringPointMissingSchema),
        z.lazy(() => missingStartDataSchema),
        z.lazy(() => missingEndDataSchema),
      ]),
    ),
    z.null(),
  ]),
  measuring_point: z.union([z.lazy(() => measuringPointNestedSchemaSchema), z.null()]).optional(),
  measuring_point_panel: z.union([z.lazy(() => measuringPointPanelNestedSchemaSchema), z.null()]).optional(),
  obis_code: z.union([z.lazy(() => obisCodesSchema), z.null()]).optional(),
  start_data: z.union([z.lazy(() => measuringPointDataSchemaSchema), z.null()]).optional(),
  start_datetime: z.string().datetime(),
  subscription: z.union([z.lazy(() => measuringPointSubscriptionNestedSchemaSchema), z.null()]).optional(),
})