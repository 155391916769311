import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { residualPowerPlanSchemaSchema } from './residualPowerPlanSchemaSchema.ts'
import { z } from 'zod'

export const getResidualPowerPlanPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getResidualPowerPlan200Schema = z.lazy(() => residualPowerPlanSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getResidualPowerPlan422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getResidualPowerPlanQueryResponseSchema = z.lazy(() => getResidualPowerPlan200Schema)