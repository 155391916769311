import { NavigateOptions as RouterNavigateOptions, useLocation, useNavigate } from 'react-router-dom';

type NavigateOptions = RouterNavigateOptions & {
  forwardSearchParams?: boolean;
};

export const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (path: string, options?: NavigateOptions) => {
    const { forwardSearchParams = true, ...rest } = options || {};
    navigate(path + (forwardSearchParams && location.search ? location.search : ''), rest);
  };
};
