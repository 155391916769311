import { Box } from '@mui/material';
import { MeteringDetailsOverview } from '../../../MeteringDetails/MeteringDetails.Overview';

type Props = {
  panelId: number;
};
export const CustomerDetailsMetering = ({ panelId }: Props) => {
  /*
   * Placeholder Component to have consistent architecture and the possibility to add funcitonality to the Details Tab
   * */
  return (
    <Box paddingBottom={'20px'}>
      <MeteringDetailsOverview panelId={panelId} />
    </Box>
  );
};
