import { MeasuringPointSchema, RemoteReadingStatus } from '@mg/api-wrappers/src/api-internal';
import { presentDateTime } from '@mg/ui/src/presenters/date.ts';
import { useTheme } from '@mui/material';
import { CircleOffIcon, LogInIcon, LogOutIcon, TriangleAlertIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from '../../../../i18n';
import { MGTooltip } from '../../../shared/MGTooltip';

type Props = {
  obis_code: string;
  remote_reading_status: RemoteReadingStatus | undefined;
  measuring_point: MeasuringPointSchema;
};
export const RemoteReadingStatusIcon = ({ obis_code, remote_reading_status, measuring_point }: Props) => {
  const { t } = useTranslation('latestReadings');
  const theme = useTheme();

  let icon: ReactNode = <span></span>;
  let tooltipText: ReactNode | undefined = undefined;

  if (obis_code === '1-0:1.8.0') {
    icon = <LogInIcon size={16} color={theme.palette.primary.dark} />;
  } else if (obis_code === '1-0:2.8.0') {
    icon = <LogOutIcon size={16} color={theme.palette.secondary.main} />;
  }

  if (remote_reading_status?.status === 'no_data') {
    icon = <CircleOffIcon size={16} color="#e70a3f" />;
    tooltipText = t('no_remote_readability_ever');
  } else if (remote_reading_status?.status === 'delayed') {
    icon = <TriangleAlertIcon size={16} color="#fab005" />;
    tooltipText = t('no_remote_readability_since', {
      date: presentDateTime(remote_reading_status.latest_data_at),
    });
  } else if (remote_reading_status?.status === 'ok') {
    tooltipText = t('remote_readability_ok');
  } else if (!measuring_point.is_read_remotely) {
    tooltipText = t('read_manually');
  }

  return (
    <MGTooltip title={t(`${measuring_point.type}_${obis_code}`, { nsSeparator: false })} text={tooltipText}>
      {icon}
    </MGTooltip>
  );
};
