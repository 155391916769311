import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { customerNoteUpdateSchemaSchema } from './customerNoteUpdateSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const updateCustomerNotesPathParamsSchema = z.object({
  customer_id: z.number().int(),
})

/**
 * @description OK
 */
export const updateCustomerNotes200Schema = z.lazy(() => customerNestedSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const updateCustomerNotes422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const updateCustomerNotesMutationRequestSchema = z.lazy(() => customerNoteUpdateSchemaSchema)

export const updateCustomerNotesMutationResponseSchema = z.lazy(() => updateCustomerNotes200Schema)