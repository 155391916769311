import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { Control, Controller } from 'react-hook-form';
import { FieldHeading } from '../layout/FieldHeading';
import { FieldLayout } from '../layout/FieldLayout';
import { BaseFieldProps } from '../types/BaseFieldProps';

export type DateFieldProps = BaseFieldProps &
  DatePickerProps<Dayjs, false> & {
    name: string;

    control: Control;
    datePickerProps?: Omit<
      DatePickerProps<Dayjs, false>,
      'value' | 'onChange' | 'inputRef' | 'name' | 'label'
    >;
  };

export const DateField = ({ name, heading, label, control, datePickerProps }: DateFieldProps) => {
  return (
    <FieldLayout
      titleSlot={<FieldHeading>{heading}</FieldHeading>}
      inputSlot={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                disabled={field.disabled}
                inputRef={field.ref}
                name={field.name}
                label={label}
                localeText={{ start: 'Beginn', end: 'Ende' }}
                {...datePickerProps}
              />
            </LocalizationProvider>
          )}
        />
      }
    />
  );
};
