import { AxiosError } from 'axios';
import { ReactNode } from 'react';
import { DjangoForm } from '../shared/DjangoForm';
import { MuiBaseModal } from './MuiBaseModal';

type Props = {
  open: boolean;
  handleToggle: () => void;
  title: ReactNode;
  pathToDjangoForm: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
  prefill?: { id: string; value: string | number }[];
  BodyStyle?: React.CSSProperties;
  contentBefore?: React.ReactNode;
};
export const DjangoFormModal = ({
  open,
  handleToggle,
  title,
  pathToDjangoForm,
  onSuccess,
  onError,
  prefill,
  BodyStyle,
  contentBefore = null,
}: Props) => {
  return (
    <MuiBaseModal
      showCloseButton
      open={open}
      handleToggle={handleToggle}
      height={'750px'}
      width={'1000px'}
      PaperStyle={{ padding: '40px', paddingRight: '20px', paddingBottom: '20px' }}
      BodyStyle={{ paddingRight: '20px', ...BodyStyle }}
      title={title}
    >
      {contentBefore}
      <DjangoForm
        hideTitle
        pathToDjangoForm={pathToDjangoForm}
        onSuccess={onSuccess}
        onError={onError}
        prefill={prefill}
      />
    </MuiBaseModal>
  );
};
