import { letterCreateSchemaSchema } from './letterCreateSchemaSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const previewLetter200Schema = z.unknown()

export const previewLetterMutationRequestSchema = z.lazy(() => letterCreateSchemaSchema)

export const previewLetterMutationResponseSchema = z.lazy(() => previewLetter200Schema)