import { createContext, useContext } from 'react';
import { PositionsStepFieldValues } from './steps/PositionsStep';
import { TimeFrameStepFieldValues } from './steps/TimeFrameStep';
import { TransactionsStepFieldValues } from './steps/TransactionsStep';

export type FunnelContextData = TimeFrameStepFieldValues &
  PositionsStepFieldValues &
  TransactionsStepFieldValues & {
    viewPositionsForProjectId: number;
  };

export const FUNNEL_CONTEXT_INITIAL_DATA: FunnelContextData = {
  range: [null, null],
  projects: [],
  transactions: [],
  allProjects: true,
  closeSelectedTransactions: true,
  createBatchSepaFiles: true,
  viewPositionsForProjectId: -1,
  wodisYuneoVoucherNumber: '',
};

export const FUNNEL_CONTEXT_INIT = {
  data: FUNNEL_CONTEXT_INITIAL_DATA,
  setData: () => {},
  error: null,
  setError: () => {},
};

export const FunnelContext = createContext<{
  data: FunnelContextData;
  setData: (data: FunnelContextData) => void;
  error: string | null;
  setError: (error: string) => void;
}>({
  ...FUNNEL_CONTEXT_INIT,
});

export const useFunnelContext = () => {
  const context = useContext(FunnelContext);
  if (context === undefined) {
    throw new Error('useFunnelContext must be used within a FunnelProvider');
  }
  return context;
};
