import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  CancelSepaMandateMutationRequest,
  CancelSepaMandateMutationResponse,
  CancelSepaMandatePathParams,
  CancelSepaMandate422,
} from '../types/CancelSepaMandate.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const cancelSepaMandateMutationKey = () => [{ url: '/api-internal/sepa_mandates/{id}/cancel' }] as const

export type CancelSepaMandateMutationKey = ReturnType<typeof cancelSepaMandateMutationKey>

/**
 * @summary Cancel Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/cancel}
 */
async function cancelSepaMandate(
  id: CancelSepaMandatePathParams['id'],
  data: CancelSepaMandateMutationRequest,
  config: Partial<RequestConfig<CancelSepaMandateMutationRequest>> = {},
) {
  const res = await client<CancelSepaMandateMutationResponse, CancelSepaMandate422, CancelSepaMandateMutationRequest>({
    method: 'POST',
    url: `/api-internal/sepa_mandates/${id}/cancel`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Cancel Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/cancel}
 */
export function useCancelSepaMandate(
  options: {
    mutation?: UseMutationOptions<
      CancelSepaMandateMutationResponse,
      CancelSepaMandate422,
      { id: CancelSepaMandatePathParams['id']; data: CancelSepaMandateMutationRequest }
    >
    client?: Partial<RequestConfig<CancelSepaMandateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? cancelSepaMandateMutationKey()

  return useMutation<
    CancelSepaMandateMutationResponse,
    CancelSepaMandate422,
    { id: CancelSepaMandatePathParams['id']; data: CancelSepaMandateMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return cancelSepaMandate(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}