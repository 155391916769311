import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  BulkEmailPreflightMutationRequest,
  BulkEmailPreflightMutationResponse,
  BulkEmailPreflightQueryParams,
  BulkEmailPreflight422,
} from '../types/BulkEmailPreflight.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const bulkEmailPreflightMutationKey = () => [{ url: '/api-internal/document_email/bulk_preflight' }] as const

export type BulkEmailPreflightMutationKey = ReturnType<typeof bulkEmailPreflightMutationKey>

/**
 * @summary Bulk Email Preflight
 * {@link /api-internal/document_email/bulk_preflight}
 */
async function bulkEmailPreflight(
  data?: BulkEmailPreflightMutationRequest,
  params?: BulkEmailPreflightQueryParams,
  config: Partial<RequestConfig<BulkEmailPreflightMutationRequest>> = {},
) {
  const res = await client<BulkEmailPreflightMutationResponse, BulkEmailPreflight422, BulkEmailPreflightMutationRequest>({
    method: 'POST',
    url: `/api-internal/document_email/bulk_preflight`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Bulk Email Preflight
 * {@link /api-internal/document_email/bulk_preflight}
 */
export function useBulkEmailPreflight(
  options: {
    mutation?: UseMutationOptions<
      BulkEmailPreflightMutationResponse,
      BulkEmailPreflight422,
      { data?: BulkEmailPreflightMutationRequest; params?: BulkEmailPreflightQueryParams }
    >
    client?: Partial<RequestConfig<BulkEmailPreflightMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkEmailPreflightMutationKey()

  return useMutation<
    BulkEmailPreflightMutationResponse,
    BulkEmailPreflight422,
    { data?: BulkEmailPreflightMutationRequest; params?: BulkEmailPreflightQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return bulkEmailPreflight(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}