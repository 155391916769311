import { CustomerSchema, useListCustomers } from '@mg/api-wrappers/src/api-internal';
import { customerDisplayName } from '@mg/ui/src/presenters';
import { PresentableCustomer } from '@mg/ui/src/presenters/customer';
import {
  Alert,
  AlertTitle,
  Box,
  debounce,
  Divider,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from '../../i18n';
import { MuiBaseModal } from './MuiBaseModal';

export const SearchModal = ({ open, setOpen }) => {
  const [searchValue, setSearchValue] = useState('');

  const { t, tString } = useTranslation('sidebar');

  const customersQuery = useListCustomers(
    {
      search: searchValue,
      order_by: 'id',
      page_size: 5,
      include_address: true,
      include_current_plan: true,
    },
    { query: { enabled: !!searchValue } },
  );

  const handleSearchValueChange = async function (e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  };

  const handleCustomerSelect = (customer: CustomerSchema) => {
    const projectId = customer.project!.id;
    window.location.href = `/project-switch/${projectId}?next=/customers/${customer.id}`;
  };

  const Loader = () => {
    return (
      <>
        <Skeleton
          sx={{ marginBottom: '10px', borderRadius: '5px' }}
          variant="rectangular"
          animation={'wave'}
          height={30}
        />
        <Skeleton
          sx={{ marginBottom: '10px', borderRadius: '5px' }}
          variant="rectangular"
          animation={'wave'}
          height={30}
        />
        <Skeleton
          sx={{ marginBottom: '10px', borderRadius: '5px' }}
          variant="rectangular"
          animation={'wave'}
          height={30}
        />
        <Skeleton
          sx={{ marginBottom: '10px', borderRadius: '5px' }}
          variant="rectangular"
          animation={'wave'}
          height={30}
        />
      </>
    );
  };

  const NoResults = () => {
    return (
      <>
        <Alert severity={'info'}>
          <AlertTitle sx={{ marginBottom: '10px', fontSize: '16px', color: 'inherit' }}>
            {t('search_modal_no_results_title')}
          </AlertTitle>
          {t('search_modal_no_results_content')}
        </Alert>
      </>
    );
  };

  return (
    <MuiBaseModal
      open={open}
      handleToggle={() => setOpen(!open)}
      height={'auto'}
      width={'700px'}
      position={{ top: '200px !important', anchorTop: 'top' }}
    >
      <Grid container padding={'20px'}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{
              autoComplete: 'off',
            }}
            onChange={debounce(handleSearchValueChange, 200)}
            placeholder={tString('search_modal_search_placeholder')}
          />
          <Divider sx={{ marginY: '20px' }} />

          {customersQuery.isLoading ? (
            <Loader />
          ) : !customersQuery.data?.total_items ? (
            <NoResults />
          ) : (
            customersQuery.data?.items.map((customer) => (
              <MenuItem
                key={`${customer.id}-${customer.first_name}`}
                sx={{
                  paddingX: '20px',
                  paddingY: '10px',
                  borderRadius: '.625rem',
                  marginBottom: '10px',
                  marginX: '0',
                  overflow: 'hidden',
                }}
                onClick={() => handleCustomerSelect(customer)}
              >
                <Stack direction="row" gap={3} width="100%">
                  <Box width="40px">{customer.id}</Box>
                  <Box flex="2" textOverflow={'ellipsis'} overflow={'hidden'}>
                    {customerDisplayName(customer as PresentableCustomer)}
                  </Box>
                  <Box
                    flex="3"
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    paddingLeft={'20px'}
                    sx={{ textAlign: 'right' }}
                  >
                    {`${customer.address!.street} ${customer.address!.street_number}, ${customer.address!.city}`}
                  </Box>
                </Stack>
              </MenuItem>
            ))
          )}
        </Grid>
      </Grid>
    </MuiBaseModal>
  );
};
