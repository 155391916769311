// noinspection AllyJsxHardcodedStringInspection

import { Avatar, AvatarGroup, Button, Stack, Typography } from '@mui/material';
import { CircleHelp, MessageCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../i18n';

export const DashboardSupportCard = () => {
  const { t } = useTranslation('dashboard');
  const openFaqBar = () => {
    const elements = document.getElementsByClassName('helpbar-placeholder');
    const faqBar = elements[0];
    faqBar.classList.add('open');
  };

  return (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={2} paddingX={15} paddingY={5}>
      <AvatarGroup sx={{ alignItems: 'flex-end', '.MuiAvatarGroup-avatar': { borderColor: '#F6F5F3' } }}>
        <Avatar sx={{ width: 60, height: 60 }} alt="Alan" src="/static/img/avatar_alan.png" />
        <Avatar sx={{ width: 72, height: 72, zIndex: 1 }} alt="Julian" src="/static/img/avatar_julian.png" />
        <Avatar sx={{ width: 60, height: 60 }} alt="Hannes" src="/static/img/avatar_hannes.png" />
      </AvatarGroup>

      <Typography variant={'h3'}>{t('support_card_title')}</Typography>
      <Typography variant={'small'} textAlign={'center'}>
        {t('support_card_subtitle')}
      </Typography>

      <Stack direction={'row'} spacing={1}>
        <Button variant={'contained'} startIcon={<CircleHelp size={18} />} onClick={openFaqBar}>
          {t('support_card_faq_button')}
        </Button>
        <Button
          variant={'contained'}
          color={'white'}
          startIcon={<MessageCircle size={18} />}
          component={Link}
          to={'/support/'}
        >
          {t('support_card_contact_support_button')}
        </Button>
      </Stack>
    </Stack>
  );
};
