import { panelType } from '@mg/api-wrappers/src/api-internal';
import { hooklessT } from '../i18n';

export const getMeasuringPointDataLabel = (graphPointId: string) => {
  if (graphPointId.includes('1-0:1.8.0') && graphPointId.includes(panelType.INOUT)) {
    return hooklessT('meteringDetails', 'metering_graph_residual_current');
  }
  if (graphPointId.includes('1-0:2.8.0') && graphPointId.includes(panelType.INOUT)) {
    return hooklessT('meteringDetails', 'metering_graph_feedin_current');
  }
  if (graphPointId.includes('1-0:2.8.0') && graphPointId.includes(panelType.SOURCE)) {
    return hooklessT('meteringDetails', 'metering_graph_production');
  }
  if (graphPointId.includes('1-0:1.8.0') && graphPointId.includes(panelType.SOURCE)) {
    return hooklessT('meteringDetails', 'metering_graph_consumption_pv_system');
  }
  if (graphPointId.includes('1-0:1.8.0') && graphPointId.includes(panelType.SINK)) {
    return hooklessT('meteringDetails', 'metering_graph_consumption');
  }
  if (graphPointId.includes('1-0:2.8.0') && graphPointId.includes(panelType.SINK)) {
    return hooklessT('meteringDetails', 'metering_graph_feedin_participant');
  }
};
