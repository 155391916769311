import { billingPreflightMeasuringPointOutSchema } from './billingPreflightMeasuringPointOutSchema.ts'
import { levyStructureNestedSchemaSchema } from './levyStructureNestedSchemaSchema.ts'
import { z } from 'zod'

export const billingPreflightLevyStructureOutSchema = z.object({
  consumption: z.number(),
  end: z.string().date(),
  levy_structure: z.lazy(() => levyStructureNestedSchemaSchema),
  measuring_points: z.array(z.lazy(() => billingPreflightMeasuringPointOutSchema)),
  start: z.string().date(),
})