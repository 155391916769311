import { palette } from '@mg/ui/src/styles';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ArrowRight, AtSign } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useTranslation } from '../../../i18n';

export const DashboardNewsletterCard = () => {
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const { t, tString } = useTranslation('dashboard');

  const handleNewsletterSubscription = () => {
    const url =
      '\n' +
      'https://549c87b7.sibforms.com/serve/MUIFAFNWk_iphLGBO2pOcDQ5nJqJZpzjHWk7ie4IPYFHJThO6eHWRDYHSX6_OkKCG-4uMT7wgUOM8G677M0hHu-eRcKN-gPo9YGCyGda7msIYtm2WdqWB9mzULOmhW05gLbd_8waQvgtkpaOlAwVTR_kg3kTRwt4yPzppsLMkg2IitF4cW_O047b3ltfz6AkO7zDJ7x_MA1YACWf?isAjax=1';
    const formData = new FormData();
    formData.append('EMAIL', email);
    formData.append('OPT_IN', '1');
    formData.append('locale', 'de');

    axios
      .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        toast.success(t('newsletter_card_success_toast'));
        window.open(res.data.redirect);
      })
      .catch(({ response }) => {
        toast.error(Object.values(response.data.errors)[0] as string);
      });
  };
  return (
    <>
      <Card
        sx={{
          height: '400px',
          backgroundColor: palette.primary.dark,
          position: 'relative',
          backgroundImage: 'url(/static/img/windpark.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right -100px',
        }}
      >
        <Stack
          sx={{ color: palette.primary.contrastText }}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={15}
          spacing={2}
          height={'100%'}
        >
          <Typography variant={'h3'}>{t('newsletter_card_title')}</Typography>
          <Typography variant={'small'} textAlign={'center'}>
            {t('newsletter_card_subtitle')}
          </Typography>
          <Stack direction={'row'} spacing={1} width={'100%'} justifyContent={'center'}>
            <TextField
              type={'email'}
              sx={{ minWidth: '400px' }}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={tString('newsletter_card_input_placeholder')}
              value={email}
              error={email.length > 0 && !validator.isEmail(email)}
              InputProps={{
                sx: { backgroundColor: palette.primary.contrastText },
                startAdornment: (
                  <InputAdornment position="start">
                    <AtSign size={20} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant={'contained'}
              color={'success'}
              endIcon={<ArrowRight />}
              size={'large'}
              onClick={handleNewsletterSubscription}
              disabled={!checked || (email.length > 0 && !validator.isEmail(email))}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: palette.secondary.light,
                  color: palette.gray.main,
                },
              }}
            >
              {t('newsletter_card_button')}
            </Button>
          </Stack>
          <FormControlLabel
            sx={{ color: palette.primary.contrastText }}
            componentsProps={{
              typography: {
                variant: 'extraSmall',
              },
            }}
            label={t('newsletter_card_checkbox_text')}
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                sx={{
                  color: '#FFF',
                  '&.Mui-checked': {
                    color: '#FFF',
                  },
                }}
              />
            }
          />
        </Stack>
      </Card>
    </>
  );
};
