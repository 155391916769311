import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { DismissNotificationMutationResponse, DismissNotificationPathParams, DismissNotification422 } from '../types/DismissNotification.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const dismissNotificationMutationKey = () => [{ url: '/api-internal/notifications/{id}/dismiss' }] as const

export type DismissNotificationMutationKey = ReturnType<typeof dismissNotificationMutationKey>

/**
 * @summary Dismiss Notification
 * {@link /api-internal/notifications/:id/dismiss}
 */
async function dismissNotification(id: DismissNotificationPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<DismissNotificationMutationResponse, DismissNotification422, unknown>({
    method: 'POST',
    url: `/api-internal/notifications/${id}/dismiss`,
    ...config,
  })
  return res.data
}

/**
 * @summary Dismiss Notification
 * {@link /api-internal/notifications/:id/dismiss}
 */
export function useDismissNotification(
  options: {
    mutation?: UseMutationOptions<DismissNotificationMutationResponse, DismissNotification422, { id: DismissNotificationPathParams['id'] }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? dismissNotificationMutationKey()

  return useMutation<DismissNotificationMutationResponse, DismissNotification422, { id: DismissNotificationPathParams['id'] }>({
    mutationFn: async ({ id }) => {
      return dismissNotification(id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}