import {
  MGRichTextEditor,
  MGRichTextEditorProps,
} from '@mg/ui/src/components/MGRichTextEditor/MGRichTextEditor';
import { Box, Stack, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from '../../../i18n';
import { DatePicker } from '../../shared/HookFormIntegration/DatePicker';
import { TextField } from '../../shared/HookFormIntegration/TextField';

export type CreateLetterFormValues = {
  subject: string;
  issue_date: null | Dayjs;
};

type CreateLetterFormProps = {
  formMethods: UseFormReturn<CreateLetterFormValues>;
  initialBodyAst: MGRichTextEditorProps['initialValue'];
  onBodyAstChange: MGRichTextEditorProps['onAstChange'];
};

export const CreateLetterForm = ({ formMethods, initialBodyAst, onBodyAstChange }: CreateLetterFormProps) => {
  const { t } = useTranslation('createLetter');
  const { control } = formMethods;

  return (
    <Box component={'form'} mt={2}>
      <Stack direction={'row'} gap={2}>
        <TextField name="subject" control={control} label={t('label_subject')} fullWidth />

        <DatePicker name="issue_date" control={control} />
      </Stack>

      <Box mt={2}>
        <Typography>{t('label_letter_body')}</Typography>
        <Box mt={1}>
          {initialBodyAst && (
            <MGRichTextEditor
              label={t('label_secondary_letter_body')}
              initialValue={initialBodyAst}
              onAstChange={(value) => onBodyAstChange(value)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
