import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countPlansQueryParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const countPlans200Schema = z.number().int()

/**
 * @description Unprocessable Entity
 */
export const countPlans422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countPlansQueryResponseSchema = z.lazy(() => countPlans200Schema)