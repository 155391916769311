import { useGetCurrentUser, useGetProject } from '@mg/api-wrappers/src/api-internal';
import { Alert, Paper, Skeleton, Typography } from '@mui/material';
import HubspotForm from 'react-hubspot-form';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useTranslation } from '../../../i18n';

export const SupportPageFormContent = () => {
  const { t } = useTranslation('supportPage');
  const projectId = useCurrentProject();
  const projectQuery = useGetProject(projectId);
  const userQuery = useGetCurrentUser();

  const addDataToHiddenFields = ($form) => {
    // fetch UserInfo and add email
    $form
      .find('input[name="email"]')
      .val(userQuery?.data?.email || userQuery?.data?.username)
      .change();

    const projectName = projectQuery.data?.name || projectQuery.data?.location;
    $form
      .find('input[name="TICKET.projekt_name"]')
      .val(projectName || '')
      .change();
    $form.find('input[name="TICKET.projekt_id"]').val(projectId).change();
  };

  if (projectQuery.isLoading || userQuery.isLoading) return <Skeleton variant="rounded" height={552} />;

  if (projectQuery.isError || userQuery.isError)
    return (
      <Alert severity="error" sx={{ height: '552px', justifyContent: 'center', alignItems: 'center' }}>
        {t('support_error')}
      </Alert>
    );

  return (
    <>
      <Paper sx={{ padding: '50px' }}>
        <Typography variant={'h3'} sx={{ fontWeight: '900', marginBottom: '30px' }}>
          {t('support_request')}
        </Typography>
        <HubspotForm
          portalId={import.meta.env.VITE_HUBSPOT_PORTAL_ID}
          formId={import.meta.env.VITE_HUBSPOT_SUPPORT_FORM_ID}
          region="eu1"
          onBeforeFormSubmit={addDataToHiddenFields}
        />
      </Paper>
    </>
  );
};
