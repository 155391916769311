import { MuiBaseModalProps } from '../../modals/MuiBaseModal';
import {
  MultiStepPanelProvider,
  MultiStepPanelProviderProps,
} from '../MultiStepPanel/MultiStepPanel.Provider';
import { MultiStepPanelView } from '../MultiStepPanel/MultiStepPanel.View';
import { MultiStepModalBaseModal } from './MultiStepModal.BaseModal';

type MultiStepModalProps = {
  steps: MultiStepPanelProviderProps['steps'];
  layout: MultiStepPanelView['layout'];
} & MuiBaseModalProps;

export const MultiStepModal = ({ steps, layout, ...muiBaseModalProps }: MultiStepModalProps) => {
  return (
    <MultiStepPanelProvider steps={steps}>
      <MultiStepModalBaseModal {...muiBaseModalProps}>
        <MultiStepPanelView layout={layout} />
      </MultiStepModalBaseModal>
    </MultiStepPanelProvider>
  );
};
