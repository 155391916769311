import { useGetProject, useListMeasuringPoints } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles';
import { Box, Stack, Typography } from '@mui/material';
import { ChartColumn } from 'lucide-react';
import { useState } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { GraphTableToggleButton } from '../../shared/GraphTableToggleButton';
import { MetergridCard } from '../../shared/MetergridCard';
import { PageLayout } from '../../shared/PageLayout';
import { MeteringHistoryActionMenu } from './MeteringHistory.ActionMenu';
import { MeteringHistoryGraph } from './MeteringHistory.Graph';
import { MeteringHistoryTable } from './MeteringHistory.Table';

export const MeteringHistory = () => {
  const projectFilter = useSelectedProjectsFilter();
  const projectQuery = useGetProject(projectFilter.project_id);

  const measuringPointQuery = useListMeasuringPoints({
    ...projectFilter,
    include_data: true,
    include_measuring_point_panel: true,
    include_customer: true,
  });

  const [selectedView, setSelectedView] = useState<'chart' | 'table'>('chart');

  const { t } = useTranslation('meteringHistory');
  return (
    <PageLayout title={t('title')} rightSection={<MeteringHistoryActionMenu />}>
      <MetergridCard
        isLoading={measuringPointQuery.isLoading}
        isError={measuringPointQuery.isError}
        sx={{
          minHeight: selectedView === 'chart' ? '740px' : 'none',
        }}
      >
        <Stack spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <ChartColumn color={palette.primary.main} />
            {!projectQuery.data?.is_read_remotely && (
              <GraphTableToggleButton selectedView={selectedView} onChange={setSelectedView} />
            )}
          </Stack>
          <Typography variant={'regular'} fontWeight={400} color={'text.secondary'}>
            {t('subtitle')}
          </Typography>

          {selectedView === 'chart' && <MeteringHistoryGraph measuringPointList={measuringPointQuery.data} />}
          {selectedView === 'table' && (
            <Box height={'calc(100vh - 320px)'}>
              <MeteringHistoryTable measuringPointList={measuringPointQuery.data} />
            </Box>
          )}
        </Stack>
      </MetergridCard>
    </PageLayout>
  );
};
