import { sepaMandateStateSchema } from './sepaMandateStateSchema.ts'
import { z } from 'zod'

export const getSepaMandatePdfsAsZipQueryParamsSchema = z.object({
  document_type: z.literal('sepa_mandate').default('sepa_mandate'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  state: z.union([z.lazy(() => sepaMandateStateSchema), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getSepaMandatePdfsAsZip200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getSepaMandatePdfsAsZip400Schema = z.string()

export const getSepaMandatePdfsAsZipQueryResponseSchema = z.lazy(() => getSepaMandatePdfsAsZip200Schema)