import { useDismissNotification } from '@mg/api-wrappers/src/api-internal';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { X } from 'lucide-react';
import { ReactElement } from 'react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../../../helpers/invalidateAllQueries';

type Props = {
  title: string;
  showDivider?: boolean;
  icon: ReactElement;
  notificationId?: number;
};

export const DashboardNotificationsItem = ({
  notificationId = 0,
  title,
  showDivider = false,
  icon,
}: Props) => {
  const dismissMutation = useDismissNotification();
  const handleDismiss = () => {
    dismissMutation
      .mutateAsync({ id: notificationId })
      .then(() => {
        invalidateAllQueries();
      })
      .catch(() => {
        toast.error('Ein unerwarter Fehler ist aufgetreten');
      });
  };

  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          height: '75px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: '20px',
        }}
      >
        <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={3}>
          {icon}
          <Typography
            variant={'small'}
            display={'block'}
            whiteSpace={'pre-line'}
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
        </Stack>
        {!!notificationId && (
          <IconButton onClick={() => handleDismiss()}>
            <X />
          </IconButton>
        )}
      </Stack>
      {showDivider && <Divider />}
    </>
  );
};
