import { Skeleton, Stack } from '@mui/material';

export const DashboardNotificationsLoadingItem = () => {
  return (
    <>
      <Stack
        height={'75px'}
        paddingX={'20px'}
        direction={'row'}
        display={'flex'}
        alignItems={'center'}
        spacing={3}
        width={'100%'}
      >
        <Skeleton variant="rounded" width={40} height={40} />
        <Skeleton variant="text" animation={'wave'} sx={{ width: 'calc(100% - 300px)' }} />
      </Stack>
    </>
  );
};
