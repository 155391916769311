import { PropsWithChildren, useState } from 'react';
import { MultiStepPanelContext } from './MultiStepPanel.Context';
import { Step } from './MultiStepPanel.Types';

export type MultiStepPanelProviderProps = {
  steps: Step[];
} & PropsWithChildren;

export const MultiStepPanelProvider = ({ steps, children }: MultiStepPanelProviderProps) => {
  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const [context, setContext] = useState<Record<string, unknown>>({});

  const next = (context) => {
    if (context) setContext(context);

    const currentIndex = steps.findIndex((step) => step.key === currentStep.key);
    if (currentIndex + 1 < steps.length) {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const previous = (context) => {
    if (context) setContext(context);

    const currentIndex = steps.findIndex((step) => step.key === currentStep.key);
    if (currentIndex - 1 >= 0) {
      setCurrentStep(steps[currentIndex - 1]);
    }
  };

  const goto = (key: string, context) => {
    if (context) setContext(context);

    const step = steps.find((step) => step.key === key);
    if (step) {
      setCurrentStep(step);
    }
  };

  return (
    <MultiStepPanelContext.Provider value={{ currentStep, context, setContext, next, previous, goto }}>
      {children}
    </MultiStepPanelContext.Provider>
  );
};
