import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListResidualPowerPlansQueryResponse, ListResidualPowerPlansQueryParams, ListResidualPowerPlans422 } from '../types/ListResidualPowerPlans.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listResidualPowerPlansInfiniteQueryKey = (params: ListResidualPowerPlansQueryParams) =>
  [{ url: '/api-internal/residual_power_plans/' }, ...(params ? [params] : [])] as const

export type ListResidualPowerPlansInfiniteQueryKey = ReturnType<typeof listResidualPowerPlansInfiniteQueryKey>

/**
 * @summary List Residual Power Plans
 * {@link /api-internal/residual_power_plans/}
 */
async function listResidualPowerPlans(params: ListResidualPowerPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListResidualPowerPlansQueryResponse, ListResidualPowerPlans422, unknown>({
    method: 'GET',
    url: `/api-internal/residual_power_plans/`,
    params,
    ...config,
  })
  return res.data
}

export function listResidualPowerPlansInfiniteQueryOptions(params: ListResidualPowerPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listResidualPowerPlansInfiniteQueryKey(params)
  return infiniteQueryOptions<ListResidualPowerPlansQueryResponse, ListResidualPowerPlans422, ListResidualPowerPlansQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListResidualPowerPlansQueryParams['page']
      }
      return listResidualPowerPlans(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Residual Power Plans
 * {@link /api-internal/residual_power_plans/}
 */
export function useListResidualPowerPlansInfinite<
  TData = InfiniteData<ListResidualPowerPlansQueryResponse>,
  TQueryData = ListResidualPowerPlansQueryResponse,
  TQueryKey extends QueryKey = ListResidualPowerPlansInfiniteQueryKey,
>(
  params: ListResidualPowerPlansQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListResidualPowerPlansQueryResponse, ListResidualPowerPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listResidualPowerPlansInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listResidualPowerPlansInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListResidualPowerPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}