import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountSepaMandatesQueryResponse, CountSepaMandatesQueryParams, CountSepaMandates422 } from '../types/CountSepaMandates.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countSepaMandatesQueryKey = (params: CountSepaMandatesQueryParams) =>
  [{ url: '/api-internal/sepa_mandates/count' }, ...(params ? [params] : [])] as const

export type CountSepaMandatesQueryKey = ReturnType<typeof countSepaMandatesQueryKey>

/**
 * @summary Count Sepa Mandates
 * {@link /api-internal/sepa_mandates/count}
 */
async function countSepaMandates(params: CountSepaMandatesQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountSepaMandatesQueryResponse, CountSepaMandates422, unknown>({
    method: 'GET',
    url: `/api-internal/sepa_mandates/count`,
    params,
    ...config,
  })
  return res.data
}

export function countSepaMandatesQueryOptions(params: CountSepaMandatesQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countSepaMandatesQueryKey(params)
  return queryOptions<CountSepaMandatesQueryResponse, CountSepaMandates422, CountSepaMandatesQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countSepaMandates(params, config)
    },
  })
}

/**
 * @summary Count Sepa Mandates
 * {@link /api-internal/sepa_mandates/count}
 */
export function useCountSepaMandates<
  TData = CountSepaMandatesQueryResponse,
  TQueryData = CountSepaMandatesQueryResponse,
  TQueryKey extends QueryKey = CountSepaMandatesQueryKey,
>(
  params: CountSepaMandatesQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountSepaMandatesQueryResponse, CountSepaMandates422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countSepaMandatesQueryKey(params)

  const query = useQuery({
    ...(countSepaMandatesQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountSepaMandates422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}