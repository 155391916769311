import { InputLabel } from '@mui/material';
import { PropsWithChildren } from 'react';

export type FieldLabelProps = PropsWithChildren<{ id?: string }>;

export const FieldLabel = ({ id, children }: FieldLabelProps) => {
  if (typeof children === 'string') return <InputLabel id={id + '-label'}>{children}</InputLabel>;

  return <>{children}</>;
};
