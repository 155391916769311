import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';
import { NewPasswordForm } from './NewPassword.Form.tsx';

export const NewPassword = () => {
  const { t } = useTranslation('login');
  return (
    <>
      <Stack maxWidth={'900px'} justifyContent={'center'} spacing={5}>
        <Stack spacing={3}>
          <Typography variant={'h1'} fontWeight={'500'}>
            {t('new_password')}
          </Typography>
          <Typography variant={'regular'} fontWeight={'300'}>
            {t('new_password_subtitle')}
          </Typography>
        </Stack>
        <NewPasswordForm />
      </Stack>
    </>
  );
};
