import { MGBadge } from '@mg/ui/src/components/MGBadge/MGBadge';
import { palette } from '@mg/ui/src/styles';
import { Tabs as MuiTabs, Stack, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type MGTabProps = {
  value: string;
  label: string;
  selected?: boolean;
  count?: number;
  showError?: boolean;
};

type Props = {
  tabs: MGTabProps[];
  onChange: (value: string) => void;
};
export const MetergridTabs = ({ tabs, onChange }: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (_, value: string) => {
    searchParams.set('tab', value);
    setSearchParams(searchParams);
    setSelectedTab(value);
    onChange(value);
  };

  // Allow manual tab selection by setting the selected property to true
  const tabManuallySelected = tabs.find((tab) => tab.selected);
  const selectedTabWithFallback =
    tabManuallySelected?.selected === true ? tabManuallySelected.value : selectedTab;

  /* Set tab value as search param for redirects back to the selected tab */
  useEffect(() => {
    const value = searchParams.get('tab');
    if (!value) {
      searchParams.set('tab', selectedTabWithFallback);
      setSearchParams(searchParams);
    } else {
      handleTabChange(null, value);
    }
  }, []);

  return (
    <MuiTabs
      value={selectedTabWithFallback}
      onChange={handleTabChange}
      sx={{
        alignItems: 'center',
        minHeight: 0,

        '& .MuiTabs-flexContainer': {
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {tabs.map((tab, tabIndex) => (
        <Tab
          key={tabIndex}
          sx={{
            flexDirection: 'row',
            '.MuiTab-iconWrapper': { marginBottom: '0px !important' },
            transition: 'background-color 0.15s ease-in',
            // Overrides
            minHeight: 0,

            // Layout
            display: 'flex',
            padding: '6px 12px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            textTransform: 'none',
            paddingLeft: '12px',

            // Style
            borderRadius: '16px',
            border: '1px solid transparent',
            background: 'transparent',

            color: '#50606B',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',

            transitionProperty: 'background, border',
            transitionDuration: '.1s',
            transitionTimingFunction: 'ease',

            // color: "rgba(255, 255, 255, 0.7)",
            '&.Mui-selected': {
              color: '#50606B',
              backgroundColor: 'white !important',
              boxShadow: '0px 0.5px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
              border: '1px solid rgba(5, 28, 44, 0.04)',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'white',
            },
          }}
          label={
            <Stack direction="row" alignItems="center" gap={0.8}>
              <Typography variant={'extraSmall'}>{tab.label}</Typography>
              <MGBadge
                sx={{
                  backgroundColor: tab.showError
                    ? palette.error.main
                    : selectedTabWithFallback === tab.value
                      ? palette.primary.main
                      : '#EBEAE5',
                  color: tab.showError
                    ? palette.error.light
                    : selectedTabWithFallback === tab.value
                      ? palette.primary.contrastText
                      : '#50606B',
                  width: tab.showError ? '18px' : 'auto',
                  height: tab.showError ? '18px' : 'auto',
                }}
              >
                {tab.showError ? '!' : tab.count}
              </MGBadge>
            </Stack>
          }
          value={tab.value}
        />
      ))}
    </MuiTabs>
  );
};
