import { palette } from '@mg/ui/src/styles';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  id?: string;
  icon?: ReactNode;
  text: ReactNode;
  onClick?: () => void;
  href?: string;
  selected?: boolean;
};
export const SidebarItemLink = ({ id = '', icon, text, onClick, selected, href }: Props) => {
  return (
    <Box paddingY={'10px'}>
      <Box
        id={id}
        display={'flex'}
        alignItems={'center'}
        borderRadius={'10px'}
        paddingX={'15px'}
        component={href ? Link : Box}
        to={href as string}
        onClick={onClick}
        sx={{
          backgroundColor: 'rgb(246, 245, 243)',
          color: selected ? palette.primary.main : palette.gray.dark,
          boxShadow: 'none',
          userSelect: 'none',
          border: 'none',
          cursor: 'pointer',
          ':hover': { color: palette.primary.main, transition: 'color 0.5s' },
        }}
      >
        {icon}
        <Typography variant={'small'} sx={{ color: 'currentcolor' }} marginLeft={'25px'}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
