import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetCustomerDataExportQueryResponse, GetCustomerDataExport400, GetCustomerDataExport422 } from '../types/GetCustomerDataExport.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCustomerDataExportQueryKey = () => [{ url: '/api-internal/customers/export' }] as const

export type GetCustomerDataExportQueryKey = ReturnType<typeof getCustomerDataExportQueryKey>

/**
 * @summary Get Customer Data Export
 * {@link /api-internal/customers/export}
 */
async function getCustomerDataExport(config: Partial<RequestConfig> = {}) {
  const res = await client<GetCustomerDataExportQueryResponse, GetCustomerDataExport400 | GetCustomerDataExport422, unknown>({
    method: 'GET',
    url: `/api-internal/customers/export`,
    ...config,
  })
  return res.data
}

export function getCustomerDataExportQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = getCustomerDataExportQueryKey()
  return queryOptions<
    GetCustomerDataExportQueryResponse,
    GetCustomerDataExport400 | GetCustomerDataExport422,
    GetCustomerDataExportQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCustomerDataExport(config)
    },
  })
}

/**
 * @summary Get Customer Data Export
 * {@link /api-internal/customers/export}
 */
export function useGetCustomerDataExport<
  TData = GetCustomerDataExportQueryResponse,
  TQueryData = GetCustomerDataExportQueryResponse,
  TQueryKey extends QueryKey = GetCustomerDataExportQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<GetCustomerDataExportQueryResponse, GetCustomerDataExport400 | GetCustomerDataExport422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCustomerDataExportQueryKey()

  const query = useQuery({
    ...(getCustomerDataExportQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCustomerDataExport400 | GetCustomerDataExport422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}