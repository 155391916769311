import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetPaymentPlanPdfQueryResponse, GetPaymentPlanPdfPathParams, GetPaymentPlanPdf400 } from '../types/GetPaymentPlanPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPaymentPlanPdfQueryKey = (id: GetPaymentPlanPdfPathParams['id']) =>
  [{ url: '/api-internal/payment_plans/:id/pdf', params: { id: id } }] as const

export type GetPaymentPlanPdfQueryKey = ReturnType<typeof getPaymentPlanPdfQueryKey>

/**
 * @summary Get Payment Plan Pdf
 * {@link /api-internal/payment_plans/:id/pdf}
 */
async function getPaymentPlanPdf(id: GetPaymentPlanPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetPaymentPlanPdfQueryResponse, GetPaymentPlanPdf400, unknown>({
    method: 'GET',
    url: `/api-internal/payment_plans/${id}/pdf`,
    ...config,
  })
  return res.data
}

export function getPaymentPlanPdfQueryOptions(id: GetPaymentPlanPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getPaymentPlanPdfQueryKey(id)
  return queryOptions<GetPaymentPlanPdfQueryResponse, GetPaymentPlanPdf400, GetPaymentPlanPdfQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPaymentPlanPdf(id, config)
    },
  })
}

/**
 * @summary Get Payment Plan Pdf
 * {@link /api-internal/payment_plans/:id/pdf}
 */
export function useGetPaymentPlanPdf<
  TData = GetPaymentPlanPdfQueryResponse,
  TQueryData = GetPaymentPlanPdfQueryResponse,
  TQueryKey extends QueryKey = GetPaymentPlanPdfQueryKey,
>(
  id: GetPaymentPlanPdfPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetPaymentPlanPdfQueryResponse, GetPaymentPlanPdf400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPaymentPlanPdfQueryKey(id)

  const query = useQuery({
    ...(getPaymentPlanPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPaymentPlanPdf400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}