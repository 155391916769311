import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { transactionStatisticsSchemaSchema } from './transactionStatisticsSchemaSchema.ts'
import { z } from 'zod'

export const getTransactionStatisticsQueryParamsSchema = z
  .object({
    project_id: z.union([z.number().int(), z.null()]).optional(),
    customer_id: z.union([z.number().int(), z.null()]).optional(),
  })
  .optional()

/**
 * @description OK
 */
export const getTransactionStatistics200Schema = z.lazy(() => transactionStatisticsSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getTransactionStatistics422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getTransactionStatisticsQueryResponseSchema = z.lazy(() => getTransactionStatistics200Schema)