import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountResidualPowerPlansQueryResponse, CountResidualPowerPlansQueryParams, CountResidualPowerPlans422 } from '../types/CountResidualPowerPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countResidualPowerPlansQueryKey = (params: CountResidualPowerPlansQueryParams) =>
  [{ url: '/api-internal/residual_power_plans/count' }, ...(params ? [params] : [])] as const

export type CountResidualPowerPlansQueryKey = ReturnType<typeof countResidualPowerPlansQueryKey>

/**
 * @summary Count Residual Power Plans
 * {@link /api-internal/residual_power_plans/count}
 */
async function countResidualPowerPlans(params: CountResidualPowerPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountResidualPowerPlansQueryResponse, CountResidualPowerPlans422, unknown>({
    method: 'GET',
    url: `/api-internal/residual_power_plans/count`,
    params,
    ...config,
  })
  return res.data
}

export function countResidualPowerPlansQueryOptions(params: CountResidualPowerPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countResidualPowerPlansQueryKey(params)
  return queryOptions<CountResidualPowerPlansQueryResponse, CountResidualPowerPlans422, CountResidualPowerPlansQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countResidualPowerPlans(params, config)
    },
  })
}

/**
 * @summary Count Residual Power Plans
 * {@link /api-internal/residual_power_plans/count}
 */
export function useCountResidualPowerPlans<
  TData = CountResidualPowerPlansQueryResponse,
  TQueryData = CountResidualPowerPlansQueryResponse,
  TQueryKey extends QueryKey = CountResidualPowerPlansQueryKey,
>(
  params: CountResidualPowerPlansQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountResidualPowerPlansQueryResponse, CountResidualPowerPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countResidualPowerPlansQueryKey(params)

  const query = useQuery({
    ...(countResidualPowerPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountResidualPowerPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}