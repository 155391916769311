import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { RequestPasswordResetMutationRequest, RequestPasswordResetMutationResponse, RequestPasswordReset422 } from '../types/RequestPasswordReset.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const requestPasswordResetMutationKey = () => [{ url: '/api-internal/auth/request_password_reset' }] as const

export type RequestPasswordResetMutationKey = ReturnType<typeof requestPasswordResetMutationKey>

/**
 * @summary Request Password Reset
 * {@link /api-internal/auth/request_password_reset}
 */
async function requestPasswordReset(data: RequestPasswordResetMutationRequest, config: Partial<RequestConfig<RequestPasswordResetMutationRequest>> = {}) {
  const res = await client<RequestPasswordResetMutationResponse, RequestPasswordReset422, RequestPasswordResetMutationRequest>({
    method: 'POST',
    url: `/api-internal/auth/request_password_reset`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Request Password Reset
 * {@link /api-internal/auth/request_password_reset}
 */
export function useRequestPasswordReset(
  options: {
    mutation?: UseMutationOptions<RequestPasswordResetMutationResponse, RequestPasswordReset422, { data: RequestPasswordResetMutationRequest }>
    client?: Partial<RequestConfig<RequestPasswordResetMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? requestPasswordResetMutationKey()

  return useMutation<RequestPasswordResetMutationResponse, RequestPasswordReset422, { data: RequestPasswordResetMutationRequest }>({
    mutationFn: async ({ data }) => {
      return requestPasswordReset(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}