import {
  deliverableDocumentType,
  PaymentPlanSchema,
  useGetPaymentPlanPdfsAsZip,
  useListPaymentPlansInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { presentDate } from '../../../presenters/date';
import { presentEuro } from '../../../presenters/number';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { DocumentsPaymentPlansTableFilterForm } from './Documents.PaymentPlansTable.FilterForm';
import { DocumentsPaymentPlansTableRowActionMenu } from './Documents.PaymentPlansTable.RowActionMenu';
import { DocumentsRowAction } from './Documents.RowAction';
import { DocumentsStatusAndQuickaction } from './Documents.StatusAndQuickaction';
import { useSetActionBarButtons } from './useSetActionBarButtons';

type Props = {
  customerId?: number | null;
};

export const DocumentsPaymentPlansTable = ({ customerId }: Props) => {
  const { tString } = useTranslation('documents');
  const currentProjectId = useSelectedProjectsFilter();

  const getPaymentPlanPdfsAsZipQuery = useGetPaymentPlanPdfsAsZip(
    { ...currentProjectId, customer_id: customerId },
    { query: { enabled: false } },
  );

  const { dataGridApiRef } = useGridApi();
  const [filter] = useAtom(filterValueAtom);

  useSetActionBarButtons({
    getAsZipQuery: getPaymentPlanPdfsAsZipQuery,
    documentType: deliverableDocumentType.payment_plan,
  });

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        // @ts-expect-error TS2322
        return <MGCustomerWithAvatar customer={params.row.customer} />;
      },
    },
    {
      field: 'created_at',
      headerName: tString('created_at'),
      flex: 0.2,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'start_date',
      headerName: tString('beginning_date'),
      flex: 0.2,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'amount',
      headerName: tString('total'),
      flex: 0.2,
      valueGetter: (value) => presentEuro(value),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 0,
      align: 'right',
      renderCell: (params) =>
        dataGridApiRef.current?.apiLoaded && (
          <DocumentsRowAction
            document={params.row}
            documentType={deliverableDocumentType.payment_plan}
            queryKey={dataGridApiRef.current.getQueryKey()}
          >
            <DocumentsStatusAndQuickaction />
            <DocumentsPaymentPlansTableRowActionMenu />
          </DocumentsRowAction>
        ),
    },
  ] as GridColDef<PaymentPlanSchema>[];

  return (
    <Box sx={{ height: '100%' }}>
      <DataGridCard
        height={'calc(100% - 50px)'}
        searchPlaceholder={tString('search_placeholder') as string}
        columns={columns}
        selectable
        reactQueryHook={useListPaymentPlansInfinite}
        reactQueryHookParams={{
          ...currentProjectId,
          ...filter,
          document_type: deliverableDocumentType.payment_plan,
          include_customer: true,
          include_pdf: true,
          customer_id: customerId,
        }}
        reactQueryHookQueryOptions={{
          meta: {
            refetchForActiveTasks: true,
          },
        }}
        datagridProps={{
          columnVisibilityModel: { id: false },
        }}
        rowHeight={50}
        filterModalContent={<DocumentsPaymentPlansTableFilterForm />}
      />
    </Box>
  );
};
