import { ProjectSchema, useListProjects } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { palette } from '@mg/ui/src/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from '../../../../i18n';
import { Checkbox } from '../../../shared/HookFormIntegration/Checkbox';
import { DateRangePicker } from '../../../shared/HookFormIntegration/DateRangePicker';
import { Switch } from '../../../shared/HookFormIntegration/Switch';
import { useMultiStepPanelContext } from '../../../shared/MultiStepPanel/MultiStepPanel.Context';
import { CheckboxFieldsetContainer } from '../CheckboxFieldset';
import { ErrorIndicator } from '../ErrorIndicator';
import { FunnelContextData, useFunnelContext } from '../FunnelProvider.Context';

export type TimeFrameStepFieldValues = {
  range: [Dayjs | null, Dayjs | null];
  allProjects: boolean;
  projects: { value: boolean; label: string; id: number }[];
};

export const TimeFrameStep = () => {
  const { t, tString } = useTranslation('sepaGeneration');
  const { currentStep, next } = useMultiStepPanelContext();
  const { data: funnelData, setData: setFunnelData } = useFunnelContext();

  /** Form */
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TimeFrameStepFieldValues>({
    values: {
      range: funnelData.range,
      allProjects: funnelData.allProjects,
      projects: funnelData.projects,
    },
  });
  const allProjects = useWatch({ control, name: 'allProjects' });
  const projects = useWatch({ control, name: 'projects' });

  const { fields, replace } = useFieldArray({
    control,
    name: 'projects',
    rules: {
      validate: (value) => {
        const selectedProjects = value.filter((project) => project.value);

        if (selectedProjects.length === 0) {
          return tString('time_frame_error_too_few_projects_selected');
        }

        if (value.filter((v) => v.value).length > 50) {
          return tString('time_frame_error_too_many_projects_selected');
        }
        return true;
      },
    },
  });

  const onSubmit = () => {
    const updatedFunnelData: FunnelContextData = {
      ...funnelData,
      ...getValues(),
    };

    setFunnelData(updatedFunnelData);
    next();
  };

  const { data, isError, isLoading, isSuccess } = useListProjects({
    order_by: 'id',
    page_size: 1000,
  });

  const setMappedProjectListToFieldArray = useCallback(
    (value: boolean | ((project: ProjectSchema) => boolean) = true) => {
      if (data) {
        const replacement = data.items
          .map((project) => {
            return {
              value: typeof value === 'function' ? value(project) : value,
              label: project.name || project.location,
              id: project.id ?? -1,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));

        replace(replacement);
      }
    },
    [data, replace],
  );

  /** Initialize form values from loaded data */
  useEffect(() => {
    // Form state could not be restored from funnel data, so we need to initialize it
    if (isSuccess && funnelData.projects?.length !== data?.items.length) {
      setMappedProjectListToFieldArray();
    }
  }, [setMappedProjectListToFieldArray, getValues, data, isSuccess, funnelData.projects]);

  useEffect(() => {
    if (errors.projects) {
      setValue('allProjects', false);
    }
  });

  useEffect(() => {
    if (allProjects) {
      setMappedProjectListToFieldArray();
    }
  });

  return (
    <>
      {(isLoading || isError) && (
        <Stack height={250} alignItems={'center'} justifyContent={'center'} gap={2}>
          {isLoading && <MGLoader />}
          {isError && <ErrorIndicator>{t('time_frame_step_error')}</ErrorIndicator>}
        </Stack>
      )}

      {isSuccess && (
        <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} gap={3}>
          <DateRangePicker
            name="range"
            control={control}
            label={'Zeitraum für die SEPA XML'}
            rules={{
              validate: (fieldValue) => {
                if (fieldValue[0] === null) return tString('time_range_error_start_empty');

                const start = fieldValue[0] as Dayjs;
                const end = fieldValue[1] as Dayjs | null;

                if (end === null) return true;

                if (start.isAfter(end)) return tString('time_range_error_start_after_end');

                return true;
              },
            }}
            dateRangePickerProps={{
              localeText: { start: tString('time_range_start'), end: tString('time_range_end') },
            }}
          />

          <Box>
            <Typography color={palette.text.black} fontWeight={400}>
              {t('time_frame_step_projects')}
            </Typography>

            <Switch name={'allProjects'} control={control} label={t('switch_all_projects')} />
          </Box>

          <Stack sx={{ gap: 1 }}>
            {!allProjects && (
              <CheckboxFieldsetContainer
                title={t('checkbox_fieldset_projects')}
                itemCount={projects.filter((p) => p.value).length}
                height={'150px'}
                error={errors.projects?.root?.message}
                selectAllLabel={t('checkbox_fieldset_select_all')}
                deselectAllLabel={t('checkbox_fieldset_deselect_all')}
                onSelectAll={() => setMappedProjectListToFieldArray()}
                onDeselectAll={() => setMappedProjectListToFieldArray(false)}
              >
                <Box sx={{ mt: 1 }}>
                  {fields.map((field, index) => (
                    <Box key={field.id} pl={2}>
                      <Checkbox name={`projects.${index}.value`} control={control} label={field.label} />
                    </Box>
                  ))}
                </Box>
              </CheckboxFieldsetContainer>
            )}
          </Stack>

          <Stack direction={'row'} justifyContent={'flex-end'} mt={1}>
            <Button variant={'contained'} type="submit">
              {t(`modal_button_next_${currentStep.key}`)}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};
