import {
  deliverableDocumentType,
  PlanChangeDocumentSchema,
  useGetPlanChangeDocumentPdfsAsZip,
  useListPlanChangeDocumentsInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { presentDate } from '../../../presenters/date';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { DocumentsPlanChangeTableFilterForm } from './Documents.PlanChangeTable.FilterForm';
import { DocumentsPlanChangeTableRowActionMenu } from './Documents.PlanChangeTable.RowActionMenu';
import { DocumentsRowAction } from './Documents.RowAction';
import { DocumentsStatusAndQuickaction } from './Documents.StatusAndQuickaction';
import { useSetActionBarButtons } from './useSetActionBarButtons';

type Props = {
  customerId?: number | null;
};

export const DocumentsPlanChangeTable = ({ customerId }: Props) => {
  const { tString } = useTranslation('documents');
  const currentProjectId = useSelectedProjectsFilter();
  const getPlanChangeAsZipQuery = useGetPlanChangeDocumentPdfsAsZip(
    { ...currentProjectId, customer_id: customerId },
    { query: { enabled: false } },
  );

  const { dataGridApiRef } = useGridApi();
  const [filter] = useAtom(filterValueAtom);

  useSetActionBarButtons({
    getAsZipQuery: getPlanChangeAsZipQuery,
    documentType: deliverableDocumentType.plan_change,
  });

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      sortable: false,
      flex: 0.7,
      renderCell: (params) => {
        return <MGCustomerWithAvatar customer={params.row.customer} />;
      },
    },
    {
      field: 'valid_from',
      sortable: false,
      headerName: tString('changed_at'),
      flex: 0.4,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'issue_date',
      sortable: false,
      headerName: tString('issue_date'),
      flex: 0.4,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0,
      sortable: false,
      renderCell: (params) =>
        dataGridApiRef.current?.apiLoaded && (
          <DocumentsRowAction
            document={params.row}
            documentType={deliverableDocumentType.plan_change}
            queryKey={dataGridApiRef.current.getQueryKey()}
          >
            <DocumentsStatusAndQuickaction />
            <DocumentsPlanChangeTableRowActionMenu />
          </DocumentsRowAction>
        ),
    },
  ] as GridColDef<PlanChangeDocumentSchema>[];

  return (
    <Box sx={{ height: '100%' }}>
      <DataGridCard
        height={'calc(100% - 50px)'}
        searchPlaceholder={tString('search_placeholder') as string}
        columns={columns}
        reactQueryHook={useListPlanChangeDocumentsInfinite}
        reactQueryHookParams={{
          ...currentProjectId,
          ...filter,
          document_type: deliverableDocumentType.plan_change,
          include_customer: true,
          include_pdf: true,
          customer_id: customerId,
        }}
        reactQueryHookQueryOptions={{
          meta: {
            refetchForActiveTasks: true,
          },
        }}
        datagridProps={{
          columnVisibilityModel: { id: false },
        }}
        selectable
        rowHeight={50}
        filterModalContent={<DocumentsPlanChangeTableFilterForm />}
      />
    </Box>
  );
};
