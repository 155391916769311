import { invoiceFilterSchema } from './invoiceFilterSchema.ts'
import { letterFilterSchema } from './letterFilterSchema.ts'
import { paymentPlanFilterSchema } from './paymentPlanFilterSchema.ts'
import { planChangeDocumentFilterSchema } from './planChangeDocumentFilterSchema.ts'
import { sepaMandateFilterSchema } from './sepaMandateFilterSchema.ts'
import { z } from 'zod'

export const bulkEmailSendInSchema = z.object({
  filter: z.union([
    z.lazy(() => invoiceFilterSchema),
    z.lazy(() => paymentPlanFilterSchema),
    z.lazy(() => planChangeDocumentFilterSchema),
    z.lazy(() => sepaMandateFilterSchema),
    z.lazy(() => letterFilterSchema),
  ]),
  is_test_mail: z.boolean().default(false),
})