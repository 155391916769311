type Environment = 'localhost' | 'development' | 'testing' | 'production' | 'review' | 'unknown';

export const getEnvironment = (): Environment => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'localhost':
      return 'localhost';

    case 'dev.my.metergrid.de':
      return 'development';

    case 'test.my.metergrid.de':
      return 'testing';

    case 'my.metergrid.de':
      return 'production';

    default: {
      const shortenedReviewPath = hostname.match(/(\w+)\.metergrid\.de/g);
      if (shortenedReviewPath?.length && shortenedReviewPath[0] === 'review.metergrid.de') {
        return 'review';
      } else {
        return 'unknown';
      }
    }
  }
};
