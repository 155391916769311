import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  ExportMeasuringPointDataQueryResponse,
  ExportMeasuringPointDataQueryParams,
  ExportMeasuringPointData400,
} from '../types/ExportMeasuringPointData.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const exportMeasuringPointDataQueryKey = (params: ExportMeasuringPointDataQueryParams) =>
  [{ url: '/api-internal/measuring_point_data/export' }, ...(params ? [params] : [])] as const

export type ExportMeasuringPointDataQueryKey = ReturnType<typeof exportMeasuringPointDataQueryKey>

/**
 * @description Export customer data
 * @summary Export Measuring Point Data
 * {@link /api-internal/measuring_point_data/export}
 */
async function exportMeasuringPointData(params: ExportMeasuringPointDataQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ExportMeasuringPointDataQueryResponse, ExportMeasuringPointData400, unknown>({
    method: 'GET',
    url: `/api-internal/measuring_point_data/export`,
    params,
    ...config,
  })
  return res.data
}

export function exportMeasuringPointDataQueryOptions(params: ExportMeasuringPointDataQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = exportMeasuringPointDataQueryKey(params)
  return queryOptions<ExportMeasuringPointDataQueryResponse, ExportMeasuringPointData400, ExportMeasuringPointDataQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return exportMeasuringPointData(params, config)
    },
  })
}

/**
 * @description Export customer data
 * @summary Export Measuring Point Data
 * {@link /api-internal/measuring_point_data/export}
 */
export function useExportMeasuringPointData<
  TData = ExportMeasuringPointDataQueryResponse,
  TQueryData = ExportMeasuringPointDataQueryResponse,
  TQueryKey extends QueryKey = ExportMeasuringPointDataQueryKey,
>(
  params: ExportMeasuringPointDataQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ExportMeasuringPointDataQueryResponse, ExportMeasuringPointData400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? exportMeasuringPointDataQueryKey(params)

  const query = useQuery({
    ...(exportMeasuringPointDataQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ExportMeasuringPointData400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}