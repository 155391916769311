import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { SidebarItem } from './Sidebar.Item';
import { SidebarItemButton } from './Sidebar.Item.Button';
import { SidebarItemLink } from './Sidebar.Item.Link';

export const SidebarItemCollapsable = (item: SidebarItem) => {
  const { t } = useTranslation('sidebar');
  const [itemCollapsed, setItemCollapsed] = useState(false);
  const navigate = useNavigate();

  const currentLocation = location.pathname.split('/')[1];

  const someSubItemSelected = item.children?.some((route) =>
    route.selected !== undefined ? route.selected : route.path?.replace(/^\//, '') === currentLocation,
  );

  return (
    <>
      <SidebarItemButton
        i18nKey={item.i18nKey}
        icon={item.icon}
        onClick={() => setItemCollapsed(!itemCollapsed)}
        endIcon={itemCollapsed ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        selected={someSubItemSelected || false}
      />
      <Box sx={{ paddingLeft: '25px' }}>
        <AnimatePresence>
          {itemCollapsed &&
            item.children!.map((route) => {
              const isSelected =
                route.selected !== undefined
                  ? route.selected
                  : route.path?.replace(/^\//, '') === currentLocation;

              return (
                <motion.div
                  key={route.path}
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  style={{ overflow: 'hidden', borderLeft: '1px solid lightgray' }}
                >
                  <SidebarItemLink
                    text={t(route.i18nKey)}
                    onClick={() => navigate(route.path as string)}
                    selected={isSelected}
                  />
                </motion.div>
              );
            })}
        </AnimatePresence>
      </Box>
    </>
  );
};
