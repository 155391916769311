import { MeasuringPointPanelSchema, MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { MGModal, MGModalBody, MGModalFooter, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { MGSelectAllButton } from '@mg/ui/src/components/MGSelectAllButton/MGSelectAllButton';
import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { SlidersHorizontal } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../i18n';

type Props = {
  selectedMeasuringPointIds: number[];
  measuringPoints: MeasuringPointSchema[] | undefined;
  onSubmit: (selectedIds: number[]) => void;
};
export const MeteringHistoryFilterModal = ({
  selectedMeasuringPointIds,
  measuringPoints,
  onSubmit,
}: Props) => {
  const { t, tString } = useTranslation('meteringHistory');

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState(selectedMeasuringPointIds);

  useEffect(() => {
    setSelection(selectedMeasuringPointIds);
  }, [selectedMeasuringPointIds]);

  const columns: GridColDef<MeasuringPointSchema>[] = [
    {
      field: 'measuring_point_panel',
      headerName: tString('measuring_point_panel'),
      flex: 1,
      valueGetter: (value: MeasuringPointPanelSchema) => value.name,
    },
    {
      field: 'customer',
      headerName: '',
      sortable: false,
      flex: 1.6,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Box display={'flex'} alignItems={'center'} height={'100%'}>
              <Typography variant={'small'}>-</Typography>
            </Box>
          );
        }
        return <MGCustomerWithAvatar customer={params.value} />;
      },
    },
    {
      field: 'type',
      headerName: tString('type'),
      flex: 0.8,
      valueGetter: (value) => t(value),
    },
    {
      field: 'serial',
      sortable: false,
      headerName: tString('measuring_point_serial'),
      flex: 1,
    },
  ];

  return (
    <>
      <Button onClick={() => setOpen(!open)} endIcon={<SlidersHorizontal size={18} />}>
        {t('modal_button')}
      </Button>

      <MGModal width={'1100px'} open={open} handleToggle={() => setOpen(!open)}>
        <MGModalTitle>{t('modal_title')}</MGModalTitle>
        <MGModalBody>
          <Stack spacing={3}>
            <Typography variant={'small'}>{t('modal_subtitle')}</Typography>
            <Stack spacing={2}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1}>
                  <Typography>{t('modal_table_title')}</Typography>
                  <Chip size={'small'} color={'primary'} label={selection.length} />
                </Stack>
                <MGSelectAllButton
                  onSelectAll={() => setSelection(measuringPoints?.map((point) => point.id) as number[])}
                  onUnselectAll={() => setSelection([])}
                  selectAllDisabled={selection.length === measuringPoints?.length}
                  unselectAllDisabled={selection.length === 0}
                />
              </Stack>

              <Card>
                <Box sx={{ height: '300px' }}>
                  <DataGridPro
                    columns={columns}
                    rows={measuringPoints}
                    checkboxSelection
                    isRowSelectable={() => true}
                    columnHeaderHeight={0}
                    getRowSpacing={() => ({ top: 5, bottom: 5 })}
                    onRowSelectionModelChange={(selectedRows) => {
                      setSelection(selectedRows as number[]);
                    }}
                    rowSelectionModel={selection}
                  />
                </Box>
              </Card>
            </Stack>
          </Stack>
        </MGModalBody>

        <MGModalFooter>
          <Button
            variant={'contained'}
            onClick={() => {
              onSubmit(selection);
              setOpen(false);
            }}
          >
            {t('modal_apply_button')}
          </Button>
        </MGModalFooter>
      </MGModal>
    </>
  );
};
