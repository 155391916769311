import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { planSchemaSchema } from './planSchemaSchema.ts'
import { z } from 'zod'

export const getPlanPathParamsSchema = z.object({
  id: z.number().int(),
})

export const getPlanQueryParamsSchema = z
  .object({
    include_active_subscriptions: z.boolean().default(false),
    include_expired_subscriptions: z.boolean().default(false),
    include_planned_subscriptions: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const getPlan200Schema = z.lazy(() => planSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getPlan422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getPlanQueryResponseSchema = z.lazy(() => getPlan200Schema)