import { Stack } from '@mui/material';
import { useTranslation } from '../../../i18n';
import { PageLayout } from '../../shared/PageLayout';
import { DashboardHelpSection } from './Dashboard.HelpSection';
import { DashboardMetergridPlusCard } from './Dashboard.MetergridPlusCard';
import { DashboardNewProjectCard } from './Dashboard.NewProjectCard';
import { DashboardNewsletterCard } from './Dashboard.NewsletterCard';
import { DashboardSupportCard } from './Dashboard.SupportCard';
import { DashboardNotifications } from './Notifications/Dashboard.Notifications';
import { DashboardQuickActions } from './QuickActions/Dashboard.QuickActions';

export const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  return (
    <PageLayout title={t('dashboard_page_title')} loading={false}>
      <Stack spacing={8}>
        <DashboardQuickActions />
        <DashboardNotifications />
        <DashboardNewsletterCard />
        <DashboardHelpSection />
        <Stack direction={'row'} spacing={2}>
          <DashboardNewProjectCard />
          <DashboardMetergridPlusCard />
        </Stack>

        <DashboardSupportCard />
      </Stack>
    </PageLayout>
  );
};
