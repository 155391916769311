import { Avatar, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { customerDisplayName, PresentableCustomer } from '../../presenters/customer';

export interface MGCustomerWithAvatarProps {
  customer?: PresentableCustomer | null;
  noCustomerDisplayText?: ReactNode;
}
export const MGCustomerWithAvatar = ({
  customer,
  noCustomerDisplayText = '-',
}: MGCustomerWithAvatarProps) => {
  if (!customer) {
    return noCustomerDisplayText;
  }
  return (
    <Box display={'flex'} alignItems={'center'} height={'100%'}>
      <Avatar
        sx={{
          width: '30px',
          height: '30px',
          bgcolor: 'primary.light',
          color: 'primary.main',
        }}
      >
        <Typography variant={'small'}>{customerDisplayName(customer)[0]}</Typography>
      </Avatar>
      <Typography sx={{ marginLeft: '10px' }} variant={'small'}>
        {customerDisplayName(customer)}
      </Typography>
    </Box>
  );
};
