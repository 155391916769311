import { MGCallToActionCard } from '@mg/ui/src/components/MGCallToActionCard';
import { useTranslation } from '../../../i18n';

export const DashboardMetergridPlusCard = () => {
  const { t } = useTranslation('dashboard');

  return (
    <>
      <MGCallToActionCard
        title={t('metergrid_plus_card_title')}
        buttonTitle={t('metergrid_plus_button')}
        color={'light'}
        href={'/metergridplus'}
      />
    </>
  );
};
