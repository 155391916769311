import { paramValidationErrorInstanceOutSchema } from './paramValidationErrorInstanceOutSchema.ts'
import { z } from 'zod'

/**
 * @description Returned when input parameters do not validate
 */
export const paramValidationErrorOutSchema = z
  .object({
    errors: z.array(z.lazy(() => paramValidationErrorInstanceOutSchema)),
    title: z.string().optional(),
    type: z.literal('https://metergrid.de/api-errors/param-validation-error').default('https://metergrid.de/api-errors/param-validation-error'),
  })
  .describe('Returned when input parameters do not validate')