import { planSchemaSchema } from './planSchemaSchema.ts'
import { z } from 'zod'

export const subscriptionPlanSchemaSchema = z.object({
  id: z.union([z.number().int(), z.null()]).optional(),
  is_active: z.boolean(),
  plan: z.union([z.lazy(() => planSchemaSchema), z.null()]).optional(),
  subscription: z.number().int(),
  valid_from: z.string().date().describe('The start of the validity period (including).'),
  valid_to: z.union([z.string().date(), z.null()]).describe('The end of the validity period (excluding). If unset, valid indefinitely.').optional(),
})