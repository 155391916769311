import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetSepaMandatePdfQueryResponse, GetSepaMandatePdfPathParams, GetSepaMandatePdf400 } from '../types/GetSepaMandatePdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSepaMandatePdfQueryKey = (id: GetSepaMandatePdfPathParams['id']) =>
  [{ url: '/api-internal/sepa_mandates/:id/pdf', params: { id: id } }] as const

export type GetSepaMandatePdfQueryKey = ReturnType<typeof getSepaMandatePdfQueryKey>

/**
 * @summary Get Sepa Mandate Pdf
 * {@link /api-internal/sepa_mandates/:id/pdf}
 */
async function getSepaMandatePdf(id: GetSepaMandatePdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetSepaMandatePdfQueryResponse, GetSepaMandatePdf400, unknown>({
    method: 'GET',
    url: `/api-internal/sepa_mandates/${id}/pdf`,
    ...config,
  })
  return res.data
}

export function getSepaMandatePdfQueryOptions(id: GetSepaMandatePdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getSepaMandatePdfQueryKey(id)
  return queryOptions<GetSepaMandatePdfQueryResponse, GetSepaMandatePdf400, GetSepaMandatePdfQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSepaMandatePdf(id, config)
    },
  })
}

/**
 * @summary Get Sepa Mandate Pdf
 * {@link /api-internal/sepa_mandates/:id/pdf}
 */
export function useGetSepaMandatePdf<
  TData = GetSepaMandatePdfQueryResponse,
  TQueryData = GetSepaMandatePdfQueryResponse,
  TQueryKey extends QueryKey = GetSepaMandatePdfQueryKey,
>(
  id: GetSepaMandatePdfPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetSepaMandatePdfQueryResponse, GetSepaMandatePdf400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSepaMandatePdfQueryKey(id)

  const query = useQuery({
    ...(getSepaMandatePdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSepaMandatePdf400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}