import { z } from 'zod'

export const projectCompleteSchemaSchema = z.object({
  created_levies: z.boolean(),
  created_plan: z.boolean(),
  created_residual_power_plan: z.boolean(),
  project_data: z.boolean(),
  renamed_panels: z.boolean(),
  setup_customers: z.boolean(),
  setup_measuring_points: z.boolean(),
})