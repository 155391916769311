import { z } from 'zod'

export const measuringPointPanelIncludesSchemaSchema = z.object({
  include_current_measuring_point: z.boolean().default(false),
  include_customer: z.boolean().default(false),
  include_last_reading: z.boolean().default(false),
  include_measuring_points: z.boolean().default(false),
  include_project: z.boolean().default(false),
  include_status: z.boolean().default(false),
  include_subscriptions: z.boolean().default(false),
})