import { measuringPointSchemaSchema } from './measuringPointSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listMeasuringPointsQueryParamsSchema = z.object({
  include_data: z.boolean().default(false),
  include_measuring_point_panel: z.boolean().default(false),
  include_customer: z.boolean().default(false),
  panel_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const listMeasuringPoints200Schema = z.array(z.lazy(() => measuringPointSchemaSchema))

/**
 * @description Unprocessable Entity
 */
export const listMeasuringPoints422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listMeasuringPointsQueryResponseSchema = z.lazy(() => listMeasuringPoints200Schema)