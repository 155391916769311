import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  ListMeasuringPointDataForGraphQueryResponse,
  ListMeasuringPointDataForGraphQueryParams,
  ListMeasuringPointDataForGraph422,
} from '../types/ListMeasuringPointDataForGraph.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listMeasuringPointDataForGraphQueryKey = (params?: ListMeasuringPointDataForGraphQueryParams) =>
  [{ url: '/api-internal/measuring_point_data/graph' }, ...(params ? [params] : [])] as const

export type ListMeasuringPointDataForGraphQueryKey = ReturnType<typeof listMeasuringPointDataForGraphQueryKey>

/**
 * @summary List Measuring Point Data For Graph
 * {@link /api-internal/measuring_point_data/graph}
 */
async function listMeasuringPointDataForGraph(params?: ListMeasuringPointDataForGraphQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListMeasuringPointDataForGraphQueryResponse, ListMeasuringPointDataForGraph422, unknown>({
    method: 'GET',
    url: `/api-internal/measuring_point_data/graph`,
    params,
    ...config,
  })
  return res.data
}

export function listMeasuringPointDataForGraphQueryOptions(params?: ListMeasuringPointDataForGraphQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listMeasuringPointDataForGraphQueryKey(params)
  return queryOptions<
    ListMeasuringPointDataForGraphQueryResponse,
    ListMeasuringPointDataForGraph422,
    ListMeasuringPointDataForGraphQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listMeasuringPointDataForGraph(params, config)
    },
  })
}

/**
 * @summary List Measuring Point Data For Graph
 * {@link /api-internal/measuring_point_data/graph}
 */
export function useListMeasuringPointDataForGraph<
  TData = ListMeasuringPointDataForGraphQueryResponse,
  TQueryData = ListMeasuringPointDataForGraphQueryResponse,
  TQueryKey extends QueryKey = ListMeasuringPointDataForGraphQueryKey,
>(
  params?: ListMeasuringPointDataForGraphQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListMeasuringPointDataForGraphQueryResponse, ListMeasuringPointDataForGraph422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMeasuringPointDataForGraphQueryKey(params)

  const query = useQuery({
    ...(listMeasuringPointDataForGraphQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListMeasuringPointDataForGraph422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}