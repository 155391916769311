import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { BulkEmailSendMutationRequest, BulkEmailSendMutationResponse, BulkEmailSend422 } from '../types/BulkEmailSend.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const bulkEmailSendMutationKey = () => [{ url: '/api-internal/document_email/bulk_send' }] as const

export type BulkEmailSendMutationKey = ReturnType<typeof bulkEmailSendMutationKey>

/**
 * @summary Bulk Email Send
 * {@link /api-internal/document_email/bulk_send}
 */
async function bulkEmailSend(data: BulkEmailSendMutationRequest, config: Partial<RequestConfig<BulkEmailSendMutationRequest>> = {}) {
  const res = await client<BulkEmailSendMutationResponse, BulkEmailSend422, BulkEmailSendMutationRequest>({
    method: 'POST',
    url: `/api-internal/document_email/bulk_send`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Bulk Email Send
 * {@link /api-internal/document_email/bulk_send}
 */
export function useBulkEmailSend(
  options: {
    mutation?: UseMutationOptions<BulkEmailSendMutationResponse, BulkEmailSend422, { data: BulkEmailSendMutationRequest }>
    client?: Partial<RequestConfig<BulkEmailSendMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkEmailSendMutationKey()

  return useMutation<BulkEmailSendMutationResponse, BulkEmailSend422, { data: BulkEmailSendMutationRequest }>({
    mutationFn: async ({ data }) => {
      return bulkEmailSend(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}