import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListProjectsQueryResponse, ListProjectsQueryParams, ListProjects422 } from '../types/ListProjects.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listProjectsQueryKey = (params?: ListProjectsQueryParams) => [{ url: '/api-internal/projects/' }, ...(params ? [params] : [])] as const

export type ListProjectsQueryKey = ReturnType<typeof listProjectsQueryKey>

/**
 * @summary List Projects
 * {@link /api-internal/projects/}
 */
async function listProjects(params?: ListProjectsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListProjectsQueryResponse, ListProjects422, unknown>({ method: 'GET', url: `/api-internal/projects/`, params, ...config })
  return res.data
}

export function listProjectsQueryOptions(params?: ListProjectsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listProjectsQueryKey(params)
  return queryOptions<ListProjectsQueryResponse, ListProjects422, ListProjectsQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listProjects(params, config)
    },
  })
}

/**
 * @summary List Projects
 * {@link /api-internal/projects/}
 */
export function useListProjects<TData = ListProjectsQueryResponse, TQueryData = ListProjectsQueryResponse, TQueryKey extends QueryKey = ListProjectsQueryKey>(
  params?: ListProjectsQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListProjectsQueryResponse, ListProjects422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listProjectsQueryKey(params)

  const query = useQuery({
    ...(listProjectsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListProjects422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}