import { Box, LinearProgress } from '@mui/material';
import { Loader } from '../Loader/Loader';

type DataGridCardLoadingOverlayProps = {
  showLinearProgress: boolean;
  showSpinner: boolean;
};

export const DataGridCardLoadingOverlay = ({
  showLinearProgress,
  showSpinner,
}: DataGridCardLoadingOverlayProps) => {
  return (
    <>
      {showLinearProgress && <LinearProgress sx={{ borderRadius: 4 }} />}
      {showSpinner && (
        <Box display={'flex'} height="100%" alignItems="center" justifyContent="center">
          <Box width={'200px'} height={'200px'} textAlign={'center'}>
            <Loader />
          </Box>
        </Box>
      )}
    </>
  );
};
