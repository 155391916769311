import {
  listTransactionsInfiniteQueryKey,
  ListTransactionsQueryParams,
  TransactionFilter,
  useGetTransactionStatistics,
  useListTransactionsInfinite,
  useReopenTransactions,
  useSettleTransactions,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { presentDate, presentEuro } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Chip, IconButton } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { CheckIcon, Undo2 } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { ActionBarActionButtonsAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { MGTabProps } from '../../shared/MetergridTabs';
import { AccountingFilterForm } from './Accounting.FilterForm';
import { AccountingCustomFilters } from './Accounting.types';

type Props = {
  height?: string;
  customerId?: number | null;
  dueDateStart?: string;
  dueDateEnd?: string;
  hideSelect?: boolean;
  showTableOnly?: boolean;
  hideFilter?: boolean;
  hideCustomer?: boolean;
};

export const AccountingData = ({
  height,
  customerId,
  dueDateStart,
  dueDateEnd,
  hideSelect,
  hideFilter,
  showTableOnly,
  hideCustomer = false,
}: Props) => {
  const queryClient = useQueryClient();

  const [filterValue] = useAtom<AccountingCustomFilters>(filterValueAtom);
  const projectFilter = useSelectedProjectsFilter();
  const statisticsQuery = useGetTransactionStatistics({ ...projectFilter, customer_id: customerId });
  const [tabFilter, setTabFilter] = useState<TransactionFilter>({});
  const setActionButtons = useSetAtom(ActionBarActionButtonsAtom);

  const { dataGridApiRef } = useGridApi();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const { t, tString } = useTranslation('transactions');

  const invalidateQueries = useCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: statisticsQuery.queryKey }),
      queryClient.invalidateQueries({
        queryKey: listTransactionsInfiniteQueryKey({ ...projectFilter }),
      }),
    ]);
  }, [queryClient, statisticsQuery.queryKey, projectFilter]);

  const settleTransactionsMutation = useSettleTransactions();
  const reopenTransactionsMutation = useReopenTransactions();

  const reopenTransactions = useCallback(
    (filter: TransactionFilter) => {
      (async () => {
        setIsLoading(true);
        const tid = toast.info(t(`toast_loading_reopen`));

        try {
          const result = await reopenTransactionsMutation.mutateAsync({
            data: {
              ...projectFilter,
              ...filter,
            },
          });

          if (result.errors.length === 0) {
            toast.update(tid, {
              type: 'success',
              render: t(`toast_success_reopen`),
            });
          } else if (result.reopened_transactions.length === 0) {
            toast.update(tid, {
              type: 'error',
              render: t('toast_error_reopen_txs_not_editable', { error_count: result.errors.length }),
            });
          } else {
            toast.update(tid, {
              type: 'warning',
              render: t(`toast_warning_reopen`, {
                error_count: result.errors.length,
                reopened_tx_count: result.reopened_transactions.length,
              }),
            });
          }
          await invalidateQueries();
        } catch (_) {
          toast.update(tid, {
            type: 'error',
            render: t('toast_error'),
          });
          return;
        }
        setIsLoading(false);
      })();
    },
    [projectFilter, invalidateQueries, reopenTransactionsMutation, t],
  );

  const settleTransactions = useCallback(
    (filter: TransactionFilter) => {
      toast.promise(
        async () => {
          setIsLoading(true);
          await settleTransactionsMutation.mutateAsync({
            data: {
              ...projectFilter,
              ...filter,
            },
          });
          await invalidateQueries();
          setIsLoading(false);
        },
        {
          pending: tString(`toast_loading_settle`),
          success: tString(`toast_success_settle`),
          error: tString('toast_error'),
        },
      );
    },
    [projectFilter, settleTransactionsMutation, invalidateQueries, tString],
  );

  const handleMainActionClick = useCallback(
    (action: 'settle' | 'reopen', filter: TransactionFilter) =>
      action === 'reopen' ? reopenTransactions(filter) : settleTransactions(filter),
    [reopenTransactions, settleTransactions],
  );

  const tabs: MGTabProps[] = [
    {
      label: tString('tab_all'),
      value: 'all',
      count: statisticsQuery.data?.total_count,
    },
    {
      label: tString('tab_overdue'),
      value: 'overdue',
      count: statisticsQuery.data?.overdue_count,
    },
    {
      label: tString('tab_pending'),
      value: 'pending',
      count: statisticsQuery.data?.open_count,
    },
    {
      label: tString('tab_settled'),
      value: 'settled',
      count: statisticsQuery.data?.closed_count,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'customer',
      headerName: tString('header_customer'),
      flex: 0.8,
      sortable: false,
      renderCell: (params) => <MGCustomerWithAvatar customer={params.value} />,
    },
    {
      field: 'reason',
      headerName: tString('header_reason'),
      flex: 0.5,
      valueGetter: (value) => t(value),
    },
    {
      field: 'voucher_date',
      headerName: tString('header_voucher_date'),
      flex: 0.5,
      valueGetter: presentDate,
    },
    {
      field: 'due_date',
      headerName: tString('header_due_date'),
      flex: 0.5,
      valueGetter: presentDate,
    },
    {
      field: 'amount',
      headerName: tString('header_amount'),
      valueGetter: (value) => presentEuro(value),
    },
    {
      field: 'granular_state',
      flex: 0.4,
      headerName: tString('header_state'),
      valueGetter: (value) => t(value),
      renderCell: (params) => (
        <Chip
          variant={'soft'}
          color={
            params.row.granular_state === 'overdue'
              ? 'error'
              : params.row.granular_state === 'due'
                ? 'warning'
                : params.row.granular_state === 'future'
                  ? 'gray'
                  : 'success'
          }
          sx={{ borderRadius: '8px' }}
          label={params.value}
          size={'small'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.1,
      renderCell: (params) => {
        const isSettled = params.row.state === 'settled';
        const colorTheme = isSettled ? palette.error : palette.success;

        return (
          <IconButton
            size={'small'}
            sx={{
              borderRadius: '10px',
              border: '1px solid lightgray',
              ':hover': {
                backgroundColor: colorTheme.light,
                border: `1px solid ${colorTheme.main}`,
                color: colorTheme.main,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              handleMainActionClick(isSettled ? 'reopen' : 'settle', { ids: [params.row.id] });
            }}
          >
            {isSettled ? <Undo2 size={14} /> : <CheckIcon size={14} />}
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (!dataGridApiRef.current?.apiLoaded) return;

    const selectedRows = Array.from(dataGridApiRef.current.getSelectedRows().values());
    const action = selectedRows.some((row) => row.state === 'pending') ? 'settle' : 'reopen';
    const isDisabled =
      selectedRows.some((row) => row.state === 'pending') &&
      selectedRows.some((row) => row.state === 'settled');

    setActionButtons([
      {
        label: tString(action === 'reopen' ? 'mark_as_reopened' : 'mark_as_settled'),
        icon: action === 'reopen' ? <Undo2 size={20} /> : <CheckIcon size={20} />,
        onClick: () => {
          if (!dataGridApiRef.current?.apiLoaded) return;

          const selectedRowsFromRef = Array.from(dataGridApiRef.current.getSelectedRows().values());
          const action = selectedRowsFromRef.some((row) => row.state === 'pending') ? 'settle' : 'reopen';

          if (dataGridApiRef.current.allItemsSelected()) {
            handleMainActionClick(action, dataGridApiRef.current.getReactQueryFilter());
          } else {
            handleMainActionClick(action, { ids: selectedRowsFromRef.map((row) => row.id) as number[] });
          }
        },
        disabled: isDisabled || isLoading,
        loading: isLoading,
      },
    ]);
  }, [isLoading, selectedRows, dataGridApiRef, handleMainActionClick, setActionButtons, t, tString]);

  return (
    <>
      <DataGridCard
        reactQueryHook={useListTransactionsInfinite}
        height={height}
        reactQueryHookParams={
          {
            ...projectFilter,
            ...tabFilter,
            states: ['pending', 'settled'],
            include_customer: true,
            customer: customerId,
            time_range_from: dueDateStart,
            time_range_to: dueDateEnd,
            ...filterValue,
          } as ListTransactionsQueryParams
        }
        columns={columns}
        selectable={!hideSelect}
        showSearch={!showTableOnly}
        searchPlaceholder={tString('search_placeholder')}
        tabs={showTableOnly ? undefined : tabs}
        onTabChange={(key) => {
          switch (key) {
            case 'all':
              setTabFilter({});
              break;
            case 'overdue':
              setTabFilter({ granular_state: key });
              break;
            case 'pending':
            case 'settled':
              setTabFilter({ state: key });
              break;
          }
        }}
        datagridProps={{ columnVisibilityModel: { customer: !hideCustomer } }}
        showFilter={!hideFilter}
        defaultSort={'-voucher_date'}
        filterModalContent={hideFilter ? undefined : <AccountingFilterForm />}
        onSelectionChange={setSelectedRows}
      />
    </>
  );
};
