import { MGTextRadioButton } from '@mg/ui/src/components/MGTextRadioButton';
import { Button, ButtonPropsColorOverrides, Grid, Stack, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { invalidateAllQueries } from '../../helpers/invalidateAllQueries';
import { useTranslation } from '../../i18n';
import { DisplayCard } from '../shared/DisplayCard';
import { DjangoFormModal } from './DjangoFormModal';
import { MuiBaseModal } from './MuiBaseModal';

type Props = {
  buttonColor?: OverridableStringUnion<
    'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  defaultSelectedPage?: 'plan' | 'residual_plan';
  hideButton?: boolean;
  isOpen?: boolean;
  onToggle?: (open: boolean) => void;
};

export const CreatePlanModal = ({
  buttonColor = 'primary',
  defaultSelectedPage = 'plan',
  isOpen,
  onToggle,
  hideButton,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(defaultSelectedPage);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [createFormUrl, setCreateFormUrl] = useState('');

  const { t } = useTranslation('plans');

  /* Added controlled behaviour if needed */
  useEffect(() => {
    if (onToggle) {
      onToggle(open);
    }
  }, [open]);

  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  return (
    <>
      {!hideButton && (
        <Button
          size={'large'}
          variant={'contained'}
          endIcon={<Plus size={20} />}
          onClick={() => setOpen(!open)}
          color={buttonColor}
        >
          {t('create_plan')}
        </Button>
      )}
      <MuiBaseModal
        open={open}
        handleToggle={() => setOpen(!open)}
        height={'580px'}
        width={'710px'}
        position={{ anchorTop: 'center' }}
        showCloseButton
        PaperStyle={{ overflow: 'hidden' }}
        BodyStyle={{ overflow: 'hidden' }}
      >
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>{t('create_plan')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <MGTextRadioButton
              label={t('tenant_power')}
              selected={selectedButton === 'plan'}
              onClick={() => setSelectedButton('plan')}
            />
          </Grid>
          <Grid item xs={6}>
            <MGTextRadioButton
              label={t('residual_power')}
              selected={selectedButton === 'residual_plan'}
              onClick={() => setSelectedButton('residual_plan')}
            />
          </Grid>

          <Grid item container xs={12}>
            {selectedButton === 'plan' && (
              <Stack spacing={2} direction={'row'}>
                <DisplayCard
                  label={t('mixed_price')}
                  color={'primary'}
                  text={t('card_mixed_price_text')}
                  onButtonClick={() => {
                    setCreateFormUrl(`/plans/create/`);
                    setFormModalOpen(!formModalOpen);
                  }}
                />

                <DisplayCard
                  label={t('two_components')}
                  color={'purple'}
                  text={t('card_two_components_text')}
                  onButtonClick={() => {
                    setCreateFormUrl(`/plans/create/two-component`);
                    setFormModalOpen(!formModalOpen);
                  }}
                />
              </Stack>
            )}

            {selectedButton === 'residual_plan' && (
              <Grid item xs={12}>
                <DisplayCard
                  label={t('residual_plan')}
                  color={'warning'}
                  text={t('card_residual_text')}
                  onButtonClick={() => {
                    setCreateFormUrl(`/residualpowerplans/create/`);
                    setFormModalOpen(!formModalOpen);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Button
          color={'white'}
          variant={'contained'}
          sx={{ position: 'absolute', bottom: '5px', right: '10px' }}
          onClick={() => setOpen(false)}
        >
          {t('cancel')}
        </Button>
      </MuiBaseModal>

      <DjangoFormModal
        open={formModalOpen}
        handleToggle={() => setFormModalOpen(!formModalOpen)}
        title={t('create_plan')}
        pathToDjangoForm={createFormUrl}
        onSuccess={() => {
          setFormModalOpen(false);
          setOpen(false);
          invalidateAllQueries();
        }}
      />
    </>
  );
};
