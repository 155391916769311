import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetPaymentPlanPdfsAsZipQueryResponse, GetPaymentPlanPdfsAsZipQueryParams, GetPaymentPlanPdfsAsZip400 } from '../types/GetPaymentPlanPdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPaymentPlanPdfsAsZipQueryKey = (params: GetPaymentPlanPdfsAsZipQueryParams) =>
  [{ url: '/api-internal/payment_plans/pdfs' }, ...(params ? [params] : [])] as const

export type GetPaymentPlanPdfsAsZipQueryKey = ReturnType<typeof getPaymentPlanPdfsAsZipQueryKey>

/**
 * @summary Get Payment Plan Pdfs As Zip
 * {@link /api-internal/payment_plans/pdfs}
 */
async function getPaymentPlanPdfsAsZip(params: GetPaymentPlanPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetPaymentPlanPdfsAsZipQueryResponse, GetPaymentPlanPdfsAsZip400, unknown>({
    method: 'GET',
    url: `/api-internal/payment_plans/pdfs`,
    params,
    ...config,
  })
  return res.data
}

export function getPaymentPlanPdfsAsZipQueryOptions(params: GetPaymentPlanPdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getPaymentPlanPdfsAsZipQueryKey(params)
  return queryOptions<GetPaymentPlanPdfsAsZipQueryResponse, GetPaymentPlanPdfsAsZip400, GetPaymentPlanPdfsAsZipQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPaymentPlanPdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Payment Plan Pdfs As Zip
 * {@link /api-internal/payment_plans/pdfs}
 */
export function useGetPaymentPlanPdfsAsZip<
  TData = GetPaymentPlanPdfsAsZipQueryResponse,
  TQueryData = GetPaymentPlanPdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetPaymentPlanPdfsAsZipQueryKey,
>(
  params: GetPaymentPlanPdfsAsZipQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetPaymentPlanPdfsAsZipQueryResponse, GetPaymentPlanPdfsAsZip400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPaymentPlanPdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getPaymentPlanPdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPaymentPlanPdfsAsZip400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}