import { pagedProjectSchemaSchema } from './pagedProjectSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listProjectsQueryParamsSchema = z
  .object({
    include_provider: z.boolean().default(false),
    current_project_id: z.union([z.number().int(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
    exclude_current_project: z.union([z.boolean(), z.null()]).default(false),
    page: z.union([z.number().int(), z.null()]).default(1),
    page_size: z.union([z.number().int(), z.null()]).default(25),
    order_by: z.union([z.string(), z.null()]).optional(),
    skip_pagination: z.union([z.boolean(), z.null()]).default(false),
    single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
  })
  .optional()

/**
 * @description OK
 */
export const listProjects200Schema = z.lazy(() => pagedProjectSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const listProjects422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listProjectsQueryResponseSchema = z.lazy(() => listProjects200Schema)