import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { setPasswordErrorOutSchema } from './setPasswordErrorOutSchema.ts'
import { setPasswordInSchema } from './setPasswordInSchema.ts'
import { z } from 'zod'

/**
 * @description Created
 */
export const setNewPassword201Schema = z.unknown()

/**
 * @description Unauthorized
 */
export const setNewPassword401Schema = z.lazy(() => setPasswordErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const setNewPassword422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => modelValidationErrorOutSchema)])

export const setNewPasswordMutationRequestSchema = z.lazy(() => setPasswordInSchema)

export const setNewPasswordMutationResponseSchema = z.lazy(() => setNewPassword201Schema)