import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ObtainTokenMutationRequest, ObtainTokenMutationResponse, ObtainToken422 } from '../types/ObtainToken.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const obtainTokenMutationKey = () => [{ url: '/api-internal/auth/token' }] as const

export type ObtainTokenMutationKey = ReturnType<typeof obtainTokenMutationKey>

/**
 * @summary Obtain Token
 * {@link /api-internal/auth/token}
 */
async function obtainToken(data: ObtainTokenMutationRequest, config: Partial<RequestConfig<ObtainTokenMutationRequest>> = {}) {
  const res = await client<ObtainTokenMutationResponse, ObtainToken422, ObtainTokenMutationRequest>({
    method: 'POST',
    url: `/api-internal/auth/token`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Obtain Token
 * {@link /api-internal/auth/token}
 */
export function useObtainToken(
  options: {
    mutation?: UseMutationOptions<ObtainTokenMutationResponse, ObtainToken422, { data: ObtainTokenMutationRequest }>
    client?: Partial<RequestConfig<ObtainTokenMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? obtainTokenMutationKey()

  return useMutation<ObtainTokenMutationResponse, ObtainToken422, { data: ObtainTokenMutationRequest }>({
    mutationFn: async ({ data }) => {
      return obtainToken(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}