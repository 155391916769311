import { currentUserSchemaSchema } from './currentUserSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const getCurrentUser200Schema = z.lazy(() => currentUserSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getCurrentUser422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getCurrentUserQueryResponseSchema = z.lazy(() => getCurrentUser200Schema)