import { z } from 'zod'

/**
 * @description OK
 */
export const exportTransactions200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const exportTransactions400Schema = z.string()

export const exportTransactionsQueryResponseSchema = z.lazy(() => exportTransactions200Schema)