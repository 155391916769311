import { DeliverableDocumentType } from '@mg/api-wrappers/src/api-internal';
import { atom, useAtom } from 'jotai';

type SettableState = {
  documentType: DeliverableDocumentType;
  ids: number[];
  allSelected: boolean;
};
type State =
  | { open: false }
  | ({
      open: true;
      step: number;
    } & SettableState);
const stateAtom = atom<State>({
  open: false,
});

export const useBulkEmailDocumentsModalControl = () => {
  const [state, setState] = useAtom(stateAtom);

  const close = () => setState({ open: false });
  const open = (state: SettableState) => setState({ open: true, step: 0, ...state });

  const nextStep = () => {
    if (!state.open) return;
    setState({ ...state, step: state.step + 1 });
  };

  return { state, open, close, nextStep };
};
