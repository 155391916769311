import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode | ReactNode[];
};

export const MGCardListLayout = ({ children }: Props) => {
  return (
    <Stack spacing={3} paddingTop={1}>
      {children}
    </Stack>
  );
};
