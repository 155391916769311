import { useGetMeasuringPointPanel } from '@mg/api-wrappers/src/api-internal';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { invalidateAllQueries } from '../../helpers/invalidateAllQueries';
import { useTranslation } from '../../i18n';
import { DjangoFormModal } from './DjangoFormModal';

export const CreateMeasuringPointDataModal = ({ panelId }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('meteringDetails');
  const { data } = useGetMeasuringPointPanel(panelId, {
    include_current_measuring_point: true,
  });

  return (
    <>
      <Button
        disabled={!data?.current_measuring_point}
        size={'large'}
        variant={'contained'}
        onClick={() => setOpen(true)}
        sx={{ marginLeft: '20px' }}
        endIcon={<Add sx={{ marginLeft: '10px' }} />}
      >
        {t('add_data')}
      </Button>
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?panel_id=${panelId}`}
        title={t('add_data')}
        open={open}
        handleToggle={() => {
          setOpen(!open);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setOpen(false);
        }}
      />
    </>
  );
};
