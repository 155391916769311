import { Box } from '@mui/material';
import { ReactNode } from 'react';

export interface MGModalFooterProps {
  children: ReactNode;
}
export const MGModalFooter = ({ children }: MGModalFooterProps) => {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      {children}
    </Box>
  );
};
