import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingV1CompleteMutationResponse,
  SetProjectToOnboardingV1CompletePathParams,
  SetProjectToOnboardingV1CompleteQueryParams,
  SetProjectToOnboardingV1Complete400,
  SetProjectToOnboardingV1Complete422,
} from '../types/SetProjectToOnboardingV1Complete.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const setProjectToOnboardingV1CompleteMutationKey = () => [{ url: '/api-internal/projects/{id}/complete_onboarding_v1' }] as const

export type SetProjectToOnboardingV1CompleteMutationKey = ReturnType<typeof setProjectToOnboardingV1CompleteMutationKey>

/**
 * @summary Set Project To Onboarding V1 Complete
 * {@link /api-internal/projects/:id/complete_onboarding_v1}
 */
async function setProjectToOnboardingV1Complete(
  id: SetProjectToOnboardingV1CompletePathParams['id'],
  params?: SetProjectToOnboardingV1CompleteQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const res = await client<
    SetProjectToOnboardingV1CompleteMutationResponse,
    SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete422,
    unknown
  >({ method: 'POST', url: `/api-internal/projects/${id}/complete_onboarding_v1`, params, ...config })
  return res.data
}

/**
 * @summary Set Project To Onboarding V1 Complete
 * {@link /api-internal/projects/:id/complete_onboarding_v1}
 */
export function useSetProjectToOnboardingV1Complete(
  options: {
    mutation?: UseMutationOptions<
      SetProjectToOnboardingV1CompleteMutationResponse,
      SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete422,
      { id: SetProjectToOnboardingV1CompletePathParams['id']; params?: SetProjectToOnboardingV1CompleteQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setProjectToOnboardingV1CompleteMutationKey()

  return useMutation<
    SetProjectToOnboardingV1CompleteMutationResponse,
    SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete422,
    { id: SetProjectToOnboardingV1CompletePathParams['id']; params?: SetProjectToOnboardingV1CompleteQueryParams }
  >({
    mutationFn: async ({ id, params }) => {
      return setProjectToOnboardingV1Complete(id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}