import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetCustomerQueryResponse, GetCustomerPathParams, GetCustomerQueryParams, GetCustomer422 } from '../types/GetCustomer.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCustomerQueryKey = (customer_id: GetCustomerPathParams['customer_id'], params?: GetCustomerQueryParams) =>
  [{ url: '/api-internal/customers/:customer_id', params: { customer_id: customer_id } }, ...(params ? [params] : [])] as const

export type GetCustomerQueryKey = ReturnType<typeof getCustomerQueryKey>

/**
 * @summary Get Customer
 * {@link /api-internal/customers/:customer_id}
 */
async function getCustomer(customer_id: GetCustomerPathParams['customer_id'], params?: GetCustomerQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetCustomerQueryResponse, GetCustomer422, unknown>({
    method: 'GET',
    url: `/api-internal/customers/${customer_id}`,
    params,
    ...config,
  })
  return res.data
}

export function getCustomerQueryOptions(
  customer_id: GetCustomerPathParams['customer_id'],
  params?: GetCustomerQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCustomerQueryKey(customer_id, params)
  return queryOptions<GetCustomerQueryResponse, GetCustomer422, GetCustomerQueryResponse, typeof queryKey>({
    enabled: !!customer_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCustomer(customer_id, params, config)
    },
  })
}

/**
 * @summary Get Customer
 * {@link /api-internal/customers/:customer_id}
 */
export function useGetCustomer<TData = GetCustomerQueryResponse, TQueryData = GetCustomerQueryResponse, TQueryKey extends QueryKey = GetCustomerQueryKey>(
  customer_id: GetCustomerPathParams['customer_id'],
  params?: GetCustomerQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetCustomerQueryResponse, GetCustomer422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCustomerQueryKey(customer_id, params)

  const query = useQuery({
    ...(getCustomerQueryOptions(customer_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCustomer422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}