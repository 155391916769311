import { deliverableDocumentType, InvoiceSchema, useGetInvoicePdf } from '@mg/api-wrappers/src/api-internal';
import { useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Download, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../helpers/downloadFile';
import { useTranslation } from '../../../i18n';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

import { axiosInstance } from '@mg/api-wrappers/src/client';
import { useDocumentContext } from '../../features/documents/document-context/document-context';

export const DocumentsInvoiceTableRowActionMenu = () => {
  const { document, queryKey } = useDocumentContext<InvoiceSchema>();
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const { t } = useTranslation('documents');
  const getInvoicePdfQuery = useGetInvoicePdf(document.id, { query: { enabled: false } });
  const getCancelInvoicePdfQuery = useGetInvoicePdf(document.canceled_by || -1, {
    query: { enabled: false },
  });
  const getOriginalInvoicePdfQuery = useGetInvoicePdf(document.cancels || -1, { query: { enabled: false } });

  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.invoice,
    downloadPdfQuery: getInvoicePdfQuery,
  });

  const queryClient = useQueryClient();

  const { palette } = useTheme();

  const isCanceled = document.state === 'canceled';
  const isCancelInvoice = document.cancels;

  const menuItems: MenuItem[] = [
    ...commonItems,
    {
      text: t('download_original_inoice'),
      hidden: !isCancelInvoice,
      icon: <Download size={'18'} />,
      onClick: () => {
        downloadFile(getOriginalInvoicePdfQuery);
      },
    },
    {
      text: t('download_cancel_invoice'),
      hidden: !isCanceled,
      icon: <Download size={'18'} />,
      onClick: () => {
        downloadFile(getCancelInvoicePdfQuery);
      },
    },
    {
      text: t('cancel'),
      hidden: document.state !== 'issued' || !!document.cancels,
      icon: <Trash2 color={palette.error.main} size={'18'} />,
      onClick: () => {
        fireSweetalert({
          title: t('delete_invoice_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_invoice_text', { invoiceNumber: `${document.series}-${document.number}` }),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axiosInstance.post(`/invoices/${document.id}/cancel/`).then(() => {
              queryClient.invalidateQueries({ queryKey });
              toast.info(t('cancel_invoice_will_be_created'));
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
    </>
  );
};
