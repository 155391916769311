import { z } from 'zod'

export const exportMeasuringPointDataQueryParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const exportMeasuringPointData200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const exportMeasuringPointData400Schema = z.string()

export const exportMeasuringPointDataQueryResponseSchema = z.lazy(() => exportMeasuringPointData200Schema)