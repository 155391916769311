import { useSetNewPassword } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { addErrorsToFormFields } from '@mg/ui/src/components/MGForm/addErrorsToFormFields.ts';
import { MGFormFieldPassword } from '@mg/ui/src/components/MGForm/MGForm.Field.Password.tsx';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton.tsx';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm.tsx';
import { Box, Button, Stack } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { useTranslation } from '../../../i18n';

export const NewPasswordForm = () => {
  const { tString } = useTranslation('login');
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromParams = urlParams.get('token');
  const emailFromParams = urlParams.get('username');

  const setNewPasswordMutation = useSetNewPassword();

  const form = useForm({
    onSubmit: async ({ value }) => {
      await setNewPasswordMutation.mutateAsync(
        {
          data: {
            ...value,
            token: tokenFromParams as string,
            username: emailFromParams as string,
          },
        },
        {
          onSuccess: () => {
            setShowSuccessScreen(true);
          },
          onError: (errOut) => {
            if (errOut.type === 'https://metergrid.de/api-errors/model-validation-error') {
              addErrorsToFormFields({ form, modelValidationError: errOut });
              form.store.state.fieldMeta.new_password1.errors.push('');
            }
            // The other Error would return a 401 which redirects the user back to the login page in case token or username is wrong or has been used already.
          },
        },
      );
    },
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        new_password1: z
          .string()
          .min(8, tString('err_password_min_length'))
          .regex(/\D/, tString('err_password_not_numeric')),
        new_password2: z.string(),
      }),
    },
  });

  if (showSuccessScreen) {
    return (
      <Stack spacing={2}>
        <MGAlert
          severity={'success'}
          title={tString('password_changed')}
          subtitle={tString('password_changed_subtitle')}
          icon={<Check />}
        />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button component={Link} to={'/login'} variant={'contained'}>
            {tString('btn_back_to_login')}
          </Button>
        </Box>
      </Stack>
    );
  }

  return (
    <MGForm form={form}>
      <MGFormFieldPassword
        name={'new_password1'}
        form={form}
        label={tString('label_new_password')}
        onChange={() => {
          // Reset errors when user types in the field, runs before the validator
          form.store.state.fieldMeta.new_password1.errors = [];
          form.store.state.fieldMeta.new_password2.errors = [];
        }}
      />
      <MGFormFieldPassword
        name={'new_password2'}
        form={form}
        label={tString('label_confirm_new_password')}
        FieldProps={{
          validators: {
            onChangeListenTo: ['new_password1'],
            onChange: ({ value, fieldApi }) => {
              if (value && value !== fieldApi.form.getFieldValue('new_password1')) {
                return tString('err_passwords_dont_match');
              }
              return undefined;
            },
          },
        }}
      />

      <MGFormSubmitButton buttonLabel={tString('btn_submit_password_reset')} form={form} />
    </MGForm>
  );
};
