import { useSelectedProjectsFilter } from './useSelectedProjectsFilter';

/**
 * Used to return the current project id, extracted from the hidden input set by Django.
 * Now replaced by current_project_id in userInfo and is returend by the useSelectedProjectsFilter hook.
 * @deprecated Use useSelectedProjectsFilter.projectId instead.
 */
export const useCurrentProject = (): number => {
  const selectedProject = useSelectedProjectsFilter();
  return selectedProject.project_id;
};
