import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { withController } from './with-controller';

export const DatePicker = withController<MuiDatePickerProps<Dayjs, false>>(
  MuiDatePicker,
  ({ field, fieldState }) => ({
    value: field.value,
    onChange: field.onChange,
    disabled: field.disabled,
    inputRef: field.ref,
    name: field.name,

    slotProps: {
      textField: { error: !!fieldState.error, helperText: fieldState.error?.message },
    },
  }),
);
