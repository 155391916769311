import {
  EmailDocumentSchema,
  useBulkEmailPreflight,
  useBulkEmailSend,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGLoader } from '@mg/ui/src/components/MGLoader/MGLoader';
import { palette } from '@mg/ui/src/styles';
import { Paper, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai/index';
import { TriangleAlertIcon } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useTranslation } from '../../../../i18n';
import { filterValueAtom } from '../../../../jotai/actionBar';
import { MuiBaseModal } from '../../../modals/MuiBaseModal';
import { useGridApi } from '../../../shared/DataGridCard/useGridApi';
import { CustomerStep } from './CustomerStep';
import { DocumentStep } from './DocumentStep';
import { SendStep } from './SendStep';
import { useBulkEmailDocumentsModalControl } from './hooks';

type ModalTitleProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
};
const ModalTitle = ({ title, subtitle }: ModalTitleProps) => {
  return (
    <Stack alignItems="center" mb={2}>
      {title && (
        <Typography variant="h2" color={palette.text.black} fontWeight="500" mb={2}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography color={palette.text.gray} mb={2}>
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export const BulkEmailDocumentsModal = () => {
  const { t, tString } = useTranslation('documents');

  const projectId = useCurrentProject();
  const [filter] = useAtom(filterValueAtom);
  const { state, close, nextStep } = useBulkEmailDocumentsModalControl();

  const send = useBulkEmailSend({});
  const { dataGridApiRef } = useGridApi();
  const queryClient = useQueryClient();

  const preflight = useBulkEmailPreflight();

  const [documentsToSend, setDocumentsToSend] = useState<EmailDocumentSchema[]>([]);

  useEffect(() => {
    if (!state.open) {
      setDocumentsToSend([]);
      return;
    }

    const data = {
      document_type: state.documentType,
      project_id: projectId,
      ...filter,
    };
    if (!state.allSelected) {
      data['ids'] = state.ids;
    }

    preflight.mutate(
      { data, params: { include_customer: true, include_pdf: true } },
      {
        onSuccess: (data) => {
          setDocumentsToSend(data);
        },
      },
    );
  }, [state.open]);

  if (!state.open) return null;

  const customerStep = {
    title: tString('bulk_email_modal_check_recipients'),
    content: preflight.isSuccess && (
      <CustomerStep
        documents={documentsToSend}
        onContinue={(documentsToSend) => {
          setDocumentsToSend(documentsToSend);
          nextStep();
        }}
      />
    ),
  };
  const documentStep = {
    title: tString('bulk_email_modal_check_documents'),
    content: preflight.isSuccess && (
      <DocumentStep
        documents={documentsToSend}
        onContinue={(documentsToSend) => {
          setDocumentsToSend(documentsToSend);
          const data = {
            document_type: state.documentType,
            project_id: projectId,
            ids: documentsToSend.map((doc) => doc.id),
            ...filter,
          };

          send.mutate(
            { data: { filter: data } },
            {
              onSuccess: () => {
                if (dataGridApiRef.current?.apiLoaded) {
                  queryClient.invalidateQueries({ queryKey: dataGridApiRef.current.getQueryKey() });
                }
              },
            },
          );
          nextStep();
        }}
      />
    ),
  };
  const sendStep = {
    title: tString('bulk_email_modal_send_documents'),
    content: preflight.isSuccess && (
      <SendStep
        isError={send.isError}
        isSuccess={send.isSuccess}
        isPending={send.isPending}
        dataCount={send.data?.length}
        onContinue={() => close()}
      />
    ),
  };
  const steps = [customerStep, documentStep, sendStep];

  return (
    <MuiBaseModal
      open={state.open}
      handleToggle={() => close()}
      width={1000}
      height={'80vh'}
      showCloseButton={true}
    >
      <Stack alignItems="center" sx={{ height: '100%' }}>
        <ModalTitle title={t('bulk_email_modal_title')} subtitle={t('bulk_email_modal_subtitle')} />

        <Paper sx={{ padding: 3, marginBottom: 4, alignSelf: 'stretch' }} elevation={24}>
          <Stepper activeStep={state.step} sx={{ marginX: 'auto', maxWidth: 800 }}>
            {steps.map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>

        <Stack sx={{ alignSelf: 'stretch', flex: 1, minHeight: 0 }} className="content">
          {preflight.isSuccess ? (
            steps[state.step].content
          ) : preflight.isPending ? (
            <MGLoader />
          ) : preflight.isError ? (
            <MGAlert
              icon={<TriangleAlertIcon />}
              severity="error"
              title={t('bulk_email_modal_error_title')}
              subtitle={t('bulk_email_modal_error_subtitle')}
            />
          ) : null}
        </Stack>
      </Stack>
    </MuiBaseModal>
  );
};
