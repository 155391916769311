import {
  deliverableDocumentType,
  SepaMandateSchema,
  useGetSepaMandatePdfsAsZip,
  useListSepaMandatesInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAtomValue } from 'jotai/index';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { presentDate } from '../../../presenters/date';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { DocumentsRowAction } from './Documents.RowAction';
import { DocumentsSepaTableFilterForm } from './Documents.SepaTable.FilterForm';
import { DocumentsSepaTableRowActionMenu } from './Documents.SepaTable.RowActionMenu';
import { DocumentsStatusAndQuickaction } from './Documents.StatusAndQuickaction';
import { useSetActionBarButtons } from './useSetActionBarButtons';

type Props = {
  customerId?: number | null;
};

export const DocumentsSepaTable = ({ customerId }: Props) => {
  const { t, tString } = useTranslation('documents');
  const currentProjectId = useSelectedProjectsFilter();
  const getSepaAsZipQuery = useGetSepaMandatePdfsAsZip(
    { ...currentProjectId, customer_id: customerId },
    { query: { enabled: false } },
  );
  const { dataGridApiRef } = useGridApi();
  const filter = useAtomValue(filterValueAtom);

  useSetActionBarButtons({
    getAsZipQuery: getSepaAsZipQuery,
    documentType: deliverableDocumentType.sepa_mandate,
  });

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'reference',
      headerName: tString('mandate_reference'),
      flex: 0.4,
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      sortable: false,
      flex: 0.7,
      renderCell: (params) => {
        // @ts-expect-error TS2322
        return <MGCustomerWithAvatar customer={params.row.customer} />;
      },
    },
    {
      field: 'state',
      sortable: false,
      headerName: tString('status'),
      flex: 0.3,
      renderCell: (params) => (
        <Chip
          color={params.value === 'ACCEPTED' ? 'success' : params.value === 'CREATED' ? 'primary' : 'error'}
          variant={'soft'}
          sx={{ width: '90px', borderRadius: '8px' }}
          size={'small'}
          label={t(params.value)}
        />
      ),
    },
    {
      field: 'accepted_date',
      headerName: tString('submitted_at'),
      flex: 0.3,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'cancelled_date',
      headerName: tString('reverted_at'),
      flex: 0.3,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 0,
      align: 'right',
      renderCell: (params) =>
        dataGridApiRef.current?.apiLoaded && (
          <DocumentsRowAction
            document={params.row}
            documentType={deliverableDocumentType.sepa_mandate}
            queryKey={dataGridApiRef.current.getQueryKey()}
          >
            <DocumentsStatusAndQuickaction />
            <DocumentsSepaTableRowActionMenu />
          </DocumentsRowAction>
        ),
    },
  ] as GridColDef<SepaMandateSchema>[];

  return (
    <Box sx={{ height: '100%' }}>
      <DataGridCard
        height={'calc(100% - 50px)'}
        searchPlaceholder={tString('search_placeholder') as string}
        columns={columns}
        selectable
        reactQueryHook={useListSepaMandatesInfinite}
        reactQueryHookParams={{
          ...currentProjectId,
          ...filter,
          document_type: deliverableDocumentType.sepa_mandate,
          include_customer: true,
          include_pdf: true,
          customer_id: customerId,
        }}
        reactQueryHookQueryOptions={{
          meta: {
            refetchForActiveTasks: true,
          },
        }}
        datagridProps={{
          columnVisibilityModel: { id: false },
        }}
        rowHeight={50}
        filterModalContent={<DocumentsSepaTableFilterForm />}
      />
    </Box>
  );
};
