import { useCountCustomers } from '@mg/api-wrappers/src/api-internal';
import { Button, Typography } from '@mui/material';
import { MailPlus } from 'lucide-react';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { CreateLetterModal } from '../../../modals/CreateLetterModal/CreateLetterModal';
import { DashboardCard } from '../Dashboard.Card';

export const DashboardQuickActionsLetters = () => {
  const { t } = useTranslation('dashboard');

  const projectFilter = useSelectedProjectsFilter();
  const customerQuery = useCountCustomers({ ...projectFilter });

  return (
    <DashboardCard
      loading={customerQuery.isLoading}
      title={t('quick_actions_letters_title')}
      subtitle={
        <Typography variant={'small'}>
          {t('quick_actions_letters_subtitle', { countCustomers: customerQuery.data?.active })}
        </Typography>
      }
      icon={<MailPlus />}
      button={
        <CreateLetterModal
          toggler={
            <Button variant={'contained'} color={'white'}>
              {t('quick_actions_letters_last_button')}
            </Button>
          }
        />
      }
    />
  );
};
