import { notificationSchemaSchema } from './notificationSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const dismissNotificationPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const dismissNotification200Schema = z.lazy(() => notificationSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const dismissNotification422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const dismissNotificationMutationResponseSchema = z.lazy(() => dismissNotification200Schema)