import { billingPreflightReturnDataOutSchema } from './billingPreflightReturnDataOutSchema.ts'
import { z } from 'zod'

export const billingPreflightMeasuringPointOutSchema = z.object({
  consumption: z.number(),
  end: z.union([z.string().date(), z.null()]),
  end_data: z.lazy(() => billingPreflightReturnDataOutSchema),
  measuring_point: z.union([z.string(), z.null()]),
  start: z.union([z.string().date(), z.null()]),
  start_data: z.lazy(() => billingPreflightReturnDataOutSchema),
})