import { useGetCurrentUser } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { palette } from '@mg/ui/src/styles';
import { Button, Card, Chip, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { downloadFile } from '../../../../helpers/downloadFile';
import { useTranslation } from '../../../../i18n';
import { Switch } from '../../../shared/HookFormIntegration/Switch';
import { TextField } from '../../../shared/HookFormIntegration/TextField';
import { useMultiStepPanelContext } from '../../../shared/MultiStepPanel/MultiStepPanel.Context';
import { ErrorIndicator } from '../ErrorIndicator';
import { useFunnelContext } from '../FunnelProvider.Context';

export type TransactionsStepFieldValues = {
  closeSelectedTransactions: boolean;
  createBatchSepaFiles: boolean;
  wodisYuneoVoucherNumber: string;
};

export const TransactionsStep = () => {
  const { t } = useTranslation('sepaGeneration');
  const userQuery = useGetCurrentUser();

  const { goto, next } = useMultiStepPanelContext();
  const { data: funnelData } = useFunnelContext();
  const { control, handleSubmit, getValues } = useForm<TransactionsStepFieldValues>({
    values: {
      closeSelectedTransactions: funnelData.closeSelectedTransactions,
      createBatchSepaFiles: funnelData.createBatchSepaFiles,
      wodisYuneoVoucherNumber: '',
    },
  });

  const downloadSepaXml = async () => {
    // FIXME:
    const url = '/api-internal/transactions/generate_sepa_files';
    const params = {};
    const values = getValues();

    await downloadFile({ queryKey: [{ url }, { params }] }, params, {
      method: 'post',
      data: {
        settle_transactions: values.closeSelectedTransactions,
        create_batch_sepa_files: values.createBatchSepaFiles,
        ids: funnelData.transactions.filter((ta) => ta.value).map((ta) => ta.id),
        wodis_yuneo_voucher_number: values.wodisYuneoVoucherNumber,
      },
    });

    next();
  };

  const onSubmit = () => {
    downloadSepaXml();
  };

  if (!userQuery.isSuccess) {
    return (
      <Stack height={250} alignItems={'center'} justifyContent={'center'} gap={2}>
        {userQuery.isLoading && <MGLoader />}
        {userQuery.isError && <ErrorIndicator>{t('transactions_step_error')}</ErrorIndicator>}
      </Stack>
    );
  }

  return (
    <>
      <Stack component={'form'} gap={4} onSubmit={handleSubmit(onSubmit)}>
        <Card variant="elevation">
          <Stack direction={'row'} gap={2} sx={{ p: 3 }} justifyContent={'space-between'}>
            <Stack gap={2}>
              <Switch
                name="closeSelectedTransactions"
                control={control}
                label={t('transactions_step_switch_label')}
              />

              <Switch
                name="createBatchSepaFiles"
                control={control}
                label={
                  <>
                    <Typography display={'block'}>
                      {t('transactions_step_create_batch_sepa_files_label')}
                    </Typography>
                    <Typography variant={'small'} color={palette.text.gray} fontWeight={400}>
                      {t('transactions_step_create_batch_sepa_files_label_description')}
                    </Typography>
                  </>
                }
              />

              {userQuery.data.flags.wodis_yuneo_export && (
                <TextField
                  id="outlined-basic"
                  name="wodisYuneoVoucherNumber"
                  control={control}
                  label={t('transactions_step_wodis_yuneo_voucher_number_label')}
                  variant="outlined"
                  helperText={t('transactions_step_wodis_yuneo_voucher_number_helper_text')}
                  required={true}
                />
              )}
            </Stack>
            <Chip variant="soft" color="primary" label={t('transactions_step_switch_tag_label')} />
          </Stack>
        </Card>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Button
            variant={'contained'}
            color={'white'}
            onClick={(event) => {
              event.preventDefault();
              goto('projects');
            }}
          >
            {t(`transactions_step_button_back`)}
          </Button>
          <Button variant={'contained'} type="submit">
            {t(`transactions_step_button_submit`)}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
