import { palette } from '@mg/ui/src/styles';
import axios from 'axios';
import { useAtom } from 'jotai';
import { ListPlus, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { refreshMessagesAtom } from '../../../jotai/atoms';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

export const MeteringDetailsMeasuringPointListActionMenu = ({ meterId }) => {
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [messageRefreshCounter, setMessageRefreshCounter] = useAtom(refreshMessagesAtom);
  const { t } = useTranslation('meteringDetails');

  const menuItems: MenuItem[] = [
    {
      text: t('add_data'),
      icon: <ListPlus size={'18'} />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
    },
    {
      text: t('meter_list_action_menu_delete_meter'),
      icon: <Trash2 size={'18'} color={palette.error.main} />,
      disabledHint: t('meter_list_action_menu_delete_tooltip'),
      onClick: () => {
        fireSweetalert({
          title: t('meter_list_action_menu_delete_meter_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('meter_list_action_menu_delete_meter_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios.get(`/metering/meters/${meterId}/delete`).then(() => {
              invalidateAllQueries();
              setMessageRefreshCounter(messageRefreshCounter + 1);
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <ActionsMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?measuring_point_id=${meterId}`}
        title={t('add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
        }}
      />
    </>
  );
};
