import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { IconButton, Stack, Typography } from '@mui/material';
import { Edit } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';
import { CustomerDetailsDataAddressCard } from './CustomerDetails.Data.AddressCard';
import { CustomerDetailsDataBankAccountCard } from './CustomerDetails.Data.BankAccountCard';
import { CustomerDetailsDataBaseDataCard } from './CustomerDetails.Data.BaseDataCard';
import { CustomerDetailsDataNotesCard } from './CustomerDetails.Data.NotesCard';
import { CustomerDetailsDataOpenTransactionsCard } from './CustomerDetails.Data.OpenTransactionsCard';
import { CustomerDetailsDataPaidTransactionsCard } from './CustomerDetails.Data.PaidTransactionsCard';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsData = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');

  return (
    <Stack spacing={5} paddingBottom={'20px'}>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h5'}>{t('data_general_title')}</Typography>
          <IconButton href={`/customers/${customer?.id}/edit/`}>
            <Edit size={20} />
          </IconButton>
        </Stack>

        <Stack direction={'row'} spacing={2}>
          <CustomerDetailsDataBaseDataCard isLoading={isLoading} customer={customer} />
          <Stack flex={0.4} spacing={2}>
            <CustomerDetailsDataOpenTransactionsCard isLoading={isLoading} customer={customer} />
            <CustomerDetailsDataPaidTransactionsCard isLoading={isLoading} customer={customer} />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2}>
        <Stack flex={1} spacing={2}>
          <Typography variant={'h5'}>{t('data_address_title')}</Typography>
          <CustomerDetailsDataAddressCard isLoading={isLoading} customer={customer} />
        </Stack>
        <Stack flex={1} spacing={2}>
          <Typography variant={'h5'}>{t('data_bank_account_title')}</Typography>
          <CustomerDetailsDataBankAccountCard isLoading={isLoading} customer={customer} />
        </Stack>
      </Stack>
      <Stack flex={1} spacing={2}>
        <Typography variant={'h5'}>{t('data_notes_title')}</Typography>
        <CustomerDetailsDataNotesCard customer={customer} />
      </Stack>
    </Stack>
  );
};
