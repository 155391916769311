import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

export const NavigateWithSearch = ({
  forwardSearchParams = true,
  ...props
}: NavigateProps & { forwardSearchParams?: boolean }) => {
  const location = useLocation();

  return (
    <Navigate {...props} to={props.to + (forwardSearchParams && location.search ? location.search : '')} />
  );
};
