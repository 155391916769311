import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import { CustomElement, CustomText, Descendant } from './MGRichTextEditor.CustomTypes';

export const serialize = (node: CustomElement | CustomText) => {
  if (Text.isText(node)) {
    let str = escapeHtml(node.text);
    if (node.bold) {
      str = `<strong>${str}</strong>`;
    }
    if (node.italic) {
      str = `<em>${str}</em>`;
    }
    if (node.underline) {
      str = `<u>${str}</u>`;
    }
    return str;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    default:
      return children;
  }
};

export const serializeAst = (nodes: Descendant[]) => {
  return nodes.map((n) => serialize(n)).join('');
};

export const deserialize = (el: HTMLElement | ChildNode, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: Omit<CustomText, 'text'> = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);

    default:
      return children;
  }
};

export const deserializeHtmlStr = (html: string) => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return deserialize(document.body);
};
