import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export type FieldHeadingProps = PropsWithChildren;

export const FieldHeading = ({ children }: FieldHeadingProps) => {
  if (typeof children === 'string') {
    return <Typography>{children}</Typography>;
  }

  return <>{children}</>;
};
