import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import {
  MGCardContent,
  MGCardHeader,
  MGCardListLayout,
  MGCardListLayoutItem,
} from '@mg/ui/src/components/MGCard';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard';
import { presentDate } from '@mg/ui/src/presenters';
import { UserRound } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataBaseDataCard = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const { t: tShared } = useTranslation('shared');
  const deliveryPeriod = `${presentDate(customer?.subscription?.start_date)} - ${customer?.subscription?.ended_at ? presentDate(customer?.subscription?.ended_at) : 'Heute'}`;

  return (
    <MGCard isLoading={isLoading} sx={{ flex: 0.8 }}>
      <MGCardHeader leftHeader={<UserRound />} />
      <MGCardContent>
        <MGCardListLayout>
          <MGCardListLayoutItem label={t('data_card_name')}>{customer?.display_name}</MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_customer_number')}>{customer?.id}</MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_contract_number')}>
            {customer?.subscription?.id}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_panel_name')}>
            {customer?.measuring_point_panel?.name}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_email')}>{customer?.email || '-'}</MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_phone')}>
            {customer?.phone_number || '-'}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_delivery_period')}>{deliveryPeriod}</MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_is_in_fiscal_unity_with_provider_company')}>
            {customer?.is_in_fiscal_unity_with_provider_company ? tShared('yes') : tShared('no')}
          </MGCardListLayoutItem>
        </MGCardListLayout>
      </MGCardContent>
    </MGCard>
  );
};
