import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  CreateMeasuringPointDataMutationRequest,
  CreateMeasuringPointDataMutationResponse,
  CreateMeasuringPointData422,
} from '../types/CreateMeasuringPointData.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const createMeasuringPointDataMutationKey = () => [{ url: '/api-internal/measuring_point_data/' }] as const

export type CreateMeasuringPointDataMutationKey = ReturnType<typeof createMeasuringPointDataMutationKey>

/**
 * @summary Create Measuring Point Data
 * {@link /api-internal/measuring_point_data/}
 */
async function createMeasuringPointData(
  data: CreateMeasuringPointDataMutationRequest,
  config: Partial<RequestConfig<CreateMeasuringPointDataMutationRequest>> = {},
) {
  const res = await client<CreateMeasuringPointDataMutationResponse, CreateMeasuringPointData422, CreateMeasuringPointDataMutationRequest>({
    method: 'POST',
    url: `/api-internal/measuring_point_data/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Create Measuring Point Data
 * {@link /api-internal/measuring_point_data/}
 */
export function useCreateMeasuringPointData(
  options: {
    mutation?: UseMutationOptions<CreateMeasuringPointDataMutationResponse, CreateMeasuringPointData422, { data: CreateMeasuringPointDataMutationRequest }>
    client?: Partial<RequestConfig<CreateMeasuringPointDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createMeasuringPointDataMutationKey()

  return useMutation<CreateMeasuringPointDataMutationResponse, CreateMeasuringPointData422, { data: CreateMeasuringPointDataMutationRequest }>({
    mutationFn: async ({ data }) => {
      return createMeasuringPointData(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}