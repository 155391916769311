export const sanitizeNumberInput = (input: string, previous: string): string => {
  const sanitized = input.replace(/[^0-9.,]/g, '');
  if (validateNumberInput(sanitized)) return sanitized;
  else return previous;
};

export const parseNumberInput = (input: string): string => {
  if (!validateNumberInputNonempty(input)) throw new Error(`Invalid number input ${input}`);
  return input.replace(/,/g, '.');
};

export const validateNumberInput = (input: string): boolean => {
  return /^\d*([.,]\d*)?$/.test(input);
};

export const validateNumberInputNonempty = (input: string): boolean => {
  return validateNumberInput(input) && input.trim() != '';
};
