import { trackedTaskStatusChoices } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader/MGLoader';
import { MGTooltip } from '@mg/ui/src/components/MGTooltip';
import { Box, IconButton, useTheme } from '@mui/material';
import {
  CircleAlertIcon,
  FileX2Icon,
  MailCheckIcon,
  MailIcon,
  MailWarningIcon,
  MailXIcon,
} from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { useDocumentContext } from '../../features/documents/document-context/document-context';
import { useDocumentEmailDialog } from './useDocumentEmailDialog';

const iconProps = { size: 18 };

export const DocumentsStatusAndQuickaction = () => {
  return (
    <Box sx={{ width: '36px', textAlign: 'center', flexShrink: 0, flexGrow: 0 }}>
      <Inner />
    </Box>
  );
};

const Inner = () => {
  const { palette } = useTheme();
  const { document } = useDocumentContext();
  const { t } = useTranslation('documents');

  if (!document.pdf) {
    return (
      <MGTooltip
        title={t('status_and_quickaction_unknown_error_title')}
        text={t('status_and_quickaction_unknown_error_subtitle')}
      >
        <CircleAlertIcon color={palette.error.main} {...iconProps} />
      </MGTooltip>
    );
  }

  if (document.pdf.is_ready) {
    return <EmailQuickaction />;
  }

  if (document.generate_pdf_task && !document.generate_pdf_task.is_finished) {
    return (
      <MGTooltip
        title={t('status_and_quickaction_generate_loading_title')}
        text={t('status_and_quickaction_generate_loading_subtitle')}
      >
        <div>
          <MGLoader size="20px" />
        </div>
      </MGTooltip>
    );
  } else {
    return (
      <MGTooltip
        title={t('status_and_quickaction_generate_error_title')}
        text={t('status_and_quickaction_generate_error_subtitle')}
      >
        <FileX2Icon color={palette.warning.main} {...iconProps} />
      </MGTooltip>
    );
  }
};

const EmailQuickaction = () => {
  const { document } = useDocumentContext();
  const { openSendDialog } = useDocumentEmailDialog();
  const { palette } = useTheme();
  const { t } = useTranslation('documents');

  if (document.delivery_in_progress)
    return (
      <MGTooltip
        title={t('status_and_quickaction_sending_document_title')}
        text={t('status_and_quickaction_sending_document_subtitle')}
      >
        <div>
          <MGLoader size="20px" />
        </div>
      </MGTooltip>
    );

  if (!document.customer) return null;

  if (document.delivered_to_customer)
    return (
      <MGTooltip
        title={t('status_and_quickaction_document_sent_title')}
        text={t('status_and_quickaction_document_sent_subtitle')}
      >
        <IconButton onClick={openSendDialog}>
          <MailCheckIcon color={palette.primary.main} {...iconProps} />
        </IconButton>
      </MGTooltip>
    );

  if (!document.customer.email)
    return (
      <MGTooltip
        title={t('status_and_quickaction_no_email_title')}
        text={t('status_and_quickaction_no_email_subtitle')}
      >
        <MailXIcon color={palette.error.main} {...iconProps} />
      </MGTooltip>
    );

  if (
    document.deliver_to_customer_task &&
    document.deliver_to_customer_task.status === trackedTaskStatusChoices.failure
  )
    return (
      <MGTooltip
        title={t('status_and_quickaction_send_document_title')}
        text={t('status_and_quickaction_send_document_again_subtitle')}
      >
        <IconButton onClick={openSendDialog}>
          <MailWarningIcon color={palette.warning.main} {...iconProps} />
        </IconButton>
      </MGTooltip>
    );

  return (
    <MGTooltip
      title={t('status_and_quickaction_send_document_title')}
      text={t('status_and_quickaction_send_document_subtitle')}
    >
      <IconButton onClick={openSendDialog}>
        <MailIcon color={palette.gray.main} {...iconProps} />
      </IconButton>
    </MGTooltip>
  );
};
