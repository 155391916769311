import { billingPreflightMeasuringPointOutSchema } from './billingPreflightMeasuringPointOutSchema.ts'
import { subscriptionPlanNestedSchemaSchema } from './subscriptionPlanNestedSchemaSchema.ts'
import { z } from 'zod'

export const billingPreflightSubscriptionOutSchema = z.object({
  consumption: z.number(),
  end: z.string().date(),
  measuring_points: z.array(z.lazy(() => billingPreflightMeasuringPointOutSchema)),
  start: z.string().date(),
  subscription_plan: z.lazy(() => subscriptionPlanNestedSchemaSchema),
})