import { palette } from '@mg/ui/src/styles';
import { RadialBar } from '@nivo/radial-bar';

export const ProgressChart = ({ value, label }) => {
  const Metric = ({ center }) => {
    return (
      <>
        <text
          x={center[0] - 0}
          y={center[1]}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: 50,
            fontFamily: 'Inter, sans-serif',
          }}
        >
          <tspan y={center[0]} style={{ fontSize: 38 }}>
            {value}
          </tspan>
          <tspan textAnchor={'left'} dy={'-6'} style={{ fontSize: 20 }}>
            %
          </tspan>
        </text>

        <text
          x={center[0]}
          y={center[1] + 19}
          textAnchor="middle"
          dominantBaseline="hanging"
          style={{
            fontSize: 12,
            fontWeight: 400,
            fontFamily: 'Inter, sans-serif',
          }}
        >
          {label}
        </text>
      </>
    );
  };
  return (
    <RadialBar
      width={120}
      height={120}
      maxValue={100}
      startAngle={0}
      endAngle={360}
      cornerRadius={100}
      innerRadius={0.85}
      colors={[palette.secondary.main]}
      isInteractive={false}
      data={[
        {
          // @ts-expect-error TS2741
          data: [{ y: value }],
        },
      ]}
      layers={['tracks', 'bars', Metric]}
    />
  );
};
