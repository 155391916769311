import { MeasuringPointDataSchema } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Stack, Typography } from '@mui/material';

export const BillingMeteringDataData = ({ data }: { data: MeasuringPointDataSchema }) => {
  return (
    <Stack direction={'column'} justifyContent={'center'} height={'100%'}>
      <Typography variant={'small'}>{presentKwh(data.value)}</Typography>
      <Typography variant="extraSmall" color={palette.gray.dark}>
        vom {presentDate(new Date(data.timestamp))}
      </Typography>
    </Stack>
  );
};
