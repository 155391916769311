import { Box, Stack } from '@mui/material';
import { MeteringDetailsMeasuringPoint } from './MeteringDetails.MeasuringPoint';
import { MeteringDetailsMeasuringPointData } from './MeteringDetails.MeasuringPointData';
import { MeteringDetailsMeasuringPointPanel } from './MeteringDetails.MeasuringPointPanel';

export const MeteringDetailsOverview = ({ panelId }) => {
  return (
    <Stack direction={'row'} spacing={2} height={'100%'}>
      <Stack flex={0.4} spacing={2} minHeight={'500px'}>
        <MeteringDetailsMeasuringPointPanel panelId={panelId} />
        <MeteringDetailsMeasuringPoint panelId={panelId} />
      </Stack>
      <Box flex={0.6}>
        <MeteringDetailsMeasuringPointData panelId={panelId} />
      </Box>
    </Stack>
  );
};
