import { z } from 'zod'

export const getInvoicePdfPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getInvoicePdf200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getInvoicePdf400Schema = z.string()

export const getInvoicePdfQueryResponseSchema = z.lazy(() => getInvoicePdf200Schema)