import { Box, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { TriangleAlert } from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { CreatePlanModal } from '../../modals/CreatePlanModal';
import { DataGridCardNoRowsOverlay } from '../../shared/DataGridCard/DataGridCard.NoRowsOverlay';
import { SoftIcon } from '../../shared/SoftIcon';

type Props = {
  type: 'plan' | 'residual_plan';
};
export const PlansNoRowsOverlay = ({ type }: Props) => {
  const { t } = useTranslation('plans');

  const [filterValue] = useAtom(filterValueAtom);

  if (filterValue.search) return <DataGridCardNoRowsOverlay />;

  return (
    <Box display={'flex'} height="100%" alignItems="center" justifyContent="center">
      <Stack
        width={'480px'}
        height={'300px'}
        textAlign={'center'}
        marginBottom={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={3}
      >
        <SoftIcon variant={'error'}>
          <TriangleAlert size={25} />
        </SoftIcon>
        <Stack spacing={0.5}>
          <Typography variant={'regular'} fontWeight={'600'}>
            {t(`no_rows_overlay_header_${type}`)}
          </Typography>
          <Typography variant={'small'} fontWeight={'300'}>
            {t(`no_rows_overlay_text_${type}`)}
          </Typography>
        </Stack>
        <CreatePlanModal buttonColor={'white'} defaultSelectedPage={type} />
      </Stack>
    </Box>
  );
};
