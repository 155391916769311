import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetSepaMandatePdfsAsZipQueryResponse, GetSepaMandatePdfsAsZipQueryParams, GetSepaMandatePdfsAsZip400 } from '../types/GetSepaMandatePdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSepaMandatePdfsAsZipQueryKey = (params: GetSepaMandatePdfsAsZipQueryParams) =>
  [{ url: '/api-internal/sepa_mandates/pdfs' }, ...(params ? [params] : [])] as const

export type GetSepaMandatePdfsAsZipQueryKey = ReturnType<typeof getSepaMandatePdfsAsZipQueryKey>

/**
 * @summary Get Sepa Mandate Pdfs As Zip
 * {@link /api-internal/sepa_mandates/pdfs}
 */
async function getSepaMandatePdfsAsZip(params: GetSepaMandatePdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetSepaMandatePdfsAsZipQueryResponse, GetSepaMandatePdfsAsZip400, unknown>({
    method: 'GET',
    url: `/api-internal/sepa_mandates/pdfs`,
    params,
    ...config,
  })
  return res.data
}

export function getSepaMandatePdfsAsZipQueryOptions(params: GetSepaMandatePdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getSepaMandatePdfsAsZipQueryKey(params)
  return queryOptions<GetSepaMandatePdfsAsZipQueryResponse, GetSepaMandatePdfsAsZip400, GetSepaMandatePdfsAsZipQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSepaMandatePdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Sepa Mandate Pdfs As Zip
 * {@link /api-internal/sepa_mandates/pdfs}
 */
export function useGetSepaMandatePdfsAsZip<
  TData = GetSepaMandatePdfsAsZipQueryResponse,
  TQueryData = GetSepaMandatePdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetSepaMandatePdfsAsZipQueryKey,
>(
  params: GetSepaMandatePdfsAsZipQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetSepaMandatePdfsAsZipQueryResponse, GetSepaMandatePdfsAsZip400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSepaMandatePdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getSepaMandatePdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSepaMandatePdfsAsZip400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}