type PresentNumberOptions = {
  locale?: string;
  suffix?: string;
  prefix?: string;
} & Intl.NumberFormatOptions;

const presentNumber = (value: number | string, options?: PresentNumberOptions): string => {
  const { locale, suffix, prefix, ...numberFormatOptions } = Object.assign(
    { locale: 'de-DE', style: 'decimal' } as PresentNumberOptions,
    options,
  );

  let numberValue: number;

  if (typeof value === 'number') numberValue = value;
  else if (typeof value === 'string' && value.includes('.')) numberValue = parseFloat(value);
  else numberValue = parseInt(value);

  return [prefix, new Intl.NumberFormat(locale, numberFormatOptions).format(numberValue), suffix]
    .filter(Boolean)
    .join(' ');
};

type ShorthandFunctionOptions = {
  locale?: string;
  fractionDigits?: number;
};

/**
 * Creates a shorthand function for presenting numbers with presentNumber(...).
 *
 * @example
 * const presentPercent = createShorthandFunction({ style: "percent", maximumFractionDigits: 2 });
 * presentPercent(0.1234);
 * // => "12,34 %"
 * presentPercent(0.1234, { locale: "en-US" });
 * // => "12.34 %"
 * presentPercent(0.1234, { fractionDigits: 1 });
 * // => "12,3 %"
 *
 */
function createShorthandFunction(defaultPresentNumberOptions: PresentNumberOptions) {
  return (value?: number | string | null, options?: ShorthandFunctionOptions) => {
    if (!value) return '-';
    const presentNumberOptions = defaultPresentNumberOptions;

    const shOpts = options || {};

    if (shOpts.fractionDigits !== undefined) {
      presentNumberOptions.maximumFractionDigits = shOpts.fractionDigits;
      presentNumberOptions.minimumFractionDigits = shOpts.fractionDigits;
    }

    if (shOpts.locale !== undefined) {
      presentNumberOptions.locale = shOpts.locale;
    }

    return presentNumber(value, presentNumberOptions);
  };
}

/**
 * Formats a number as percentage `%`.
 */
export const presentPercent = createShorthandFunction({
  style: 'percent',
  maximumFractionDigits: 2,
});

export const presentKwh = (value: number | string, options?: Omit<PresentNumberOptions, 'suffix'>) => {
  return presentNumber(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    suffix: 'kWh',
    ...options,
  });
};
export const presentKilowatt = createShorthandFunction({
  style: 'unit',
  unit: 'kilowatt',
  maximumFractionDigits: 3,
});

export const presentEuro = createShorthandFunction({
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
});

export const presentEuroPerKilowattHour = createShorthandFunction({
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  suffix: '/ kWh',
});

export const presentEuroPerYear = createShorthandFunction({
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  suffix: '/ Jahr',
});

export const presentEuroPerMonth = createShorthandFunction({
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  suffix: '/ Monat',
});

export const presentEuroCentPerKilowattHour = createShorthandFunction({
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  suffix: 'ct / kWh',
});
