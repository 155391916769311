import { useSendDocumentByEmail } from '@mg/api-wrappers/src/api-internal';
import { customerDisplayName } from '@mg/ui/src/presenters';
import { PresentableCustomer } from '@mg/ui/src/presenters/customer';
import { Box, Button, useTheme } from '@mui/material';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { Send } from 'lucide-react';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../i18n';
import { DeliverableDocument } from '../../features/documents/document-context/common';
import { useDocumentContext } from '../../features/documents/document-context/document-context';
import { fireSweetalert } from '../../modals/SweetalertModal';

export const useDocumentEmailDialog = () => {
  const { palette } = useTheme();
  const queryClient = useQueryClient();
  const { document, documentType, queryKey } = useDocumentContext();
  const { t, tString } = useTranslation('documents');

  const updateDocument = (updater: (doc: DeliverableDocument) => void) => {
    queryClient.setQueriesData<InfiniteData<{ items: DeliverableDocument[] }>>(
      { type: 'active', queryKey: queryKey.slice(0, 1) },
      (state) => {
        return produce(state, (draft) => {
          if (!draft) return;

          draft.pages.forEach((page) =>
            page.items.forEach((item) => {
              if (item.id === document.id) {
                updater(item);
              }
            }),
          );
        });
      },
    );
  };

  const sendTestEmailMutation = useSendDocumentByEmail({
    mutation: {
      onError: () => {
        toast.error(tString('email_dialog_sending_test_mail_error'));
      },
      onSuccess: () => {
        toast.success(tString('email_dialog_sending_test_mail_success'));
      },
    },
  });

  const sendEmailMutation = useSendDocumentByEmail({
    mutation: {
      onMutate: () => {
        updateDocument((doc) => (doc.delivery_in_progress = true));
      },
      onError: () => {
        toast.error(tString('email_dialog_sending_mail_error'));
        updateDocument((doc) => (doc.delivery_in_progress = false));
      },
      onSuccess: () => {
        toast.success(tString('email_dialog_sending_mail_success'));
        updateDocument((doc) => {
          doc.delivery_in_progress = false;
          doc.delivered_to_customer = true;
        });
      },
    },
  });

  const openSendDialog = () => {
    const html = (
      <Box>
        <Box mb={2}>
          {t(
            document.delivered_to_customer
              ? 'email_dialog_email_sent_already_to_recipient'
              : 'email_dialog_email_send_to_customer',
            {
              name: customerDisplayName(document.customer as PresentableCustomer),
              email: document.customer?.email,
            },
          )}
        </Box>
        <Box>
          <Button
            sx={{ marginLeft: '20px', textTransform: 'none' }}
            onClick={() =>
              sendTestEmailMutation.mutate({
                params: { id: document.id as number, document_type: documentType, is_test_mail: true },
              })
            }
            disabled={sendEmailMutation.isPending}
          >
            {t('email_dialog_send_test_email_button')}
          </Button>
        </Box>
      </Box>
    );
    fireSweetalert({
      title: t('email_dialog_sweetalert_title'),
      html,
      confirmButtonText: tString('email_dialog_sweetalert_confirm_button'),
      denyButtonColor: 'secondary',
      cancelButtonText: tString('email_dialog_sweetalert_cancel_button'),
      iconHtml: <Send color={palette.primary.main} size={35} />,
      iconColor: '#FFF0', // transparent to hide the icon border
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;

      sendEmailMutation.mutate({
        params: { id: document.id as number, document_type: documentType, is_test_mail: false },
      });
    });
  };

  return {
    openSendDialog,
  };
};
