import { measuringPointDataCreateInSchema } from './measuringPointDataCreateInSchema.ts'
import { measuringPointDataSchemaSchema } from './measuringPointDataSchemaSchema.ts'
import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { multiplePublicErrorsOutUnionDataNotMonotonicallyIncreasingDataForTimestampExistsSchema } from './multiplePublicErrorsOutUnionDataNotMonotonicallyIncreasingDataForTimestampExistsSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const createMeasuringPointData200Schema = z.lazy(() => measuringPointDataSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const createMeasuringPointData422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => multiplePublicErrorsOutUnionDataNotMonotonicallyIncreasingDataForTimestampExistsSchema),
  z.lazy(() => modelValidationErrorOutSchema),
])

export const createMeasuringPointDataMutationRequestSchema = z.lazy(() => measuringPointDataCreateInSchema)

export const createMeasuringPointDataMutationResponseSchema = z.lazy(() => createMeasuringPointData200Schema)