import { useGetContractHtml } from '@mg/api-wrappers/src/api-internal';
import { axiosInstance } from '@mg/api-wrappers/src/client';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Button, Paper, Stack } from '@mui/material';
import { Download } from 'lucide-react';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { downloadFileContent } from '../../../helpers/downloadFile';
import { useTranslation } from '../../../i18n';
import { ContractIFrame } from './Contract.IFrame';

export const Contract = () => {
  const { t } = useTranslation('contract');

  const [searchParams] = useSearchParams();
  const planIdStr = searchParams.get('plan');
  const planId = planIdStr ? parseInt(planIdStr) : undefined;

  const getHTML = useGetContractHtml({ plan_id: planId ?? -1 }, { query: { enabled: !!planId } });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isDownloading, setIsDownloading] = useState(false);

  const download = async () => {
    if (!planId) throw new Error('planId should be present here');

    if (iframeRef.current?.contentDocument) {
      const form = iframeRef.current.contentDocument.getElementById('form') as HTMLFormElement;
      const data = Object.fromEntries(new FormData(form).entries());

      setIsDownloading(true);
      try {
        const response = await axiosInstance.post(
          '/api-internal/contracts/render',
          { plan_id: planId, form_values: data },
          {
            responseType: 'blob',
          },
        );
        const fileName = response.headers['content-disposition'].split('filename=')[1];
        downloadFileContent(response.data, fileName);
      } catch (error) {
        console.error(error);
      }
      setIsDownloading(false);
    } else {
      // TODO: Handle error
    }
  };

  return (
    <MGPageLayout title={t('page_title')} subtitle={t('page_subtitle')}>
      <Stack spacing={2}>
        {getHTML.isSuccess || getHTML.isLoading ? (
          <div>
            <Paper square elevation={3} sx={{ height: 1000, maxHeight: '75vh', overflow: 'hidden' }}>
              {getHTML.isSuccess ? <ContractIFrame ref={iframeRef} html={getHTML.data.html} /> : <MGLoader />}
            </Paper>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                variant="contained"
                size="large"
                onClick={download}
                disabled={getHTML.isLoading || isDownloading}
                style={{ margin: '10px 0' }}
                startIcon={getHTML.isLoading || isDownloading ? <MGLoader size="30px" /> : <Download />}
              >
                {t('download_as_pdf')}
              </Button>
            </div>
          </div>
        ) : getHTML.isError ? (
          getHTML.error.type === 'https://metergrid.de/api-errors/render-contract/missing-data' ? (
            getHTML.error.errors.map((error) => (
              <MGAlert key={error} icon={'!'} severity={'error'} title={error} />
            ))
          ) : (
            <MGAlert icon={'!'} severity={'error'} title={getHTML.error.title} />
          )
        ) : null}
      </Stack>
    </MGPageLayout>
  );
};
