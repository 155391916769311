import { useState } from 'react';
import { MGControlledCollapsibleCard, MGControlledCollapsibleCardProps } from './MGControlledCollapsibleCard';

export type MGCollapsibleCardProps = {
  defaultOpen?: boolean;
} & Pick<MGControlledCollapsibleCardProps, 'collapsedContent' | 'children'>;

export const MGCollapsibleCard = ({ defaultOpen = false, ...props }: MGCollapsibleCardProps) => {
  const [expanded, setExpanded] = useState(defaultOpen);
  return <MGControlledCollapsibleCard {...props} expanded={expanded} onExpand={setExpanded} />;
};
