import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListLettersQueryResponse, ListLettersQueryParams, ListLetters422 } from '../types/ListLetters.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listLettersInfiniteQueryKey = (params: ListLettersQueryParams) => [{ url: '/api-internal/letters/' }, ...(params ? [params] : [])] as const

export type ListLettersInfiniteQueryKey = ReturnType<typeof listLettersInfiniteQueryKey>

/**
 * @summary List Letters
 * {@link /api-internal/letters/}
 */
async function listLetters(params: ListLettersQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListLettersQueryResponse, ListLetters422, unknown>({ method: 'GET', url: `/api-internal/letters/`, params, ...config })
  return res.data
}

export function listLettersInfiniteQueryOptions(params: ListLettersQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listLettersInfiniteQueryKey(params)
  return infiniteQueryOptions<ListLettersQueryResponse, ListLetters422, ListLettersQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListLettersQueryParams['page']
      }
      return listLetters(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Letters
 * {@link /api-internal/letters/}
 */
export function useListLettersInfinite<
  TData = InfiniteData<ListLettersQueryResponse>,
  TQueryData = ListLettersQueryResponse,
  TQueryKey extends QueryKey = ListLettersInfiniteQueryKey,
>(
  params: ListLettersQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListLettersQueryResponse, ListLetters422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listLettersInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listLettersInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListLetters422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}