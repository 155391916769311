import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { TriangleAlert } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardNoRowsOverlay } from '../../shared/DataGridCard/DataGridCard.NoRowsOverlay';
import { SoftIcon } from '../../shared/SoftIcon';

export const CustomersNoRowsOverlay = () => {
  const { t } = useTranslation('customers');

  const [filterValue] = useAtom(filterValueAtom);
  if (filterValue.search) return <DataGridCardNoRowsOverlay />;

  return (
    <Box display={'flex'} height="100%" alignItems="center" justifyContent="center">
      <Stack
        width={'480px'}
        height={'300px'}
        textAlign={'center'}
        marginBottom={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={3}
      >
        <SoftIcon variant={'error'}>
          <TriangleAlert size={25} />
        </SoftIcon>
        <Stack spacing={0.5}>
          <Typography variant={'regular'} fontWeight={'600'}>
            {t(`no_rows_overlay_header`)}
          </Typography>
          <Typography variant={'small'} fontWeight={'300'}>
            {t(`no_rows_overlay_text`)}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Button variant={'contained'} component={Link} to={'/import/customers'}>
            {t('no_rows_overlay_button_import_customer')}
          </Button>
          <Button variant={'contained'} color={'white'} component={Link} to={'/customers/create/'}>
            {t('no_rows_overlay_button_create_customer')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
