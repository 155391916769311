import { palette } from '@mg/ui/src/styles';
import {
  FormControlLabel,
  FormGroup,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { IntegrationBaseProps } from './IntegrationBaseProps';

type SwitchProps = IntegrationBaseProps & {
  switchProps?: MuiSwitchProps;
};

export const Switch = ({ name, label, control, switchProps }: SwitchProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormGroup>
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': { color: palette.text.black },
              }}
              control={
                <MuiSwitch
                  checked={field.value}
                  onChange={(event) => field.onChange(event.target.checked)}
                  disabled={field.disabled}
                  inputRef={field.ref}
                  name={field.name}
                  {...switchProps}
                />
              }
              label={label}
            />
          </FormGroup>
        );
      }}
    ></Controller>
  );
};
