import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListNotificationsQueryResponse, ListNotificationsQueryParams, ListNotifications422 } from '../types/ListNotifications.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listNotificationsQueryKey = (params: ListNotificationsQueryParams) =>
  [{ url: '/api-internal/notifications/' }, ...(params ? [params] : [])] as const

export type ListNotificationsQueryKey = ReturnType<typeof listNotificationsQueryKey>

/**
 * @summary List Notifications
 * {@link /api-internal/notifications/}
 */
async function listNotifications(params: ListNotificationsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListNotificationsQueryResponse, ListNotifications422, unknown>({
    method: 'GET',
    url: `/api-internal/notifications/`,
    params,
    ...config,
  })
  return res.data
}

export function listNotificationsQueryOptions(params: ListNotificationsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listNotificationsQueryKey(params)
  return queryOptions<ListNotificationsQueryResponse, ListNotifications422, ListNotificationsQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listNotifications(params, config)
    },
  })
}

/**
 * @summary List Notifications
 * {@link /api-internal/notifications/}
 */
export function useListNotifications<
  TData = ListNotificationsQueryResponse,
  TQueryData = ListNotificationsQueryResponse,
  TQueryKey extends QueryKey = ListNotificationsQueryKey,
>(
  params: ListNotificationsQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListNotificationsQueryResponse, ListNotifications422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listNotificationsQueryKey(params)

  const query = useQuery({
    ...(listNotificationsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListNotifications422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}