import { MGDateRadioButton } from '@mg/ui/src/components/MGDateRadioButton/MGDateRadioButton';
import { MGTextRadioButton } from '@mg/ui/src/components/MGTextRadioButton';
import { Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtom } from 'jotai/index';
import { billingMolecule } from '../../Billing.Atoms';

export const BillingBillingPeriodCardFormEndDateSelect = () => {
  const { endDateAtom, endDurationAtom } = useMolecule(billingMolecule);
  const [endDate, setEndDate] = useAtom(endDateAtom);
  const [endDuration, setEndDuration] = useAtom(endDurationAtom);

  return (
    <Stack spacing={2}>
      <Typography variant={'small'} fontWeight={'fontWeightBold'}>
        Enddatum der Abrechnung
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <MGTextRadioButton
          label={'Bis Ende letzten Jahres'}
          selected={endDuration === 'LAST_YEAR'}
          onClick={() => {
            setEndDate(null);
            setEndDuration('LAST_YEAR');
          }}
        />
        <MGTextRadioButton
          label={'12M nach letzter Abrechnung'}
          selected={endDuration === '12MONTHS'}
          onClick={() => {
            setEndDate(null);
            setEndDuration('12MONTHS');
          }}
        />
        <MGDateRadioButton
          label={'Individuelles Ende'}
          selected={endDuration === 'DATE'}
          value={endDate}
          onChange={(date) => {
            if (endDuration !== 'DATE') {
              setEndDuration('DATE');
            }
            setEndDate(date ?? null);
          }}
        />
      </Stack>
    </Stack>
  );
};
