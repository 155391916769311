import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { BillingMutationRequest, BillingMutationResponse, Billing400, Billing422 } from '../types/Billing.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const billingMutationKey = () => [{ url: '/api-internal/billing/billing' }] as const

export type BillingMutationKey = ReturnType<typeof billingMutationKey>

/**
 * @summary Billing
 * {@link /api-internal/billing/billing}
 */
async function billing(data?: BillingMutationRequest, config: Partial<RequestConfig<BillingMutationRequest>> = {}) {
  const res = await client<BillingMutationResponse, Billing400 | Billing422, BillingMutationRequest>({
    method: 'POST',
    url: `/api-internal/billing/billing`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Billing
 * {@link /api-internal/billing/billing}
 */
export function useBilling(
  options: {
    mutation?: UseMutationOptions<BillingMutationResponse, Billing400 | Billing422, { data?: BillingMutationRequest }>
    client?: Partial<RequestConfig<BillingMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? billingMutationKey()

  return useMutation<BillingMutationResponse, Billing400 | Billing422, { data?: BillingMutationRequest }>({
    mutationFn: async ({ data }) => {
      return billing(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}