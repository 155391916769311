import { useGridApiRef } from '@mui/x-data-grid-pro';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useHydrateAtoms } from 'jotai/utils';
import { PropsWithChildren, useEffect } from 'react';
import { ExtendedDataGridApiRef } from './DataGridRef';

type ApiAtom = {
  dataGridApiRef: ExtendedDataGridApiRef;
};
export const apiAtom = atom<ApiAtom | undefined>(undefined);

export const useGridApi = () => {
  const api = useAtomValue(apiAtom);
  if (!api) throw new Error('useGridApi can only be used inside a DataGridApiProvider');
  return api;
};

export const DataGridApiProvider = ({ children }) => {
  const parentApi = useAtomValue(apiAtom);
  if (parentApi) throw new Error("DataGridApiProvider can't be nested");

  const dataGridApiRef = useGridApiRef() as ExtendedDataGridApiRef;

  const api = {
    dataGridApiRef,
  };

  return (
    <ScopeProvider atoms={[apiAtom]}>
      <DataGridApiProviderInner api={api}>{children}</DataGridApiProviderInner>
    </ScopeProvider>
  );
};

const DataGridApiProviderInner = ({ children, api }: PropsWithChildren<{ api: ApiAtom }>) => {
  useHydrateAtoms([[apiAtom, api]]);
  const setApi = useSetAtom(apiAtom);

  useEffect(() => {
    setApi(api);
    return () => {
      setApi(undefined);
    };
  }, [api, setApi]);

  return <>{children}</>;
};
