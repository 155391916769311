import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const letterSchemaSchema = z.object({
  body: z.union([z.string(), z.null()]).describe('The content of the letter, without salutation.').optional(),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  issue_date: z.string().date().describe('The date when the letter was issued').optional(),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
  subject: z.union([z.string(), z.null()]).describe('The subject of the letter').optional(),
  subscription: z.union([z.number().int(), z.null()]).describe('The subscription for which the letters pdf will be generated.').optional(),
})