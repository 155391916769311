import { palette } from '@mg/ui/src/styles';
import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

type CheckboxFieldsetContainerProps = {
  title: ReactNode;
  height?: string | number;
  itemCount?: number;
  error?: ReactNode;
  selectAllLabel?: ReactNode;
  deselectAllLabel?: ReactNode;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  disableSelectAll?: boolean;
  disableDeselectAll?: boolean;
} & PropsWithChildren;

export const CheckboxFieldsetContainer = ({
  title,
  itemCount,
  height,
  error,
  selectAllLabel,
  onSelectAll,
  deselectAllLabel,
  onDeselectAll,
  children,
}: CheckboxFieldsetContainerProps) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" pb={2}>
        <Typography color={palette.text.black} fontWeight={400}>
          {title} {itemCount && <Chip label={itemCount} color="primary" size="small" />}
        </Typography>
        {(selectAllLabel || deselectAllLabel) && (
          <Stack direction={'row'} gap={0.5}>
            {selectAllLabel && (
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  event.preventDefault();
                  onSelectAll?.();
                }}
                variant="small"
                color={palette.text.gray}
                underline="hover"
              >
                {selectAllLabel}
              </Link>
            )}
            {selectAllLabel && deselectAllLabel && <Typography variant="small">|</Typography>}
            {deselectAllLabel && (
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  event.preventDefault();
                  onDeselectAll?.();
                }}
                variant="small"
                color={palette.text.gray}
                underline="hover"
              >
                {deselectAllLabel}
              </Link>
            )}
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          border: `${error ? palette.error.main : palette.gray.main} 1px solid`,
          borderRadius: '16px',
          pl: 1,
          pr: 2,
          py: 1,
        }}
      >
        <Box sx={{ maxHeight: height, overflowY: 'scroll' }}>{children}</Box>
      </Stack>

      {error && (
        <Typography color={palette.error.main} variant="small" mt={1} component={'p'}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
