import { Typography } from '@mui/material';
import { ValidationError } from '@tanstack/react-form';

type Props = {
  errors: ValidationError[];
};
export const MGFieldError = ({ errors = [] }: Props) => {
  if (!errors.length) {
    return null;
  }
  return (
    <Typography
      color={'error'}
      variant={'extraSmall'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      display={'flex'}
    >
      {errors.join(', ')}
    </Typography>
  );
};
