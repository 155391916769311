import { forwardRef } from 'react';
import Frame from 'react-frame-component';

type ContractIFrameProps = {
  html: string;
};

export const ContractIFrame = forwardRef<HTMLIFrameElement, ContractIFrameProps>(({ html }, ref) => {
  return (
    <Frame
      ref={ref}
      style={{ border: 'none', margin: 0, padding: 0, width: '100%', height: '100%' }}
      initialContent={html}
    >
      <div></div>
    </Frame>
  );
});
