import { palette } from '@mg/ui/src/styles';
import { Typography } from '@mui/material';
import { MuiBaseModal, MuiBaseModalProps } from '../../modals/MuiBaseModal';
import { useMultiStepPanelContext } from '../MultiStepPanel/MultiStepPanel.Context';

export const MultiStepModalBaseModal = ({ children, ...muiBaseModalProps }: MuiBaseModalProps) => {
  const { currentStep } = useMultiStepPanelContext();

  return (
    <MuiBaseModal
      height={'auto'}
      width={'910px'}
      position={{ anchorTop: 'center' }}
      PaperStyle={{ overflow: 'hidden', padding: '40px', paddingRight: '20px', paddingBottom: '20px' }}
      BodyStyle={{ overflow: 'hidden', paddingRight: '20px' }}
      title={
        currentStep.title ? (
          <Typography variant="h2" color={palette.text.black}>
            {currentStep.title}
          </Typography>
        ) : undefined
      }
      showCloseButton
      {...muiBaseModalProps}
    >
      {children}
    </MuiBaseModal>
  );
};
