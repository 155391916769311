import { useGetCustomer } from '@mg/api-wrappers/src/api-internal';
import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../../i18n';
import { DataGridApiProvider } from '../../../shared/DataGridCard/useGridApi';
import { CustomerDetailsAccounting } from './Accounting/CustomerDetails.Accounting';
import { CustomerDetailsContracts } from './Contracts/CustomerDetails.Contracts';
import { CustomerDetailsData } from './Data/CustomerDetails.Data';
import { CustomerDetailsDocuments } from './Documents/CustomerDetails.Documents';
import { CustomerDetailsMetering } from './Metering/CustomerDetails.Metering';

export const CustomerDetailsPages = ({ currentTab }) => {
  const { t } = useTranslation('customerDetails');

  const params = useParams();

  const { isLoading, data } = useGetCustomer(parseInt(params.id!), {
    include_address: true,
    include_postal_address: true,
    include_bank_account: true,
    include_measuring_point_panel: true,
    include_total_open_amount: true,
    include_subscription: true,
    include_total_settled_amount: true,
    include_current_plan: true,
    include_active_sepa_mandate_id: true,
  });

  const getContent = () => {
    switch (currentTab) {
      case 'data':
        return <CustomerDetailsData isLoading={isLoading} customer={data} />;
      case 'contract':
        return <CustomerDetailsContracts customer={data} />;
      case 'metering':
        return <CustomerDetailsMetering panelId={data?.measuring_point_panel!.id as number} />;
      case 'documents':
        return <CustomerDetailsDocuments customerId={data?.id as number} />;
      case 'transactions':
        return <CustomerDetailsAccounting customerId={data?.id as number} />;
    }
  };

  return (
    <DataGridApiProvider>
      <Box maxHeight={'calc(100vh - 320px)'}>
        <Stack spacing={3}>
          <Typography variant={'h2'}>{t(`tabs_${currentTab}`)}</Typography>
          {getContent()}
        </Stack>
      </Box>
    </DataGridApiProvider>
  );
};
