import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { palette } from '../../styles/palette';

type Props = {
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
};
export const MGCardHeader = ({ leftHeader, rightHeader }: Props) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Box color={palette.primary.main}>{leftHeader}</Box>
      <Box>{rightHeader}</Box>
    </Stack>
  );
};
