import { palette } from '@mg/ui/src/styles';
import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../i18n';

type Props = {
  label?: ReactNode;
  text: ReactNode;
  color?: 'primary' | 'success' | 'error' | 'warning' | 'purple' | 'white' | 'gray';
  onButtonClick?: () => void;
  buttonSize?: 'large' | 'small' | 'medium';
  hideButton?: boolean;
  buttonText?: ReactNode;
  title?: ReactNode;
  spacing?: number;
  icon?: ReactElement;
  rightSection?: ReactElement;
  href?: string;
};

/**
 * @deprecated Use MGCard instead
 * */
export const DisplayCard = ({
  label,
  text,
  color = 'primary',
  onButtonClick,
  title,
  buttonSize = 'large',
  spacing = 3,
  icon,
  hideButton,
  buttonText,
  rightSection,
  href,
}: Props) => {
  const { t } = useTranslation('plans');

  return (
    <Card sx={{ padding: '20px', flex: 1 }}>
      <Stack spacing={spacing} height={'100%'} justifyContent={'space-between'}>
        <Box display={'flex'} width={'100%'}>
          <Stack
            direction={'row'}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {icon || <Chip label={label} variant={'soft'} color={color} />}
            {rightSection}
          </Stack>
        </Box>
        {title && (
          <Box display={'flex'} alignItems={'flex-start'}>
            <Typography variant={'regular'} fontWeight={'600'}>
              {title}
            </Typography>
          </Box>
        )}
        <Box display={'flex'} alignItems={'flex-start'} height={'100%'}>
          <Typography variant={'small'} color={palette.gray.dark}>
            {text}
          </Typography>
        </Box>
        {!hideButton && (
          <Box display={'flex'} alignItems={'flex-end'} height={'100%'}>
            <Button
              variant={'contained'}
              onClick={onButtonClick}
              to={href}
              component={onButtonClick ? Button : Link}
              endIcon={<ArrowRight size={18} />}
              size={buttonSize}
              color={color}
            >
              {buttonText || t('select_and_continue')}
            </Button>
          </Box>
        )}
      </Stack>
    </Card>
  );
};
