import { Box, useTheme } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

type Props = {
  size?: number;
  variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'gray' | 'white';
  children: ReactNode;
  shape?: 'round' | 'square';
};

/** @deprecated Use MGIcon from lib/ui */
export const SoftIcon = forwardRef(
  ({ size = 50, variant = 'primary', children, shape, ...props }: Props, ref) => {
    const theme = useTheme();

    const backgroundColor = theme.palette[variant]?.light || theme.palette.gray[300];
    const color =
      variant === 'white'
        ? theme.palette[variant]?.contrastText
        : theme.palette[variant]?.main || theme.palette.gray[700];

    return (
      <Box
        {...props}
        ref={ref}
        sx={{
          width: `${size}px !important`,
          height: `${size}px !important`,
          color,
          backgroundColor,
          borderRadius: shape === 'square' ? '5px' : size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    );
  },
);
