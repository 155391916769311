import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetContractHtmlQueryResponse, GetContractHtmlQueryParams, GetContractHtml400, GetContractHtml422 } from '../types/GetContractHtml.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getContractHtmlQueryKey = (params: GetContractHtmlQueryParams) => [{ url: '/api-internal/contracts/html' }, ...(params ? [params] : [])] as const

export type GetContractHtmlQueryKey = ReturnType<typeof getContractHtmlQueryKey>

/**
 * @summary Get Contract Html
 * {@link /api-internal/contracts/html}
 */
async function getContractHtml(params: GetContractHtmlQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetContractHtmlQueryResponse, GetContractHtml400 | GetContractHtml422, unknown>({
    method: 'GET',
    url: `/api-internal/contracts/html`,
    params,
    ...config,
  })
  return res.data
}

export function getContractHtmlQueryOptions(params: GetContractHtmlQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getContractHtmlQueryKey(params)
  return queryOptions<GetContractHtmlQueryResponse, GetContractHtml400 | GetContractHtml422, GetContractHtmlQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getContractHtml(params, config)
    },
  })
}

/**
 * @summary Get Contract Html
 * {@link /api-internal/contracts/html}
 */
export function useGetContractHtml<
  TData = GetContractHtmlQueryResponse,
  TQueryData = GetContractHtmlQueryResponse,
  TQueryKey extends QueryKey = GetContractHtmlQueryKey,
>(
  params: GetContractHtmlQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetContractHtmlQueryResponse, GetContractHtml400 | GetContractHtml422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getContractHtmlQueryKey(params)

  const query = useQuery({
    ...(getContractHtmlQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetContractHtml400 | GetContractHtml422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}