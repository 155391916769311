import {
  CustomerSchema,
  SubscriptionPlanSchema,
  useListSubscriptionPlans,
} from '@mg/api-wrappers/src/api-internal';
import { Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../../../i18n';
import { presentDate } from '../../../../../presenters/date';
import { presentEuroCentPerKilowattHour, presentEuroPerYear } from '../../../../../presenters/number';
import { CustomerDetailsContractsCard } from './CustomerDetails.Contracts.Card';
import { CustomerDetailsContractsPlanActionMenu } from './CustomerDetails.Contracts.Plan.ActionMenu';
import { CustomerDetailsContractsSwitchPlanModal } from './CustomerDetails.Contracts.SwitchPlanModal';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsContractsPlans = ({ customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const navigate = useNavigate();
  const subscriptionPlanQuery = useListSubscriptionPlans(
    { subscription_id: customer?.subscription?.id as number, order_by: '-valid_from' },
    { query: { enabled: !!customer } },
  );

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    SubscriptionPlanSchema | undefined
  >(undefined);

  useEffect(() => {
    const activeSubscriptionPlan = subscriptionPlanQuery.data?.items?.find((plan) => plan?.is_active);
    setSelectedSubscriptionPlan(activeSubscriptionPlan || subscriptionPlanQuery.data?.items[0]);
  }, [subscriptionPlanQuery.data]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography>{t('contracts_plan')}</Typography>
        <CustomerDetailsContractsSwitchPlanModal
          subscriptionPlans={subscriptionPlanQuery.data?.items}
          initiallySelectedPlanId={selectedSubscriptionPlan?.id}
          onSelect={(planId) =>
            setSelectedSubscriptionPlan(
              subscriptionPlanQuery.data?.items?.find((plan) => plan?.id === planId),
            )
          }
        />
      </Stack>
      <CustomerDetailsContractsCard
        isLoading={subscriptionPlanQuery.isLoading || !selectedSubscriptionPlan}
        title={
          <Typography variant={'h4'} color={'text.secondary'}>
            {customer?.current_plan?.name || 'Vertrag'}
          </Typography>
        }
        alertTitle={t('contracts_card_plan_alert_title')}
        alertButtonClick={() => navigate(`/customers/${customer?.id}/subscription/edit/`)}
        icon={
          !selectedSubscriptionPlan ? (
            <div></div>
          ) : (
            <Chip
              color={selectedSubscriptionPlan?.is_active ? 'success' : 'error'}
              label={selectedSubscriptionPlan?.is_active ? 'Aktiv' : 'Inaktiv'}
              sx={{ borderRadius: '8px' }}
              size={'small'}
            />
          )
        }
        actionMenu={<CustomerDetailsContractsPlanActionMenu customerId={customer?.id} />}
        rows={
          subscriptionPlanQuery.isLoading || !selectedSubscriptionPlan
            ? []
            : [
                {
                  label: t('contracts_card_base_price'),
                  value: presentEuroPerYear(selectedSubscriptionPlan?.plan?.price_base),
                },
                ...(selectedSubscriptionPlan?.plan?.plan_components?.map((component) => ({
                  label: t(`calculation_basis_${component.calculation_basis}`),
                  value: presentEuroCentPerKilowattHour(component.price_per_kwh),
                })) as []),
                {
                  label: t('contracts_card_cancellation_terms'),
                  value: t(`cancel_period_${selectedSubscriptionPlan?.plan?.cancel_period}`),
                },
                {
                  label: t('contracts_card_duration'),
                  value: t(`duration_${selectedSubscriptionPlan?.plan?.duration}`),
                },
                {
                  label: t('contracts_card_delivery_period'),
                  value: selectedSubscriptionPlan.valid_to
                    ? `${presentDate(selectedSubscriptionPlan.valid_from)} - ${presentDate(selectedSubscriptionPlan.valid_to)}`
                    : `Ab ${presentDate(selectedSubscriptionPlan.valid_from)}`,
                },
              ]
        }
      />
    </>
  );
};
