import { IconButton, IconButtonProps, Stack } from '@mui/material';
import { LucideProps } from 'lucide-react';
import { createElement, JSXElementConstructor, PropsWithChildren } from 'react';
import { useSlate } from 'slate-react';
import { EditorApi } from './MGRichTextEditor.EditorApi';

type MGRichTextEditorToolbarProps = PropsWithChildren;

export const MGRichTextEditorToolbar = ({ children }: MGRichTextEditorToolbarProps) => (
  <Stack direction={'row'} gap={1}>
    {children}
  </Stack>
);

const MGRichTextEditorToolbarButton = ({ ...props }: IconButtonProps) => (
  <IconButton
    {...props}
    sx={{
      borderRadius: 1,
      boxShadow: '0px 0.5px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
      border: '1px solid rgba(5, 28, 44, 0.04)',
      background: '#FFF',
      ...props?.sx,
    }}
  />
);

export const MGRichTextEditorToolbarMarkButton = ({
  format,
  icon,
}: {
  format: string;
  icon: JSXElementConstructor<LucideProps>;
}) => {
  const editor = useSlate();
  const iconEl = createElement(icon, { size: 24 });
  return (
    <MGRichTextEditorToolbarButton
      onClick={(event) => {
        event.preventDefault();
        EditorApi.toggleMark(editor, format);
      }}
    >
      {iconEl}
    </MGRichTextEditorToolbarButton>
  );
};
MGRichTextEditorToolbar.MarkButton = MGRichTextEditorToolbarMarkButton;

export const MGRichTextEditorToolbarBlockButton = ({
  icon,
}: {
  format: string;
  icon: JSXElementConstructor<LucideProps>;
}) => {
  const iconEl = createElement(icon, { size: 24 });

  return <MGRichTextEditorToolbarButton>{iconEl}</MGRichTextEditorToolbarButton>;
};
MGRichTextEditorToolbar.BlockButton = MGRichTextEditorToolbarBlockButton;
