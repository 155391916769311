import { pagedPlanSchemaSchema } from './pagedPlanSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listPlansQueryParamsSchema = z.object({
  include_active_subscriptions: z.boolean().default(false),
  include_expired_subscriptions: z.boolean().default(false),
  include_planned_subscriptions: z.boolean().default(false),
  search: z.union([z.string(), z.null()]).optional(),
  project_id: z.number().int(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listPlans200Schema = z.lazy(() => pagedPlanSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const listPlans422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listPlansQueryResponseSchema = z.lazy(() => listPlans200Schema)