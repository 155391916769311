import { palette } from '@mg/ui/src/styles';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useTranslation } from '../../../i18n';

export const BillingStepper = () => {
  const { t } = useTranslation('billing');

  enum Steps {
    'billing',
    'metering-data',
    'details-per-customer',
  }

  const step = Steps[window.location.pathname.split('/').pop() as string];
  return (
    <Stepper activeStep={step} orientation={'horizontal'} sx={{ paddingX: 10 }}>
      <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
        <StepLabel>
          <Typography variant={'small'}>{t('step_billing_period')}</Typography>
        </StepLabel>
      </Step>
      <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
        <StepLabel>
          <Typography variant={'small'}>{t('step_metering_data')}</Typography>
        </StepLabel>
      </Step>
      <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
        <StepLabel>
          <Typography variant={'small'}>{t('step_details_per_customer')}</Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );
};
