import { PlanChangeDocumentFilter, useListCustomers } from '@mg/api-wrappers/src/api-internal';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardFilterForm } from '../../shared/DataGridCard/DataGridCard.FilterForm';
import { DeclarativeFormFieldType } from '../../shared/DeclarativeForm';
type CustomPlanChangeFilter = Partial<PlanChangeDocumentFilter>;

type PlanChangeFilterFormValues = {
  customer: string;
  validFromDateRange: [Dayjs | null, Dayjs | null];
};

export const DocumentsPlanChangeTableFilterForm = () => {
  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);

  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t, tString } = useTranslation('documents');

  const createInitialFormValues: () => PlanChangeFilterFormValues = () => ({
    customer: '',
    validFromDateRange: [null, null],
  });

  const customerOptions = useMemo(() => {
    if (!customersQuery.data) return [];

    return customersQuery.data?.items
      .map((customer) => ({ value: customer.id + '', label: customer.display_name + '' }))
      .filter((option, optionIndex, self) => self.findIndex((o) => o.value === option.value) === optionIndex);
  }, [customersQuery.data]);

  return (
    <>
      <DataGridCardFilterForm
        formId={'plan-change-table-filter'}
        formTitle={t('plan_change_filter_title')}
        filter={filter}
        onFilter={(filter) => {
          setFilterValue(filter);
          setFilterModalOpen(false);
        }}
        initialValues={createInitialFormValues()}
        serialize={(formValues: PlanChangeFilterFormValues) => {
          const customerId = parseInt(formValues.customer);

          const result: CustomPlanChangeFilter = {};

          if (customerId) result.customer = customerId;

          if (formValues.validFromDateRange[0])
            result.valid_from_from = (formValues.validFromDateRange[0] as Dayjs)?.toDate().toISOString();

          if (formValues.validFromDateRange[1])
            result.valid_from_to = (formValues.validFromDateRange[1] as Dayjs)?.toDate().toISOString();

          return result;
        }}
        deserialize={(filter: CustomPlanChangeFilter) => {
          const formValues: PlanChangeFilterFormValues = createInitialFormValues();

          if (filter.customer) formValues.customer = filter.customer + '';
          if (filter?.valid_from_from)
            formValues.validFromDateRange[0] = dayjs(new Date(filter.valid_from_from));
          if (filter?.valid_from_to) formValues.validFromDateRange[1] = dayjs(new Date(filter.valid_from_to));

          return formValues;
        }}
        fieldDefinitions={[
          {
            name: 'customer',
            label: t('filter_customer_label'),
            innerLabel: t('filter_customer_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: customerOptions,
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'validFromDateRange',
            label: t('plan_change_filter_valid_from_date_range_label'),
            type: DeclarativeFormFieldType.DateRange,
            dateRangePickerInputProps: { clearable: true },
          },
        ]}
      />
    </>
  );
};
