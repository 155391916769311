import { Box, Stack } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Login } from '../components/pages/Login/Login.tsx';
import { NewPassword } from '../components/pages/NewPassword/NewPassword.tsx';
import { ResetPassword } from '../components/pages/ResetPassword/ResetPassword.tsx';

export const UnprotectedAppLayout = () => {
  const location = useLocation();
  return (
    <Stack direction={'row'} height={'100vh'}>
      <Box
        width={'50vw'}
        sx={{
          background: 'var(--gradient, linear-gradient(99deg, #0032A0 0%, #002574 100%))',
          position: 'relative',
        }}
      >
        <img
          src={'/static/img/metergrid_logo_white.svg'}
          style={{ position: 'absolute', top: 58, left: 53 }}
          alt={'Logo'}
        />
        <img
          src={'/static/img/login_img.svg'}
          alt={'Login'}
          style={{ position: 'absolute', bottom: 0, right: 0, height: '80vh', objectFit: 'cover' }}
        />
      </Box>
      <Box width={'50vw'} display={'flex'} justifyContent={'center'}>
        <Stack width={'28vw'} maxWidth={'500px'} justifyContent={'center'} spacing={5}>
          <Routes location={location} key={location.pathname}>
            <Route path={'/'} element={<Login />} />
            <Route path={'/reset-password'} element={<ResetPassword />} />
            <Route path={'/new-password'} element={<NewPassword />} />
          </Routes>
        </Stack>
      </Box>
    </Stack>
  );
};
