import { useGetTransactionStatistics, useListTransactions } from '@mg/api-wrappers/src/api-internal';
import { customerDisplayName, presentEuro } from '@mg/ui/src/presenters';
import { PresentableCustomer } from '@mg/ui/src/presenters/customer';
import { Avatar, AvatarGroup, Box, Button, Skeleton, Typography } from '@mui/material';
import { Check, CreditCard } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { SoftIcon } from '../../../shared/SoftIcon';
import { DashboardCard } from '../Dashboard.Card';

export const DashboardQuickActionsTransactionsCard = () => {
  const { t } = useTranslation('dashboard');
  const projectFilter = useSelectedProjectsFilter();
  const transactionQuery = useListTransactions({
    ...projectFilter,
    state: 'pending',
    include_customer: true,
  });
  const transactionStatsQuery = useGetTransactionStatistics({ ...projectFilter });
  const countOpenTransactions = transactionQuery.data?.total_items || 0;

  const AdditionalContent = () => {
    if (countOpenTransactions === 0) {
      return (
        <Box display={'flex'}>
          <SoftIcon variant={'success'} size={24}>
            <Check size={12} />
          </SoftIcon>
          <Typography variant={'small'} color={'secondary'} sx={{ marginLeft: '10px' }}>
            {t('quick_actions_transactions_subtitle_all_good')}
          </Typography>
        </Box>
      );
    }
    return (
      <Box display={'flex'} justifyContent={'flex-start'}>
        <AvatarGroup
          max={6}
          total={countOpenTransactions}
          slotProps={{
            additionalAvatar: {
              sx: {
                width: '32px',
                height: '32px',
                fontSize: '14px',
                backgroundColor: 'error.main',
                color: 'error.light',
              },
            },
          }}
        >
          {transactionQuery.data?.items.map((transaction) => (
            <Avatar
              key={`${transaction?.customer?.display_name || ''}  ${transaction.id}`}
              sx={{
                width: '32px',
                height: '32px',
                fontSize: '14px',
                backgroundColor: 'error.light',
                color: 'error.main',
              }}
            >
              {customerDisplayName(transaction?.customer as PresentableCustomer)[0]}
            </Avatar>
          ))}
        </AvatarGroup>
      </Box>
    );
  };
  return (
    <DashboardCard
      title={t('quick_actions_transactions_title')}
      loading={transactionQuery.isLoading || transactionStatsQuery.isLoading}
      subtitle={
        countOpenTransactions === 0 ? (
          t('quick_actions_transactions_subtitle_all_good')
        ) : (
          <Box>
            <Typography variant={'small'}>
              {t('quick_actions_transactions_subtitle_open_transactions', { count: countOpenTransactions })}
            </Typography>
            <Typography variant={'small'} color={'error'}>
              {presentEuro(transactionStatsQuery.data?.open_amount)}
            </Typography>
          </Box>
        )
      }
      icon={<CreditCard />}
      additionalContent={<AdditionalContent />}
      additionalContentLoadingComponent={<Skeleton variant={'rounded'} height={35} sx={{ width: '100%' }} />}
      button={
        <Button
          variant={'contained'}
          color={countOpenTransactions === 0 ? 'white' : 'primary'}
          component={Link}
          to={'/transactions?tab=pending'}
        >
          {t('quick_actions_transactions_last_button')}
        </Button>
      }
    />
  );
};
