import { z } from 'zod'

export const providerNestedSchemaSchema = z.object({
  chairperson_advisory_board: z.union([z.string(), z.null()]).describe('Several persons can be entered comma separated.').optional(),
  chairperson_supervisory_board: z.union([z.string(), z.null()]).describe('Several persons can be entered comma separated.').optional(),
  commercial_register_number: z.union([z.string(), z.null()]).optional(),
  company: z.string().max(128).default(''),
  creditor_identifier: z
    .union([z.string(), z.null()])
    .describe('An identifier within SEPA that provides a clear indication of the creditor without making reference to an account.')
    .optional(),
  email: z.union([z.string(), z.null()]).optional(),
  first_name: z.union([z.string(), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  invoice_type: z.string().max(128).default('ENWG_2021_COMPLIANT').describe('Issued invoices are compliant with these regulations.'),
  last_name: z.union([z.string(), z.null()]).optional(),
  managing_directors: z.union([z.string(), z.null()]).describe('Several persons can be entered comma separated.').optional(),
  register_court: z.union([z.string(), z.null()]).optional(),
  salutation: z.string().max(128).default('NONE'),
  tax_liability: z.union([z.boolean(), z.null()]).describe('Indicates if the provider is liable to tax (VAT).').optional(),
  tax_number: z.union([z.string(), z.null()]).optional(),
  vat_number: z.union([z.string(), z.null()]).optional(),
})