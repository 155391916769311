import 'dayjs/locale/de';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'vite/modulepreload-polyfill';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import './api/interceptors';
import { App } from './App';
import './i18n';
import './index.css';

z.setErrorMap(zodI18nMap);

createRoot(document.getElementById('react-app')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
