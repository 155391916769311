import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ExportTransactionsQueryResponse, ExportTransactions400 } from '../types/ExportTransactions.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const exportTransactionsQueryKey = () => [{ url: '/api-internal/transactions/export' }] as const

export type ExportTransactionsQueryKey = ReturnType<typeof exportTransactionsQueryKey>

/**
 * @description Export transaction data
 * @summary Export Transactions
 * {@link /api-internal/transactions/export}
 */
async function exportTransactions(config: Partial<RequestConfig> = {}) {
  const res = await client<ExportTransactionsQueryResponse, ExportTransactions400, unknown>({
    method: 'GET',
    url: `/api-internal/transactions/export`,
    ...config,
  })
  return res.data
}

export function exportTransactionsQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = exportTransactionsQueryKey()
  return queryOptions<ExportTransactionsQueryResponse, ExportTransactions400, ExportTransactionsQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return exportTransactions(config)
    },
  })
}

/**
 * @description Export transaction data
 * @summary Export Transactions
 * {@link /api-internal/transactions/export}
 */
export function useExportTransactions<
  TData = ExportTransactionsQueryResponse,
  TQueryData = ExportTransactionsQueryResponse,
  TQueryKey extends QueryKey = ExportTransactionsQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<ExportTransactionsQueryResponse, ExportTransactions400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? exportTransactionsQueryKey()

  const query = useQuery({
    ...(exportTransactionsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ExportTransactions400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}