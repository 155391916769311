import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetInvoicePdfsAsZipQueryResponse, GetInvoicePdfsAsZipQueryParams, GetInvoicePdfsAsZip400 } from '../types/GetInvoicePdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getInvoicePdfsAsZipQueryKey = (params: GetInvoicePdfsAsZipQueryParams) =>
  [{ url: '/api-internal/invoices/pdfs' }, ...(params ? [params] : [])] as const

export type GetInvoicePdfsAsZipQueryKey = ReturnType<typeof getInvoicePdfsAsZipQueryKey>

/**
 * @summary Get Invoice Pdfs As Zip
 * {@link /api-internal/invoices/pdfs}
 */
async function getInvoicePdfsAsZip(params: GetInvoicePdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetInvoicePdfsAsZipQueryResponse, GetInvoicePdfsAsZip400, unknown>({
    method: 'GET',
    url: `/api-internal/invoices/pdfs`,
    params,
    ...config,
  })
  return res.data
}

export function getInvoicePdfsAsZipQueryOptions(params: GetInvoicePdfsAsZipQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getInvoicePdfsAsZipQueryKey(params)
  return queryOptions<GetInvoicePdfsAsZipQueryResponse, GetInvoicePdfsAsZip400, GetInvoicePdfsAsZipQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getInvoicePdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Invoice Pdfs As Zip
 * {@link /api-internal/invoices/pdfs}
 */
export function useGetInvoicePdfsAsZip<
  TData = GetInvoicePdfsAsZipQueryResponse,
  TQueryData = GetInvoicePdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetInvoicePdfsAsZipQueryKey,
>(
  params: GetInvoicePdfsAsZipQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetInvoicePdfsAsZipQueryResponse, GetInvoicePdfsAsZip400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getInvoicePdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getInvoicePdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetInvoicePdfsAsZip400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}