import { useState } from 'react';
import { FUNNEL_CONTEXT_INITIAL_DATA, FunnelContext, FunnelContextData } from './FunnelProvider.Context';

export const FunnelProvider = ({ children }) => {
  const [funnelData, setFunnelData] = useState<FunnelContextData>(FUNNEL_CONTEXT_INITIAL_DATA);
  const [funnelError, setFunnelError] = useState<string | null>(null);

  return (
    <FunnelContext.Provider
      value={{ data: funnelData, setData: setFunnelData, error: funnelError, setError: setFunnelError }}
    >
      {children}
    </FunnelContext.Provider>
  );
};
