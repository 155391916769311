import { MeasuringPointDataSchema, MeasuringPointSchema, ObisCodes } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { getMeasuringPointDataLabel } from '../../../helpers/getMeasuringPointDataLabel';
import { useTranslation } from '../../../i18n';
import { MGAreaChart } from '../../shared/Charts/MGAreaChart';
import { LineChartData, LineType } from '../../shared/Charts/MGMultiLineChart';

type Props = {
  measuring_point_informations: MeasuringPointSchema[];
  measuring_point_data: Record<number, Record<ObisCodes, MeasuringPointDataSchema[]>> | undefined;
  currentMeasuringPointId: number | undefined | null;
};
export const MeteringDetailsMeasuringPointDataGraph = ({
  measuring_point_informations,
  measuring_point_data,
  currentMeasuringPointId,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('meteringDetails');

  const chartData: LineChartData[] =
    measuring_point_informations && measuring_point_data
      ? measuring_point_informations
          .map((point) => {
            const data = measuring_point_data[point.id!];
            if (!data) {
              return [];
            }
            const obisCodes = Object.keys(data);
            return obisCodes.map((obisCode) => {
              const isConsumption = obisCode === '1-0:1.8.0';
              const isActive = point.id === currentMeasuringPointId;
              return {
                id: `${point.id}-${obisCode}-${point.type}-active:<${isActive}>`,
                active: isActive,
                color: !isActive
                  ? palette.gray.main
                  : isConsumption
                    ? palette.primary.dark
                    : palette.secondary.main,
                lineType: LineType.Solid,
                fill: true,
                unit: 'kWh',
                data: data[obisCode].map(({ timestamp, value }) => ({
                  x: new Date(timestamp),
                  y: value * point.conversion_factor!,
                })),
              };
            });
          })
          .flat()
      : [];

  return (
    <ParentSize>
      {({ width, height }) => (
        <MGAreaChart
          dataSet={chartData}
          height={height}
          width={width}
          renderTooltip={({ tooltipData }) => {
            if (!tooltipData?.nearestDatum) return;
            return (
              <Paper sx={{ borderRadius: theme.spacing(1), padding: theme.spacing(1, 2) }}>
                <Stack sx={{ gap: theme.spacing(1) }}>
                  <Typography variant={'small'} fontWeight={'bold'}>
                    {getMeasuringPointDataLabel(tooltipData.nearestDatum.key)}
                    {tooltipData.nearestDatum.key.includes('active:<true>') ? '' : ` (${t('inactive')})`}
                  </Typography>
                  <Typography variant={'extraSmall'}>
                    {presentDate(tooltipData.nearestDatum.datum.x)}
                  </Typography>
                  <Typography fontWeight={'bold'} variant={'small'}>
                    {presentKwh(tooltipData.nearestDatum.datum.y)}
                  </Typography>
                </Stack>
              </Paper>
            );
          }}
        />
      )}
    </ParentSize>
  );
};
