import { palette } from '@mg/ui/src/styles';
import { CircularProgress, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useTranslation } from '../../../../i18n';
import { ActionBarActionButtonsAtom, EverythingSelectedAtom } from '../../../../jotai/actionBar';
import { ActionBar, ActionBarButton, ActionBarDivider } from '../DataGridCard.ActionBar';

export const ActionBarSelectionView = ({ onSelectionCancel, selectedItemCount, onSelectAll }) => {
  const [everythingSelected] = useAtom(EverythingSelectedAtom);
  const [actionButtons] = useAtom(ActionBarActionButtonsAtom);
  const { t } = useTranslation('actionBar');

  return (
    <ActionBar onCancel={() => onSelectionCancel()}>
      <Typography variant="small" color={palette.primary.contrastText} whiteSpace="nowrap" flexShrink={0}>
        {everythingSelected ? t('all_n_selected', { count: selectedItemCount }) : ''}
      </Typography>
      <ActionBarDivider />

      <ActionBarButton
        disabled={false}
        onClick={() => {
          onSelectAll();
        }}
      >
        {t('select_all')}
      </ActionBarButton>

      {!!actionButtons.length && <ActionBarDivider />}

      {actionButtons.map((button) => (
        <ActionBarButton
          key={button.label}
          onClick={button.onClick}
          endIcon={button.loading ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : button.icon}
          disabled={button.disabled}
        >
          {button.label}
        </ActionBarButton>
      ))}
    </ActionBar>
  );
};
