import { useAtomValue } from 'jotai/index';
import { DeliverableDocument, DocumentContext, documentContextAtom } from './common';

export const useDocumentContext = <
  TDocument extends DeliverableDocument = DeliverableDocument,
>(): DocumentContext<TDocument> => {
  const context = useAtomValue(documentContextAtom);

  if (!context) {
    throw new Error('useDocumentContext must be used within a ProvideDocumentContext');
  }

  return context as DocumentContext<TDocument>;
};
