import { useListCustomers } from '@mg/api-wrappers/src/api-internal';
import { customerDisplayName } from '@mg/ui/src/presenters';
import { PresentableCustomer } from '@mg/ui/src/presenters/customer';
import { Avatar, AvatarGroup, Box, Skeleton, Typography } from '@mui/material';
import { FileText } from 'lucide-react';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { SepaXmlGenerationModal } from '../../../modals/SepaXmlGenerationModal/SepaXmlGenerationModal';
import { DashboardCard } from '../Dashboard.Card';

export const DashboardQuickActionsSepaXmlCard = () => {
  const { t } = useTranslation('dashboard');

  const projectFilter = useSelectedProjectsFilter();
  const customerQuery = useListCustomers({ ...projectFilter, order_by: 'id' });

  const AdditionalContent = () => {
    return (
      <Box display={'flex'} justifyContent={'flex-start'}>
        <AvatarGroup
          max={6}
          total={customerQuery.data?.total_items}
          slotProps={{
            additionalAvatar: {
              sx: {
                width: '32px',
                height: '32px',
                fontSize: '14px',
                backgroundColor: 'primary.main',
                color: 'primary.light',
              },
            },
          }}
        >
          {customerQuery.data?.items.map((customer) => (
            <Avatar
              key={customer.display_name}
              sx={{
                width: '32px',
                height: '32px',
                fontSize: '14px',
                backgroundColor: 'primary.light',
                color: 'primary.main',
              }}
            >
              {customerDisplayName(customer as PresentableCustomer)[0]}
            </Avatar>
          ))}
        </AvatarGroup>
      </Box>
    );
  };

  return (
    <DashboardCard
      loading={customerQuery.isLoading}
      title={t('quick_actions_sepa_xml_title')}
      subtitle={<Typography variant={'small'}>{t('quick_actions_sepa_xml_subtitle')}</Typography>}
      icon={<FileText />}
      additionalContent={<AdditionalContent />}
      additionalContentLoadingComponent={<Skeleton variant={'rounded'} height={35} sx={{ width: '100%' }} />}
      button={<SepaXmlGenerationModal />}
    />
  );
};
