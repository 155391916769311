import { palette } from '@mg/ui/src/styles';
import { Box, Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { SidebarItemOnboardingTooltip } from './Sidebar.Item.OnboardingTooltip';

type Props = {
  selected: boolean;
  disabled?: boolean;
  icon: ReactNode;
  i18nKey: string;
  onClick?: () => void;
  href?: string;
  endIcon?: React.ReactNode;
};
export const SidebarItemButton = ({
  selected,
  disabled,
  icon,
  i18nKey,
  onClick = () => {},
  href,
  endIcon = null,
}: Props) => {
  const { t } = useTranslation('sidebar');

  const itemStyles = {
    backgroundColor: selected ? palette.primary.main : 'none',
    transition: selected ? 'background-color 1s' : 'border 0s',
    cursor: 'pointer',
    userSelect: 'none',
    justifyContent: 'space-between',
    color: selected ? palette.primary.contrastText : disabled ? palette.gray.main : palette.gray.dark,
    ':hover':
      !selected && !disabled
        ? {
            backgroundColor: '#EBEAE5',
            transition: 'background-color 0.5s',
          }
        : {},
  };

  return (
    <SidebarItemOnboardingTooltip hidden={!disabled}>
      <Box
        display={'flex'}
        alignItems={'center'}
        height={'44px'}
        borderRadius={'10px'}
        paddingX={'15px'}
        sx={itemStyles}
        disabled={disabled}
        onClick={onClick}
        component={disabled ? Button : Link}
        to={href as string}
      >
        <Box>
          {icon}
          <Typography variant={'small'} sx={{ color: 'currentcolor' }} marginLeft={'25px'}>
            {t(i18nKey)}
          </Typography>
        </Box>
        {endIcon}
      </Box>
    </SidebarItemOnboardingTooltip>
  );
};
