import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../i18n';
import { DashboardQuickActionsCustomers } from './Dashboard.QuickActions.Customers';
import { DashboardQuickActionsLetters } from './Dashboard.QuickActions.Letters';
import { DashboardQuickActionsMetering } from './Dashboard.QuickActions.Metering';
import { DashboardQuickActionsSepaXmlCard } from './Dashboard.QuickActions.SepaXmlCard';
import { DashboardQuickActionsTransactionsCard } from './Dashboard.QuickActions.TransactionsCard';

export const DashboardQuickActions = () => {
  const { t } = useTranslation('dashboard');
  return (
    <Stack spacing={2}>
      <Typography variant={'small'}>{t('quick_actions_title')}</Typography>
      <Stack direction={'row'} spacing={2} minHeight={'240px'}>
        <DashboardQuickActionsSepaXmlCard />
        <DashboardQuickActionsTransactionsCard />
      </Stack>
      <Stack direction={'row'} spacing={2} height={'210px'}>
        <DashboardQuickActionsCustomers />
        <DashboardQuickActionsMetering />
        <DashboardQuickActionsLetters />
      </Stack>
    </Stack>
  );
};
