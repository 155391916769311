import { palette } from '@mg/ui/src/styles';
import { FormControl, Stack, Typography } from '@mui/material';
import {
  DateRangePickerProps,
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { IntegrationBaseProps } from './IntegrationBaseProps';

export type DateRangeFieldProps = IntegrationBaseProps & {
  dateRangePickerProps?: Omit<
    DateRangePickerProps<never, false>,
    'value' | 'onChange' | 'inputRef' | 'name' | 'label'
  >;
};

export const DateRangePicker = ({
  name,
  control,
  rules,
  label,
  dateRangePickerProps,
}: DateRangeFieldProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <Stack gap={1}>
            <Typography color={palette.text.black} fontWeight={400}>
              {label}
            </Typography>
            <FormControl fullWidth>
              <MuiDateRangePicker
                value={field.value}
                onChange={field.onChange}
                disabled={field.disabled}
                inputRef={field.ref}
                name={field.name}
                label={label}
                localeText={{ start: 'Beginn', end: 'Ende' }}
                {...dateRangePickerProps}
              />
              {fieldState.error && (
                <Typography variant="small" color={palette.error.main}>
                  {fieldState.error?.message}
                </Typography>
              )}
            </FormControl>
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};
