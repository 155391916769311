import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  CountPlanChangeDocumentsQueryResponse,
  CountPlanChangeDocumentsQueryParams,
  CountPlanChangeDocuments422,
} from '../types/CountPlanChangeDocuments.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countPlanChangeDocumentsQueryKey = (params: CountPlanChangeDocumentsQueryParams) =>
  [{ url: '/api-internal/plan_change_documents/count' }, ...(params ? [params] : [])] as const

export type CountPlanChangeDocumentsQueryKey = ReturnType<typeof countPlanChangeDocumentsQueryKey>

/**
 * @summary Count Plan Change Documents
 * {@link /api-internal/plan_change_documents/count}
 */
async function countPlanChangeDocuments(params: CountPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountPlanChangeDocumentsQueryResponse, CountPlanChangeDocuments422, unknown>({
    method: 'GET',
    url: `/api-internal/plan_change_documents/count`,
    params,
    ...config,
  })
  return res.data
}

export function countPlanChangeDocumentsQueryOptions(params: CountPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countPlanChangeDocumentsQueryKey(params)
  return queryOptions<CountPlanChangeDocumentsQueryResponse, CountPlanChangeDocuments422, CountPlanChangeDocumentsQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countPlanChangeDocuments(params, config)
    },
  })
}

/**
 * @summary Count Plan Change Documents
 * {@link /api-internal/plan_change_documents/count}
 */
export function useCountPlanChangeDocuments<
  TData = CountPlanChangeDocumentsQueryResponse,
  TQueryData = CountPlanChangeDocumentsQueryResponse,
  TQueryKey extends QueryKey = CountPlanChangeDocumentsQueryKey,
>(
  params: CountPlanChangeDocumentsQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountPlanChangeDocumentsQueryResponse, CountPlanChangeDocuments422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countPlanChangeDocumentsQueryKey(params)

  const query = useQuery({
    ...(countPlanChangeDocumentsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountPlanChangeDocuments422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}