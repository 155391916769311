import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  AcceptSepaMandateMutationRequest,
  AcceptSepaMandateMutationResponse,
  AcceptSepaMandatePathParams,
  AcceptSepaMandate422,
} from '../types/AcceptSepaMandate.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const acceptSepaMandateMutationKey = () => [{ url: '/api-internal/sepa_mandates/{id}/accept' }] as const

export type AcceptSepaMandateMutationKey = ReturnType<typeof acceptSepaMandateMutationKey>

/**
 * @summary Accept Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/accept}
 */
async function acceptSepaMandate(
  id: AcceptSepaMandatePathParams['id'],
  data: AcceptSepaMandateMutationRequest,
  config: Partial<RequestConfig<AcceptSepaMandateMutationRequest>> = {},
) {
  const res = await client<AcceptSepaMandateMutationResponse, AcceptSepaMandate422, AcceptSepaMandateMutationRequest>({
    method: 'POST',
    url: `/api-internal/sepa_mandates/${id}/accept`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Accept Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/accept}
 */
export function useAcceptSepaMandate(
  options: {
    mutation?: UseMutationOptions<
      AcceptSepaMandateMutationResponse,
      AcceptSepaMandate422,
      { id: AcceptSepaMandatePathParams['id']; data: AcceptSepaMandateMutationRequest }
    >
    client?: Partial<RequestConfig<AcceptSepaMandateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? acceptSepaMandateMutationKey()

  return useMutation<
    AcceptSepaMandateMutationResponse,
    AcceptSepaMandate422,
    { id: AcceptSepaMandatePathParams['id']; data: AcceptSepaMandateMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return acceptSepaMandate(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}