import { PlanComponentSchema } from '@mg/api-wrappers/src/api-internal';
import { presentEuroCentPerKilowattHour } from '@mg/ui/src/presenters';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Sun, Zap } from 'lucide-react';
import { useTranslation } from '../../../i18n';

export interface ComponentPricesProps {
  planComponents: PlanComponentSchema[];
}
export const ComponentPrices = ({ planComponents }: ComponentPricesProps) => {
  const { t } = useTranslation('shared');
  return (
    <Stack justifyContent={'center'} height={'100%'} spacing={1}>
      {planComponents.map((pc) => (
        <Stack key={pc.calculation_basis} direction={'row'} alignItems={'center'}>
          {pc.calculation_basis === 'RESIDUAL_CONSUMPTION' && (
            <Tooltip arrow placement="left" title={t('residual_consumption')}>
              <Zap size={14} style={{ marginRight: '5px' }} />
            </Tooltip>
          )}
          {pc.calculation_basis === 'DIRECT_CONSUMPTION' && (
            <Tooltip arrow placement="left" title={t('direct_consumption')}>
              <Sun size={14} style={{ marginRight: '5px' }} />
            </Tooltip>
          )}
          {pc.calculation_basis === 'TOTAL_CONSUMPTION' && (
            <Tooltip arrow placement="left" title={t('total_consumption')}>
              <Sun size={14} style={{ marginRight: '5px' }} />
            </Tooltip>
          )}

          <Typography key={pc.price_per_kwh} variant={'small'}>
            {presentEuroCentPerKilowattHour(pc.price_per_kwh)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
