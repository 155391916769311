import { ProjectFilter } from '@mg/api-wrappers/src/api-internal';
import { useAtomValue } from 'jotai';
import { currentProjectIdAtom } from '../jotai/atoms';

/**
 * Returns a filter object that filters by the currently selected project.
 * Corresponds to the `ProjectFilter` in the internal API.
 */
export const useSelectedProjectsFilter = (): ProjectFilter => {
  const projectId = useAtomValue(currentProjectIdAtom);

  return {
    project_id: projectId || -1,
  };
};
