import {
  useGetProject,
  useGetProjectOnboardingCompleteness,
  useListMeasuringPointPanels,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { Button, Grid, Stack } from '@mui/material';
import { CircleGauge, Mail } from 'lucide-react';
import { useState } from 'react';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { DjangoFormModal } from '../../../modals/DjangoFormModal';
import { RenamePanelsModal } from '../../../modals/RenamePanelsModal';
import { OnboardingCard, OnboardingCardProps } from './Onboarding.Card';

export const OnboardingStepProjectData = () => {
  const { t } = useTranslation('onboarding');
  const [editProjectModalOpen, setEditProjectModalOpen] = useState(false);
  const projectId = useCurrentProject();
  const projectFilter = useSelectedProjectsFilter();

  const projectQuery = useGetProject(projectId);
  const panelQuery = useListMeasuringPointPanels({ ...projectFilter, skip_pagination: true });
  const onboardingQuery = useGetProjectOnboardingCompleteness(projectId);

  const hasRenamedAllRequiredPanels = panelQuery.data?.items.every(
    (panel) => panel.should_be_renamed === false,
  );

  const tiles: Omit<OnboardingCardProps, 'title' | 'subtitle'>[] = [
    {
      key: 'your_data',
      loading: onboardingQuery.isLoading,
      icon: <CircleGauge />,
      status: onboardingQuery.data?.project_data ? 'done' : 'todo',
      onClick: () => setEditProjectModalOpen(true),
    },
    {
      key: 'rename_panels',
      loading: panelQuery.isLoading,
      icon: <CircleGauge />,
      status: hasRenamedAllRequiredPanels ? 'done' : 'todo',
      button: (
        <RenamePanelsModal
          buttonText={t(
            hasRenamedAllRequiredPanels
              ? 'onboarding_tile_rename_panels_button'
              : 'onboarding_tile_rename_panels_edit_button',
          )}
          buttonVariant={hasRenamedAllRequiredPanels ? 'outlined' : 'contained'}
        />
      ),
    },
  ];
  return (
    <>
      <Stack gap={2}>
        <Grid container spacing={3}>
          {tiles.map((tile) => (
            <Grid key={tile.key} item xs={12} md={6}>
              <OnboardingCard
                loading={false}
                title={t(`onboarding_tile_${tile.key}_title`)}
                subtitle={t(`onboarding_tile_${tile.key}_subtitle`)}
                icon={tile.icon}
                status={tile.status}
                button={
                  tile.button || (
                    <Button
                      onClick={tile.onClick}
                      variant={tile.status === 'done' ? 'outlined' : 'contained'}
                    >
                      {t(
                        tile.status === 'done'
                          ? `onboarding_tile_${tile.key}_edit_button`
                          : `onboarding_tile_${tile.key}_button`,
                      )}
                    </Button>
                  )
                }
              />
            </Grid>
          ))}
        </Grid>

        <MGAlert
          icon={<Mail size={20} />}
          severity={'info'}
          title={t('onboarding_alert_title')}
          subtitle={t('onboarding_alert_subtitle')}
        />
      </Stack>

      <DjangoFormModal
        open={editProjectModalOpen}
        handleToggle={() => setEditProjectModalOpen(!editProjectModalOpen)}
        title={'Deine Daten bearbeiten'}
        pathToDjangoForm={`/projects/${projectId}/edit/`}
        onSuccess={() => {
          setEditProjectModalOpen(false);
          projectQuery.refetch();
        }}
        contentBefore={
          <MGAlert
            icon={'!'}
            severity={'info'}
            title={t('onboarding_project_data_update_modal_warning')}
            subtitle={t('onboarding_project_data_update_modal_warning_text')}
            sx={{ marginTop: '20px' }}
          />
        }
      />
    </>
  );
};
