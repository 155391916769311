import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListCustomersQueryResponse, ListCustomersQueryParams, ListCustomers422 } from '../types/ListCustomers.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listCustomersInfiniteQueryKey = (params?: ListCustomersQueryParams) => [{ url: '/api-internal/customers/' }, ...(params ? [params] : [])] as const

export type ListCustomersInfiniteQueryKey = ReturnType<typeof listCustomersInfiniteQueryKey>

/**
 * @summary List Customers
 * {@link /api-internal/customers/}
 */
async function listCustomers(params?: ListCustomersQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<ListCustomersQueryResponse, ListCustomers422, unknown>({ method: 'GET', url: `/api-internal/customers/`, params, ...config })
  return res.data
}

export function listCustomersInfiniteQueryOptions(params?: ListCustomersQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listCustomersInfiniteQueryKey(params)
  return infiniteQueryOptions<ListCustomersQueryResponse, ListCustomers422, ListCustomersQueryResponse, typeof queryKey, number>({
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListCustomersQueryParams['page']
      }
      return listCustomers(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Customers
 * {@link /api-internal/customers/}
 */
export function useListCustomersInfinite<
  TData = InfiniteData<ListCustomersQueryResponse>,
  TQueryData = ListCustomersQueryResponse,
  TQueryKey extends QueryKey = ListCustomersInfiniteQueryKey,
>(
  params?: ListCustomersQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListCustomersQueryResponse, ListCustomers422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listCustomersInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listCustomersInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListCustomers422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}