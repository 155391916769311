import { Box, Typography } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from '../../../../i18n';

export const DashboardNotificationsExpandItem = ({ expanded, onClick, moreCount }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Box
      onClick={onClick}
      sx={{
        borderBottom: '1px solid lightgray',
        borderRadius: '0px',
        color: 'text.primary',
        height: '55px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          cursor: 'pointer',
          backgroundColor: '#EBEAE5',
        },
        transition: 'background-color 0.5s',
      }}
    >
      {!expanded ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
      <Typography variant={'small'} marginLeft={'5px'}>
        {!expanded
          ? t('notifications_expand_button_collapsed', { count: moreCount })
          : t('notifications_expand_button_expanded')}
      </Typography>
    </Box>
  );
};
