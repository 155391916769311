import { granularStateSchema } from './granularStateSchema.ts'
import { z } from 'zod'

export const transactionFilterSchema = z.object({
  customer: z.union([z.number().int(), z.null()]).optional(),
  granular_state: z.union([z.lazy(() => granularStateSchema), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  invoice_relevant: z.union([z.boolean(), z.null()]).optional(),
  project_id: z.union([z.number().int(), z.null()]).optional(),
  search: z.union([z.string(), z.null()]).optional(),
  state: z.union([z.enum(['pending', 'settled']), z.null()]).optional(),
  time_range_from: z.union([z.string().datetime(), z.null()]).optional(),
  time_range_to: z.union([z.string().datetime(), z.null()]).optional(),
})