import { useGetPaymentPlanPdf } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu } from '@mg/ui/src/components/MGActionMenu';
import { Download, Plus } from 'lucide-react';
import { downloadFile } from '../../../../../helpers/downloadFile';
import { useTranslation } from '../../../../../i18n';

export const CustomerDetailsContractsPaymentPlanActionMenu = ({ customerId, paymentPlanId }) => {
  const getPaymantPlanPdfQuery = useGetPaymentPlanPdf(paymentPlanId, { query: { enabled: false } });
  const { t } = useTranslation('customerDetails');
  const menuItems = [
    {
      text: t('payment_plans_action_menu_change_plan'),
      icon: <Plus size={18} />,
      href: `/customers/${customerId}/paymentplan/edit/`,
    },
    {
      text: t('payment_plans_action_menu_download_plan'),
      icon: <Download size={18} />,
      onClick: () => downloadFile(getPaymantPlanPdfQuery),
    },
  ];
  return <MGActionMenu menuItems={menuItems} />;
};
