import { z } from 'zod'

export const invoiceFilterSchema = z.object({
  category: z.union([z.enum(['regular invoice', 'cancellation invoice']), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  document_type: z.literal('invoice').default('invoice'),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  issue_date_from: z.union([z.string().datetime(), z.null()]).optional(),
  issue_date_to: z.union([z.string().datetime(), z.null()]).optional(),
  project_id: z.number().int(),
  search: z.union([z.string(), z.null()]).optional(),
  state: z.union([z.enum(['issued', 'canceled']), z.null()]).optional(),
})