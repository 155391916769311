import { LetterCreateSchema } from '@mg/api-wrappers/src/api-internal';
import { axiosInstance } from '@mg/api-wrappers/src/client';

export async function generatePreviewPdf(letter: LetterCreateSchema) {
  const response = await axiosInstance.request({
    method: 'POST',
    url: '/api-internal/letters/preview',
    data: letter,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
  });

  return response.data as Blob;
}
