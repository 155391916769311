import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { Chip, Typography } from '@mui/material';
import { useTranslation } from '../../../../../i18n';
import { presentEuro } from '../../../../../presenters/number';
import { CustomerDetailsDataSingleItemCard } from './CustomerDetails.Data.SingleItemCard';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataOpenTransactionsCard = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  return (
    <CustomerDetailsDataSingleItemCard
      loading={isLoading}
      topContent={
        <Chip
          label={presentEuro(customer?.total_open_amount || 0)}
          color={'error'}
          variant={'soft'}
          size={'small'}
          sx={{ borderRadius: '8px' }}
        />
      }
      bottomContent={
        <Typography variant={'small'} textAlign={'center'}>
          {t('data_card_open_transactions_title')}
        </Typography>
      }
    />
  );
};
