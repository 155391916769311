import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { CalendlyModal } from '../../modals/CalendlyModal';
import { PageLayout } from '../../shared/PageLayout';
import { MetergridPlusCard } from './MetergridPlus.Card';

export const MetergridPlusPage = () => {
  const { t } = useTranslation('metergridPlus');
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);

  return (
    <>
      <PageLayout center title={t('page_title')} subtitle={''}>
        <Grid container spacing={'20px'} height={'100%'}>
          <Grid item xs={6}>
            <MetergridPlusCard type={'basic'} onToggle={() => setCalendlyModalOpen(!calendlyModalOpen)} />
          </Grid>
          <Grid item xs={6}>
            <MetergridPlusCard
              type={'plus'}
              color={'dark'}
              onToggle={() => setCalendlyModalOpen(!calendlyModalOpen)}
            />
          </Grid>
        </Grid>
      </PageLayout>
      <CalendlyModal
        calendlyLink={'https://calendly.com/d/47c-qs9-xfd/metergrid-plus-beratung'}
        open={calendlyModalOpen}
        handleToggle={() => setCalendlyModalOpen(!calendlyModalOpen)}
        translationPrefix={'metergrid_plus'}
      />
    </>
  );
};
