import { CustomerSchema, PaymentPlanSchema, useListPaymentPlans } from '@mg/api-wrappers/src/api-internal';
import { Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../../i18n';
import { presentDate } from '../../../../../presenters/date';
import { presentEuroPerMonth } from '../../../../../presenters/number';
import { CustomerDetailsContractsCard } from './CustomerDetails.Contracts.Card';
import { CustomerDetailsContractsPaymentPlanActionMenu } from './CustomerDetails.Contracts.PaymentPlan.ActionMenu';
import { CustomerDetailsContractsSwitchPaymentPlanModal } from './CustomerDetails.Contracts.SwitchPaymentPlanModal';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsContractsPaymentPlans = ({ customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const navigate = useNavigate();
  const projectFilter = useSelectedProjectsFilter();
  const paymentPlanQuery = useListPaymentPlans(
    { ...projectFilter, customer_id: customer?.id, order_by: '-id' },
    { query: { enabled: !!customer } },
  );

  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<PaymentPlanSchema | undefined>(undefined);

  useEffect(() => {
    const activePaymentPlan = paymentPlanQuery.data?.items?.find((plan) => plan?.is_active);
    setSelectedPaymentPlan(activePaymentPlan || paymentPlanQuery.data?.items[0]);
  }, [paymentPlanQuery.data]);

  return (
    <>
      <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
        <Typography>{t('contracts_payment_plan')}</Typography>
        <CustomerDetailsContractsSwitchPaymentPlanModal
          paymentPlans={paymentPlanQuery.data?.items}
          initiallySelectedPaymentPlan={selectedPaymentPlan?.id}
          onSelect={(paymentPlanId: number) =>
            setSelectedPaymentPlan(paymentPlanQuery.data?.items.find((p) => p.id === paymentPlanId))
          }
        />
      </Stack>

      <CustomerDetailsContractsCard
        isLoading={!selectedPaymentPlan}
        title={
          <Typography variant={'h4'} color={'text.secondary'}>
            {`Abschlagsplan ID - ${selectedPaymentPlan?.id}`}
          </Typography>
        }
        icon={
          !selectedPaymentPlan ? (
            <div></div>
          ) : (
            <Chip
              color={selectedPaymentPlan?.is_active ? 'success' : 'error'}
              label={selectedPaymentPlan?.is_active ? 'Aktiv' : 'Inaktiv'}
              sx={{ borderRadius: '8px' }}
              size={'small'}
            />
          )
        }
        actionMenu={
          <CustomerDetailsContractsPaymentPlanActionMenu
            customerId={customer?.id}
            paymentPlanId={selectedPaymentPlan?.id}
          />
        }
        alertTitle={t('contracts_card_payment_plan_alert_title')}
        alertButtonClick={() => navigate(`/customers/${customer?.id}/paymentplan/edit/`)}
        rows={[
          {
            label: t('contracts_card_base_price'),
            value: presentEuroPerMonth(selectedPaymentPlan?.amount),
          },
          {
            label: t('contracts_card_due_date'),
            value: t(`interval_${selectedPaymentPlan?.interval}`),
          },
          {
            label: t('contracts_card_start_date'),
            value: presentDate(selectedPaymentPlan?.start_date),
          },
        ]}
      />
    </>
  );
};
