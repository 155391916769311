import { useMolecule } from 'bunshi/react';
import { useAtomValue } from 'jotai/index';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { billingMolecule } from './Billing.Atoms';
import { useNavigateWithSearch } from './useNavigateWithSearch';

/** Makes sure that the billing process was initialized on the first step (billing period).
 * If the user navigated directly to subsequent step, redirects to the first step.
 */
export const useRequireBillingInitialized = () => {
  const location = useLocation();
  const navigate = useNavigateWithSearch();

  const { isInitializedAtom } = useMolecule(billingMolecule);
  const isInitialized = useAtomValue(isInitializedAtom);

  useEffect(() => {
    if (!isInitialized) {
      navigate('/customers/billing', {
        replace: true,
      });
    }
  }, [isInitialized, navigate, location.search]);
};
