import {
  deliverableDocumentType,
  PaymentPlanSchema,
  useGetPaymentPlanPdf,
} from '@mg/api-wrappers/src/api-internal';
import { useState } from 'react';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

import { useDocumentContext } from '../../features/documents/document-context/document-context';

export const DocumentsPaymentPlansTableRowActionMenu = () => {
  const { document } = useDocumentContext<PaymentPlanSchema>();
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const getPaymantPlanPdfQuery = useGetPaymentPlanPdf(document.id, { query: { enabled: false } });
  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.payment_plan,
    downloadPdfQuery: getPaymantPlanPdfQuery,
  });

  const menuItems: MenuItem[] = [...commonItems];

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
    </>
  );
};
