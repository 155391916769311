import { createTheme, Theme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
  typography,
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlinedGray': {
            backgroundColor: palette.white.main,
            color: '#051C2C',
            border: '1px solid' + palette.gray.light,
            boxShadow: '0px 0.5px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
          },
          '&.MuiButton-containedWhite': {
            boxShadow: 'none',
            border: '1px solid' + palette.gray.main,

            '&:hover': {
              boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
              backgroundColor: '#FFF',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            paddingTop: '44px',
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingBottom: '44px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccd6ec',
          boxShadow: '0 0 2rem rgba(0, 50, 160, 0.05)',
          backgroundColor: '#FDFDFC',
        },
        rounded: {
          borderRadius: '16px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center',
        },
        outlinedError: {
          backgroundColor: palette.error.light,
          border: `1px solid ${palette.error.main}`,
          fontFamily: typography.fontFamily,
        },
      },
    },
    MuiTextField: {},
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            backgroundColor: '#E5EAF6',
          },
        },
        // @ts-expect-error formControl not in typedef here?
        formControl: {
          borderRadius: '.625rem !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: '10px',
          borderRadius: '.625rem',
          '&:hover': {
            backgroundColor: '#E5EAF6',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'regular',
      },
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
        },
        h1: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
        h2: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
        h3: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
        h4: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
        h5: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        disableColumnResize: true,
        isCellEditable: () => false,
        isRowSelectable: () => false,
        hideFooter: true,
      },
      styleOverrides: {
        root: {
          border: 'none',
          '.MuiDataGrid-container--top': {
            '&::after': {
              width: '0px !important',
              backgroundColor: '#FFF',
            },
          },
          '.MuiDataGrid-filler': {
            div: {
              border: 'none',
            },
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        },

        columnHeader: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontSize: '14px',
          userSelect: 'none',
          outline: 'none',
          ':focus': { outline: 'none' },
          ':focus-within': { outline: 'none' },
        },

        cell: {
          border: 'none',
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontSize: '14px',
        },
        row: {
          borderRadius: '15px',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        slotProps: {
          tooltip: {
            sx: {
              maxWidth: '350px',
              border: 'solid 1px lightgray',
              padding: '10px',
            },
          },
          arrow: {
            sx: {
              '&:before': {
                border: 'solid 1px lightgray',
              },
            },
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'soft' },
          style: (args) => {
            const ownerState = (args as { theme: Omit<Theme, 'components'>; ownerState: { color: string } })
              .ownerState;
            const color = palette[ownerState.color].light || palette.primary.light;
            return {
              backgroundColor: color,
              borderColor: color,
              color: palette[ownerState.color].main,
            };
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.border.main} !important`,
          borderRadius: '10px',
        },
      },
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonOwnProps {
    to?: string;
  }
  interface ButtonPropsColorOverrides {
    white: true;
    gray: true;
    purple: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    extraSmall: true;
    small: true;
    regular: true;
    large: true;
    h6: false;
    body1: false;
    body2: false;
    subtitle1: false;
    subtitle2: false;
  }

  interface TypographyPropsColorOverrides {
    black: true;
    gray: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    extraSmall: React.CSSProperties;
    small: React.CSSProperties;
    regular: React.CSSProperties;
    large: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    extraSmall?: React.CSSProperties;
    small?: React.CSSProperties;
    regular?: React.CSSProperties;
    large?: React.CSSProperties;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
  }

  interface ChipPropsColorOverrides {
    white: true;
    gray: true;
    purple: true;
  }
}
