import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, ModalProps, Paper, SxProps, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  open: boolean;
  handleToggle: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  showCloseButton?: boolean;
  width?: number | string;
  height?: number | string;
  position?: {
    top?: number | string;
    left?: number | string;
    anchorTop?: 'center' | 'top';
    anchorLeft?: 'center' | 'left';
  };
  ModalStyle?: SxProps;
  PaperStyle?: SxProps;
  BodyStyle?: SxProps;
  ModalProps?: ModalProps;
  title?: ReactNode;
}>;

export type MuiBaseModalProps = Props;

export const MuiBaseModal = ({
  open,
  handleToggle,
  children,
  width,
  height,
  position = { top: '50%', left: '50%', anchorTop: 'center', anchorLeft: 'left' },
  showCloseButton,
  ModalStyle,
  PaperStyle,
  ModalProps,
  BodyStyle,
  title,
}: Props) => {
  // Positioning
  const style: SxProps = {
    width: width || '85vw',
    maxWidth: '100vw',
    height: height,
    maxHeight: '100vh !important',
    top: position?.top || '50% !important',
    left: position?.left || '50% !important',
    transform: `translate(${position?.anchorLeft === 'center' ? '0%' : '-50%'}, ${
      position?.anchorTop === 'center' ? '-50%' : '0%'
    }) !important`,
    position: 'absolute',
    overflow: 'hidden',
    ...ModalStyle,
  };

  // Background
  const paperStyle: SxProps = {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: ' 20px !important',
    overflow: 'hidden',
    padding: '30px',
    ...PaperStyle,
  };

  // Modal body for spacing
  const bodyStyle: SxProps = {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    ...BodyStyle,
  };

  const modalSx: SxProps = { zIndex: 800 };
  if (ModalProps?.sx) Object.assign(modalSx, ModalProps?.sx);

  return (
    <Modal open={open} onClose={handleToggle} {...ModalProps} sx={modalSx}>
      <Box sx={style}>
        <Paper sx={paperStyle}>
          {title && (typeof title === 'string' ? <Typography variant={'h2'}>{title}</Typography> : title)}
          {showCloseButton && (
            <IconButton
              sx={{ position: 'absolute', right: 5, top: 5 }}
              onClick={(e) => handleToggle?.(e, 'closeButtonClick')}
            >
              <Close />
            </IconButton>
          )}

          <Box sx={bodyStyle}>{children}</Box>
        </Paper>
      </Box>
    </Modal>
  );
};
