import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import './fonts.scss';

export const typography = {
  fontWeightBold: '600',
  fontFamily: ['Inter', 'sans-serif'].join(','),
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },

  h1: {
    fontSize: '36px',
    lineHeight: '130%',
  },

  h2: {
    fontSize: '26px',
    lineHeight: '130%',
  },
  h3: {
    fontSize: '23px',
    fontWeight: 500,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 400,
  },
  h5: {
    fontSize: '18px',
    fontWeight: 500,
  },
  extraSmall: {
    fontSize: '12px',
    lineHeight: '150%',
  },
  small: {
    fontSize: '14px',
    lineHeight: '150%',
  },
  regular: {
    fontSize: '16px',
    lineHeight: '150%',
  },
  large: {
    fontSize: '18px',
    lineHeight: '150%',
  },

  h6: undefined,
  // Same as small -> Needed for internal components e.g. placeholders etc
  body1: undefined,
  body2: undefined,
  subtitle2: undefined,
  subtitle1: undefined,
} as TypographyOptions;

typography.body1 = typography.small as TypographyStyleOptions;
