import { useSwitchProject } from '@mg/api-wrappers/src/api-internal';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currentProjectIdAtom } from '../jotai/atoms';

export const useSwitchProjectHook = () => {
  const projectSwitchMutation = useSwitchProject();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const setCurrentProjectId = useSetAtom(currentProjectIdAtom);

  return (projectId: number) => {
    const newLocation = location.pathname.split('/')[1];
    projectSwitchMutation.mutateAsync({ data: { id: projectId } }).then(() => {
      navigate(`/${newLocation}`, { replace: true });
      setCurrentProjectId(projectId);
      queryClient.getQueryCache().clear();
      toast.success('Projekt erfolgreich gewechselt.');
    });
  };
};
