import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  GetPlanChangeDocumentPdfQueryResponse,
  GetPlanChangeDocumentPdfPathParams,
  GetPlanChangeDocumentPdf400,
} from '../types/GetPlanChangeDocumentPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPlanChangeDocumentPdfQueryKey = (id: GetPlanChangeDocumentPdfPathParams['id']) =>
  [{ url: '/api-internal/plan_change_documents/:id/pdf', params: { id: id } }] as const

export type GetPlanChangeDocumentPdfQueryKey = ReturnType<typeof getPlanChangeDocumentPdfQueryKey>

/**
 * @summary Get Plan Change Document Pdf
 * {@link /api-internal/plan_change_documents/:id/pdf}
 */
async function getPlanChangeDocumentPdf(id: GetPlanChangeDocumentPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetPlanChangeDocumentPdfQueryResponse, GetPlanChangeDocumentPdf400, unknown>({
    method: 'GET',
    url: `/api-internal/plan_change_documents/${id}/pdf`,
    ...config,
  })
  return res.data
}

export function getPlanChangeDocumentPdfQueryOptions(id: GetPlanChangeDocumentPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getPlanChangeDocumentPdfQueryKey(id)
  return queryOptions<GetPlanChangeDocumentPdfQueryResponse, GetPlanChangeDocumentPdf400, GetPlanChangeDocumentPdfQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPlanChangeDocumentPdf(id, config)
    },
  })
}

/**
 * @summary Get Plan Change Document Pdf
 * {@link /api-internal/plan_change_documents/:id/pdf}
 */
export function useGetPlanChangeDocumentPdf<
  TData = GetPlanChangeDocumentPdfQueryResponse,
  TQueryData = GetPlanChangeDocumentPdfQueryResponse,
  TQueryKey extends QueryKey = GetPlanChangeDocumentPdfQueryKey,
>(
  id: GetPlanChangeDocumentPdfPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetPlanChangeDocumentPdfQueryResponse, GetPlanChangeDocumentPdf400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPlanChangeDocumentPdfQueryKey(id)

  const query = useQuery({
    ...(getPlanChangeDocumentPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPlanChangeDocumentPdf400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}