import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectCompleteSchemaSchema } from './projectCompleteSchemaSchema.ts'
import { z } from 'zod'

export const getProjectOnboardingCompletenessPathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const getProjectOnboardingCompleteness200Schema = z.lazy(() => projectCompleteSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const getProjectOnboardingCompleteness422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getProjectOnboardingCompletenessQueryResponseSchema = z.lazy(() => getProjectOnboardingCompleteness200Schema)