import { CustomerSchema, useGetSepaMandatePdf } from '@mg/api-wrappers/src/api-internal';
import {
  MGCard,
  MGCardContent,
  MGCardHeader,
  MGCardListLayout,
  MGCardListLayoutItem,
} from '@mg/ui/src/components/MGCard';
import { Button } from '@mui/material';
import { Landmark } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { downloadFile } from '../../../../../helpers/downloadFile';
import { useTranslation } from '../../../../../i18n';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataBankAccountCard = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const navigate = useNavigate();
  const getSepaPdfQuery = useGetSepaMandatePdf(customer?.active_sepa_mandate_id || 0, {
    query: { enabled: false },
  });

  return (
    <MGCard isLoading={isLoading}>
      <MGCardHeader leftHeader={<Landmark />} />
      <MGCardContent>
        <MGCardListLayout>
          <MGCardListLayoutItem label={t('data_card_account_owner')}>
            {customer?.bank_account?.owner}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_iban')}>
            {customer?.bank_account?.iban}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_bic')}>
            {customer?.bank_account?.bic}
          </MGCardListLayoutItem>
          <MGCardListLayoutItem label={t('data_card_sepa_mandate')}>
            {customer?.has_active_sepa_mandate ? (
              <Button onClick={() => downloadFile(getSepaPdfQuery)}>{t('download')}</Button>
            ) : (
              <Button onClick={() => navigate(`/customers/${customer?.id}/sepa/`)}>{t('create')}</Button>
            )}
          </MGCardListLayoutItem>
        </MGCardListLayout>
      </MGCardContent>
    </MGCard>
  );
};
