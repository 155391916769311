import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListMissingDataMutationRequest, ListMissingDataMutationResponse, ListMissingData422 } from '../types/ListMissingData.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const listMissingDataMutationKey = () => [{ url: '/api-internal/billing/missing_data' }] as const

export type ListMissingDataMutationKey = ReturnType<typeof listMissingDataMutationKey>

/**
 * @summary List Missing Data
 * {@link /api-internal/billing/missing_data}
 */
async function listMissingData(data?: ListMissingDataMutationRequest, config: Partial<RequestConfig<ListMissingDataMutationRequest>> = {}) {
  const res = await client<ListMissingDataMutationResponse, ListMissingData422, ListMissingDataMutationRequest>({
    method: 'POST',
    url: `/api-internal/billing/missing_data`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary List Missing Data
 * {@link /api-internal/billing/missing_data}
 */
export function useListMissingData(
  options: {
    mutation?: UseMutationOptions<ListMissingDataMutationResponse, ListMissingData422, { data?: ListMissingDataMutationRequest }>
    client?: Partial<RequestConfig<ListMissingDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? listMissingDataMutationKey()

  return useMutation<ListMissingDataMutationResponse, ListMissingData422, { data?: ListMissingDataMutationRequest }>({
    mutationFn: async ({ data }) => {
      return listMissingData(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}