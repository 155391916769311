import { useExportMeasuringPointData } from '@mg/api-wrappers/src/api-internal';
import { Button } from '@mui/material';
import { ChevronDown, Download, FilePlus, Plus } from 'lucide-react';
import { useState } from 'react';
import { downloadFile } from '../../../helpers/downloadFile';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

export const MeteringHistoryActionMenu = () => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);

  const { t } = useTranslation('meteringHistory');

  const projectFilter = useSelectedProjectsFilter();
  const exportMeteringData = useExportMeasuringPointData({ project_id: projectFilter.project_id });

  const menuItems: MenuItem[] = [
    {
      text: t('action_menu_add_data'),
      icon: <Plus size={18} />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
    },
    {
      text: t('action_menu_import_data'),
      icon: <FilePlus size={18} />,
      href: '/import/metering',
    },
    {
      text: t('action_menu_export_data'),
      icon: <Download size={18} />,
      onClick: () => downloadFile(exportMeteringData, { project_id: projectFilter.project_id }),
    },
  ];

  return (
    <>
      <ActionsMenu
        buttonComponent={
          <Button variant={'contained'} endIcon={<ChevronDown />}>
            {t('action_menu_title')}
          </Button>
        }
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create`}
        title={t('metering_menu_add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
      />
    </>
  );
};
