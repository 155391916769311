import { Alert, Box, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import '../../styles/theme';
import { MGIcon } from '../MGIcon/MGIcon';

export interface MGAlertProps {
  icon?: ReactNode;
  severity: 'error' | 'warning' | 'success' | 'info';
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  sx?: SxProps;
  button?: ReactNode;
}

export const MGAlert = ({
  icon,
  severity = 'info',
  title,
  subtitle,
  children,
  sx = {},
  button,
}: MGAlertProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Alert
      icon={
        icon ? (
          <MGIcon variant={'filled'} color={severity} size={isMobile ? 30 : 40}>
            {icon}
          </MGIcon>
        ) : (
          <div />
        )
      }
      severity={severity}
      variant={'outlined'}
      sx={{
        '.MuiAlert-message': {
          marginLeft: isMobile ? '2px' : '5px',
          flex: 1,
        },
        borderColor: theme.palette[severity].main,
        backgroundColor: theme.palette[severity].light,
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row',
        textAlign: isMobile ? 'center' : 'left',
        ...sx,
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Typography
            variant={isMobile ? 'regular' : 'small'}
            fontWeight={'fontWeightBold'}
            color={'text.secondary'}
          >
            {title}
          </Typography>
          {subtitle && (
            <>
              <br /> <Typography variant={isMobile ? 'caption' : 'extraSmall'}>{subtitle}</Typography>
            </>
          )}
        </Box>
        {button}
      </Stack>

      {children}
    </Alert>
  );
};
