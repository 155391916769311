import { Button, Stack, Typography } from '@mui/material';
import { Files } from 'lucide-react';
import { openFaqBar } from '../../../helpers/faqBar';
import { useTranslation } from '../../../i18n';
import { DashboardCard } from './Dashboard.Card';

export const DashboardHelpSection = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Stack spacing={2}>
      <Typography variant={'small'}>{t('help_section_title')}</Typography>
      <Stack direction={'row'} spacing={1} minHeight={'200px'}>
        <DashboardCard
          title={t('help_section_setup_title')}
          subtitle={t('help_section_setup_subtitle')}
          icon={<Files />}
          button={
            <Button
              variant={'contained'}
              color={'white'}
              onClick={() => openFaqBar('helpbar-instruction-01')}
            >
              {t('help_section_setup_button')}
            </Button>
          }
        />
        <DashboardCard
          title={t('help_section_change_plans_title')}
          subtitle={t('help_section_change_plans_subtitle')}
          icon={<Files />}
          button={
            <Button
              variant={'contained'}
              color={'white'}
              onClick={() => openFaqBar('helpbar-instruction-02')}
            >
              {t('help_section_change_plans_button')}
            </Button>
          }
        />
        <DashboardCard
          title={t('help_section_malo_title')}
          subtitle={t('help_section_malo_subtitle')}
          icon={<Files />}
          button={
            <Button variant={'contained'} color={'white'} onClick={() => openFaqBar('helpbar-faq-04')}>
              {t('help_section_malo_button')}
            </Button>
          }
        />
      </Stack>
    </Stack>
  );
};
