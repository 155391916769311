import { Box, TextField as MUITextField, Stack, TextFieldProps, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { ValidationError } from '@tanstack/react-form';
import { MGFieldError } from './MGField.Error';

type Props = {
  name: string;
  value: string;
  label?: string | null;
  errors: ValidationError[];
  onChange: InputBaseProps['onChange'];
  onBlur: InputBaseProps['onBlur'];
  TextFieldProps?: TextFieldProps;
};

export const MGFieldText = ({ name, label, errors = [], value, onChange, onBlur, TextFieldProps }: Props) => {
  return (
    <Stack spacing={1}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Stack>
        <MUITextField
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={errors.length > 0}
          {...TextFieldProps}
        />

        <Box height={'12px'}>
          <MGFieldError errors={errors} />
        </Box>
      </Stack>
    </Stack>
  );
};
