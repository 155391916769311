import { useListNotifications } from '@mg/api-wrappers/src/api-internal';
import { Card, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Check, CircleAlert } from 'lucide-react';
import { useState } from 'react';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';
import { SoftIcon } from '../../../shared/SoftIcon';
import { DashboardNotificationsExpandItem } from './Dashboard.Notifications.ExpandItem';
import { DashboardNotificationsItem } from './Dashboard.Notifications.Item';
import { DashboardNotificationsLoadingItem } from './Dashboard.Notifications.LoadingItem';

export const DashboardNotifications = () => {
  const projectFilter = useSelectedProjectsFilter();
  const notificationQuery = useListNotifications(
    { ...projectFilter, order_by: 'id' },
    { query: { staleTime: 0 } },
  );

  const { t } = useTranslation('dashboard');

  const [expanded, setExpanded] = useState(false);

  const MAX_ITEMS = 3;

  const totalCount = notificationQuery.data?.total_items || 0;

  return (
    <Stack spacing={2}>
      <Typography variant={'small'}>{t('notifications_title')}</Typography>
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }}>
          {notificationQuery.isLoading && <DashboardNotificationsLoadingItem />}
          {notificationQuery.isSuccess &&
            (totalCount === 0 ? (
              <DashboardNotificationsItem
                title={'Du hast keine neuen Benachrichtigungen'}
                icon={
                  <SoftIcon variant={'success'} size={40}>
                    <Check size={20} />
                  </SoftIcon>
                }
              />
            ) : (
              <>
                {notificationQuery.data?.items.map((notification, index) => {
                  if (index < MAX_ITEMS) {
                    return (
                      <DashboardNotificationsItem
                        notificationId={notification.id as number}
                        showDivider={index < MAX_ITEMS && index < totalCount - 1}
                        key={notification.id + notification.message}
                        title={notification.message}
                        icon={
                          <SoftIcon variant={'warning'} size={40}>
                            <CircleAlert size={20} />
                          </SoftIcon>
                        }
                      />
                    );
                  }
                })}

                <AnimatePresence>
                  {expanded && (
                    <motion.div
                      initial={{ height: 0, opacity: 1 }}
                      animate={{ transition: { duration: 0.2, type: 'tween' }, height: 'auto' }}
                      exit={{ height: 0, opacity: 0, transition: { duration: 0.2, type: 'tween' } }}
                    >
                      {notificationQuery.data?.items.map((notification, index) => {
                        if (index >= MAX_ITEMS) {
                          return (
                            <DashboardNotificationsItem
                              notificationId={notification.id as number}
                              showDivider={index < totalCount - 1}
                              key={notification.id + notification.message}
                              title={notification.message}
                              icon={
                                <SoftIcon variant={'warning'} size={40}>
                                  <CircleAlert size={20} />
                                </SoftIcon>
                              }
                            />
                          );
                        }
                      })}
                    </motion.div>
                  )}
                </AnimatePresence>
                {totalCount > MAX_ITEMS && (
                  <DashboardNotificationsExpandItem
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                    moreCount={totalCount - MAX_ITEMS}
                  />
                )}
              </>
            ))}
        </Stack>
      </Card>
    </Stack>
  );
};
