import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';

/**
 * HOC component to integrate a react-hook-form controller with an input component.
 * It allows to map the passed `field` prop onto the components props.
 *
 * @param WrappedComponent Component to be integrated with the controller.
 * @param mapControllerReturnToProps Function to map the controller return to the components props.
 * @returns The wrapped component with the controller integrated.
 *
 * @example
 * const TextField = withController<MuiTextFieldProps>(MuiTextField, ({ field, fieldState }) => ({
 *   value: field.value,
 *   onChange: field.onChange,
 *   disabled: field.disabled,
 *   inputRef: field.ref,
 *   name: field.name,
 *   error: !!fieldState.error,
 *   helperText: fieldState.error?.message,
 * }));
 *
 */
export const withController = <TWrappedComponentProps = unknown,>(
  WrappedComponent: React.ComponentType<TWrappedComponentProps>,
  mapControllerReturnToProps: (
    controllerReturn: UseControllerReturn<FieldValues, string>,
    propsOnWrappedComponent: TWrappedComponentProps,
  ) => Partial<TWrappedComponentProps>,
) => {
  const WithController = (
    props: {
      name: string;
      // Allow any, as WithController does not know the type of the control which is defined in the implemented form. This is necessary to allow generic usage of the component.
      /* eslint-disable @typescript-eslint/no-explicit-any */
      control?: Control<any>;
      controllerProps?: Partial<UseControllerProps>;
    } & TWrappedComponentProps,
  ) => {
    const controllerReturn = useController({
      name: props.name,
      control: props.control,
      ...props.controllerProps,
    });

    const mappedControllerReturn = mapControllerReturnToProps(controllerReturn, props);

    return <WrappedComponent {...props} {...mappedControllerReturn} />;
  };

  return WithController;
};
