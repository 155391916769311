import { Grid } from '@mui/material';
import { useTranslation } from '../../../i18n';
import { PageLayout } from '../../shared/PageLayout';
import { SupportPageFAQCard } from './SupportPage.FAQCard';
import { SupportPageFormContent } from './SupportPage.FormContent';

export const SupportPage = () => {
  const { t } = useTranslation('supportPage');

  return (
    <PageLayout title={t('support_page_title')} subtitle={t('support_page_subtitle')}>
      <Grid container spacing={2}>
        <Grid item md={8} sm={12}>
          <SupportPageFormContent />
        </Grid>
        <Grid item md={4} sm={12}>
          <SupportPageFAQCard />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
