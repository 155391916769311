export const presentDate = (date: Date | string | null | undefined) => {
  if (!date) {
    return '-';
  }

  return new Date(date).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const presentDateTime = (date: Date | string | null | undefined) => {
  if (!date) {
    return '-';
  }

  return new Date(date).toLocaleString('de-DE');
};
