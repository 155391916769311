import { Button, Stack } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from '../../../i18n';
import { DateField } from './fields/DateField';
import { DateRangeField } from './fields/DateRangeField';
import { SelectField } from './fields/SelectField';
import { DeclarativeFormFieldDefinition } from './types/DeclarativeFormFieldDefinition';
import { DeclarativeFormFieldType } from './types/DeclarativeFormFieldType';

export type DeclarativeFormProps = {
  id: string;
  fieldDefinitions: DeclarativeFormFieldDefinition[];
  onSubmit: SubmitHandler<FieldValues>;
  onCancel?: () => void;

  values: FieldValues;
  submitButton?: ReactNode;
};

export const DeclarativeForm = ({
  id,
  fieldDefinitions,
  onSubmit,
  onCancel,
  values,
  submitButton,
}: DeclarativeFormProps) => {
  const { handleSubmit, control } = useForm({ values });
  const { t } = useTranslation('shared');

  const renderField = useMemo(
    () => (fieldDef: DeclarativeFormFieldDefinition) => {
      const fieldId = id + '-' + fieldDef.name;
      const fieldKey = fieldId;

      if (fieldDef.type === DeclarativeFormFieldType.Select) {
        return (
          <SelectField
            key={fieldKey}
            id={fieldId}
            name={fieldDef.name}
            control={control}
            heading={fieldDef.label}
            label={fieldDef.innerLabel}
            options={fieldDef.options || []}
            hasBlankOption={fieldDef.hasBlankOption}
            blankOptionLabel={fieldDef.blankOptionLabel}
            blankOptionValue={fieldDef.blankOptionValue}
          />
        );
      }

      if (fieldDef.type === DeclarativeFormFieldType.Date) {
        return (
          <DateField
            key={fieldKey}
            id={fieldId}
            name={fieldDef.name}
            heading={fieldDef.label}
            label={fieldDef.innerLabel}
            control={control}
          />
        );
      }

      if (fieldDef.type === DeclarativeFormFieldType.DateRange)
        return (
          <DateRangeField
            key={fieldKey}
            id={fieldId}
            name={fieldDef.name}
            heading={fieldDef.label}
            label={fieldDef.innerLabel}
            control={control}
            inputProps={fieldDef.dateRangePickerInputProps}
          />
        );

      return null;
    },
    [id, control],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>{fieldDefinitions.map((field) => renderField(field))}</Stack>
        {submitButton || (
          <Stack flexDirection="row" gap={2} sx={{ position: 'absolute', bottom: 10, right: 0 }}>
            {onCancel && (
              <Button
                variant="outlined"
                color="gray"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                {t('cancel')}
              </Button>
            )}
            <Button variant={'contained'} type="submit">
              {t('filter')}
            </Button>
          </Stack>
        )}
      </form>
    </>
  );
};
