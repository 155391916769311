import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const emailDocumentSchemaSchema = z.object({
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  file_name_for_delivery: z.string(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  main_date: z.union([z.string().date(), z.null()]),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
})