import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { transactionFilterSchema } from './transactionFilterSchema.ts'
import { transactionReopenSchemaSchema } from './transactionReopenSchemaSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const reopenTransactions200Schema = z.lazy(() => transactionReopenSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const reopenTransactions422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const reopenTransactionsMutationRequestSchema = z.lazy(() => transactionFilterSchema)

export const reopenTransactionsMutationResponseSchema = z.lazy(() => reopenTransactions200Schema)