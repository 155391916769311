import { Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';

import { useAtom } from 'jotai/index';
import { CircleX } from 'lucide-react';
import { useEffect, useState } from 'react';
import { filterValueAtom } from '../../../jotai/actionBar';

type DataGridCardSearchProps = {
  placeholder?: string;
  width: string;
};

export const DataGridCardSearch = ({ placeholder, width }: DataGridCardSearchProps) => {
  const [textFieldValue, setTextFieldValue] = useState<string>();
  const [filterValue, setFilterValue] = useAtom(filterValueAtom);

  useEffect(() => {
    if (filterValue.search) {
      setTextFieldValue(filterValue.search as string);
    } else {
      setTextFieldValue('');
    }
  }, [filterValue]);

  return (
    <TextField
      fullWidth
      sx={{ width }}
      placeholder={placeholder || 'Suchen...'}
      onBlur={(e) => {
        if (e.target.value) {
          setFilterValue({ search: e.target.value });
        } else {
          setFilterValue({});
        }
      }}
      onChange={(e) => setTextFieldValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          (e.target as HTMLInputElement).blur();
        }
      }}
      value={textFieldValue || ''}
      InputProps={{
        sx: { backgroundColor: '#FFF' },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Box marginRight={'15px'} width={'20px'} display={'flex'} alignItems={'center'}>
              {textFieldValue && (
                <IconButton
                  onClick={() => {
                    setTextFieldValue('');
                    setFilterValue({});
                  }}
                >
                  <CircleX />
                </IconButton>
              )}
            </Box>
            <Box
              width={'40px'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              padding={'5px'}
              borderRadius={'5px'}
              sx={{ backgroundColor: '#F6F5F3' }}
            >
              <Typography>↩</Typography>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};
