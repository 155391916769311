import { Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
export const MGCardTitle = ({ children }: Props) => {
  if (typeof children === 'string') {
    return <Typography variant={'h5'}>{children}</Typography>;
  }
  return children;
};
