import { palette } from '@mg/ui/src/styles';
import { Button, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from '../../../i18n';
import { MultiStepModal } from '../../shared/MultiStepModal/MultiStepModal';
import { useMultiStepPanelContext } from '../../shared/MultiStepPanel/MultiStepPanel.Context';
import { StepKey } from './constants';
import { FunnelProvider } from './FunnelProvider';
import { DownloadingStep } from './steps/DownloadingStep';
import { PositionsStep } from './steps/PositionsStep';
import { ProjectsStep } from './steps/ProjectsStep';
import { TimeFrameStep } from './steps/TimeFrameStep';
import { TransactionsStep } from './steps/TransactionsStep';
type SepaXmlGenerationModalProps = {
  open?: boolean;
  handleToggle?: (open: boolean) => void;
};

export const SepaXmlGenerationModal = ({
  open: controlledOpenState,
  handleToggle,
}: SepaXmlGenerationModalProps) => {
  const { t, tString } = useTranslation('sepaGeneration');
  const [_open, _setOpen] = useState(false);

  const getStepTitle = (key: StepKey) => tString(`${key}_step_title`);

  const isControlled = controlledOpenState !== undefined;
  const open = controlledOpenState ?? _open;
  const setOpen = (open: boolean) => {
    isControlled ? handleToggle && handleToggle(open) : _setOpen(open);
  };

  return (
    <>
      {!isControlled && (
        <Button
          variant={'contained'}
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          {t('open_sepa_xml_gen_modal_button')}
        </Button>
      )}
      <FunnelProvider>
        <MultiStepModal
          open={open}
          handleToggle={(_, reason) => reason !== 'backdropClick' && setOpen(false)}
          layout={<MultiStepModalLayout />}
          steps={[
            { key: StepKey.TimeFrame, title: getStepTitle(StepKey.TimeFrame), content: <TimeFrameStep /> },
            { key: StepKey.Projects, title: getStepTitle(StepKey.Projects), content: <ProjectsStep /> },
            { key: StepKey.Postitions, title: getStepTitle(StepKey.Postitions), content: <PositionsStep /> },
            {
              key: StepKey.Transactions,
              title: getStepTitle(StepKey.Transactions),
              content: <TransactionsStep />,
            },
            {
              key: StepKey.Download,
              content: <DownloadingStep closeModal={() => setOpen(false)} />,
            },
          ]}
        />
      </FunnelProvider>
    </>
  );
};

const MultiStepModalLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('sepaGeneration');
  const { currentStep } = useMultiStepPanelContext();

  return (
    <>
      {currentStep.key !== StepKey.Download && (
        <Typography variant="small" component={'p'} color={palette.text.gray} mt={3} mb={4}>
          {t(`${currentStep.key}_step_subtitle`)}
        </Typography>
      )}
      <Stack gap={3}>
        <>{children}</>
      </Stack>
    </>
  );
};
