import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { MGCardContent, MGCardHeader, MGCardVerticalListLayout } from '@mg/ui/src/components/MGCard';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard';
import { presentDate } from '@mg/ui/src/presenters';
import { UserRound } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataBaseDataCardShort = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const deliveryPeriod = `${presentDate(customer?.subscription?.start_date)} - ${customer?.subscription?.ended_at ? presentDate(customer?.subscription?.ended_at) : 'Heute'}`;

  return (
    <MGCard isLoading={isLoading}>
      <MGCardHeader leftHeader={<UserRound />} />
      <MGCardContent>
        <MGCardVerticalListLayout>
          <MGCardVerticalListLayout.Item label={t('data_card_panel_name')}>
            {customer?.measuring_point_panel?.name}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label={t('data_card_email')}>
            {customer?.email || '-'}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label={t('data_card_delivery_period')}>
            {deliveryPeriod}
          </MGCardVerticalListLayout.Item>
        </MGCardVerticalListLayout>
      </MGCardContent>
    </MGCard>
  );
};
