import { palette } from '@mg/ui/src/styles';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { IntegrationBaseProps } from './IntegrationBaseProps';

type CheckboxProps = IntegrationBaseProps & {
  checkboxProps?: MuiCheckboxProps;
  formControlLabelProps?: Partial<FormControlLabelProps>;
};

export const Checkbox = ({ name, label, control, checkboxProps, formControlLabelProps }: CheckboxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <MuiCheckbox
                checked={field.value}
                onChange={field.onChange}
                disabled={field.disabled}
                inputRef={field.ref}
                name={field.name}
                {...checkboxProps}
              />
            }
            label={
              typeof label === 'string' ? (
                <Typography
                  fontSize={14}
                  color={palette.text.primary}
                  sx={{ display: 'inline-flex', flexDirection: 'row', gap: 1 }}
                >
                  {label}
                </Typography>
              ) : (
                label
              )
            }
            {...formControlLabelProps}
          />
        </FormGroup>
      )}
    ></Controller>
  );
};
