import { z } from 'zod'

export const getLetterPdfPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getLetterPdf200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getLetterPdf400Schema = z.string()

export const getLetterPdfQueryResponseSchema = z.lazy(() => getLetterPdf200Schema)