import { LevyNestedSchema, useListLevyStructuresInfinite } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentEuroCentPerKilowattHour } from '@mg/ui/src/presenters';
import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { CreateLevyStructureModal } from '../../modals/CreateLevyStructureModal';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { PageLayout } from '../../shared/PageLayout';
import { LevyStructuresNoRowsOverlay } from './LevyStructures.NoRowsOverlay';
import { LevyStructureRowActionMenu } from './LevyStructures.RowActionMenu';

export const LevyStructures = () => {
  const { t, tString } = useTranslation('levies');
  const projectFilter = useSelectedProjectsFilter();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      flex: 0,
    },
    {
      field: 'year',
      headerName: tString('year'),
      sortable: false,
      flex: 0.2,
      renderCell: (params) => {
        const year = new Date(params.row.valid_from).getFullYear();
        const currentYear = new Date().getFullYear();
        return (
          <Chip
            variant={'soft'}
            color={year === currentYear ? 'success' : 'gray'}
            sx={{ borderRadius: '8px' }}
            label={year}
          />
        );
      },
    },
    {
      field: 'valid_from',
      headerName: tString('valid_from') as string,
      flex: 0.2,
      renderCell: ({ value }) => presentDate(value),
    },
    {
      field: 'valid_to',
      headerName: tString('valid_to') as string,
      flex: 0.2,
      renderCell: ({ value }) => presentDate(value),
    },
    {
      field: 'levies',
      headerName: tString('total_sum') as string,
      sortable: false,
      flex: 0.4,
      valueGetter: (value: LevyNestedSchema[]) =>
        presentEuroCentPerKilowattHour(value.reduce((acc, curr) => acc + +curr.costs_per_kwh, 0)),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 20,
      renderCell: (params) => {
        return (
          <LevyStructureRowActionMenu levyStructureId={params.row.id} isDeletable={params.row.is_deletable} />
        );
      },
    },
  ];

  return (
    <>
      <PageLayout
        title={t('page_title')}
        subtitle={t('page_subtitle')}
        rightSection={
          <>
            <CreateLevyStructureModal />
          </>
        }
      >
        <FilterProvider>
          <Box sx={{ height: '100%' }}>
            <DataGridCard
              height={'calc(100vh - 300px)'}
              datagridProps={{
                onRowClick: (row) => navigate(`${row.id}/levies`),
                columnVisibilityModel: { id: false },
                slots: {
                  noRowsOverlay: () => <LevyStructuresNoRowsOverlay />,
                },
              }}
              rowHeight={70}
              showSearch={true}
              searchPlaceholder={tString('search_placeholder')}
              columns={columns}
              reactQueryHook={useListLevyStructuresInfinite}
              reactQueryHookParams={{ ...projectFilter }}
              defaultSort={'-valid_from'}
            />
          </Box>
        </FilterProvider>
      </PageLayout>
    </>
  );
};
