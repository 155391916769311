import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import '../../styles/theme';

export type MGCardVerticalListLayoutItemProps = {
  label: ReactNode;
  children: ReactNode;
};

export const MGCardVerticalListLayoutItem = ({ label, children }: MGCardVerticalListLayoutItemProps) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant={'small'} color={'text.secondary'} fontWeight={'medium'}>
        {label}
      </Typography>

      {typeof children === 'string' ? (
        <Typography variant={'small'} fontWeight={'400'}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Stack>
  );
};
