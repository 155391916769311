import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // TODO: reconsider this
      retry: false,
    },
    /* TODO: activate this?
    mutations: {
      onError: defaultErrorHandler,
    },
    */
  },
});
