import { LetterFilter, useListCustomers } from '@mg/api-wrappers/src/api-internal';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { DataGridCardFilterForm } from '../../shared/DataGridCard/DataGridCard.FilterForm';
import { DeclarativeFormFieldType } from '../../shared/DeclarativeForm';

type CustomLetterFilter = Partial<LetterFilter>;

type LetterFilterFormValues = {
  customer: string;
  issueDateRange: [Dayjs | null, Dayjs | null];
};

export const DocumentsLetterTableFilterForm = () => {
  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);
  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t, tString } = useTranslation('documents');

  const createInitialFormValues: () => LetterFilterFormValues = () => ({
    customer: '',
    issueDateRange: [null, null],
  });

  const customerOptions = useMemo(() => {
    if (!customersQuery.data) return [];

    return customersQuery.data?.items
      .map((customer) => ({ value: customer.id + '', label: customer.display_name + '' }))
      .filter((option, optionIndex, self) => self.findIndex((o) => o.value === option.value) === optionIndex);
  }, [customersQuery.data]);

  return (
    <>
      <DataGridCardFilterForm
        formId={'letter-table-filter'}
        formTitle={t('letter_filter_title')}
        filter={filter}
        onFilter={(filter) => {
          setFilterValue(filter);
          setFilterModalOpen(false);
        }}
        initialValues={createInitialFormValues()}
        serialize={(formValues: LetterFilterFormValues) => {
          const customerId = parseInt(formValues.customer);

          const result: CustomLetterFilter = {};

          if (customerId) result.customer = customerId;
          if (formValues.issueDateRange[0])
            result.issue_date_from = (formValues.issueDateRange[0] as Dayjs)?.format('YYYY-MM-DD');

          if (formValues.issueDateRange[1])
            result.issue_date_to = (formValues.issueDateRange[1] as Dayjs)?.format('YYYY-MM-DD');

          return result;
        }}
        deserialize={(filter: CustomLetterFilter) => {
          const formValues: LetterFilterFormValues = createInitialFormValues();

          if (filter.customer) formValues.customer = filter.customer + '';
          if (filter?.issue_date_from) formValues.issueDateRange[0] = dayjs(new Date(filter.issue_date_from));
          if (filter?.issue_date_to) formValues.issueDateRange[1] = dayjs(new Date(filter.issue_date_to));

          return formValues;
        }}
        fieldDefinitions={[
          {
            name: 'customer',
            label: t('filter_customer_label'),
            innerLabel: t('filter_customer_inner_label'),
            type: DeclarativeFormFieldType.Select,
            options: customerOptions,
            hasBlankOption: true,
            blankOptionLabel: tString('select_blank_option_label'),
          },
          {
            name: 'issueDateRange',
            label: t('letter_filter_issue_date_range_label'),
            type: DeclarativeFormFieldType.DateRange,
            dateRangePickerInputProps: { clearable: true },
          },
        ]}
      />
    </>
  );
};
