import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ReopenTransactionsMutationRequest, ReopenTransactionsMutationResponse, ReopenTransactions422 } from '../types/ReopenTransactions.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const reopenTransactionsMutationKey = () => [{ url: '/api-internal/transactions/reopen' }] as const

export type ReopenTransactionsMutationKey = ReturnType<typeof reopenTransactionsMutationKey>

/**
 * @summary Reopen Transactions
 * {@link /api-internal/transactions/reopen}
 */
async function reopenTransactions(data?: ReopenTransactionsMutationRequest, config: Partial<RequestConfig<ReopenTransactionsMutationRequest>> = {}) {
  const res = await client<ReopenTransactionsMutationResponse, ReopenTransactions422, ReopenTransactionsMutationRequest>({
    method: 'POST',
    url: `/api-internal/transactions/reopen`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Reopen Transactions
 * {@link /api-internal/transactions/reopen}
 */
export function useReopenTransactions(
  options: {
    mutation?: UseMutationOptions<ReopenTransactionsMutationResponse, ReopenTransactions422, { data?: ReopenTransactionsMutationRequest }>
    client?: Partial<RequestConfig<ReopenTransactionsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? reopenTransactionsMutationKey()

  return useMutation<ReopenTransactionsMutationResponse, ReopenTransactions422, { data?: ReopenTransactionsMutationRequest }>({
    mutationFn: async ({ data }) => {
      return reopenTransactions(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}