import { MeasuringPointDataNestedSchema } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles';
import axios from 'axios';
import { Edit, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu } from '../../shared/ActionsMenu';

type Props = {
  row: MeasuringPointDataNestedSchema;
};
export const MeteringHistoryTableRowActionMenu = ({ row }: Props) => {
  const { t } = useTranslation('meteringHistory');
  const [editDataModalOpen, setEditDataModalOpen] = useState(false);

  const menuItems = [
    {
      text: t('edit'),
      icon: <Edit size={18} />,
      disabled: !row.is_editable,
      onClick: () => setEditDataModalOpen(true),
    },
    {
      text: t('delete'),
      icon: <Trash2 size={18} color={palette.error.main} />,
      disabled: !row.is_deletable,
      onClick: () => {
        fireSweetalert({
          title: t('delete_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios.get(`/metering/data/${row.id}/delete`).then(() => {
              invalidateAllQueries()
                .then(() => toast.success(t('delete_success')))
                .catch(() => toast.error(t('delete_error')));
            });
          }
        });
      },
    },
  ];
  return (
    <>
      <ActionsMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/${row.id}/edit`}
        title={t('edit_modal_title')}
        open={editDataModalOpen}
        handleToggle={() => {
          setEditDataModalOpen(!editDataModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setEditDataModalOpen(false);
        }}
      />
    </>
  );
};
