import { Box, useTheme } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

type Props = {
  size?: number;
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'gray' | 'white' | 'info';
  variant: 'filled' | 'soft';
  children: ReactNode;
  shape?: 'round' | 'square';
};
export const MGIcon = forwardRef(
  ({ size = 50, color = 'primary', children, variant, shape = 'round', ...props }: Props, ref) => {
    const theme = useTheme();

    const backgroundColor = variant === 'soft' ? theme.palette[color]?.light : theme.palette[color]?.main;
    const colorOverride =
      color === 'white'
        ? theme.palette[color]?.contrastText
        : variant === 'soft'
          ? theme.palette[color]?.main
          : theme.palette[color]?.light;

    return (
      <Box
        {...props}
        ref={ref}
        sx={{
          width: `${size}px !important`,
          height: `${size}px !important`,
          color: colorOverride,
          backgroundColor,
          borderRadius: shape === 'square' ? '5px' : size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    );
  },
);
