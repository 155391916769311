import { MGControlledCollapsibleCard } from '@mg/ui/src/components/MGCollapsibleCard/MGControlledCollapsibleCard';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { Button, Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from '../../../../../i18n';
import { DataGridApiProvider } from '../../../../shared/DataGridCard/useGridApi';
import { AccountingData } from '../../../Accounting/Accounting.Data';
import { billingMolecule } from '../../Billing.Atoms';

type Props = {
  customerId: number;
  startDate: string;
  endDate: string;
};

export const BillingDetailsPerCustomerTransactionsCard = ({ customerId, startDate, endDate }: Props) => {
  const { t } = useTranslation('billing');

  const { verifiedTransactionsAtom } = useMolecule(billingMolecule);
  const [verifiedTransactions, setVerifiedTransactions] = useAtom(verifiedTransactionsAtom);
  const [isExpanded, setIsExpanded] = useState(!verifiedTransactions[customerId]);

  const getEndDateEndOfDayIsoString = () => {
    if (!endDate) return undefined;

    const end = dayjs(endDate);
    return end.toISOString();
  };

  const confirmedTransactions = verifiedTransactions[customerId];

  return (
    <MGControlledCollapsibleCard
      expanded={isExpanded}
      onExpand={(exp) => setIsExpanded(exp)}
      collapsedContent={
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <MGStatusIndicator status={confirmedTransactions ? 'success' : 'warning'} />
            <Typography variant={'large'} color={'text.secondary'}>
              {t('details_per_customer_transactions_card_title')}
            </Typography>
            {!confirmedTransactions && (
              <Button
                variant={'outlined'}
                size={'small'}
                endIcon={<Check size={18} />}
                onClick={(event) => {
                  event.stopPropagation();
                  setVerifiedTransactions((prev) => ({ ...prev, [customerId as number]: true }));
                  setIsExpanded(false);
                }}
              >
                {t('btn_confirm_transactions')}
              </Button>
            )}
          </Stack>
        </>
      }
    >
      <Stack spacing={2}>
        <Typography variant={'small'}>{t('details_per_customer_transactions_card_subtitle')}</Typography>
        <DataGridApiProvider>
          <AccountingData
            customerId={customerId}
            dueDateStart={startDate ? new Date(startDate).toISOString() : undefined}
            dueDateEnd={getEndDateEndOfDayIsoString()}
            showTableOnly
            hideFilter
            hideSelect
            hideCustomer
          />
        </DataGridApiProvider>
      </Stack>
    </MGControlledCollapsibleCard>
  );
};
