import { Stack, SxProps } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export type MGBannerActionLayoutProps = PropsWithChildren<{
  action?: ReactNode;
  sx?: SxProps;
}>;
export const MGBannerActionLayout = ({ children, action, sx }: MGBannerActionLayoutProps) => (
  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={sx}>
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      {children}
    </Stack>
    {action && (
      <Stack direction={'row'} alignItems={'center'}>
        {action}
      </Stack>
    )}
  </Stack>
);
