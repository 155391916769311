import { customerCountSchemaSchema } from './customerCountSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countCustomersQueryParamsSchema = z
  .object({
    project_id: z.union([z.number().int(), z.null()]).optional(),
    is_active: z.union([z.boolean(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description OK
 */
export const countCustomers200Schema = z.lazy(() => customerCountSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const countCustomers422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countCustomersQueryResponseSchema = z.lazy(() => countCustomers200Schema)