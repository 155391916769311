import {
  BillingPreflightResidualPowerPlanOut,
  BillingPreflightSubscriptionOut,
  MissingPlanForInterval,
  NoActivePlan,
  NoResidualPowerPlan,
} from '@mg/api-wrappers/src/api-internal';
import { useState } from 'react';

import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { MGCollapsibleCard } from '@mg/ui/src/components/MGCollapsibleCard/MGCollapsibleCard';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../../i18n';
import { DjangoFormModal } from '../../../../modals/DjangoFormModal';
import { PlanRowCard } from '../../../../shared/PlanRowCard/PlanRowCard';
import { ResidualPlanRowCard } from '../../../../shared/ResidualPlanRowCard/ResidualPlanRowCard';

type Props = {
  subscriptionPlanIntervals: BillingPreflightSubscriptionOut[];
  residualPowerPlanIntervals: BillingPreflightResidualPowerPlanOut[];
  subscriptionPlanErrors?: (NoActivePlan | MissingPlanForInterval)[];
  residualPowerPlanErrors?: NoResidualPowerPlan[];
};
export const BillingDetailsPerCustomerPlansCard = ({
  subscriptionPlanIntervals,
  residualPowerPlanIntervals,
  subscriptionPlanErrors,
  residualPowerPlanErrors,
}: Props) => {
  const { t } = useTranslation('billing');

  const [createFormUrl, setCreateFormUrl] = useState('');
  const [formModalOpen, setFormModalOpen] = useState(false);
  const hasSubscriptionPlansErrors =
    !!subscriptionPlanErrors?.length || subscriptionPlanIntervals.length === 0;
  const hasResidualPowerPlansErrors =
    !!residualPowerPlanErrors?.length || residualPowerPlanIntervals.length === 0;
  const hasErrors = hasSubscriptionPlansErrors || hasResidualPowerPlansErrors;

  return (
    <>
      <MGCollapsibleCard
        defaultOpen={hasErrors}
        collapsedContent={
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <MGStatusIndicator status={hasErrors ? 'error' : 'success'} />
            <Typography variant={'large'} color={'text.secondary'}>
              {t('details_per_customer_plans_card_title')}
            </Typography>
          </Stack>
        }
      >
        <Stack spacing={5}>
          <Stack spacing={2}>
            <Typography variant={'regular'}>
              {t('details_per_customer_plans_card_tenant_plans_title')}
            </Typography>

            {hasSubscriptionPlansErrors ? (
              <>
                {!!subscriptionPlanErrors?.length && (
                  <MGAlert severity={'error'} title={t(subscriptionPlanErrors[0].code + '_title')} />
                )}
                {subscriptionPlanIntervals.length === 0 && (
                  <MGAlert
                    severity={'error'}
                    title={t('details_per_customer_plans_card_tenant_plans_missing_title')}
                    subtitle={t('details_per_customer_plans_card_tenant_plans_missing_subtitle')}
                  />
                )}
              </>
            ) : (
              <>
                <Typography variant={'small'}>
                  {t('details_per_customer_plans_card_tenant_plans_subtitle')}
                </Typography>

                <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                  {subscriptionPlanIntervals.map((spi) => (
                    <Grid
                      item
                      key={`sp-${spi.subscription_plan.id}`}
                      xs={12}
                      lg={subscriptionPlanIntervals.length === 1 ? 12 : 6}
                    >
                      <PlanRowCard
                        planName={spi.subscription_plan.plan!.name!}
                        priceBase={spi.subscription_plan.plan!.price_base!}
                        startDate={spi.subscription_plan.valid_from}
                        endDate={spi.subscription_plan.valid_to}
                        planComponents={spi.subscription_plan.plan!.plan_components}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Stack>

          <Stack spacing={2}>
            <Typography variant={'regular'}>
              {t('details_per_customer_plans_card_residual_plans_title')}
            </Typography>

            {!!residualPowerPlanErrors?.length && (
              <MGAlert
                severity={'error'}
                title={t(residualPowerPlanErrors[0].code + '_title')}
                button={
                  <Button
                    color="error"
                    variant={'contained'}
                    onClick={() => {
                      setCreateFormUrl(`/residualpowerplans/create/`);
                      setFormModalOpen(!formModalOpen);
                    }}
                  >
                    {t('residual_plan_billing_error_button')}
                  </Button>
                }
              />
            )}

            {residualPowerPlanIntervals.length > 0 &&
              residualPowerPlanIntervals.map((rppi) => (
                <Grid
                  item
                  key={`rpp-${rppi.plan.id}`}
                  xs={12}
                  lg={residualPowerPlanIntervals.length === 1 ? 12 : 6}
                >
                  <ResidualPlanRowCard
                    planName={rppi.plan.name!}
                    priceBase={rppi.plan.price_base!}
                    pricePerKwh={rppi.plan.price_per_kwh!}
                    startDate={rppi.plan.valid_from}
                    endDate={rppi.plan.valid_to}
                  />
                </Grid>
              ))}
          </Stack>
        </Stack>
      </MGCollapsibleCard>
      <DjangoFormModal
        open={formModalOpen}
        handleToggle={() => setFormModalOpen(!formModalOpen)}
        title={t('create_plan')}
        pathToDjangoForm={createFormUrl}
        onSuccess={() => {
          setFormModalOpen(false);
          invalidateAllQueries().then();
        }}
      />
    </>
  );
};
