import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';

/**
 * @deprecated Use MGActionMenuItemProps from @mg/ui instead
 */
export type MenuItem = MGActionMenuItemProps;

/**
 * @deprecated Use MGActionMenu from @mg/ui instead
 */
export const ActionsMenu = MGActionMenu;
