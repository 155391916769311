import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { granularStateSchema } from './granularStateSchema.ts'
import { z } from 'zod'

export const transactionSchemaSchema = z.object({
  amount: z.union([z.string(), z.number()]).describe('The amount that is transferred.'),
  currency: z.string().max(4).default('EUR').describe('The currency used in this transaction.'),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  due_date: z.string().date(),
  granular_state: z.lazy(() => granularStateSchema),
  id: z.union([z.number().int(), z.null()]).optional(),
  project: z.union([z.number().int(), z.null()]).optional(),
  reason: z.string().max(255).default('UNKNOWN').describe('The reason for creating this transaction.'),
  state: z.string().max(32).default('initial'),
  voucher_date: z.string().date(),
})