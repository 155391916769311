import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getProjectRemoteReadabilityStatusPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getProjectRemoteReadabilityStatus200Schema = z.enum(['ok', 'error'])

/**
 * @description Unprocessable Entity
 */
export const getProjectRemoteReadabilityStatus422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getProjectRemoteReadabilityStatusQueryResponseSchema = z.lazy(() => getProjectRemoteReadabilityStatus200Schema)