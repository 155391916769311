import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGLoader } from '@mg/ui/src/components/MGLoader/MGLoader';
import { Box, Button, Stack } from '@mui/material';
import { CheckIcon, XIcon } from 'lucide-react';
import { useTranslation } from '../../../../i18n';

export const SendStep = ({
  isPending,
  isError,
  isSuccess,
  dataCount,
  onContinue,
}: {
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  dataCount?: number;
  onContinue: () => void;
}) => {
  const { t } = useTranslation('documents');
  return (
    <>
      {isPending ? (
        <MGLoader />
      ) : isSuccess ? (
        <MGAlert
          icon={<CheckIcon />}
          severity="success"
          title={`${dataCount == 1 ? t('bulk_email_modal_send_step_success_alert_title_one_document') : t('bulk_email_modal_send_step_success_alert_title_multiple_documents', { dataCount })}`}
          subtitle={t('bulk_email_modal_send_step_success_alert_subtitle')}
        />
      ) : isError ? (
        <MGAlert
          icon={<XIcon />}
          severity="error"
          title={t('bulk_email_modal_send_step_error_alert_title')}
          subtitle={t('bulk_email_modal_send_step_error_alert_subtitle')}
        />
      ) : null}

      <Box flex={1} mb={4} />

      <Stack direction="row" justifyContent="end">
        <Button disabled={isPending} variant="contained" onClick={onContinue}>
          {t('bulk_email_modal_send_step_all_good')}
        </Button>
      </Stack>
    </>
  );
};
