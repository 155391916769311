import { MGActionMenu } from '@mg/ui/src/components/MGActionMenu';
import { Button } from '@mui/material';
import { ChevronDown, Download, Plus, RefreshCw } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { SepaXmlGenerationModal } from '../../modals/SepaXmlGenerationModal/SepaXmlGenerationModal';

type AccountingActionMenuProps = {
  onCreateTransactionSuccess: () => void;
};

export const AccountingActionMenu = ({ onCreateTransactionSuccess }: AccountingActionMenuProps) => {
  const [createTransactionModalOpen, setCreateTransactionModalOpen] = useState(false);
  const [sepaXmlGenerationModalOpen, setSepaXmlGenerationModalOpen] = useState(false);
  const { t } = useTranslation('transactions');
  const items = [
    {
      text: t('create_transaction'),
      icon: <Plus size={20} />,
      onClick: () => setCreateTransactionModalOpen(true),
    },
    {
      text: t('upload_mt940'),
      icon: <RefreshCw size={20} />,
      href: '/transactions/mt940_upload',
    },
    {
      text: t('generate_sepa'),
      icon: <Download size={20} />,
      onClick: () => setSepaXmlGenerationModalOpen(true),
    },
  ];

  return (
    <>
      <MGActionMenu
        menuItems={items}
        spacing={15}
        buttonComponent={
          <Button endIcon={<ChevronDown />} variant={'contained'}>
            {t('actions')}
          </Button>
        }
      />
      <DjangoFormModal
        open={createTransactionModalOpen}
        handleToggle={() => setCreateTransactionModalOpen(!createTransactionModalOpen)}
        title={t('create_transaction')}
        pathToDjangoForm={'/transactions/create'}
        onSuccess={() => {
          setCreateTransactionModalOpen(false);
          onCreateTransactionSuccess();
        }}
      />
      <SepaXmlGenerationModal
        open={sepaXmlGenerationModalOpen}
        handleToggle={setSepaXmlGenerationModalOpen}
      />
    </>
  );
};
