import { EmailDocumentSchema } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGBadge } from '@mg/ui/src/components/MGBadge/MGBadge';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { presentDate } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Button, TableCell, TableRow } from '@mui/material';
import { SendIcon, TriangleAlertIcon } from 'lucide-react';
import { useTranslation } from '../../../../i18n';
import { StepWithTable } from './StepWithTable';

export const DocumentStep = ({
  documents,
  onContinue,
}: {
  documents: EmailDocumentSchema[];
  onContinue: (documentsToSend: EmailDocumentSchema[]) => void;
}) => {
  const { t } = useTranslation('documents');
  const numDocuments = documents.length;
  const unsentDocuments = documents.filter((document) => !considerSent(document));
  const numSentDocuments = numDocuments - unsentDocuments.length;
  const numUnsentDocuments = numDocuments - numSentDocuments;

  return (
    <StepWithTable
      alert={
        numSentDocuments > 0 && (
          <MGAlert
            icon={<TriangleAlertIcon />}
            severity="warning"
            title={`Du hast ${numSentDocuments} von ${`${numDocuments} Dokumenten`} bereits versendet`}
            subtitle="Möchtest du diese erneut senden?"
          />
        )
      }
      buttons={
        <>
          <Button variant="outlined" disabled={numDocuments === 0} onClick={() => onContinue(documents)}>
            {t('bulk_email_modal_document_step_send_all', { numDocuments })}
          </Button>
          <Button
            variant="contained"
            disabled={numUnsentDocuments === 0}
            onClick={() => onContinue(unsentDocuments)}
          >
            {t('bulk_email_modal_document_step_send_not_send', { numUnsentDocuments })}
          </Button>
        </>
      }
      tableTitle={
        <>
          {t('bulk_email_modal_document_step_table_title')}{' '}
          <MGBadge sx={{ marginLeft: 0.5 }}>{numDocuments}</MGBadge>
        </>
      }
      rows={documents.map((document) => (
        <Row key={document.id} document={document} />
      ))}
    />
  );
};

const Row = ({ document }: { document: EmailDocumentSchema }) => {
  const { t } = useTranslation('documents');
  return (
    <TableRow>
      <TableCell>{document.file_name_for_delivery}</TableCell>
      <TableCell>
        <MGCustomerWithAvatar customer={document.customer} />
      </TableCell>
      <TableCell>{presentDate(document.main_date)}</TableCell>
      <TableCell>
        <SendIcon
          color={considerSent(document) ? palette.primary.main : palette.gray.main}
          size={20}
          style={{ marginRight: '0.5rem' }}
        />
        {considerSent(document)
          ? t('bulk_email_modal_document_step_already_sent')
          : t('bulk_email_modal_document_step_not_sent_yet')}
      </TableCell>
    </TableRow>
  );
};

const considerSent = (document: EmailDocumentSchema) => {
  return (
    document.delivered_to_customer ||
    (document.deliver_to_customer_task && !document.deliver_to_customer_task.is_finished)
  );
};
