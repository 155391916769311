import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  GetProjectOnboardingCompletenessQueryResponse,
  GetProjectOnboardingCompletenessPathParams,
  GetProjectOnboardingCompleteness422,
} from '../types/GetProjectOnboardingCompleteness.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getProjectOnboardingCompletenessQueryKey = (project_id: GetProjectOnboardingCompletenessPathParams['project_id']) =>
  [{ url: '/api-internal/projects/:project_id/onboarding_completeness', params: { project_id: project_id } }] as const

export type GetProjectOnboardingCompletenessQueryKey = ReturnType<typeof getProjectOnboardingCompletenessQueryKey>

/**
 * @summary Get Project Onboarding Completeness
 * {@link /api-internal/projects/:project_id/onboarding_completeness}
 */
async function getProjectOnboardingCompleteness(project_id: GetProjectOnboardingCompletenessPathParams['project_id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetProjectOnboardingCompletenessQueryResponse, GetProjectOnboardingCompleteness422, unknown>({
    method: 'GET',
    url: `/api-internal/projects/${project_id}/onboarding_completeness`,
    ...config,
  })
  return res.data
}

export function getProjectOnboardingCompletenessQueryOptions(
  project_id: GetProjectOnboardingCompletenessPathParams['project_id'],
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getProjectOnboardingCompletenessQueryKey(project_id)
  return queryOptions<
    GetProjectOnboardingCompletenessQueryResponse,
    GetProjectOnboardingCompleteness422,
    GetProjectOnboardingCompletenessQueryResponse,
    typeof queryKey
  >({
    enabled: !!project_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getProjectOnboardingCompleteness(project_id, config)
    },
  })
}

/**
 * @summary Get Project Onboarding Completeness
 * {@link /api-internal/projects/:project_id/onboarding_completeness}
 */
export function useGetProjectOnboardingCompleteness<
  TData = GetProjectOnboardingCompletenessQueryResponse,
  TQueryData = GetProjectOnboardingCompletenessQueryResponse,
  TQueryKey extends QueryKey = GetProjectOnboardingCompletenessQueryKey,
>(
  project_id: GetProjectOnboardingCompletenessPathParams['project_id'],
  options: {
    query?: Partial<QueryObserverOptions<GetProjectOnboardingCompletenessQueryResponse, GetProjectOnboardingCompleteness422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getProjectOnboardingCompletenessQueryKey(project_id)

  const query = useQuery({
    ...(getProjectOnboardingCompletenessQueryOptions(project_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetProjectOnboardingCompleteness422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}