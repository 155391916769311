import { emailDocumentSchemaSchema } from './emailDocumentSchemaSchema.ts'
import { invoiceFilterSchema } from './invoiceFilterSchema.ts'
import { letterFilterSchema } from './letterFilterSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { paymentPlanFilterSchema } from './paymentPlanFilterSchema.ts'
import { planChangeDocumentFilterSchema } from './planChangeDocumentFilterSchema.ts'
import { sepaMandateFilterSchema } from './sepaMandateFilterSchema.ts'
import { z } from 'zod'

export const bulkEmailPreflightQueryParamsSchema = z
  .object({
    include_customer: z.boolean().default(true),
    include_pdf: z.boolean().default(true),
  })
  .optional()

/**
 * @description OK
 */
export const bulkEmailPreflight200Schema = z.array(z.lazy(() => emailDocumentSchemaSchema))

/**
 * @description Unprocessable Entity
 */
export const bulkEmailPreflight422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const bulkEmailPreflightMutationRequestSchema = z.union([
  z.lazy(() => invoiceFilterSchema),
  z.lazy(() => paymentPlanFilterSchema),
  z.lazy(() => planChangeDocumentFilterSchema),
  z.lazy(() => sepaMandateFilterSchema),
  z.lazy(() => letterFilterSchema),
])

export const bulkEmailPreflightMutationResponseSchema = z.lazy(() => bulkEmailPreflight200Schema)