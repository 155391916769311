import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, ModalProps, Paper, Stack, SxProps } from '@mui/material';
import { cloneElement, ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';

interface MGModalBaseProps {
  showCloseButton?: boolean;
  width?: string;
  height?: string;
  position?: {
    top?: number | string;
    left?: number | string;
    anchorTop?: 'center' | 'top';
    anchorLeft?: 'center' | 'left';
  };
  ModalStyle?: SxProps;
  PaperStyle?: SxProps;
  BodyStyle?: SxProps;
  ModalProps?: ModalProps;
  children: ReactNode | ReactNode[];
}

type ToggleReason = 'closeButtonClick' | 'escapeKeyDown' | 'backdropClick';
interface MGModalControlled {
  open: boolean;
  handleToggle: (e: SyntheticEvent, reason: ToggleReason) => void;
  buttonText?: undefined; // buttonText should be absent
  buttonComponent?: undefined; // buttonComponent should be absent
}

interface MGModalWithDefaultButton {
  buttonText: string;
  handleToggle?: (e: SyntheticEvent, reason: ToggleReason) => void;
  open?: undefined; // open should be absent
  buttonComponent?: undefined; // buttonComponent should be absent
}

interface MGModalWithButtonComponent {
  buttonComponent: ReactElement;
  handleToggle?: (e: SyntheticEvent, reason: ToggleReason) => void;
  open?: undefined; // open should be absent
  buttonText?: undefined; // buttonText should be absent
}

// Define the final type as a union of the three
export type MGModalProps = MGModalBaseProps &
  (MGModalControlled | MGModalWithDefaultButton | MGModalWithButtonComponent);
export const MGModal = ({
  open,
  handleToggle,
  buttonText,
  buttonComponent,
  children,
  width,
  height,
  position = { anchorTop: 'center', anchorLeft: 'left' },
  showCloseButton,
  ModalStyle,
  PaperStyle,
  ModalProps,
}: MGModalProps) => {
  // Positioning
  const style: SxProps = {
    width: width || 'auto',
    minWidth: '300px',
    maxWidth: '100vw',
    height: height,
    maxHeight: '100vh !important',
    top: position?.top || '50% !important',
    left: position?.left || '50% !important',
    transform: `translate(${position?.anchorLeft === 'center' ? '0%' : '-50%'}, ${
      position.anchorTop && position?.anchorTop === 'center' ? '-50%' : '0%'
    }) !important`,
    position: 'absolute',
    ...ModalStyle,
  };

  // Background
  const paperStyle: SxProps = {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: ' 20px !important',
    overflow: 'hidden',
    padding: '30px',
    paddingBottom: '20px',
    paddingRight: '20px',
    ...PaperStyle,
  };

  const modalSx: SxProps = { zIndex: 800 };
  if (ModalProps?.sx) Object.assign(modalSx, ModalProps?.sx);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open || false);
  }, [open]);

  const handleModalToggle = (e: SyntheticEvent, reason) => {
    setIsOpen(!isOpen);
    if (handleToggle) {
      handleToggle(e, reason);
    }
  };

  return (
    <>
      {buttonComponent
        ? cloneElement(buttonComponent, { onClick: () => setIsOpen(!open) })
        : buttonText && (
            <Button variant={'contained'} onClick={() => setIsOpen(!open)}>
              {buttonText}
            </Button>
          )}

      <Modal open={isOpen} onClose={handleModalToggle} {...ModalProps} sx={modalSx}>
        <Box sx={style}>
          <Paper sx={paperStyle}>
            {showCloseButton && (
              <IconButton
                sx={{ position: 'absolute', right: 5, top: 5 }}
                onClick={(e) => handleModalToggle?.(e, 'closeButtonClick')}
              >
                <Close />
              </IconButton>
            )}
            <Stack display={'flex'} height={'100%'} spacing={2}>
              {children}
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
