import { Box } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { MGLoaderButton } from '../MGLoaderButton';
import { TanstackForm } from './MGForm';

interface Props {
  buttonLabel: string;
  form: TanstackForm;
}

export const MGFormSubmitButton = ({ buttonLabel, form }: Props) => {
  return (
    <form.Subscribe
      selector={(state) => [state.canSubmit, state.isSubmitting]}
      children={([canSubmit, isSubmitting]) => (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <MGLoaderButton
            loading={isSubmitting}
            type={'submit'}
            size={'large'}
            endIcon={<ArrowRight />}
            variant={'contained'}
            disabled={!canSubmit}
          >
            {buttonLabel}
          </MGLoaderButton>
        </Box>
      )}
    />
  );
};
