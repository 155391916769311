import { palette } from '@mg/ui/src/styles';
import { Box, Card, Skeleton, Stack, SxProps, Typography } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from '../../../i18n';

type Props = {
  title: ReactNode;
  subtitle: ReactNode;
  icon: ReactNode;
  status?: 'done' | 'todo' | 'coming_soon';
  button: ReactNode;
  loading?: boolean;
  additionalContent?: ReactNode;
  additionalContentLoadingComponent?: ReactElement;
  sx?: SxProps;
  height?: string;
};

/*
 * TODO: Make fully generic and add to /shared
 *  -> 3 Types of Cards: SingleItemCard, ListCard, ContentCard (used on Dashboard)
 * */
export const DashboardCard = ({
  title,
  subtitle,
  icon,
  status,
  button,
  loading,
  additionalContent,
  additionalContentLoadingComponent,
  sx = {},
  height,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const isDone = status === 'done';

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    /* Show skeleton for a minumum of 200ms to prevent a flickering of the loading to data*/
    let timeoutId;

    if (loading) {
      setShowLoader(true);
    } else {
      timeoutId = setTimeout(() => {
        setShowLoader(false);
      }, 300);
    }

    return () => clearTimeout(timeoutId);
  }, [loading]);

  return (
    <Card sx={{ paddingY: '20px', paddingX: '20px', minHeight: '120px', ...sx, width: '100%', height }}>
      <Stack justifyContent={'space-between'} height={'100%'}>
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={1} justifyContent={'space-between'} color={palette.primary.main}>
            {icon}
            {status && (
              <Box
                sx={{
                  width: '100%',
                  height: '36px',
                  backgroundColor: isDone ? palette.success.light : palette.gray.light,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  borderRadius: '8px',
                }}
              >
                <Typography color={isDone ? palette.success.main : palette.gray.dark}>
                  {t(`status_${status}`)}
                </Typography>
              </Box>
            )}
          </Stack>

          <Stack>
            <Typography variant={'regular'} fontWeight={500} color={'text.secondary'}>
              {title}
            </Typography>

            {showLoader ? (
              <>
                <Skeleton />
              </>
            ) : (
              <Box marginTop={'3px'}>
                {' '}
                {typeof subtitle === 'string' ? (
                  <Typography variant={'small'}>{subtitle}</Typography>
                ) : (
                  subtitle
                )}
              </Box>
            )}
          </Stack>
        </Stack>

        {additionalContent && (showLoader ? additionalContentLoadingComponent : additionalContent)}

        <Box>{button}</Box>
      </Stack>
    </Card>
  );
};
