import { bulkEmailSendInSchema } from './bulkEmailSendInSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const bulkEmailSend200Schema = z.array(z.lazy(() => trackedTaskNestedSchemaSchema))

/**
 * @description Unprocessable Entity
 */
export const bulkEmailSend422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const bulkEmailSendMutationRequestSchema = z.lazy(() => bulkEmailSendInSchema)

export const bulkEmailSendMutationResponseSchema = z.lazy(() => bulkEmailSend200Schema)