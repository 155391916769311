import { MGCardVerticalListLayout } from '@mg/ui/src/components/MGCard';
import { presentDate, presentEuroCentPerKilowattHour, presentEuroPerYear } from '@mg/ui/src/presenters';
import { Box, Card, Typography } from '@mui/material';
import { Sun, Zap } from 'lucide-react';

export interface ResidualPlanRowCardProps {
  planName?: string;
  priceBase?: number | string;
  pricePerKwh?: number | string;
  startDate: string | Date;
  endDate?: string | Date | null;
}

export const ResidualPlanRowCard = ({
  planName,
  priceBase,
  pricePerKwh,
  startDate,
  endDate,
}: ResidualPlanRowCardProps) => {
  return (
    <Card>
      <Box sx={{ paddingY: 3, paddingX: 4 }}>
        <MGCardVerticalListLayout>
          <MGCardVerticalListLayout.Item label="Tarifname">{planName}</MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label="Gültigkeit">
            {`${presentDate(startDate)} - ${endDate ? presentDate(endDate) : 'Heute'}`}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label="Grundpreis">
            {presentEuroPerYear(priceBase)}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label="Arbeitspreis">
            <>
              <Typography variant="small" display={'block'}>
                <Sun size={14} fill="#50606B" color="#50606B" /> {presentEuroPerYear(priceBase)}
              </Typography>
              <Typography variant="small" display={'block'}>
                <Zap size={14} fill={'#50606B'} color="#50606B" />{' '}
                {presentEuroCentPerKilowattHour(pricePerKwh)}
              </Typography>
            </>
          </MGCardVerticalListLayout.Item>
        </MGCardVerticalListLayout>
      </Box>
    </Card>
  );
};
