import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  ListSubscriptionsForPanelQueryResponse,
  ListSubscriptionsForPanelPathParams,
  ListSubscriptionsForPanelQueryParams,
  ListSubscriptionsForPanel422,
} from '../types/ListSubscriptionsForPanel.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listSubscriptionsForPanelQueryKey = (id: ListSubscriptionsForPanelPathParams['id'], params?: ListSubscriptionsForPanelQueryParams) =>
  [{ url: '/api-internal/measuring_point_panels/:id/subscriptions', params: { id: id } }, ...(params ? [params] : [])] as const

export type ListSubscriptionsForPanelQueryKey = ReturnType<typeof listSubscriptionsForPanelQueryKey>

/**
 * @summary List Subscriptions For Panel
 * {@link /api-internal/measuring_point_panels/:id/subscriptions}
 */
async function listSubscriptionsForPanel(
  id: ListSubscriptionsForPanelPathParams['id'],
  params?: ListSubscriptionsForPanelQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListSubscriptionsForPanelQueryResponse, ListSubscriptionsForPanel422, unknown>({
    method: 'GET',
    url: `/api-internal/measuring_point_panels/${id}/subscriptions`,
    params,
    ...config,
  })
  return res.data
}

export function listSubscriptionsForPanelQueryOptions(
  id: ListSubscriptionsForPanelPathParams['id'],
  params?: ListSubscriptionsForPanelQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listSubscriptionsForPanelQueryKey(id, params)
  return queryOptions<ListSubscriptionsForPanelQueryResponse, ListSubscriptionsForPanel422, ListSubscriptionsForPanelQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listSubscriptionsForPanel(id, params, config)
    },
  })
}

/**
 * @summary List Subscriptions For Panel
 * {@link /api-internal/measuring_point_panels/:id/subscriptions}
 */
export function useListSubscriptionsForPanel<
  TData = ListSubscriptionsForPanelQueryResponse,
  TQueryData = ListSubscriptionsForPanelQueryResponse,
  TQueryKey extends QueryKey = ListSubscriptionsForPanelQueryKey,
>(
  id: ListSubscriptionsForPanelPathParams['id'],
  params?: ListSubscriptionsForPanelQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListSubscriptionsForPanelQueryResponse, ListSubscriptionsForPanel422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listSubscriptionsForPanelQueryKey(id, params)

  const query = useQuery({
    ...(listSubscriptionsForPanelQueryOptions(id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListSubscriptionsForPanel422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}