import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetLetterPdfQueryResponse, GetLetterPdfPathParams, GetLetterPdf400 } from '../types/GetLetterPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLetterPdfQueryKey = (id: GetLetterPdfPathParams['id']) => [{ url: '/api-internal/letters/:id/pdf', params: { id: id } }] as const

export type GetLetterPdfQueryKey = ReturnType<typeof getLetterPdfQueryKey>

/**
 * @summary Get Letter Pdf
 * {@link /api-internal/letters/:id/pdf}
 */
async function getLetterPdf(id: GetLetterPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetLetterPdfQueryResponse, GetLetterPdf400, unknown>({ method: 'GET', url: `/api-internal/letters/${id}/pdf`, ...config })
  return res.data
}

export function getLetterPdfQueryOptions(id: GetLetterPdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getLetterPdfQueryKey(id)
  return queryOptions<GetLetterPdfQueryResponse, GetLetterPdf400, GetLetterPdfQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLetterPdf(id, config)
    },
  })
}

/**
 * @summary Get Letter Pdf
 * {@link /api-internal/letters/:id/pdf}
 */
export function useGetLetterPdf<TData = GetLetterPdfQueryResponse, TQueryData = GetLetterPdfQueryResponse, TQueryKey extends QueryKey = GetLetterPdfQueryKey>(
  id: GetLetterPdfPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetLetterPdfQueryResponse, GetLetterPdf400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLetterPdfQueryKey(id)

  const query = useQuery({
    ...(getLetterPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetLetterPdf400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}