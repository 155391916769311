import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';
import { ResetPasswordForm } from './ResetPassword.Form.tsx';

export const ResetPassword = () => {
  const { t } = useTranslation('login');
  return (
    <Stack maxWidth={'900px'} justifyContent={'center'} spacing={5}>
      <Stack spacing={3}>
        <Typography variant={'h1'} fontWeight={'500'}>
          {t('reset_password')}
        </Typography>
        <Typography variant={'regular'} fontWeight={'300'}>
          {t('reset_password_subtitle')}
        </Typography>
      </Stack>
      <ResetPasswordForm />
    </Stack>
  );
};
