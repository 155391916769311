import { z } from 'zod'

/**
 * @description One entry in ParamValidationErrorOut.errors
 */
export const paramValidationErrorInstanceOutSchema = z
  .object({
    loc: z.array(z.string()),
    msg: z.string(),
    type: z.string(),
  })
  .describe('One entry in ParamValidationErrorOut.errors')