import { billingInFutureSchema } from './billingInFutureSchema.ts'
import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { startDateAfterEndDateSchema } from './startDateAfterEndDateSchema.ts'
import { subscriptionAlreadyEndedSchema } from './subscriptionAlreadyEndedSchema.ts'
import { subscriptionNotStartedYetSchema } from './subscriptionNotStartedYetSchema.ts'
import { wasBilledAlreadySchema } from './wasBilledAlreadySchema.ts'
import { z } from 'zod'

export const billingIntervalSchemaOutputSchema = z.object({
  customer: z.lazy(() => customerNestedSchemaSchema),
  end_date: z.union([z.string().date(), z.null()]),
  errors: z.union([
    z.array(
      z.union([
        z.lazy(() => wasBilledAlreadySchema),
        z.lazy(() => subscriptionNotStartedYetSchema),
        z.lazy(() => subscriptionAlreadyEndedSchema),
        z.lazy(() => startDateAfterEndDateSchema),
        z.lazy(() => billingInFutureSchema),
      ]),
    ),
    z.null(),
  ]),
  start_date: z.union([z.string().date(), z.null()]),
  subscription: z.lazy(() => measuringPointSubscriptionNestedSchemaSchema),
})