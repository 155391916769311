import { Stack } from '@mui/material';
import { SidebarMainItems } from './Sidebar.MainItems';
import { SidebarProjectSelector } from './Sidebar.ProjectSelector';
import { SidebarSearch } from './Sidebar.Search';
import { SidebarSettings } from './Sidebar.Settings';

export const Sidebar = ({ width = '280px' }: { width?: string }) => {
  return (
    <Stack
      justifyContent={'space-between'}
      spacing={3}
      sx={{
        height: '100vh',
        width: width,
        backgroundColor: '#F6F5F3',
        zIndex: 1,
        boxShadow: '1px 0 10px -2px #888',
        overflow: 'auto',
        padding: '20px',
        position: 'relative',
      }}
    >
      <Stack spacing={3}>
        <SidebarProjectSelector />
        <SidebarSearch />
        <SidebarMainItems />
      </Stack>

      <SidebarSettings />
    </Stack>
  );
};
