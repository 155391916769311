import {
  CustomerNestedSchema,
  DeliverableDocumentType,
  PdfNestedSchema,
  TrackedTaskNestedSchema,
} from '@mg/api-wrappers/src/api-internal';
import { QueryKey } from '@tanstack/react-query';
import { atom } from 'jotai/index';

export type DeliverableDocument = {
  id: number;
  delivered_to_customer: boolean;
  delivery_in_progress?: boolean;
  deliver_to_customer_task?: TrackedTaskNestedSchema | null;
  generate_pdf_task?: TrackedTaskNestedSchema | null;
  customer?: CustomerNestedSchema | null;
  pdf?: PdfNestedSchema | null;
};

export type DocumentContext<TDocument extends DeliverableDocument = DeliverableDocument> = {
  document: TDocument;
  documentType: DeliverableDocumentType;
  queryKey: QueryKey;
};

export const documentContextAtom = atom<DocumentContext | null>(null);
