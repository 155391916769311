export const invoiceFilterCategory = {
  'regular invoice': 'regular invoice',
  'cancellation invoice': 'cancellation invoice',
} as const

export type InvoiceFilterCategory = (typeof invoiceFilterCategory)[keyof typeof invoiceFilterCategory]

export const invoiceFilterState = {
  issued: 'issued',
  canceled: 'canceled',
} as const

export type InvoiceFilterState = (typeof invoiceFilterState)[keyof typeof invoiceFilterState]

export type InvoiceFilter = {
  category?: InvoiceFilterCategory | null
  customer?: number | null
  customer_id?: number | null
  /**
   * @default "invoice"
   */
  document_type?: 'invoice'
  ids?: number[] | null
  issue_date_from?: string | null
  issue_date_to?: string | null
  /**
   * @type integer
   */
  project_id: number
  search?: string | null
  state?: InvoiceFilterState | null
}