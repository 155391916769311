import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { GetInvoicePdfQueryResponse, GetInvoicePdfPathParams, GetInvoicePdf400 } from '../types/GetInvoicePdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getInvoicePdfQueryKey = (id: GetInvoicePdfPathParams['id']) => [{ url: '/api-internal/invoices/:id/pdf', params: { id: id } }] as const

export type GetInvoicePdfQueryKey = ReturnType<typeof getInvoicePdfQueryKey>

/**
 * @summary Get Invoice Pdf
 * {@link /api-internal/invoices/:id/pdf}
 */
async function getInvoicePdf(id: GetInvoicePdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const res = await client<GetInvoicePdfQueryResponse, GetInvoicePdf400, unknown>({ method: 'GET', url: `/api-internal/invoices/${id}/pdf`, ...config })
  return res.data
}

export function getInvoicePdfQueryOptions(id: GetInvoicePdfPathParams['id'], config: Partial<RequestConfig> = {}) {
  const queryKey = getInvoicePdfQueryKey(id)
  return queryOptions<GetInvoicePdfQueryResponse, GetInvoicePdf400, GetInvoicePdfQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getInvoicePdf(id, config)
    },
  })
}

/**
 * @summary Get Invoice Pdf
 * {@link /api-internal/invoices/:id/pdf}
 */
export function useGetInvoicePdf<
  TData = GetInvoicePdfQueryResponse,
  TQueryData = GetInvoicePdfQueryResponse,
  TQueryKey extends QueryKey = GetInvoicePdfQueryKey,
>(
  id: GetInvoicePdfPathParams['id'],
  options: {
    query?: Partial<QueryObserverOptions<GetInvoicePdfQueryResponse, GetInvoicePdf400, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getInvoicePdfQueryKey(id)

  const query = useQuery({
    ...(getInvoicePdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetInvoicePdf400> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}