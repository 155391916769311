import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { Button } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';
import { CustomersRowActionMenu } from '../../../Customers/Customers.RowActionMenu';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataActionMenu = ({ customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  return (
    <CustomersRowActionMenu
      customerId={customer?.id as number}
      subscriptionId={customer?.subscription?.id}
      status={'active'}
      hideGoToProfile
      buttonComponent={
        <Button variant={'contained'} endIcon={<ChevronDown />}>
          {t('data_menu_actions')}
        </Button>
      }
    />
  );
};
