import { billingPreflightLevyStructureOutSchema } from './billingPreflightLevyStructureOutSchema.ts'
import { billingPreflightResidualPowerPlanOutSchema } from './billingPreflightResidualPowerPlanOutSchema.ts'
import { billingPreflightSubscriptionOutSchema } from './billingPreflightSubscriptionOutSchema.ts'
import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { electricPriceBreakNeedsToBeAppliedSchema } from './electricPriceBreakNeedsToBeAppliedSchema.ts'
import { electricPriceBreakQuotaNeededSchema } from './electricPriceBreakQuotaNeededSchema.ts'
import { inFutureBillingSchema } from './inFutureBillingSchema.ts'
import { missingDataFromMeasuringPointSchema } from './missingDataFromMeasuringPointSchema.ts'
import { missingLevyStructureForIntervalSchema } from './missingLevyStructureForIntervalSchema.ts'
import { missingMeasuringPointDataSchema } from './missingMeasuringPointDataSchema.ts'
import { missingPanelSchema } from './missingPanelSchema.ts'
import { missingPanelTypeSchema } from './missingPanelTypeSchema.ts'
import { missingPlanForIntervalSchema } from './missingPlanForIntervalSchema.ts'
import { multipleInoutPanelsSchema } from './multipleInoutPanelsSchema.ts'
import { noActivePlanSchema } from './noActivePlanSchema.ts'
import { noActiveSubscriptionSchema } from './noActiveSubscriptionSchema.ts'
import { noLevyStructureSchema } from './noLevyStructureSchema.ts'
import { noResidualPowerPlanSchema } from './noResidualPowerPlanSchema.ts'
import { noValidBillingIntervalSchema } from './noValidBillingIntervalSchema.ts'
import { subscriptionEndedSchema } from './subscriptionEndedSchema.ts'
import { subscriptionNotActiveSchema } from './subscriptionNotActiveSchema.ts'
import { subscriptionNotBillableSchema } from './subscriptionNotBillableSchema.ts'
import { transactionNestedSchemaSchema } from './transactionNestedSchemaSchema.ts'
import { z } from 'zod'

export const billingPreflightOutSchema = z.object({
  billing_end: z.string().date(),
  billing_start: z.string().date(),
  customer: z.lazy(() => customerNestedSchemaSchema),
  errors: z.array(
    z.union([
      z.lazy(() => inFutureBillingSchema),
      z.lazy(() => noActiveSubscriptionSchema),
      z.lazy(() => subscriptionNotBillableSchema),
      z.lazy(() => noValidBillingIntervalSchema),
      z.lazy(() => missingMeasuringPointDataSchema),
      z.lazy(() => missingDataFromMeasuringPointSchema),
      z.lazy(() => missingLevyStructureForIntervalSchema),
      z.lazy(() => subscriptionNotActiveSchema),
      z.lazy(() => subscriptionEndedSchema),
      z.lazy(() => missingPanelTypeSchema),
      z.lazy(() => multipleInoutPanelsSchema),
      z.lazy(() => electricPriceBreakNeedsToBeAppliedSchema),
      z.lazy(() => electricPriceBreakQuotaNeededSchema),
      z.lazy(() => missingPanelSchema),
    ]),
  ),
  is_billable: z.boolean(),
  levy_structure_errors: z.array(z.union([z.lazy(() => noLevyStructureSchema), z.lazy(() => missingLevyStructureForIntervalSchema)])),
  levy_structure_intervals: z.union([z.array(z.lazy(() => billingPreflightLevyStructureOutSchema)), z.null()]),
  residual_power_plan_errors: z.array(z.lazy(() => noResidualPowerPlanSchema)),
  residual_power_plan_intervals: z.union([z.array(z.lazy(() => billingPreflightResidualPowerPlanOutSchema)), z.null()]),
  subscription_id: z.number().int(),
  subscription_plan_errors: z.array(z.union([z.lazy(() => noActivePlanSchema), z.lazy(() => missingPlanForIntervalSchema)])),
  subscription_plan_intervals: z.union([z.array(z.lazy(() => billingPreflightSubscriptionOutSchema)), z.null()]),
  transactions: z.array(z.lazy(() => transactionNestedSchemaSchema)),
})