import { palette } from '@mg/ui/src/styles';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useTranslation } from '../../../../i18n';

type Props = {
  step: string;
};

export const OnboardingStepper = ({ step }: Props) => {
  const { t } = useTranslation('onboarding');
  const stepMap = {
    project_data: 0,
    plans_and_levies: 1,
    metering: 2,
  };
  return (
    <Box display={'flex'} justifyContent={'center'} paddingY={'20px'}>
      <Stepper activeStep={stepMap[step]} orientation={'horizontal'} sx={{ width: '60%' }}>
        <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
          <StepLabel
            sx={{
              '.Mui-completed': { fontWeight: 300, color: 'inherit' },
              '.Mui-disabled': { fontWeight: 300, color: 'inherit' },
            }}
          >
            <Typography variant={'small'}>{t('onboarding_stepper_project_data_title')}</Typography>
          </StepLabel>
        </Step>
        <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
          <StepLabel
            sx={{
              '.Mui-completed': { fontWeight: 300, color: 'inherit' },
              '.Mui-disabled': { fontWeight: 300, color: 'inherit' },
            }}
          >
            <Typography variant={'small'}>{t('onboarding_stepper_plans_and_levies_title')}</Typography>
          </StepLabel>
        </Step>
        <Step sx={{ '.Mui-completed .MuiStepIcon-root': { color: palette.success.main } }}>
          <StepLabel
            sx={{
              '.Mui-completed': { fontWeight: 300, color: 'inherit' },
              '.Mui-disabled': { fontWeight: 300, color: 'inherit' },
            }}
          >
            <Typography variant={'small'}>{t('onboarding_stepper_metering_title')}</Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};
