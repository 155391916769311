import {
  MeasuringPointPanelSchema,
  useCountCustomers,
  useListCustomersInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerStatusChip } from '@mg/ui/src/components/MGCustomerStatusChip';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { presentDate, presentEuro } from '@mg/ui/src/presenters';
import { Box, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { MGTabProps } from '../../shared/MetergridTabs';
import { PageLayout } from '../../shared/PageLayout';
import { CustomersActionMenu } from './Customers.ActionMenu';
import { CustomersNoRowsOverlay } from './Customers.NoRowsOverlay';
import { CustomersRowActionMenu } from './Customers.RowActionMenu';

const TAB_VALUES = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const Customers = () => {
  const [tabFilter, setTabFilter] = useState<{ isActive: string } | object>({});
  const projectFilter = useSelectedProjectsFilter();
  const { t, tString } = useTranslation('customers');
  const countQuery = useCountCustomers(projectFilter);

  const navigate = useNavigate();

  const handleTabChange = (key: string) => {
    switch (key) {
      case TAB_VALUES.ALL:
        setTabFilter({});
        break;
      case TAB_VALUES.ACTIVE:
        setTabFilter({ is_active: true });
        break;
      case TAB_VALUES.INACTIVE:
        setTabFilter({ is_active: false });
        break;
    }
  };

  const tabs: MGTabProps[] = [
    {
      label: tString('tab_all'),
      value: TAB_VALUES.ALL,
      count: countQuery.data?.total,
    },
    {
      label: tString('tab_active'),
      value: TAB_VALUES.ACTIVE,
      count: countQuery.data?.active,
    },
    {
      label: tString('tab_inactive'),
      value: TAB_VALUES.INACTIVE,
      count: countQuery.data?.inactive,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
    },
    {
      field: 'customer',
      headerName: tString('header_display_name'),
      sortable: false,
      flex: 1.2,
      renderCell: (params) => {
        return <MGCustomerWithAvatar customer={params.row} />;
      },
    },
    {
      field: 'measuring_point_panel',
      headerName: tString('header_measuring_point_panel'),
      flex: 0.7,
      sortable: false,
      valueGetter: (value: MeasuringPointPanelSchema) => value?.name || '-',
    },
    {
      field: 'payment_method',
      headerName: tString('header_payment_method'),
      flex: 0.6,
      sortable: false,
      valueGetter: (value: string) => t(`payment_method_${value.toLowerCase()}`),
    },
    {
      field: 'last_billing_date',
      headerName: tString('header_last_invoice_date'),
      flex: 0.6,
      sortable: false,
      valueGetter: (value: string | null) => presentDate(value),
    },
    {
      field: 'total_open_amount',
      headerName: tString('header_total_open_amount'),
      flex: 0.5,
      sortable: false,
      valueGetter: (value: number | null) => (value ? presentEuro(value) : '-'),
    },
    {
      field: 'status',
      headerName: tString('header_is_active'),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return <MGCustomerStatusChip status={params.value} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 0.2,
      renderCell: (params) => (
        <CustomersRowActionMenu
          status={params.row.status}
          customerId={params.row.id}
          subscriptionId={params.row.subscription?.id}
        />
      ),
    },
  ];

  return (
    <PageLayout
      title={t('page_title')}
      subtitle={t('page_subtitle')}
      rightSection={
        <Stack direction="row" gap={2.5}>
          <CustomersActionMenu />
        </Stack>
      }
    >
      <FilterProvider>
        <Box sx={{ height: '100%' }}>
          <DataGridCard
            height={'calc(100vh - 300px)'}
            reactQueryHook={useListCustomersInfinite}
            reactQueryHookParams={{
              ...projectFilter,
              ...tabFilter,
              include_measuring_point_panel: true,
              include_total_open_amount: true,
              include_last_billing_date: true,
              include_subscription: true,
            }}
            columns={columns}
            showSearch
            searchPlaceholder={tString('search_placeholder')}
            tabs={tabs}
            defaultSort="status"
            datagridProps={{
              columnVisibilityModel: { id: false },
              onRowClick: (row) => navigate(`/customers/${row.id}/`),
              slots: {
                noRowsOverlay: () => <CustomersNoRowsOverlay />,
              },
            }}
            onTabChange={handleTabChange}
          />
        </Box>
      </FilterProvider>
    </PageLayout>
  );
};
