import {
  Addchart,
  CompareArrows,
  DeleteForeverOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';

import { MeasuringPointPanelSchema } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles';
import axios from 'axios';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';

type Props = {
  rowId: number;
  customerId?: number | null;
  row: MeasuringPointPanelSchema;
};
export const MeteringActionsMenu = ({ rowId, customerId, row }: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [addMeterModalOpen, setAddMeterModalOpen] = useState(false);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const { t } = useTranslation('metering');

  const menuItems: MenuItem[] = [
    {
      text: t('change_name'),
      icon: <EditOutlined />,
      onClick: () => setEditModalOpen(!editModalOpen),
    },
    {
      text: t('add_data'),
      icon: <Addchart />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
      disabled: !row.current_measuring_point,
    },
    {
      text: t('add_meter'),
      icon: <CompareArrows />,
      onClick: () => setAddMeterModalOpen(!addMeterModalOpen),
    },
    {
      text: t('delete'),
      disabled: !row.is_deletable,
      icon: <DeleteForeverOutlined color={'error'} />,
      disabledHint: t('disabled_hint'),
      onClick: () => {
        fireSweetalert({
          title: t('delete_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios.get(`/metering/panels/${rowId}/delete`).then(() => {
              invalidateAllQueries();
            });
          }
        });
      },
    },
  ];

  if (customerId) {
    menuItems.unshift({
      text: t('show_customer'),
      icon: <VisibilityOutlined />,
      href: `/customers/${customerId}`,
    });
  }

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/panels/${rowId}/edit`}
        title={t('edit_panel')}
        open={editModalOpen}
        handleToggle={() => {
          setEditModalOpen(!editModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setEditModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?panel_id=${rowId}`}
        title={t('add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/meters/create`}
        title={t('add_meter')}
        open={addMeterModalOpen}
        handleToggle={() => {
          setAddMeterModalOpen(!addMeterModalOpen);
          setActionMenuOpen(!actionMenuOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddMeterModalOpen(false);
          setActionMenuOpen(!actionMenuOpen);
        }}
        prefill={[{ id: 'id_panel', value: rowId }]}
      />
    </>
  );
};
