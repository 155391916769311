import { TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';

type InputType =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'tel'
  | 'search'
  | 'range'
  | 'textarea'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'file'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'time'
  | 'week'
  | 'month';

export interface MGConfirmModalComponentInputProps {
  input: InputType;
  inputLabel?: string;
  value: string | Dayjs | null;
  onChange: (value: string) => void;
}

export const MGConfirmModalComponentInput = ({
  input,
  inputLabel,
  value,
  onChange,
}: MGConfirmModalComponentInputProps) => {
  switch (input) {
    case 'text':
      return (
        <TextField
          name={'value'}
          label={inputLabel}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
          <DatePicker
            value={dayjs(value)}
            onChange={(value) => {
              onChange(value?.format('YYYY-MM-DD') || '');
            }}
            label={inputLabel}
            slotProps={{
              textField: {
                name: 'value',
              },
            }}
          />
        </LocalizationProvider>
      );
    default:
      return (
        <Typography color={'error'} textAlign={'center'}>
          {`Type ${input} not implemented in MGConfirmModal.Component.Input.tsx`}
        </Typography>
      );
  }
};
