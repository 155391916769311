import { deliverableDocumentType, LetterSchema, useGetLetterPdf } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Edit2, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { ActionsMenu, MenuItem } from '../../shared/ActionsMenu';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

import { useDocumentContext } from '../../features/documents/document-context/document-context';

export const DocumentsLetterTableRowActionMenu = () => {
  const { document, queryKey } = useDocumentContext<LetterSchema>();
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const { t } = useTranslation('documents');
  const navigate = useNavigate();
  const getLetterPdfQuery = useGetLetterPdf(document.id, { query: { enabled: false } });
  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.letter,
    downloadPdfQuery: getLetterPdfQuery,
  });

  const queryClient = useQueryClient();

  const menuItems: MenuItem[] = [
    ...commonItems,
    {
      text: t('edit'),
      icon: <Edit2 size={'18'} />,
      onClick: () => navigate(`/documents/letter/${document.id}/edit/`),
    },
    {
      text: t('delete'),
      icon: <Trash2 color={palette.error.main} size={'18'} />,
      onClick: () => {
        fireSweetalert({
          title: t('delete_letter_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_letter_text', { subject: document.subject }),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axios.get(`/letter/${document.id}/delete/`).then(() => {
              queryClient.invalidateQueries({ queryKey }, { cancelRefetch: false });
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <ActionsMenu
        menuItems={menuItems}
        open={actionMenuOpen}
        onToggle={() => setActionMenuOpen(!actionMenuOpen)}
      />
    </>
  );
};
