import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { publicErrorSchema } from './publicErrorSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const getCustomerDataExport200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const getCustomerDataExport400Schema = z.lazy(() => publicErrorSchema)

/**
 * @description Unprocessable Entity
 */
export const getCustomerDataExport422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getCustomerDataExportQueryResponseSchema = z.lazy(() => getCustomerDataExport200Schema)