import { LetterCreateSchema } from '@mg/api-wrappers/src/api-internal';
import { Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '../../shared/Loader/Loader';
import { generatePreviewPdf } from './CreateLetter.utils';

type CreateLetterDocumentPreviewProps = {
  letter?: LetterCreateSchema;
};
export const CreateLetterDocumentPreview = ({ letter }: CreateLetterDocumentPreviewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<string>();
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const generatePreviewPdfUrl = useCallback(async () => {
    if (!letter) return;
    setIsLoading(true);
    try {
      const pdfBlob = await generatePreviewPdf(letter);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setData(pdfUrl);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [letter]);

  useEffect(() => {
    generatePreviewPdfUrl();
  }, [generatePreviewPdfUrl]);

  return (
    <>
      <Box sx={{ height: 496 }}>
        {isLoading && <Loader />}
        {!isLoading && !error && <iframe ref={iFrameRef} src={data} height={'100%'} width={'100%'} />}
      </Box>
    </>
  );
};
