import { useRequestPasswordReset } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm';
import { MGFormFieldText } from '@mg/ui/src/components/MGForm/MGForm.Field.Text.tsx';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton.tsx';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { ArrowLeft, Check } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { useTranslation } from '../../../i18n.tsx';

export const ResetPasswordForm = () => {
  const { tString } = useTranslation('login');
  const [emailSubitted, setEmailSubmitted] = useState(false);
  const resetPasswordMutation = useRequestPasswordReset();

  const form = useForm({
    onSubmit: async ({ value }) => {
      resetPasswordMutation.mutateAsync(
        { data: { email: value.email } },
        {
          onSuccess: () => setEmailSubmitted(true),
        },
      );
      setEmailSubmitted(true);
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({ email: z.string().email(tString('invalid_email')) }),
    },
    defaultValues: {
      email: '',
    },
  });

  if (!emailSubitted) {
    return (
      <MGForm form={form}>
        <MGFormFieldText name={'email'} form={form} label={tString('email')} />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Button startIcon={<ArrowLeft />} component={Link} to={'/login'}>
            {tString('btn_back_to_login')}
          </Button>
          <MGFormSubmitButton buttonLabel={tString('btn_submit_password_reset')} form={form} />
        </Stack>
      </MGForm>
    );
  }
  return (
    <Stack spacing={2}>
      <MGAlert severity={'success'} title={tString('email_sent')} icon={<Check />} />
      <Card sx={{ padding: 3 }}>
        <Stack spacing={1}>
          <Typography variant={'small'} fontWeight={300}>
            {tString('password_reset_info_1')}
          </Typography>
          <Typography variant={'small'} fontWeight={300}>
            {tString('password_reset_info_2')}
          </Typography>
        </Stack>
      </Card>

      <Box>
        <Button startIcon={<ArrowLeft />} onClick={() => setEmailSubmitted(false)}>
          {tString('btn_try_again')}
        </Button>
      </Box>
    </Stack>
  );
};
