import { Box } from '@mui/material';
import { palette } from '../../styles/palette';
import { MGLoader } from '../MGLoader';

export interface MGStatusIndicatorProps {
  status: 'success' | 'error' | 'warning' | 'info';
  loading?: boolean;
}

export const MGStatusIndicator = ({ status, loading = false }: MGStatusIndicatorProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Box
        sx={{
          width: '10px',
          height: '10px',
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: '20px',
              height: '10px',
            }}
          >
            <MGLoader />
          </Box>
        ) : (
          <Box
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '20px',
              backgroundColor: palette[status].main,
              boxShadow: `0px 0px 4px 5px ${palette[status].light}`,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
