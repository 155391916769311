import { billingInSchema } from './billingInSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const billing200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const billing400Schema = z.string()

/**
 * @description Unprocessable Entity
 */
export const billing422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const billingMutationRequestSchema = z.array(z.lazy(() => billingInSchema))

export const billingMutationResponseSchema = z.lazy(() => billing200Schema)