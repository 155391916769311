import { ChangeEvent } from 'react';
import { MGFieldPassword } from './MGField/MGField.Password';
import { MGFormFieldBaseProps } from './MGForm.Field';

interface Props extends MGFormFieldBaseProps {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const MGFormFieldPassword = ({
  label = '',
  form,
  name,
  FieldProps,
  TextFieldProps,
  onChange,
}: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldPassword
          value={field.state.value || ''}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            field.handleChange(e.target.value);
          }}
          onBlur={field.handleBlur}
          name={field.name}
          label={label}
          TextFieldProps={TextFieldProps}
          errors={
            typeof field.state.meta.errors[0] === 'string'
              ? [field.state.meta.errors[0].split(',')[0]]
              : field.state.meta.errors
          } // Only show first error if it's a string
        />
      )}
    </form.Field>
  );
};
