import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countPlanChangeDocumentsQueryParamsSchema = z.object({
  document_type: z.literal('plan_change').default('plan_change'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  valid_from_from: z.union([z.string().datetime(), z.null()]).optional(),
  valid_from_to: z.union([z.string().datetime(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const countPlanChangeDocuments200Schema = z.number().int()

/**
 * @description Unprocessable Entity
 */
export const countPlanChangeDocuments422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countPlanChangeDocumentsQueryResponseSchema = z.lazy(() => countPlanChangeDocuments200Schema)