import { Box, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { palette } from '../../styles';

export type MGBannerProps = PropsWithChildren<{
  sx?: SxProps;
}>;
export const MGBanner = ({ children, sx }: MGBannerProps) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: 10,
      paddingX: 5,
      paddingY: 2,
      backgroundColor: palette.white.main,
      boxShadow: '0 6px 6px -6px gray',
      zIndex: 200,
      ...sx,
    }}
  >
    {children}
  </Box>
);
