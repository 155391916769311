import { ReactNode, useCallback, useRef, useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { Box, Typography } from '@mui/material';
import isHotkey from 'is-hotkey';
import { Bold, Italic, List, Underline } from 'lucide-react';
import { palette } from '../../styles/palette';
import {
  DEFAULT_INITIAL_VALUE,
  DEFAULT_MIN_HEIGHT,
  DEFAULT_PLACEHOLDER,
  HOTKEYS,
} from './MGRichTextEditor.constants';
import { Descendant } from './MGRichTextEditor.CustomTypes';
import { EditorApi } from './MGRichTextEditor.EditorApi';
import { MGRichTextEditorElement as Element } from './MGRichTextEditor.Element';
import { MGRichTextEditorLeaf as Leaf } from './MGRichTextEditor.Leaf';
import { MGRichTextEditorPlaceholder as Placeholder } from './MGRichTextEditor.Placeholder';
import { MGRichTextEditorToolbar as Toolbar } from './MGRichTextEditor.Toolbar';

export type MGRichTextEditorProps = {
  label?: ReactNode;
  initialValue?: Descendant[];
  placeholder?: string;
  minHeight?: number | string;
  onAstChange: (value: Descendant[]) => void;
};

export const MGRichTextEditor = ({
  label,
  initialValue,
  placeholder,
  minHeight,
  onAstChange,
}: MGRichTextEditorProps) => {
  const [isEditableFocused, setIsEditableFocused] = useState(false);
  const editableRef = useRef<HTMLDivElement | null>(null);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderPlaceholder = useCallback((props) => <Placeholder {...props} />, []);
  const [editor] = useState(() => withReact(withHistory(createEditor())));

  return (
    <Box
      onClick={() => editableRef.current?.focus()}
      sx={{
        border: `1px solid ${palette.gray.main}`,
        borderRadius: 1,
        outline: isEditableFocused ? '1px solid ' + palette.primary.main : 'none',
        paddingX: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      {label && <Typography variant="extraSmall">{label}</Typography>}

      <Slate
        editor={editor}
        initialValue={initialValue ? initialValue : DEFAULT_INITIAL_VALUE}
        onChange={(value) => {
          const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
          if (isAstChange) onAstChange(value);
        }}
      >
        <Editable
          ref={editableRef}
          placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
          style={{ outline: 'none', minHeight: minHeight ?? DEFAULT_MIN_HEIGHT }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          renderPlaceholder={renderPlaceholder}
          spellCheck
          autoFocus
          onFocus={() => setIsEditableFocused(true)}
          onBlur={() => setIsEditableFocused(false)}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                EditorApi.toggleMark(editor, mark);
              }
            }
          }}
        />

        <Toolbar>
          <Toolbar.MarkButton format="bold" icon={Bold} />
          <Toolbar.MarkButton format="italic" icon={Italic} />
          <Toolbar.MarkButton format="underline" icon={Underline} />
          <Toolbar.BlockButton format="bulleted-list" icon={List} />
        </Toolbar>
      </Slate>
    </Box>
  );
};
