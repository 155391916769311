import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { CreateMeasuringPointDataModal } from '../../modals/CreateMeasuringPointDataModal';
import { PageLayout } from '../../shared/PageLayout';
import { MeteringDetailsCustomerList } from './MeteringDetails.CustomerList';
import { MeteringDetailsMeasuringPointList } from './MeteringDetails.MeasuringPointList';
import { MeteringDetailsOverview } from './MeteringDetails.Overview';

export const MeteringDetails = () => {
  const { id: panelId } = useParams();
  const { t } = useTranslation('meteringDetails');

  return (
    <PageLayout
      title={t('metering_details_page_title')}
      subtitle={t('metering_details_page_subtitle')}
      rightSection={
        <>
          <CreateMeasuringPointDataModal panelId={panelId} />
        </>
      }
    >
      <Stack spacing={5}>
        <MeteringDetailsOverview panelId={panelId} />
        <MeteringDetailsMeasuringPointList panelId={panelId} />
        <MeteringDetailsCustomerList panelId={panelId} />
      </Stack>
    </PageLayout>
  );
};
