import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  GetMeasuringPointPanelQueryResponse,
  GetMeasuringPointPanelPathParams,
  GetMeasuringPointPanelQueryParams,
  GetMeasuringPointPanel422,
} from '../types/GetMeasuringPointPanel.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getMeasuringPointPanelQueryKey = (id: GetMeasuringPointPanelPathParams['id'], params?: GetMeasuringPointPanelQueryParams) =>
  [{ url: '/api-internal/measuring_point_panels/:id', params: { id: id } }, ...(params ? [params] : [])] as const

export type GetMeasuringPointPanelQueryKey = ReturnType<typeof getMeasuringPointPanelQueryKey>

/**
 * @summary Get Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
async function getMeasuringPointPanel(
  id: GetMeasuringPointPanelPathParams['id'],
  params?: GetMeasuringPointPanelQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetMeasuringPointPanelQueryResponse, GetMeasuringPointPanel422, unknown>({
    method: 'GET',
    url: `/api-internal/measuring_point_panels/${id}`,
    params,
    ...config,
  })
  return res.data
}

export function getMeasuringPointPanelQueryOptions(
  id: GetMeasuringPointPanelPathParams['id'],
  params?: GetMeasuringPointPanelQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getMeasuringPointPanelQueryKey(id, params)
  return queryOptions<GetMeasuringPointPanelQueryResponse, GetMeasuringPointPanel422, GetMeasuringPointPanelQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getMeasuringPointPanel(id, params, config)
    },
  })
}

/**
 * @summary Get Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
export function useGetMeasuringPointPanel<
  TData = GetMeasuringPointPanelQueryResponse,
  TQueryData = GetMeasuringPointPanelQueryResponse,
  TQueryKey extends QueryKey = GetMeasuringPointPanelQueryKey,
>(
  id: GetMeasuringPointPanelPathParams['id'],
  params?: GetMeasuringPointPanelQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetMeasuringPointPanelQueryResponse, GetMeasuringPointPanel422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getMeasuringPointPanelQueryKey(id, params)

  const query = useQuery({
    ...(getMeasuringPointPanelQueryOptions(id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetMeasuringPointPanel422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}