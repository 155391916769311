import { FormControl, Stack } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export const FieldLayout = ({
  titleSlot,
  labelSlot,
  inputSlot,
}: PropsWithChildren<{ titleSlot: ReactNode; labelSlot?: ReactNode; inputSlot: ReactNode }>) => {
  return (
    <Stack gap={2}>
      {titleSlot}
      <FormControl fullWidth>
        {labelSlot && labelSlot}
        {inputSlot}
      </FormControl>
    </Stack>
  );
};
