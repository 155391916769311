import { CustomerNestedSchema, EmailDocumentSchema } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGBadge } from '@mg/ui/src/components/MGBadge/MGBadge';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { palette } from '@mg/ui/src/styles';
import { Button, TableCell, TableRow } from '@mui/material';
import { PaperclipIcon, PlusIcon, TriangleAlertIcon } from 'lucide-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../../i18n';
import routes from '../../../../routes';
import { StepWithTable } from './StepWithTable';

type CustomerGroup = {
  customer: CustomerNestedSchema;
  documents: EmailDocumentSchema[];
};

export const CustomerStep = ({
  documents,
  onContinue,
}: {
  documents: EmailDocumentSchema[];
  onContinue: (documentsToSend: EmailDocumentSchema[]) => void;
}) => {
  const { t } = useTranslation('documents');

  const groups = useMemo(
    () =>
      documents.reduce<CustomerGroup[]>((acc, doc) => {
        if (!doc.customer?.id) return acc;

        let array = acc.find((group) => group.customer.id === doc.customer?.id);
        if (!array) {
          array = { customer: doc.customer, documents: [] };
          acc.push(array);
        }
        array.documents.push(doc);

        return acc;
      }, []),
    [documents],
  );

  const numMissingEmails = groups.filter((group) => !group.customer.email).length;
  const sendableDocuments = documents.filter((doc) => doc.customer?.email);
  const numSendableDocuments = sendableDocuments.length;

  return (
    <StepWithTable
      alert={
        (numMissingEmails > 0 && (
          <MGAlert
            icon={<TriangleAlertIcon />}
            severity="warning"
            title={t('bulk_email_modal_customer_step_missing_email_title', { numMissingEmails })}
            subtitle={t('bulk_email_modal_customer_step_missing_email_subtitle')}
          />
        )) ||
        (numSendableDocuments === 0 && (
          <MGAlert
            icon={<TriangleAlertIcon />}
            severity="warning"
            title={t('bulk_email_modal_customer_step_no_documents_to_send_title')}
            subtitle={t('bulk_email_modal_customer_step_no_documents_to_send_subtitle')}
          />
        ))
      }
      buttons={
        <Button
          variant="contained"
          disabled={numSendableDocuments === 0}
          onClick={() => onContinue(sendableDocuments)}
        >
          {numSendableDocuments == 1
            ? t('bulk_email_modal_customer_step_single_document_continue_button')
            : t('bulk_email_modal_customer_step_multiple_documents_continue_button', {
                numSendableDocuments,
              })}
        </Button>
      }
      tableTitle={
        <>
          {t('bulk_email_modal_customer_step_table_title')}{' '}
          <MGBadge sx={{ marginLeft: 0.5 }}>{groups.length - numMissingEmails}</MGBadge>
        </>
      }
      rows={groups.map(({ customer, documents }) => (
        <Row key={customer.id} customer={customer} documents={documents} />
      ))}
    />
  );
};

const Row = ({ customer, documents }) => {
  const { t } = useTranslation('documents');

  return (
    <TableRow>
      <TableCell>
        <MGCustomerWithAvatar customer={customer} />
      </TableCell>
      <TableCell>
        {customer.email ? (
          customer.email
        ) : (
          <Button
            variant={'contained'}
            color={'white'}
            endIcon={<PlusIcon />}
            component={Link}
            to={routes.customer.edit(customer.id)}
            target="_blank"
          >
            {t('bulk_email_modal_customer_step_add_email')}
          </Button>
        )}
      </TableCell>
      <TableCell>
        <PaperclipIcon size={18} color={palette.gray.main} />{' '}
        {documents.length == 1
          ? t('bulk_email_modal_customer_step_one_document')
          : t('bulk_email_modal_customer_step_multiple_documents', { count: documents.length })}
      </TableCell>
    </TableRow>
  );
};
