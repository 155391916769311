import { Button } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button/Button';
import { MGLoader } from '../MGLoader/MGLoader';

export interface ButtonProps extends MuiButtonProps {
  loading: boolean;
}

export const MGLoaderButton = ({ children, loading = false, startIcon, ...rest }: ButtonProps) => (
  <Button startIcon={loading ? <MGLoader size="25px" /> : startIcon} disabled={loading} {...rest}>
    {children}
  </Button>
);
