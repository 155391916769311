import { LevyStructureSchema, useGetLevyStructure } from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { presentEuroCentPerKilowattHour } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles/palette';
import { SxProps, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { Cable, Fan, Flame, Plug, Scale, Sun, ToggleRight, Waypoints } from 'lucide-react';
import * as React from 'react';
import { createElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { LevyStructureLeviesRowActionMenu } from './LevyStructureLevies.RowActionMenu';

type Props = {
  levyStructureIdProp?: string;
  hideActions?: boolean;
  datagridSx?: SxProps;
  datagridProps?: Partial<DataGridProProps> & React.RefAttributes<HTMLDivElement>;
  levyStructure?: LevyStructureSchema;
};
export const LevyStructureLeviesTable = ({
  hideActions,
  datagridProps,
  datagridSx,
  levyStructure,
}: Props) => {
  const { t, tString } = useTranslation('levies');
  const { id: levyStructureId } = useParams();

  const { isLoading, isError, data } = useGetLevyStructure(parseInt(levyStructureId || ''), {
    query: { enabled: !levyStructure },
  });

  const mapTypeToIcon = {
    STROMSTEUER: Plug,
    EEG_UMLAGE: Sun,
    KONZESSIONSABGABE: Waypoints,
    KWK_UMLAGE: Flame,
    PAR_19_STROMNEV_UMLAGE: Scale,
    AUFSCHLAG_FUER_BESONDERE_NETZNUTZUNG: Scale,
    OFFSHORE_NETZUMLAGE: Fan,
    UMLAGE_ABSCHALTBARE_LASTEN: ToggleRight,
    NETZENTGELTE: Cable,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
    },
    {
      field: 'type',
      headerName: tString('header_type'),
      renderCell: (params) => {
        const mappedIcon = mapTypeToIcon[params.value];

        return (
          <Typography fontSize={'small'}>
            {createElement(mappedIcon, {
              size: 18,
              color: palette.primary.main,
              style: { marginRight: '10px' },
            })}{' '}
            {t(params.value)}
          </Typography>
        );
      },
      flex: 0.4,
    },
    {
      field: 'costs_per_kwh',
      headerName: tString('header_costs'),
      flex: 0.2,
      valueGetter: (value) => presentEuroCentPerKilowattHour(value, { fractionDigits: 4 }),
    },
    {
      field: 'assessment_basis',
      headerName: tString('header_assessment_basis'),
      flex: 0.3,
      valueGetter: (value) => t(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 20,
      renderCell: (params) => {
        return (
          <LevyStructureLeviesRowActionMenu
            levyId={params.row.id}
            levyStructureId={data?.id as number}
            isDeletable={params.row.is_deletable}
          />
        );
      },
    },
  ];

  return (
    <MGCard isLoading={isLoading} isError={!levyStructure && isError} noData={!levyStructure && !data}>
      <DataGridPro
        autoHeight
        disableColumnMenu
        disableColumnResize
        isCellEditable={() => false}
        isRowSelectable={() => false}
        hideFooter
        rows={levyStructure?.levies || data?.levies || []}
        loading={false}
        columnVisibilityModel={{ id: false, actions: !hideActions }}
        rowHeight={70}
        {...datagridProps}
        sx={{
          '.MuiDataGrid-row': { paddingY: '0px' },
          '.MuiDataGrid-filler': { height: '0px' },
          ...datagridSx,
        }}
        columns={columns}
      />
    </MGCard>
  );
};
