import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { MGCard, MGCardContent, MGCardHeader, MGCardVerticalListLayout } from '@mg/ui/src/components/MGCard';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { House } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from '../../../../../i18n';

type Props = {
  isLoading: boolean;
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsDataAddressCard = ({ isLoading, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const [selectedType, setSelectedType] = useState<'address' | 'postal_address'>('address');

  const AddressSwitchButton = () => {
    return (
      <ToggleButtonGroup
        size={'small'}
        value={selectedType}
        exclusive
        onChange={(_, value) => setSelectedType(value)}
        sx={{ height: '30px' }}
      >
        <ToggleButton value={'address'}>{t('switch_button_address')}</ToggleButton>
        <ToggleButton value={'postal_address'}>{t('switch_button_postal_address')}</ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const address = {
    name: customer?.display_name,
    street: `${customer?.address?.street} ${customer?.address?.street_number}`,
    zipCode: customer?.address?.zip_code,
    city: customer?.address?.city,
  };

  const postalAddress = {
    name: customer?.postal_address?.display_name,
    street: `${customer?.postal_address?.address?.street} ${customer?.postal_address?.address?.street_number}`,
    zipCode: customer?.postal_address?.address?.zip_code,
    city: customer?.postal_address?.address?.city,
  };

  const selectedAddress = selectedType === 'address' ? address : postalAddress;

  return (
    <MGCard isLoading={isLoading}>
      <MGCardHeader
        leftHeader={<House />}
        rightHeader={customer?.postal_address && <AddressSwitchButton />}
      />
      <MGCardContent>
        <MGCardVerticalListLayout>
          <MGCardVerticalListLayout.Item label={t('data_card_name')}>
            {selectedAddress.name}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label={t('data_card_street')}>
            {selectedAddress.street}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label={t('data_card_zip_code')}>
            {selectedAddress.zipCode}
          </MGCardVerticalListLayout.Item>
          <MGCardVerticalListLayout.Item label={t('data_card_city')}>
            {selectedAddress.city}
          </MGCardVerticalListLayout.Item>
        </MGCardVerticalListLayout>
      </MGCardContent>
    </MGCard>
  );
};
