import { CustomerSchema } from '@mg/api-wrappers/src/api-internal';
import { Stack } from '@mui/material';

import { CustomerDetailsContractsPaymentPlans } from './CustomerDetails.Contracts.PaymentPlans';
import { CustomerDetailsContractsPlans } from './CustomerDetails.Contracts.Plans';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsContracts = ({ customer }: Props) => {
  return (
    <Stack direction={'row'} spacing={2} paddingBottom={'50px'} sx={{ height: '600px' }}>
      <Stack flex={1} spacing={2} height={'100%'}>
        <CustomerDetailsContractsPlans customer={customer} />
      </Stack>

      <Stack flex={1} spacing={2} height={'100%'}>
        <CustomerDetailsContractsPaymentPlans customer={customer} />
      </Stack>
    </Stack>
  );
};
