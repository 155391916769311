import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { CountPaymentPlansQueryResponse, CountPaymentPlansQueryParams, CountPaymentPlans422 } from '../types/CountPaymentPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countPaymentPlansQueryKey = (params: CountPaymentPlansQueryParams) =>
  [{ url: '/api-internal/payment_plans/count' }, ...(params ? [params] : [])] as const

export type CountPaymentPlansQueryKey = ReturnType<typeof countPaymentPlansQueryKey>

/**
 * @summary Count Payment Plans
 * {@link /api-internal/payment_plans/count}
 */
async function countPaymentPlans(params: CountPaymentPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<CountPaymentPlansQueryResponse, CountPaymentPlans422, unknown>({
    method: 'GET',
    url: `/api-internal/payment_plans/count`,
    params,
    ...config,
  })
  return res.data
}

export function countPaymentPlansQueryOptions(params: CountPaymentPlansQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = countPaymentPlansQueryKey(params)
  return queryOptions<CountPaymentPlansQueryResponse, CountPaymentPlans422, CountPaymentPlansQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countPaymentPlans(params, config)
    },
  })
}

/**
 * @summary Count Payment Plans
 * {@link /api-internal/payment_plans/count}
 */
export function useCountPaymentPlans<
  TData = CountPaymentPlansQueryResponse,
  TQueryData = CountPaymentPlansQueryResponse,
  TQueryKey extends QueryKey = CountPaymentPlansQueryKey,
>(
  params: CountPaymentPlansQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountPaymentPlansQueryResponse, CountPaymentPlans422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countPaymentPlansQueryKey(params)

  const query = useQuery({
    ...(countPaymentPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CountPaymentPlans422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}