import { palette } from '@mg/ui/src/styles';
import { Typography } from '@mui/material';
import { WifiOff } from 'lucide-react';
import { cloneElement, PropsWithChildren, ReactElement } from 'react';

type ErrorIndicatorProps = PropsWithChildren & { icon?: ReactElement };

export const ErrorIndicator = ({ children, icon }: ErrorIndicatorProps) => {
  const iconProps = { size: 36, color: palette.text.gray };

  return (
    <>
      {icon ? cloneElement(icon, iconProps) : <WifiOff {...iconProps} />}
      <Typography maxWidth={250} variant="small" color={palette.text.gray} textAlign={'center'}>
        {children}
      </Typography>
    </>
  );
};
