import { MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { Grid } from '@mui/material';
import { LastReading } from './LastReading';

type Props = {
  measuringPoint: MeasuringPointSchema;
};
export const LastReadings = ({ measuringPoint: measuring_point }: Props) => {
  return (
    <>
      {measuring_point.obis_codes.map((obis_code) => (
        <Grid key={obis_code} item xs={12} display={'flex'} alignItems={'center'}>
          <LastReading measuring_point={measuring_point} obis_code={obis_code} />
        </Grid>
      ))}
    </>
  );
};
