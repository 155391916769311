import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { BulkMarkAsSentMutationRequest, BulkMarkAsSentMutationResponse, BulkMarkAsSent422 } from '../types/BulkMarkAsSent.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const bulkMarkAsSentMutationKey = () => [{ url: '/api-internal/document_email/bulk_mark_as_sent' }] as const

export type BulkMarkAsSentMutationKey = ReturnType<typeof bulkMarkAsSentMutationKey>

/**
 * @summary Bulk Mark As Sent
 * {@link /api-internal/document_email/bulk_mark_as_sent}
 */
async function bulkMarkAsSent(data: BulkMarkAsSentMutationRequest, config: Partial<RequestConfig<BulkMarkAsSentMutationRequest>> = {}) {
  const res = await client<BulkMarkAsSentMutationResponse, BulkMarkAsSent422, BulkMarkAsSentMutationRequest>({
    method: 'POST',
    url: `/api-internal/document_email/bulk_mark_as_sent`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Bulk Mark As Sent
 * {@link /api-internal/document_email/bulk_mark_as_sent}
 */
export function useBulkMarkAsSent(
  options: {
    mutation?: UseMutationOptions<BulkMarkAsSentMutationResponse, BulkMarkAsSent422, { data: BulkMarkAsSentMutationRequest }>
    client?: Partial<RequestConfig<BulkMarkAsSentMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkMarkAsSentMutationKey()

  return useMutation<BulkMarkAsSentMutationResponse, BulkMarkAsSent422, { data: BulkMarkAsSentMutationRequest }>({
    mutationFn: async ({ data }) => {
      return bulkMarkAsSent(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}