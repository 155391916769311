import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { measuringPointPanelUpdateSchemaSchema } from './measuringPointPanelUpdateSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const updateMeasuringPointPanelPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const updateMeasuringPointPanel200Schema = z.lazy(() => measuringPointPanelNestedSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const updateMeasuringPointPanel422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const updateMeasuringPointPanelMutationRequestSchema = z.lazy(() => measuringPointPanelUpdateSchemaSchema)

export const updateMeasuringPointPanelMutationResponseSchema = z.lazy(() => updateMeasuringPointPanel200Schema)