import { BillingPreflightOut } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { FileCheck2, TriangleAlert } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';

export const BillingDetailsPerCustomerAlert = ({
  isBillable,
  errors,
}: {
  isBillable: boolean;
  errors: BillingPreflightOut['errors'];
}) => {
  const { t } = useTranslation('billing');
  if (isBillable) {
    return (
      <MGAlert
        icon={<FileCheck2 />}
        severity={'success'}
        title={t('details_per_customer_data_complete_alert_title')}
        subtitle={t('details_per_customer_data_complete_alert_subtitle')}
      />
    );
  }

  return (
    <MGAlert
      icon={<TriangleAlert />}
      severity={'error'}
      title={t('details_per_customer_missing_data_alert_title')}
      subtitle={
        <>
          <p>{t('details_per_customer_missing_data_alert_subtitle')}</p>
          {errors.length > 0 && (
            <ul>
              {errors.map((error) => (
                <li>{error.message}</li>
              ))}
            </ul>
          )}
        </>
      }
    />
  );
};
