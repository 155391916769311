import { pagedPaymentPlanSchemaSchema } from './pagedPaymentPlanSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listPaymentPlansQueryParamsSchema = z.object({
  include_customer: z.boolean().default(false),
  include_pdf: z.boolean().default(false),
  document_type: z.literal('payment_plan').default('payment_plan'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  start_date_from: z.union([z.string(), z.null()]).optional(),
  start_date_to: z.union([z.string(), z.null()]).optional(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listPaymentPlans200Schema = z.lazy(() => pagedPaymentPlanSchemaSchema)

/**
 * @description Unprocessable Entity
 */
export const listPaymentPlans422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listPaymentPlansQueryResponseSchema = z.lazy(() => listPaymentPlans200Schema)