import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const planChangeDocumentSchemaSchema = z.object({
  created_at: z.union([z.string().datetime(), z.null()]).optional(),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  issue_date: z.string().date().describe('The date when the plan change document was issued').optional(),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
  project: z.union([z.number().int(), z.null()]).optional(),
  subscription_plan: z.number().int(),
  updated_at: z.union([z.string().datetime(), z.null()]).optional(),
  valid_from: z.string().datetime(),
})