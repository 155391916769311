import { Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

type StepWithTableProps = PropsWithChildren<{
  alert: ReactNode;
  buttons: ReactNode;
  tableTitle: ReactNode;
  rows: ReactNode;
}>;
export const StepWithTable = ({ alert, buttons, tableTitle, rows }: StepWithTableProps) => {
  return (
    <Stack gap={4} flex={1} minHeight={0}>
      {alert}

      <Stack gap={2} flex={1} minHeight={0}>
        <Typography>{tableTitle}</Typography>

        <Paper sx={{ overflowY: 'scroll' }}>
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Stack>

      <Stack direction="row" justifyContent="end" gap={2}>
        {buttons}
      </Stack>
    </Stack>
  );
};
