import { MeasuringPointSchema, useListMeasuringPoints } from '@mg/api-wrappers/src/api-internal';
import { presentDateTime } from '@mg/ui/src/presenters/date.ts';
import { Box, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { LastReadings } from '../../features/metering/LastReadings/LastReadings';
import { CreateMeasuringPointModal } from '../../modals/CreateMeasuringPointModal';
import { MetergridCard } from '../../shared/MetergridCard';
import { MeteringDetailsMeasuringPointListActionMenu } from './MeteringDetails.MeasuringPointList.ActionMenu';

export const MeteringDetailsMeasuringPointList = ({ panelId }) => {
  const { t, tString } = useTranslation('meteringDetails');

  const projectFilter = useSelectedProjectsFilter();
  const { data, isLoading, isError } = useListMeasuringPoints({
    panel_id: panelId,
    ...projectFilter,
  });

  const columns: GridColDef<MeasuringPointSchema>[] = [
    {
      field: 'serial',
      headerName: tString('meter_serial'),
      flex: 1,
    },
    {
      field: 'last_reading',
      headerName: tString('last_reading'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid container display={'flex'} alignItems={'center'} height={'100%'}>
            <LastReadings measuringPoint={params.row} />
          </Grid>
        );
      },
    },
    {
      field: 'last_reading.timestamp',
      headerName: tString('last_reading_at'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (!params.row.last_reading) throw new Error('No last reading included');

        if (!params.row.last_reading.length) {
          return '-';
        }
        return (
          <Grid container display={'flex'} alignItems={'center'} height={'100%'}>
            {params.row.last_reading.map((item) => (
              <Grid key={item.obis_code} item xs={12} display={'flex'} alignItems={'center'}>
                <Typography variant={'small'} sx={{ marginLeft: '10px' }}>
                  {presentDateTime(item.timestamp)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => <MeteringDetailsMeasuringPointListActionMenu meterId={params.row.id} />,
    },
  ];

  return (
    <Box>
      <Grid container paddingBottom={'20px'}>
        <Grid item xs={6} display={'flex'} alignItems={'center'}>
          <Typography>{t('meter')}</Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <CreateMeasuringPointModal panelId={panelId} />
        </Grid>
      </Grid>
      <MetergridCard
        isLoading={isLoading}
        isError={isError}
        noData={!data?.length}
        style={{ height: '300px' }}
      >
        <DataGrid
          disableColumnMenu
          disableColumnResize
          isCellEditable={() => false}
          isRowSelectable={() => false}
          hideFooter
          rows={data || []}
          loading={false}
          sx={{
            minHeight: '300px',
            '.MuiDataGrid-row': { paddingY: '0px' },
            '.MuiDataGrid-filler': { height: '0px' },
          }}
          columns={columns}
        />
      </MetergridCard>
    </Box>
  );
};
