import { CustomerSchema, useGetCustomer } from '@mg/api-wrappers/src/api-internal';
import { MGCustomerStatusChip } from '@mg/ui/src/components/MGCustomerStatusChip';
import { palette } from '@mg/ui/src/styles/palette';
import { Avatar, Card, Skeleton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { CustomerDetailsAccountingActionMenu } from './Pages/Accounting/CustomerDetails.Accounting.ActionMenu';
import { CustomerDetailsDataActionMenu } from './Pages/Data/CustomerDetails.Data.ActionMenu';
import { CustomerDetailsMeteringActionMenu } from './Pages/Metering/CustomerDetails.Metering.ActionMenu';

type Props = {
  currentTab?: string;
  hideActions?: boolean;
  customer?: CustomerSchema;
};
export const CustomerDetailsTitleCard = ({ currentTab, hideActions, customer }: Props) => {
  const { t } = useTranslation('customerDetails');
  const params = useParams();

  const customerQuery = useGetCustomer(
    parseInt(params.id!),
    {
      include_measuring_point_panel: true,
      include_subscription: true,
    },
    { query: { enabled: !customer } },
  );

  const customerOverride = customer || customerQuery.data;

  const renderActionMenu = () => {
    if (hideActions) return <></>;
    switch (currentTab) {
      case 'data':
        return <CustomerDetailsDataActionMenu customer={customerOverride} />;

      case 'metering':
        return <CustomerDetailsMeteringActionMenu customer={customerOverride} />;

      case 'transactions':
        return <CustomerDetailsAccountingActionMenu customer={customerOverride} />;

      default:
        return <></>;
    }
  };

  return (
    <Card sx={{ height: '100px', padding: '20px' }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        height={'100%'}
        width={'100%'}
        alignItems={'center'}
      >
        <Stack direction={'row'} spacing={3} alignItems={'center'}>
          <Avatar
            sx={{
              width: '58px',
              height: '58px',
              backgroundColor: palette.primary.light,
              color: palette.primary.main,
            }}
          />
          <Stack spacing={1}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant={'h4'}>
                {customerQuery.isLoading ? (
                  <Skeleton width={'300px'} height={'30px'} />
                ) : (
                  customerOverride?.display_name
                )}
              </Typography>
              <MGCustomerStatusChip status={customerOverride?.status} />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Typography variant={'extraSmall'}>
                {t('title_customer_number')}: {customerOverride?.id}
              </Typography>
              <Typography variant={'extraSmall'}>
                {t('title_contract_number')}: {customerOverride?.subscription?.id}
              </Typography>
              <Typography variant={'extraSmall'}>
                {t('title_panel_name')}: {customerOverride?.measuring_point_panel?.name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {renderActionMenu()}
      </Stack>
    </Card>
  );
};
