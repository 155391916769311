import { Chip } from '@mui/material';

export interface MGCustomerStatusChipProps {
  status?: 'active' | 'inactive' | 'future' | 'cancelled' | 'terminated' | null;
}
export const MGCustomerStatusChip = ({ status }: MGCustomerStatusChipProps) => {
  if (!status) {
    return null;
  }

  const statusColorMap = {
    active: 'success' as const,
    inactive: 'error' as const,
    future: 'primary' as const,
    cancelled: 'warning' as const,
    terminated: 'error' as const,
  };

  // TODO: Add shared i18n
  const statusTranslationMap = {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    future: 'Zukünftig',
    cancelled: 'Gekündigt',
    terminated: 'Inaktiv',
  };

  return (
    <Chip
      color={statusColorMap[status]}
      sx={{ borderRadius: '8px', width: '85px' }}
      variant={'soft'}
      label={statusTranslationMap[status]}
      size={'small'}
    />
  );
};
