import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { withController } from './with-controller';

export const TextField = withController<MuiTextFieldProps>(
  MuiTextField,
  ({ field, fieldState }, propsOnWrappedComponent) => {
    return {
      value: field.value,
      onChange: field.onChange,
      disabled: field.disabled,
      inputRef: field.ref,
      name: field.name,

      error: !!fieldState.error,
      helperText: fieldState.error?.message || propsOnWrappedComponent.helperText,
    };
  },
);
