import { palette } from '@mg/ui/src/styles';
import { ReactNode } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { hooklessT } from '../../i18n';
import '../../styles/sweetalert.scss';

type FireSweetalertOptions = Omit<SweetAlertOptions, 'title' | 'html' | 'text' | 'iconHtml'> & {
  title?: ReactNode;
  html?: ReactNode;
  text?: ReactNode;
  iconHtml?: ReactNode;
};

export const fireSweetalert = ({ confirmButtonColor = 'primary', ...props }: FireSweetalertOptions) => {
  const SwalWithReactContent = withReactContent(Swal);

  return SwalWithReactContent.fire({
    cancelButtonText: hooklessT('shared', 'cancel'),
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonColor: palette[confirmButtonColor].main,
    ...props,
  } as SweetAlertOptions);
};
