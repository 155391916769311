import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type {
  UpdateLetterMutationRequest,
  UpdateLetterMutationResponse,
  UpdateLetterPathParams,
  UpdateLetterQueryParams,
  UpdateLetter400,
  UpdateLetter422,
} from '../types/UpdateLetter.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const updateLetterMutationKey = () => [{ url: '/api-internal/letters/{id}/' }] as const

export type UpdateLetterMutationKey = ReturnType<typeof updateLetterMutationKey>

/**
 * @summary Update Letter
 * {@link /api-internal/letters/:id/}
 */
async function updateLetter(
  id: UpdateLetterPathParams['id'],
  data?: UpdateLetterMutationRequest,
  params?: UpdateLetterQueryParams,
  config: Partial<RequestConfig<UpdateLetterMutationRequest>> = {},
) {
  const res = await client<UpdateLetterMutationResponse, UpdateLetter400 | UpdateLetter422, UpdateLetterMutationRequest>({
    method: 'PATCH',
    url: `/api-internal/letters/${id}/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Update Letter
 * {@link /api-internal/letters/:id/}
 */
export function useUpdateLetter(
  options: {
    mutation?: UseMutationOptions<
      UpdateLetterMutationResponse,
      UpdateLetter400 | UpdateLetter422,
      { id: UpdateLetterPathParams['id']; data?: UpdateLetterMutationRequest; params?: UpdateLetterQueryParams }
    >
    client?: Partial<RequestConfig<UpdateLetterMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateLetterMutationKey()

  return useMutation<
    UpdateLetterMutationResponse,
    UpdateLetter400 | UpdateLetter422,
    { id: UpdateLetterPathParams['id']; data?: UpdateLetterMutationRequest; params?: UpdateLetterQueryParams }
  >({
    mutationFn: async ({ id, data, params }) => {
      return updateLetter(id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}