import { DocumentsData } from '../../../Documents/Documents.Data';

type Props = {
  customerId: number;
};
export const CustomerDetailsDocuments = ({ customerId }: Props) => {
  /*
   * Placeholder Component to have consistent architecture and the possibility to add funcitonality to the Details Tab
   * */

  return <DocumentsData height={'calc(100vh - 410px)'} customerId={customerId} />;
};
