import { useGetLevyStructure } from '@mg/api-wrappers/src/api-internal';
import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { EditLevyStructureModal } from '../../modals/EditLevyStructureModal';
import { LevyStructureLeviesTable } from './LevyStructureLevies.Table';

export const LevyStructureLevies = () => {
  const { t } = useTranslation('levies');
  const { id: levyStructureId } = useParams();
  const { data } = useGetLevyStructure(parseInt(levyStructureId || ''));

  const levyYear = data ? new Date(data.valid_from).getFullYear() : '';
  return (
    <>
      <MGPageLayout
        title={t('page_title_details', { year: levyYear })}
        subtitle={t('page_subtitle_details')}
        rightSection={
          <>
            <EditLevyStructureModal levyStructureId={data?.id} disabled={!data?.is_editable} />
          </>
        }
      >
        <LevyStructureLeviesTable />
      </MGPageLayout>
    </>
  );
};
