import { palette } from '@mg/ui/src/styles';
import { Typography } from '@mui/material';
import { Settings2 } from 'lucide-react';
import { useTranslation } from '../../../../i18n';
import { ActionBar, ActionBarButton, ActionBarDivider } from '../DataGridCard.ActionBar';

export const ActionBarFilterView = ({ filteredItemCount, onFilterEdit, onFilterCancel, showEditFilter }) => {
  const { t } = useTranslation('actionBar');

  return (
    <ActionBar onCancel={() => onFilterCancel()}>
      <Typography variant="small" color={palette.primary.contrastText}>
        {filteredItemCount === 0 ? t('no_results') : `${filteredItemCount} ${t('filtered_results')}`}
      </Typography>

      {showEditFilter && (
        <>
          <ActionBarDivider />

          <ActionBarButton onClick={onFilterEdit}>
            <Typography variant={'small'}>{t('update_filter')}</Typography>
            <Settings2 size={18} style={{ marginLeft: '10px' }} />
          </ActionBarButton>
        </>
      )}
    </ActionBar>
  );
};
