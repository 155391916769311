import { Box, IconButton, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import { X } from 'lucide-react';
import { Control, Controller } from 'react-hook-form';
import { FieldHeading } from '../layout/FieldHeading';
import { FieldLayout } from '../layout/FieldLayout';
import { BaseFieldProps } from '../types/BaseFieldProps';

export type DateRangeFieldProps = BaseFieldProps & {
  name: string;

  control: Control;

  inputProps?: Omit<DateRangePickerProps<never>, 'value' | 'onChange' | 'inputRef' | 'name' | 'label'> & {
    clearable?: boolean;
    onClear?: () => void;
  };
};

export const DateRangeField = ({ name, control, heading, label, inputProps }: DateRangeFieldProps) => {
  const { clearable, onClear, ...dateRangePickerProps } = inputProps ?? {};

  return (
    <FieldLayout
      titleSlot={<FieldHeading>{heading}</FieldHeading>}
      inputSlot={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Box flex={1}>
                <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    value={field.value}
                    onChange={field.onChange}
                    disabled={field.disabled}
                    inputRef={field.ref}
                    name={field.name}
                    label={label}
                    localeText={{ start: 'Beginn', end: 'Ende' }}
                    {...dateRangePickerProps}
                  />
                </LocalizationProvider>
              </Box>
              {clearable && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    field.onChange([null, null]);
                    onClear?.();
                  }}
                  sx={{ height: 40, width: 40 }}
                >
                  <X size={24} />
                </IconButton>
              )}
            </Stack>
          )}
        />
      }
    />
  );
};
